import React from 'react';
import { Row, Col, Card, Image } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import {
  GoogleMap,
  RequestLoading,
  RequestResult,
  ShowMoreText,
} from '../../../components';
import { useAxiosQuery } from '../../../hooks';
import DefaultProfileImage from '../../../assets/images/default-profile-img.jpg';
import PlaceHolderImg from '../../../assets/images/placeholder.png';

const onImageError = (e) => {
  e.target.src = PlaceHolderImg;
};

function Info() {
  const { id } = useParams();

  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/realestate/list',
    preventFetch: !id,
    params: {
      id,
    },
  });

  return (
    <>
      <RequestLoading loading={apiLoading} size="lg" margin="5" />
      <RequestResult type="error" message={apiError} />

      {!apiLoading && !apiError && apiData?.length > 0 && (
        <Row>
          <Col lg="7" className="mb-3 mb-lg-5">
            <Card className="h-100">
              <Card.Header className="card-header-content-between">
                <Card.Title
                  bsPrefix="card-header-title"
                  as="h4"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.info" />
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col md className="mb-4 mb-md-0">
                    <div className="mb-4">
                      <Card.Subtitle>
                        <FormattedMessage id="app.common.address" />
                      </Card.Subtitle>
                      {apiData[0].address ? apiData[0]?.address : 'N/A'} {', '}
                      {apiData[0].zip ? apiData[0]?.zip : 'N/A'}
                      {', '}
                      {apiData[0].state ? apiData[0]?.state : 'N/A'}
                    </div>
                    <div className="mb-4">
                      <Card.Subtitle>
                        <FormattedMessage id="app.common.phone" />
                      </Card.Subtitle>
                      {apiData[0]?.telephone ? (
                        <a href={`tel:${apiData[0]?.telephone}`}>
                          {apiData[0]?.telephone}
                        </a>
                      ) : (
                        'N/A'
                      )}
                    </div>
                    <div className="mb-4">
                      <Card.Subtitle>
                        <FormattedMessage id="app.common.fax" />
                      </Card.Subtitle>
                      {apiData[0]?.fax ? (
                        <a href={`tel:${apiData[0]?.fax}`}>{apiData[0]?.fax}</a>
                      ) : (
                        'N/A'
                      )}
                    </div>
                    <div className="mb-4">
                      <Card.Subtitle>
                        <FormattedMessage id="app.common.web" />
                      </Card.Subtitle>
                      {apiData[0]?.website ? (
                        <a href={`mailto:${apiData[0]?.website}`}>
                          {apiData[0]?.website}
                        </a>
                      ) : (
                        'N/A'
                      )}
                    </div>

                    <div className="mb-4">
                      <Card.Subtitle>
                        <FormattedMessage id="app.common.description" />
                      </Card.Subtitle>
                      {apiData[0]?.description ? (
                        <ShowMoreText
                          text={apiData[0].description}
                          maxLine={3}
                          basedOn="words"
                        />
                      ) : (
                        <span>N/A</span>
                      )}
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col lg="5">
            <Row>
              <Col xs="12" className="mb-3 mb-lg-5">
                <Card className="h-100">
                  <Card.Header className="card-header-content-between">
                    <Card.Title
                      bsPrefix="card-header-title"
                      as="h4"
                      className="d-flex justify-content-between"
                    >
                      <FormattedMessage id="app.common.realtor" />
                    </Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <div className="d-flex">
                      <div className="avatar avatar-lg avatar-circle">
                        <Image
                          src={apiData[0].photo || DefaultProfileImage}
                          onError={onImageError}
                          // style={{ height: 50, width: 50, objectFit: 'cover' }}
                          className="avatar-img"
                        />
                      </div>
                      <div className="d-flex flex-column ms-2">
                        <h4>{apiData[0].title}</h4>
                        <h5>{apiData[0].licence_number}</h5>
                        <span>
                          {apiData[0].zip} / {apiData[0].state}
                        </span>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs="12" className="mb-3 mb-lg-5">
                <Card className="h-100">
                  <Card.Header className="card-header-content-between">
                    <Card.Title
                      bsPrefix="card-header-title"
                      as="h4"
                      className="d-flex justify-content-between"
                    >
                      <FormattedMessage id="app.common.location" />
                    </Card.Title>
                  </Card.Header>

                  <Card.Body>
                    <GoogleMap
                      initialZoom={12}
                      initialMarks={[
                        {
                          lat: Number(apiData[0]?.latitude),
                          lng: Number(apiData[0]?.longitude),
                        },
                      ]}
                      initialCenter={{
                        lat: Number(apiData[0]?.latitude),
                        lng: Number(apiData[0]?.longitude),
                      }}
                    />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
}

export default Info;
