import React from 'react';
import { Row, Col, Card, Image, ListGroup, Badge } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { GoogleMap, RequestLoading, RequestResult } from '../../../components';
import { useAxiosQuery } from '../../../hooks';

function Info() {
  const { id } = useParams();

  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: 'contacts/detail',
    preventFetch: !id,
    params: {
      id,
    },
  });

  const renderTitle = (key) => {
    if (key === 'lat') {
      return null;
    }
    return (
      <Col>
        <span className="d-block">
          <FormattedMessage id={`app.common.${key}`} />
        </span>
      </Col>
    );
  };

  const renderValue = (key, value, rowData) => {
    let nValue = (
      <Col xs="auto">
        <h5>{value}</h5>
      </Col>
    );

    switch (key) {
      case 'categories':
        nValue = (
          <Col xs="auto">
            {value.map((item) => (
              <Badge key={item.id} className="me-1 mb-1">
                {item.name}
              </Badge>
            ))}
          </Col>
        );
        break;
      case 'photo':
        nValue = (
          <Col xs="auto">
            <Image src={value} style={{ width: 100 }} />
          </Col>
        );
        break;
      case 'lat':
        nValue = (
          <Col>
            <GoogleMap
              initialMarks={[
                {
                  latitude: Number(value),
                  longitude: Number(rowData.lon),
                },
              ]}
              initialCenter={{
                lat: Number(value),
                lng: Number(rowData.lon),
              }}
            />
          </Col>
        );
        break;
      default:
        break;
    }

    return nValue;
  };

  const keysToIgnore = ['isFavorite', 'favCount', 'revCount', 'revAvg'];

  const renderItem = (key, value, rowData) => {
    if (keysToIgnore.includes(key) || key === 'longitude') {
      return null;
    }

    return (
      <ListGroup.Item key={key}>
        <div className="d-flex align-items-center">
          <div className="flex-grow-1 ms-2">
            <Row className="align-items-center">
              {renderTitle(key)}
              {!value || value === 0 ? (
                <Col xs="auto">
                  <FormattedMessage id="app.common.n/a" />
                </Col>
              ) : (
                renderValue(key, value, rowData)
              )}
            </Row>
          </div>
        </div>
      </ListGroup.Item>
    );
  };

  return (
    <>
      <RequestLoading loading={apiLoading} size="lg" margin="5" />
      <RequestResult type="error" message={apiError} />

      {!apiLoading && !apiError && apiData && Object.keys(apiData).length > 0 && (
        <Card>
          <Card.Header>title</Card.Header>
          <Card.Body>
            <ListGroup variant="flush" className="list-group-no-gutters">
              {Object.keys(apiData).map((key) =>
                renderItem(key, apiData[key], apiData)
              )}
            </ListGroup>
          </Card.Body>
        </Card>
      )}
    </>
  );
}

export default Info;
