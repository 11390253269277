import React, { useCallback, useRef } from 'react';
import {
  Card,
  Button,
  Badge,
  ButtonGroup,
  Dropdown,
  Image,
} from 'react-bootstrap';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Routes as AppRoutes } from '../../../../router/routeMapping';
import { useFormModal, useToast } from '../../../../hooks';
import { SmartTable } from '../../../../components';
import { yup } from '../../../../lib';
import Constants from '../../../../constants';
import PlaceHolderImg from '../../../../assets/images/placeholder.png';

const onImageError = (e) => {
  e.target.src = PlaceHolderImg;
};

const typeOptionLabel = ({ id }) => (
  <div className="d-flex">
    <Badge bg={`${Constants.Review.Types[id].color}`}>
      <i className={`${Constants.Review.Types[id].icon} fs-6 me-1`} />
      <FormattedMessage
        id={`app.common.${Constants.Review.Types[id].labelKey}`}
      />
    </Badge>
  </div>
);

const priorityOptionLabel = ({ id }) => (
  <div className="d-flex">
    <Badge
      bg={`soft-${Constants.Review.Priority[id].color} text-${Constants.Review.Priority[id].color}`}
    >
      <i className={`${Constants.Review.Priority[id].icon} fs-6 me-1`} />
      <FormattedMessage
        id={`app.common.${Constants.Review.Priority[id].labelKey}`}
      />
    </Badge>
  </div>
);
const eventTypesOptionLabel = ({ id }) => (
  <div className="d-flex">
    <Badge
      bg={`soft-${Constants.EventTypes.Types[id].color} text-${Constants.EventTypes.Types[id].color}`}
    >
      <i className={`${Constants.EventTypes.Types[id].icon} fs-6 me-1`} />
      <FormattedMessage
        id={`app.common.${Constants.EventTypes.Types[id].labelKey}`}
      />
    </Badge>
  </div>
);

const GetAIScore = (v) => {
  if (v >= 0 && v <= 39) {
    return 'danger';
  }
  if (v >= 40 && v <= 59) {
    return 'warning';
  }
  if (v >= 60 && v <= 79) {
    return 'info';
  }
  return 'success';
};

function Shared() {
  const tableRef = useRef();
  const { form } = useFormModal();
  const { showToast } = useToast();

  const onActionRow = useCallback(
    async (id, type) => {
      const size = 'lg';
      const title = type;
      const confirmLabel = type;
      let requestUrl = '/reviews/insert';
      const requestParams = { RefID: id, RefTable: 'homes' };
      let fetchOnStart;
      let nFields;
      let message;

      switch (type) {
        case 'addEvent':
          requestUrl = '/calendar/insert';
          nFields = [
            {
              cols: [
                {
                  key: 'title',
                  apiKey: 'Title',
                  schema: yup.string().required(),
                },
              ],
            },
            {
              cols: [
                {
                  key: 'date',
                  apiKey: 'Date',
                  type: 'dateRangePicker',
                  options: {
                    controller: {
                      initialSettings: { timePicker: true },
                      formatter: (value) => {
                        if (value) {
                          return [
                            moment(value[0]).format(
                              Constants.DateFormats.APIWithTime
                            ),
                            moment(value[1]).format(
                              Constants.DateFormats.APIWithTime
                            ),
                          ];
                        }
                        return value;
                      },
                    },
                  },
                  schema: yup.array().min(2).nullable().required(),
                },
              ],
            },
            {
              cols: [
                {
                  key: 'description',
                  apiKey: 'Description',
                  type: 'textarea',
                  schema: yup.string().nullable(),
                },
              ],
            },
            {
              cols: [
                {
                  key: 'EventType',
                  apiKey: 'EventType',
                  type: 'react-select',
                  options: {
                    controller: {
                      props: {
                        options: Object.values(Constants.EventTypes.Types),
                        getOptionValue: (option) => option.apiId,
                        formatOptionLabel: eventTypesOptionLabel,
                        isMulti: false,
                        isSearchable: false,
                        isClearable: true,
                      },
                    },
                  },
                  schema: yup.string().nullable(),
                },
              ],
            },
          ];
          break;
        case 'addNote':
          requestUrl = '/reviews/insert';
          nFields = [
            {
              cols: [
                {
                  key: 'comment',
                  apiKey: 'Comment',
                  type: 'textarea',
                  schema: yup.string().required(),
                },
              ],
            },
            {
              cols: [
                {
                  key: 'type',
                  apiKey: 'Rating',
                  type: 'react-select',
                  options: {
                    controller: {
                      props: {
                        options: Object.values(Constants.Review.Types),
                        getOptionValue: (option) => option.apiId,
                        formatOptionLabel: typeOptionLabel,
                        isMulti: false,
                        isSearchable: false,
                        isClearable: true,
                      },
                    },
                  },
                  schema: yup.string().nullable(),
                },
              ],
            },
            {
              cols: [
                {
                  key: 'priority',
                  apiKey: 'Priority',
                  type: 'react-select',
                  options: {
                    controller: {
                      props: {
                        options: Object.values(Constants.Review.Priority),
                        getOptionValue: (option) => option.apiId,
                        formatOptionLabel: priorityOptionLabel,
                        isMulti: false,
                        isSearchable: false,
                        isClearable: true,
                      },
                    },
                  },
                  schema: yup.string().nullable(),
                },
              ],
            },
          ];
          break;

        case 'addToFavorites':
          requestUrl = '/favorites/insert';
          message = 'loading...';
          fetchOnStart = true;
          requestParams.is_favorite = 1;
          break;

        case 'removeFromFavorites':
          requestUrl = '/favorites/delete';
          message = 'loading...';
          fetchOnStart = true;
          requestParams.is_favorite = 0;
          break;

        default:
          break;
      }

      const isSuccess = await form({
        size,
        title,
        confirmLabel,
        requestUrl,
        requestParams,
        fields: nFields,
        message,
        fetchOnStart,
      });

      if (isSuccess) {
        tableRef.current.reload();

        if (type === 'addToFavorites' || type === 'removeFromFavorites') {
          showToast({
            type: 'success',
            autohide: true,
            title: 'x1',
            message: 'y2',
          });
        }
      }
    },
    [form, showToast]
  );

  const columns = [
    // ...

    {
      Header: <FormattedMessage id="app.common.image" />,
      accessor: 'photo',
      Cell: useCallback(
        ({
          cell: {
            value,
            row: { original },
          },
        }) => (
          <div className="position-relative">
            <Link
              className="stretched-link"
              to={`${AppRoutes.protected.LISTINGS_DETAIL.path.replace(
                ':id',
                original.id // Veri nesnesinin uygun alanını buraya ekleyin
              )}`}
            >
              <Image
                src={value || PlaceHolderImg}
                onError={onImageError}
                style={{ height: 50, width: 75, objectFit: 'cover' }}
              />
              {original.revCount > 0 && (
                <div className="position-absolute bottom-0 end-0 zi-1">
                  <Badge bg="danger" pill className="me-1 mb-1 fs-6">
                    {original.revCount}
                  </Badge>
                </div>
              )}
            </Link>
          </div>
        ),
        []
      ),
    },

    {
      Header: <FormattedMessage id="app.common.airate" />,
      accessor: 'AIRate',
      wrap: true,
      textAlign: 'end',
      Cell: useCallback(
        ({ cell: { value } }) => (
          <div className="text-end">
            <Badge
              bg={`soft-${GetAIScore(value)}`}
              text={`${GetAIScore(value)} fs-6`}
            >
              <i className="bi-robot me-1" />
              <FormattedNumber
                value={value}
                // eslint-disable-next-line react/style-prop-object
                minimumFractionDigits={0}
                maximumFractionDigits={0}
              />
            </Badge>
          </div>
        ),
        []
      ),
    },
    {
      Header: <FormattedMessage id="app.common.address" />,
      accessor: 'title',
      wrap: false,
      minWidth: '50%',
      Cell: useCallback(
        ({
          cell: {
            value,
            row: { original },
          },
        }) => (
          <>
            {original.isFavorite === 1 && (
              <i className="bi-heart-fill text-danger me-1" />
            )}
            {value}
          </>
        ),
        []
      ),
    },

    {
      Header: <FormattedMessage id="app.common.price" />,
      accessor: 'price',
      wrap: true,
      textAlign: 'end',
      Cell: useCallback(
        ({ cell: { value } }) => (
          <div className="text-end">
            <Badge bg="soft-info" className="text-info fs-6">
              <FormattedNumber
                value={value}
                // eslint-disable-next-line react/style-prop-object
                style="currency"
                currency="USD"
                minimumFractionDigits={0}
                maximumFractionDigits={0}
              />
            </Badge>
          </div>
        ),
        []
      ),
    },

    {
      Header: <FormattedMessage id="app.common.buildingType" />,
      accessor: 'BuildingType',
      wrap: true,
      Cell: useCallback(
        ({ cell: { value } }) => (
          <Badge
            bg={Constants.RealEstate.Types[value] ? '' : 'secondary'}
            style={{
              backgroundColor: Constants.RealEstate.Types[value]
                ? Constants.RealEstate.Types[value].color
                : undefined,
            }}
            className="fs-6"
          >
            {Constants.RealEstate.Types[value] ? (
              <FormattedMessage
                id={`${Constants.RealEstate.Types[value].labelKey}`}
              />
            ) : (
              value
            )}
          </Badge>
        ),
        []
      ),
    },
    {
      Header: <FormattedMessage id="app.common.features" />,
      accessor: 'bedrooms',
      wrap: false,
      minWidth: '35%',
      Cell: useCallback(
        ({
          cell: {
            row: { original },
          },
        }) => (
          <div className="d-flex">
            {original.bedrooms !== undefined &&
              original.bedrooms !== null &&
              original.bedrooms !== 0 && (
                <span className="me-1">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#icon_bed)">
                      <path
                        d="M1.25 19.5416V16.3666"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18.75 13.1833H1.25V16.3667H18.75V13.1833Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3.63333 9.16669H16.3667C16.9988 9.16669 17.605 9.41779 18.0519 9.86475C18.4989 10.3117 18.75 10.9179 18.75 11.55V13.1417H1.25V11.5917C1.24446 11.2752 1.30202 10.9608 1.41932 10.6668C1.53661 10.3728 1.71129 10.1052 1.93316 9.87942C2.15502 9.65367 2.41963 9.47438 2.71154 9.35201C3.00345 9.22964 3.31681 9.16664 3.63333 9.16669V9.16669Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18.75 19.5416V16.3666"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5.22503 6.02502H8.40836C8.61738 6.02502 8.82436 6.06619 9.01747 6.14618C9.21058 6.22617 9.38604 6.34341 9.53384 6.49121C9.68164 6.63901 9.79888 6.81448 9.87887 7.00759C9.95886 7.2007 10 7.40767 10 7.61669V9.16669H3.63336V7.61669C3.63336 7.19456 3.80105 6.78971 4.09955 6.49121C4.39805 6.19272 4.80289 6.02502 5.22503 6.02502Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11.5917 6.02502H14.775C15.1971 6.02502 15.602 6.19272 15.9005 6.49121C16.199 6.78971 16.3667 7.19456 16.3667 7.61669V9.16669H10V7.61669C10 7.19456 10.1677 6.78971 10.4662 6.49121C10.7647 6.19272 11.1695 6.02502 11.5917 6.02502Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16.3584 2.84167H3.63336V9.20834H16.3584V2.84167Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3.63336 0.458313V2.84165"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="∂round"
                      />
                      <path
                        d="M16.3666 0.458313V2.84165"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="icon_bed">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>{' '}
                  {original.bedrooms}
                </span>
              )}
            {original.bathrooms !== undefined &&
              original.bathrooms !== null &&
              original.bathrooms !== 0 && (
                <span className="me-1">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#icon_bath)">
                      <path
                        d="M2.01666 11.6H17.9833V14C17.9833 15.2708 17.4785 16.4896 16.5799 17.3882C15.6813 18.2868 14.4625 18.7916 13.1917 18.7916H6.80833C5.5375 18.7916 4.31872 18.2868 3.42011 17.3882C2.5215 16.4896 2.01666 15.2708 2.01666 14V11.6Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13.9917 11.6H10V15.5916H13.9917V11.6Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M19.5834 11.6H0.416687"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2.01666 11.7667V3.60834C2.01666 2.97403 2.26864 2.3657 2.71717 1.91718C3.16569 1.46865 3.77402 1.21667 4.40833 1.21667H5.24166C5.87018 1.22544 6.46998 1.48128 6.91132 1.92885C7.35266 2.37643 7.60006 2.97977 7.6 3.60834V4.44167"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M9.20003 4.40833H6.00836"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="icon_bath">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>{' '}
                  {original.bathrooms}
                </span>
              )}
            {original.totalSqFt !== undefined &&
              original.totalSqFt !== null &&
              original.totalSqFt !== 0 && (
                <span className="me-1">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#icon_sqft)">
                      <path
                        d="M1.25 3.63333L3.63333 1.25L6.025 3.63333"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16.3667 13.975L18.75 16.3666L16.3667 18.75"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18.75 16.3667H3.63333V1.25"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6.81668 1.25H18.75V13.1833"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16.3667 1.25L3.63333 13.975"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18.75 3.63336L6.02499 16.3667"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11.5917 1.25L3.63333 9.20833"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18.75 8.40833L10.7917 16.3667"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="icon_sqft">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>{' '}
                  <FormattedNumber
                    value={original.totalSqFt}
                    // eslint-disable-next-line react/style-prop-object

                    minimumFractionDigits={0}
                    maximumFractionDigits={0}
                  />{' '}
                  sqft
                </span>
              )}
          </div>
        ),
        []
      ),
    },
    {
      Header: <FormattedMessage id="app.common.daysOnQors" />,
      accessor: 'daysPassed',
      wrap: true,
    },

    {
      accessor: 'id',
      Cell: useCallback(
        ({
          cell: {
            value,
            row: { original },
          },
        }) => (
          <Dropdown align="end">
            <ButtonGroup>
              <Button
                variant="white"
                size="sm"
                as={Link}
                to={`${AppRoutes.protected.LISTINGS_DETAIL.path.replace(
                  ':id',
                  value
                )}`}
              >
                <i className="bi-eye me-1" />
                <FormattedMessage id="app.common.view" />
              </Button>
              <ButtonGroup>
                <Dropdown.Toggle
                  variant="white"
                  size="sm"
                  className="btn-icon dropdown-toggle-empty"
                />
              </ButtonGroup>
            </ButtonGroup>
            <Dropdown.Menu className="m-0" renderOnMount>
              <Dropdown.Header>
                <FormattedMessage id="app.common.actions" />
              </Dropdown.Header>
              {original.isFavorite === 0 ? (
                <Dropdown.Item
                  onClick={() => {
                    onActionRow(value, 'addToFavorites');
                  }}
                >
                  <i className="bi-heart-fill dropdown-item-icon" />
                  <span>
                    <FormattedMessage id="app.common.addToFavorites" />
                  </span>
                </Dropdown.Item>
              ) : (
                <Dropdown.Item
                  onClick={() => {
                    onActionRow(value, 'removeFromFavorites');
                  }}
                >
                  <i className="bi-heart dropdown-item-icon" />
                  <span>
                    <FormattedMessage id="app.common.removeFromFavorites" />
                  </span>
                </Dropdown.Item>
              )}
              <Dropdown.Item
                onClick={() => {
                  onActionRow(value, 'addEvent');
                }}
              >
                <i className="bi-calendar-plus dropdown-item-icon text-primary" />
                <span className="text-primary">
                  <FormattedMessage id="app.common.newEvent" />
                </span>
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  onActionRow(value, 'addNote');
                }}
              >
                <i className="bi-chat-right-text-fill dropdown-item-icon text-info" />
                <span className="text-info">
                  <FormattedMessage id="app.common.newNote" />
                </span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ),
        [onActionRow]
      ),
    },
  ];

  const tableRequestParams = {
    ai: 1,
    page: 1,
    quantity: 5,
    status: 'ACTIVE',
  };

  return (
    <Card className="h-100">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <Card.Title bsPrefix="card-header-title" as="h4">
          <FormattedMessage id="app.common.newListings" />
        </Card.Title>
        <Button
          as={Link}
          to={`${AppRoutes.protected.LISTINGS.path}`}
          variant="white"
          size="sm"
        >
          <FormattedMessage id="app.common.viewAll" />
        </Button>
      </Card.Header>

      <SmartTable
        ref={tableRef}
        columns={columns}
        requestUrl="/homes/list"
        requestParams={tableRequestParams}
        perPageValues={[4, 10, 15]}
        hoverable
      />
    </Card>
  );
}

export default Shared;
