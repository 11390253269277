// helper.jsx
export const getProgressVariant = (v) => {
  if (v >= 76 && v <= 100) {
    return 'danger';
  }
  if (v >= 50 && v <= 75) {
    return 'warning';
  }
  if (v >= 0 && v <= 49) {
    return 'info';
  }

  throw new Error(`Geçersiz değer: ${v}`);
};

export function getCompetitiveness(value) {
  const result = {
    text: '',
    color: '',
  };

  switch (value) {
    case 'Not Very Competitive':
      result.text =
        'Homes sit longer on the market with reduced buyer interest.';
      result.color = 'info';
      break;
    case 'Somewhat Competitive':
      result.text = 'Homes sell at or slightly below list prices.';
      result.color = 'warning';
      break;
    case 'Most Competitive':
      result.text =
        'Buyers vastly outnumber listings, leading to bidding wars.';
      result.color = 'danger';
      break;
    case 'Very Competitive':
      result.text = 'Homes selling quickly, often at listing prices.';
      result.color = 'danger';
      break;
    default:
      break;
  }

  return result;
}

export function getRiskColor(riskLevel) {
  switch (riskLevel) {
    case 'Extreme':
      return 'danger';
    case 'Major':
      return 'danger';
    case 'Minor':
      return 'warning';
    case 'Moderate':
      return 'success';
    case 'Severe':
      return 'danger';
    default:
      return 'info';
  }
}
