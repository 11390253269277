const BrandsVisitedByUSCitizens = [
  // A-E
  { name: '7-Eleven', abbreviation: '7-Eleven' },
  { name: 'Adidas', abbreviation: 'Adidas' },
  { name: 'Aldi', abbreviation: 'Aldi' },
  { name: 'Amazon', abbreviation: 'Amazon' },
  { name: 'Apple Store', abbreviation: 'Apple Store' },
  { name: 'Arby’s', abbreviation: 'Arby’s' },
  { name: 'AT&T', abbreviation: 'AT&T' },
  { name: 'AutoZone', abbreviation: 'AutoZone' },
  { name: 'Bank of America', abbreviation: 'BofA' },
  { name: 'Barnes & Noble', abbreviation: 'B&N' },
  { name: 'Best Buy', abbreviation: 'Best Buy' },
  { name: 'Burger King', abbreviation: 'Burger King' },
  { name: 'Chick-fil-A', abbreviation: 'Chick-fil-A' },
  { name: 'Chili’s', abbreviation: 'Chili’s' },
  { name: 'Chipotle', abbreviation: 'Chipotle' },
  { name: 'Coca-Cola', abbreviation: 'Coca-Cola' },
  { name: 'Costco', abbreviation: 'Costco' },
  { name: 'CVS Pharmacy', abbreviation: 'CVS' },
  { name: 'Dell', abbreviation: 'Dell' },
  { name: 'Denny’s', abbreviation: 'Denny’s' },
  { name: 'Dollar General', abbreviation: 'Dollar General' },
  { name: 'Dollar Tree', abbreviation: 'Dollar Tree' },
  { name: 'Domino’s Pizza', abbreviation: 'Domino’s' },
  { name: 'Dunkin', abbreviation: 'Dunkin' },
  { name: 'eBay', abbreviation: 'eBay' },

  // F-J
  { name: 'FedEx', abbreviation: 'FedEx' },
  { name: 'Foot Locker', abbreviation: 'Foot Locker' },
  { name: 'Ford', abbreviation: 'Ford' },
  { name: 'GameStop', abbreviation: 'GameStop' },
  { name: 'Gap', abbreviation: 'Gap' },
  { name: 'General Electric', abbreviation: 'GE' },
  { name: 'General Motors', abbreviation: 'GM' },
  { name: 'Google', abbreviation: 'Google' },
  { name: 'H&M', abbreviation: 'H&M' },
  { name: 'Home Depot', abbreviation: 'Home Depot' },
  { name: 'Honda', abbreviation: 'Honda' },
  { name: 'HP', abbreviation: 'HP' },
  { name: 'IBM', abbreviation: 'IBM' },
  { name: 'IKEA', abbreviation: 'IKEA' },
  { name: 'Intel', abbreviation: 'Intel' },
  { name: 'Jack in the Box', abbreviation: 'Jack in the Box' },
  { name: 'JCPenney', abbreviation: 'JCPenney' },
  { name: 'Jimmy John’s', abbreviation: 'Jimmy John’s' },
  { name: 'Johnson & Johnson', abbreviation: 'J&J' },
  { name: 'JPMorgan Chase', abbreviation: 'JPMorgan' },

  // K-O
  { name: 'KFC', abbreviation: 'KFC' },
  { name: 'Kohl’s', abbreviation: 'Kohl’s' },
  { name: 'Kroger', abbreviation: 'Kroger' },
  { name: 'Lowe’s', abbreviation: 'Lowe’s' },
  { name: 'Macy’s', abbreviation: 'Macy’s' },
  { name: 'Marriott', abbreviation: 'Marriott' },
  { name: 'McDonald’s', abbreviation: 'McDonald’s' },
  { name: 'Microsoft', abbreviation: 'Microsoft' },
  { name: 'Netflix', abbreviation: 'Netflix' },
  { name: 'Nike', abbreviation: 'Nike' },
  { name: 'Nissan', abbreviation: 'Nissan' },
  { name: 'Nordstrom', abbreviation: 'Nordstrom' },
  { name: 'Office Depot', abbreviation: 'Office Depot' },
  { name: 'Old Navy', abbreviation: 'Old Navy' },
  { name: 'Oracle', abbreviation: 'Oracle' },

  // P-T
  { name: 'Papa John’s', abbreviation: 'Papa John’s' },
  { name: 'Pepsi', abbreviation: 'Pepsi' },
  { name: 'Pfizer', abbreviation: 'Pfizer' },
  { name: 'Pizza Hut', abbreviation: 'Pizza Hut' },
  { name: 'Procter & Gamble', abbreviation: 'P&G' },
  { name: 'Ralph Lauren', abbreviation: 'Ralph Lauren' },
  { name: 'Samsung', abbreviation: 'Samsung' },
  { name: 'Sears', abbreviation: 'Sears' },
  { name: 'Shell', abbreviation: 'Shell' },
  { name: 'Sony', abbreviation: 'Sony' },
  { name: 'Starbucks', abbreviation: 'Starbucks' },
  { name: 'Subway', abbreviation: 'Subway' },
  { name: 'Taco Bell', abbreviation: 'Taco Bell' },
  { name: 'Target', abbreviation: 'Target' },
  { name: 'Toyota', abbreviation: 'Toyota' },

  // U-Z
  { name: 'Uber', abbreviation: 'Uber' },
  { name: 'Under Armour', abbreviation: 'Under Armour' },
  { name: 'UPS', abbreviation: 'UPS' },
  { name: 'Verizon', abbreviation: 'Verizon' },
  { name: 'Visa', abbreviation: 'Visa' },
  { name: 'Vodafone', abbreviation: 'Vodafone' },
  { name: 'Volkswagen', abbreviation: 'Volkswagen' },
  { name: 'Walgreens', abbreviation: 'Walgreens' },
  { name: 'Walmart', abbreviation: 'Walmart' },
  { name: 'Wendy’s', abbreviation: 'Wendy’s' },
  { name: 'Whirlpool', abbreviation: 'Whirlpool' },
  { name: 'Whole Foods', abbreviation: 'Whole Foods' },
  { name: 'Xerox', abbreviation: 'Xerox' },
  { name: 'Yamaha', abbreviation: 'Yamaha' },
  { name: 'Zara', abbreviation: 'Zara' },
];

export default BrandsVisitedByUSCitizens;
