import React, { useContext } from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { Routes as AppRoutes, protectedAreaBasePath } from './routeMapping';
import { Dashboard } from '../layouts';

/**
 * A wrapper around the element which checks if the user is authenticated
 * If authenticated, renders the passed element
 * If not authenticated, redirects the user to Login page.
 */
function PrivateRoute() {
  const location = useLocation();

  const { isAuthenticated } = useContext(AuthContext);

  if (!isAuthenticated) {
    return (
      <Navigate
        to={AppRoutes.auth.LOGIN.path}
        state={{ from: location }}
        replace
      />
    );
  }

  return (
    <Dashboard>
      {location.pathname === protectedAreaBasePath ? (
        <Navigate to={AppRoutes.protected.OVERVIEW.path} replace />
      ) : (
        <Outlet />
      )}
    </Dashboard>
  );
}

export default PrivateRoute;
