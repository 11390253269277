import { useContext } from 'react';
import { useQuery } from 'react-query';
import { axiosApiInstance, axios, qs } from '../lib';
import { AuthContext } from '../context/AuthContext';

const defaultConfig = {
  params: {},
  preventFetch: false,
  preventDataValidation: false,
  requestType: 'post',
  axiosInstance: axiosApiInstance,
  dataWithCount: false,
};

const defaultQueryFn = async (
  instance,
  method,
  url,
  params,
  preventDataValidation,
  dataWithCount
) => {
  const response = await instance[method](url, params);

  const isValid = preventDataValidation
    ? response?.data
    : response?.data.status;

  if (isValid) {
    if (dataWithCount) {
      return {
        rows:
          response?.data?.Result?.Rows ||
          response?.data?.Result?.rows ||
          response?.data?.Result ||
          response?.data,
        count:
          response?.data?.Result?.Count ||
          response?.data?.Result?.count ||
          response?.data?.Result?.co ||
          0,
      };
    }

    return (
      response?.data?.Result?.Rows ||
      response?.data?.Result?.rows ||
      response?.data?.Result ||
      response?.data
    );
  }

  throw new Error(`errors.api.${response?.data?.Message}`);
};

const useAxiosQuery = (config) => {
  const { token: userToken } = useContext(AuthContext);
  const finalConfig = { ...defaultConfig, ...config };

  return useQuery(
    [finalConfig.url, finalConfig.params],
    () => {
      const p = {};

      const otherParams = {
        token: userToken,
        ...finalConfig.params,
      };

      let req;
      if (
        finalConfig.requestType === 'get' &&
        !(p instanceof URLSearchParams)
      ) {
        delete otherParams.token;
        const sParams = new URLSearchParams();
        const merged = {
          ...otherParams,
          ...p,
        };
        Object.keys(merged).forEach((key) => {
          sParams.append(key, merged[key]);
        });
        req = { params: sParams };
      } else if (
        finalConfig.requestType === 'get' &&
        p instanceof URLSearchParams
      ) {
        delete otherParams.token;
        Object.keys(otherParams).forEach((key) => {
          p.append(key, otherParams[key]);
        });
        req = { params: p };
      } else if (p instanceof FormData) {
        Object.keys(otherParams).forEach((key) => {
          p.append(key, otherParams[key]);
        });
        req = p;
      } else {
        req = qs.stringify({
          ...otherParams,
          ...p,
        });
      }

      let staticAxios = finalConfig.axiosInstance;
      if (
        finalConfig.url.indexOf('http://') === 0 ||
        finalConfig.url.indexOf('https://') === 0
      ) {
        staticAxios = axios;
      }

      return defaultQueryFn(
        staticAxios,
        finalConfig.requestType,
        finalConfig.url,
        req,
        finalConfig.preventDataValidation,
        finalConfig.dataWithCount
      );
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !finalConfig.preventFetch,
      select: finalConfig.select,
      onSuccess: finalConfig.onSuccess,
      onError: finalConfig.onError,
    }
  );
};

export default useAxiosQuery;
