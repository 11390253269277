export const SubscriptionTypes = {
  monthly: { id: 'monthly', short: 'month', active: false },
  yearly: { id: 'yearly', short: 'year', active: true },
};

export const SubscriptionPlans = [
  {
    id: 'basicPlan',
    apiKey: 'Basic',
    prices: {
      monthly: 29,
      yearly: 348,
    },
    isBilledYearly: true,
    forceMonthlyPrice: true,
    currency: 'USD',
    paymentLink: 'https://buy.stripe.com/5kAbKD6Uy1Xo1dS8wz',
  },
  {
    id: 'advancedPlan',
    apiKey: 'Advanced',
    prices: {
      monthly: 99,
      yearly: 1188,
    },
    isBilledYearly: true,
    forceMonthlyPrice: true,
    currency: 'USD',
    paymentLink: 'https://buy.stripe.com/28o6qj5Qu9pQ2hWaEG',
  },
  {
    id: 'premiumPlan',
    apiKey: 'Premium',
    prices: {
      monthly: 299,
      yearly: 3588,
    },
    isBilledYearly: true,
    forceMonthlyPrice: true,
    currency: 'USD',
    paymentLink: 'https://buy.stripe.com/7sI01Vfr46dEbSw5kl',
  },
];
