import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Image, Form, InputGroup, Badge } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { lowerCase } from 'lodash';
import useAxiosQuery from '../hooks/useAxiosQuery';
import RequestLoading from './RequestLoading';
import RequestResult from './RequestResult';
import PlaceHolderImg from '../assets/images/placeholder.png';

const onImageError = (e) => {
  e.target.src = PlaceHolderImg;
};

function FormFilePicker({
  files,
  requestUrl,
  requestParams,
  onChange,
  value,
  getFileUrl,
  getFileId,
  getFileSearchField,
  isClearable,
  isMulti,
  isSearchable,
  itemHeight,
  itemWidth,
  itemMargin,
  maxFiles,
}) {
  const { formatMessage } = useIntl();
  const [data, setData] = useState(files && !requestUrl ? files : []);
  const [searchValue, setSearchValue] = useState('');

  const { isLoading: apiLoading, error: apiError } = useAxiosQuery({
    url: requestUrl,
    preventFetch: !requestUrl || files,
    params: requestParams,
    onSuccess: (d) => {
      setData(d);
    },
  });

  const handleOnChange = (id) => {
    let newValues;

    if (value?.includes(id)) {
      newValues = value?.filter((v) => v !== id);
    } else if (maxFiles && value.length >= maxFiles) {
      newValues = value;
    } else {
      newValues = [...(value ?? []), id];
    }

    onChange(newValues);
  };

  return (
    <div>
      <RequestLoading loading={apiLoading} />
      <RequestResult type="error" message={apiError} />
      {!apiLoading && !apiError && data?.length === 0 && (
        <RequestResult type="secondary" message="app.common.noData" />
      )}
      {!apiLoading && !apiError && data?.length > 0 && (
        <div>
          {(isSearchable || isClearable) && (
            <div
              className="d-flex align-items-center justify-content-between mt-2"
              style={{ padding: `0 ${itemMargin}px` }}
            >
              {isSearchable && (
                <div>
                  <InputGroup className="input-group-merge input-group-borderless">
                    <InputGroup.Text className="input-group-prepend">
                      <i className="bi-search" />
                    </InputGroup.Text>
                    <Form.Control
                      type="search"
                      placeholder={formatMessage({
                        id: 'app.common.search',
                      })}
                      onChange={(e) => {
                        setSearchValue(e.target.value);
                      }}
                    />
                  </InputGroup>
                </div>
              )}
              {isClearable && (isMulti ? value.length > 0 : value) && (
                <div>
                  <Button
                    variant="danger"
                    size="xs"
                    onClick={() => {
                      if (isMulti) {
                        onChange([]);
                        return;
                      }
                      onChange(undefined);
                    }}
                  >
                    <i className="bi-trash me-1" />
                    <FormattedMessage id="app.common.clearSelection" />
                  </Button>
                </div>
              )}
            </div>
          )}
          {data.filter(
            (item) =>
              `${lowerCase(getFileSearchField(item))}`.indexOf(
                lowerCase(searchValue)
              ) > -1
          ).length === 0 && (
            <RequestResult
              type="secondary"
              message="app.common.noResultsFound"
              className="p-2"
              style={{ margin: itemMargin }}
            />
          )}
          <SimpleBar
            style={{
              paddingLeft: itemMargin,
              maxHeight: itemHeight + itemMargin * 2,
              paddingBottom: 10,
            }}
          >
            <div className="d-flex">
              {data
                .filter(
                  (item) =>
                    `${lowerCase(getFileSearchField(item))}`.indexOf(
                      lowerCase(searchValue)
                    ) > -1
                )
                .map((item) => (
                  <div
                    className="flex-shrink-0"
                    style={{
                      width: itemWidth,
                      height: itemHeight,
                      padding: `${itemMargin}px ${itemMargin}px ${itemMargin}px 0`,
                    }}
                    key={getFileId(item)}
                  >
                    <div className="form-check form-check-label-highlighter w-100 h-100">
                      <input
                        type={isMulti ? 'checkbox' : 'radio'}
                        className="form-check-input position-absolute"
                        name="file[]"
                        id={`file_${getFileId(item)}`}
                        value={getFileId(item)}
                        onChange={() => {
                          if (isMulti) {
                            handleOnChange(getFileId(item));
                            return;
                          }
                          onChange(getFileId(item));
                        }}
                        checked={
                          isMulti
                            ? value?.includes(getFileId(item))
                            : value === getFileId(item)
                        }
                      />
                      <label
                        className="form-check-label w-100 h-100"
                        htmlFor={`file_${getFileId(item)}`}
                      >
                        <Image
                          className="form-check-img w-100 h-100"
                          src={getFileUrl(item) || PlaceHolderImg}
                          onError={onImageError}
                          style={{
                            objectFit: 'cover',
                          }}
                        />
                      </label>
                    </div>
                  </div>
                ))}
            </div>
          </SimpleBar>
          {maxFiles && (
            <div className="d-flex justify-content-end mt-2 mb-2 me-2">
              <Badge bg="secondary">
                {isMulti
                  ? `${value.length} / ${maxFiles}`
                  : `${value ? '1' : '0'} / 1`}
              </Badge>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

FormFilePicker.propTypes = {
  files: PropTypes.array,
  requestUrl: PropTypes.string,
  requestParams: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.number,
    PropTypes.string,
  ]),
  getFileUrl: PropTypes.func,
  getFileId: PropTypes.func,
  getFileSearchField: PropTypes.func,
  isClearable: PropTypes.bool,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  itemHeight: PropTypes.number,
  itemWidth: PropTypes.number,
  itemMargin: PropTypes.number,
  maxFiles: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
};

FormFilePicker.defaultProps = {
  files: null,
  requestUrl: null,
  requestParams: {},
  onChange: () => {},
  value: undefined,
  getFileUrl: (item) => item.ImageURL,
  getFileId: (item) => item.id,
  getFileSearchField: (item) => item.id,
  isClearable: false,
  isMulti: true,
  isSearchable: false,
  itemHeight: 200,
  itemWidth: 200,
  itemMargin: 10,
  maxFiles: undefined,
};

export default FormFilePicker;
