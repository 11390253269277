import { isBoolean } from 'lodash';
import GoogleIcon from '../assets/images/svg/brands/google-icon.svg';
import AppleIcon from '../assets/images/svg/brands/apple-icon.svg';

const allowAuthVendors = process.env.REACT_APP_ENABLE_AUTH_VENDORS || false;

const AuthVendors =
  (isBoolean(allowAuthVendors) && allowAuthVendors) ||
  allowAuthVendors === 'true'
    ? {
        google: {
          id: 'google',
          icon: { default: GoogleIcon, dark: GoogleIcon },
        },
        apple: { id: 'apple', icon: { default: AppleIcon, dark: AppleIcon } },
      }
    : {};

export default AuthVendors;
