import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useFormModal, useToast } from '../hooks';
import { yup } from '../lib';
import { AuthContext } from '../context/AuthContext';

function NavbarHelp() {
  const { user } = useContext(AuthContext);
  const { form } = useFormModal();
  const { showToast } = useToast();

  const fields = [
    {
      cols: [
        {
          key: 'name',
          schema: yup.string().required(),
        },
      ],
    },
    {
      cols: [
        {
          key: 'email',
          schema: yup.string().email().required(),
        },
        {
          key: 'phone',
          type: 'maskedInput',
          options: {
            controller: {
              mask: '(999) 999-9999',
              maskOptions: {
                rightAlign: false,
                autoUnmask: true,
              },
            },
          },
          schema: yup
            .string()
            .nullable()
            .trim()
            .transform((value) => (value === '' ? undefined : value))
            .length(10),
        },
      ],
    },
    {
      cols: [
        {
          key: 'message',
          type: 'textarea',
          schema: yup.string().required(),
        },
      ],
    },
    {
      cols: [
        {
          key: 'file',
          type: 'dropzone',
          schema: yup.mixed(),
          options: {
            controller: {
              options: {
                multiple: true,
                accept: { 'image/*': [] },
              },
            },
          },
        },
      ],
    },
  ];

  const onOpenModal = async () => {
    const formData = await form({
      size: 'lg',
      title: 'contactSupport',
      confirmLabel: 'send',
      requestUrl: '/contact/add',
      fields,
      defaultValues: { name: user.name, email: user.email, phone: user.phone },
      fetchOnStart: false,
    });

    if (formData) {
      showToast({
        type: 'success',
        autohide: true,
        title: <FormattedMessage id="app.common.contactFormSuccessTitle" />,
        message: <FormattedMessage id="app.common.contactFormSuccessMessage" />,
      });
    }
  };

  return (
    <Button
      variant="ghost-light"
      className="btn-icon rounded-circle"
      onClick={onOpenModal}
    >
      <i className="bi-question-circle" />
    </Button>
  );
}

export default NavbarHelp;
