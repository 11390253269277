import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';
import { ThemeContext } from '../context/ThemeContext';

function SmartImage({ src, darkSrc, onError, ...props }) {
  const { theme } = useContext(ThemeContext);
  return (
    <Image
      src={darkSrc && theme === 'dark' ? darkSrc : src}
      onError={(e) => {
        onError(e, theme);
      }}
      {...props}
    />
  );
}

SmartImage.propTypes = {
  src: PropTypes.string.isRequired,
  darkSrc: PropTypes.string,
  onError: PropTypes.func,
};

SmartImage.defaultProps = {
  darkSrc: null,
  onError: () => {},
};

export default SmartImage;
