export const Status = {
  8: { id: 8, labelKey: 'active', color: 'success' },
  9: { id: 9, labelKey: 'sold', color: 'danger' },
  10: { id: 10, labelKey: 'active-contract', color: 'info' },
  11: { id: 11, labelKey: 'coming soon', color: 'primary' },
};

export const Types = {
  Condo: { id: 'Condo', labelKey: 'Condo', color: '#777777' },

  'Single Family': {
    id: 'Single Family',
    labelKey: 'Single Family',
    color: '#ff0000',
  },
  'Single Family Residential': {
    id: 'Single Family Residential',
    labelKey: 'Single Family Residential',
    color: '#d4af37',
  },
  ResidentialIncome: {
    id: 'ResidentialIncome',
    labelKey: 'ResidentialIncome',
    color: '#ff3400',
  },
  Land: { id: 'Land', labelKey: 'Land', color: '#ff0000' },
  'Vacant Land': {
    id: 'Vacant Land',
    labelKey: 'Vacant Land',
    color: '#cccccc',
  },
  Office: { id: 'Office', labelKey: 'Office', color: '#ff0000' },
};

export const metricTypes = {
  All: { id: 'All', labelKey: 'All', color: '#777777' },
  condos_co_ops: { id: 'condos_co_ops', labelKey: 'Condo', color: '#777777' },
  single_family: {
    id: 'single_family',
    labelKey: 'Single Family',
    color: '#777777',
  },
  townhouses: { id: 'townhouses', labelKey: 'Town House', color: '#777777' },
};

export const bedRoomTypes = {
  '0_Bedrooms': { id: '0_Bedrooms', labelKey: '0 Bedroom', color: '#777777' },
  '1_Bedrooms': { id: '1_Bedrooms', labelKey: '1 Bedroom', color: '#777777' },
  '2_Bedrooms': { id: '2_Bedrooms', labelKey: '2 Bedrooms', color: '#777777' },
  '3_Bedrooms': { id: '3_Bedrooms', labelKey: '3 Bedrooms', color: '#777777' },
  '4_Bedrooms': { id: '4_Bedrooms', labelKey: '4 Bedrooms', color: '#777777' },
};
export const dataIcons = {
  'Garage Spaces': { id: 'Garage Space1s', icon: 'bi-car', color: '#ff0000' },
};

// userProfiles.js
export const userProfiles = {
  1: {
    id: 1,
    labelKey: 'First-Time Homebuyer',
    description:
      'Focused on affordable and entry-level properties. Prioritizes areas with potential for long-term growth and family-friendly neighborhoods.',
  },
  2: {
    id: 2,
    labelKey: 'Commercial Investor',
    description:
      'Seeks high-value commercial properties in established or rapidly growing markets. Interested in office spaces, retail locations, and multifamily units.',
  },
  3: {
    id: 3,
    labelKey: 'Flipper',
    description:
      'Looks for undervalued properties to renovate and sell for a profit. Interested in emerging neighborhoods and properties under market value.',
  },
  4: {
    id: 4,
    labelKey: 'Luxury Property Collector',
    description:
      'Interested in high-end, luxury properties in prestigious areas. Focuses on exclusive listings and unique architectural properties.',
  },
  5: {
    id: 5,
    labelKey: 'Vacation Rental Owner',
    description:
      'Targets properties in popular vacation destinations. Looks for high Airbnb or short-term rental potential with a focus on unique experiences and locations.',
  },
  6: {
    id: 6,
    labelKey: 'Long-Term Residential Landlord',
    description:
      'Invests in residential properties with the goal of renting them out. Prioritizes stable markets and properties with steady rental income potential.',
  },
  7: {
    id: 7,
    labelKey: 'Owner-Occupier Investor',
    description:
      'Purchases residential properties for personal dwelling with a long-term investment perspective. Focuses on homes that provide both a quality living experience and appreciation potential over time.',
  },
};

// Bu dosyayı başka bir bileşende kullanmak için:
// import { userProfiles } from './userProfiles';

export const EconomicIndicators = {
  US_AVERAGE: 69021,
  US_UNEMPLOYMENT_RATE: 6.0,
  US_MEDIAN_HOME_PRICE: 416100,
  US_MEDIAN_LUX_HOME_PRICE: 1100000,
  US_MEDIAN_RENT_PRICE: 1702,
  US_AVERAGE_INCOME: 59428,
  US_AVERAGE_HOME_AGE: 46,
  US_AVERAGE_COST_OF_LIVING: 100,
  US_AVERAGE_RENTERS_PERCENT: 36,
  US_AVERAGE_HOME_APPRECIATION: 14.5,
  US_AVERAGE_HOME_APPRECIATION_LAST5: 28.8,
  US_AVERAGE_HOME_APPRECIATION_LAST10: 46.6,
  US_AVERAGE_NEXT10YEARSJOBGROWTH: 33.5,
  US_AVERAGE_JOBMARKET_INCREASE: -23.8,
  US_AVERAGE_COLLEGE_DEGREE: 37.7,
  US_AVERAGE_HIGH_SCHOOL_RATE: 87,
  US_AVERAGE_PROPERTY_CRIME: 35.4,
  US_AVERAGE_VIOLENT_CRIME: 22.7,
  US_AVERAGE_HEALTH_COST_INDEX: 100,
  US_AVERAGE_WATER_QUALITY_INDEX: 55,
  US_AVERAGE_SUPERFUND_INDEX: 87,
  US_AVERAGE_AIR_QUALITY_INDEX: 58,
  US_AVERAGE_TRANSPORTATION_TIME: 26.2,
  US_AVERAGE_WALK_SCORE: 48,
  US_AVERAGE_SUNNY_DAYS: 205,
  US_AVERAGE_RAIN_FALL: 30.28,
  US_AVERAGE_SNOW_FALL: 28,
  US_AVERAGE_INCOME_TAX: 4.6,
  US_AVERAGE_SALES_TAX: 7.3,
  US_MEDIAN_HOUSEHOLD_INCOME: 74580,
};
