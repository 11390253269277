import * as Colors from './colors';
import Brands from './brands';
import Countries from './countries';
import AuthVendors from './authVendors';
import YupLocale from './yupLocale';
import * as DateFormats from './dateFormats';
import * as Review from './review';
import * as EventTypes from './eventTypes';
import * as Social from './social';
import States from './states';
import POICategories from './poiCategories';
import SchoolScore from './schoolScore';
import BrandsVisitedByUSCitizens from './BrandsVisitedByUSCitizens';
import transportationTypes from './transportationTypes';
import BuildingTypesResidential from './buildingTypesResidential';
import Languages from './languages';
import LawTypes from './lawTypes';
import * as User from './user';
import * as RealEstate from './realEstate';
import * as Video from './video';
import * as Billing from './billing';

const Constants = {
  Colors,
  Brands,
  Countries,
  AuthVendors,
  YupLocale,
  DateFormats,
  Review,
  EventTypes,
  Social,
  States,
  User,
  BuildingTypesResidential,
  RealEstate,
  Video,
  POICategories,
  SchoolScore,
  BrandsVisitedByUSCitizens,
  transportationTypes,
  Languages,
  LawTypes,
  Billing,
};

export default Constants;
