import React from 'react';
import { useParams } from 'react-router-dom';
import { SmartFileManager } from '../../../components';

const baseURL = process.env.REACT_APP_BASE_URL || '';

function Files() {
  const { id } = useParams();

  return (
    <SmartFileManager
      paths={{
        /* folders: {
          list: '/directories/list',
          create: '/directories/insert',
          delete: '/directories/delete',
        }, */
        files: {
          list: '/files/list',
          create: '/files/insert',
          delete: '/files/delete',
          preview: `${baseURL.replace('/service', '')}/files/`,
          download: `${baseURL.replace(
            '/service',
            ''
          )}/file/download?filename=`,
        },
      }}
      params={{
        RefTable: 'contacts',
        RefID: id,
      }}
    />
  );
}

export default Files;
