import React, { useContext, useState } from 'react';
import { Row, Col, Card, Button, Modal } from 'react-bootstrap';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { useAxiosQuery } from '../../../../hooks';
import { RequestLoading, RequestResult } from '../../../../components';
import { AuthContext } from '../../../../context/AuthContext';
import SubscriptionPlansTable from './SubscriptionPlansTable';

function formatEndDate(dateString) {
  const options = { year: 'numeric', month: 'long' };
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', options);
}
function BillingOverview() {
  const { pack } = useContext(AuthContext);

  const [subscriptionModalShow, setSubscriptionModalShow] = useState(false);

  const handleSubscriptionModalClose = () => {
    setSubscriptionModalShow(false);
  };
  const handleSubscriptionModalShow = () => setSubscriptionModalShow(true);

  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/membership/listTransactions',
  });

  return (
    <>
      {(apiLoading || apiError || (!apiLoading && !apiError && !apiData)) && (
        <Card.Body>
          <RequestLoading loading={apiLoading} size="lg" margin="5" />
          <RequestResult type="error" message={apiError} />

          {!apiLoading && !apiError && !apiData && (
            <RequestResult type="secondary" message="app.common.noData" />
          )}
        </Card.Body>
      )}

      {!apiLoading && !apiError && apiData && (
        <>
          <Card.Body>
            <Row>
              <Col md className="mb-4 mb-md-0">
                <div className="mb-4">
                  <Card.Subtitle>Your plan (billed yearly):</Card.Subtitle>
                  <h3>
                    {`${apiData[0].membership_type}`} -{' '}
                    {formatEndDate(apiData[0].end_date)}
                  </h3>
                </div>
                <div>
                  <Card.Subtitle>
                    <FormattedMessage id="app.common.totalPerYear" />
                  </Card.Subtitle>
                  <h1 className="text-primary">
                    <span className="me-1">
                      <FormattedNumber
                        value={apiData[0].total}
                        // eslint-disable-next-line react/style-prop-object
                        style="currency"
                        currency="USD"
                      />
                    </span>
                    USD
                  </h1>
                </div>
              </Col>
              <Col md="auto">
                <div className="d-grid d-sm-flex gap-3">
                  <Button variant="white">
                    <FormattedMessage id="app.common.cancelSubscription" />
                  </Button>
                  {pack.name !== 'Premium' && (
                    <Button
                      className="w-100 w-sm-auto"
                      onClick={handleSubscriptionModalShow}
                    >
                      <FormattedMessage id="app.common.updatePlan" />
                    </Button>
                  )}
                </div>
              </Col>
            </Row>
          </Card.Body>
          <Modal
            show={subscriptionModalShow}
            onHide={handleSubscriptionModalClose}
            size="xl"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <FormattedMessage id="app.common.subscriptionPlans" />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <SubscriptionPlansTable />
              <div className="d-flex justify-content-center justify-content-sm-end gap-3 mt-4">
                <Button onClick={handleSubscriptionModalClose} variant="white">
                  <FormattedMessage id="app.common.cancel" />
                </Button>
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
}

export default BillingOverview;
