import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  // Title,
  Tooltip,
  Legend,
  TimeScale,
  Filler,
} from 'chart.js';
import { Chart, Doughnut, Line, Bubble, Bar } from 'react-chartjs-2';
import { WordCloudController, WordElement } from 'chartjs-chart-wordcloud';
import 'chartjs-adapter-moment';

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Legend,
  Tooltip,
  TimeScale,
  WordCloudController,
  WordElement,
  Filler
);

const Charts = { Chart, Doughnut, Line, Bubble, Bar };

export { ChartJS, Charts };
