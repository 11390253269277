import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { RequestLoading, RequestResult, Tooltip } from '../../../../components';
import { BarChart } from '../../../../components/Charts';
import Utils from '../../../../utils';
import { useAxiosQuery } from '../../../../hooks';
import { ThemeContext } from '../../../../context/ThemeContext';

const getPreviousYearMonth = () => {
  const date = new Date();
  date.setMonth(date.getMonth() - 2); // Set to previous month
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // getMonth() returns 0-11
  return `${year}${month.toString().padStart(2, '0')}`; // Pads the month with a leading zero if necessary
};

const getMessageIds = (field) => {
  switch (field) {
    case 'demand_score':
      return {
        titleId: 'app.common.marketDemandScore',
        tooltipId: 'app.toolTips.marketDemandScore',
      };
    case 'hotness_score':
      return {
        titleId: 'app.common.marketHotnessScore',
        tooltipId: 'app.toolTips.marketHotnessScore',
      };
    // Add more cases as needed
    default:
      return {
        titleId: 'app.common.marketHotnessScore',
        tooltipId: 'app.toolTips.marketHotnessScore',
      };
  }
};

function BarChartCard({ field, orderby, limit, start, end, postalcode }) {
  const { formatMessage } = useIntl();
  const { theme } = useContext(ThemeContext);
  const { titleId, tooltipId } = getMessageIds(field);

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        suggestedMax: 100,
        ticks: {
          callback: (v) => v,
        },
      },
    },
  };

  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/Market_Hotness/Top10Hotness',
    preventFetch: !field,
    params: { field, orderby, limit, start, end, postal_code: postalcode },
  });

  const getLineData = () => {
    const labels = apiData.map(
      (item) => `${item.zip_name}-${item.postal_code}`
    );
    const datasets = [
      {
        data: apiData.map((item) => Number(item[field])),
        ...Utils.Chart.getBarChartOptions(0, theme),
      },
    ];
    return {
      labels,
      datasets,
    };
  };
  return (
    <Card className="h-100">
      <Card.Header className="card-header-content-between">
        <Card.Title
          bsPrefix="card-header-title"
          as="h4"
          className="d-flex justify-content-between"
        >
          <FormattedMessage id={titleId} />
          <Tooltip
            content={formatMessage({
              id: tooltipId,
            })}
          >
            <i className="bi-info-circle-fill ms-2" />
          </Tooltip>
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <RequestLoading loading={apiLoading} margin="5" />
        <RequestResult type="error" message={apiError} />
        {!apiLoading && !apiError && Object.keys(apiData)?.length === 0 && (
          <RequestResult type="secondary" message="app.common.noData" />
        )}
        {!apiLoading && !apiError && Object.keys(apiData)?.length > 0 && (
          <div className="h-100 d-flex flex-column justify-content-center align-items-center">
            <BarChart data={getLineData()} options={options} />
          </div>
        )}
      </Card.Body>
    </Card>
  );
}

BarChartCard.propTypes = {
  field: PropTypes.string.isRequired,
  orderby: PropTypes.oneOf(['ASC', 'DESC']),
  limit: PropTypes.number,
  start: PropTypes.string,
  end: PropTypes.string,
  postalcode: PropTypes.string,
};

BarChartCard.defaultProps = {
  orderby: 'DESC',
  limit: 15,
  start: getPreviousYearMonth(),
  end: getPreviousYearMonth(),
  postalcode: '21601',
};

export default BarChartCard;
