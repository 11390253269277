import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Image, ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Logo, SmartImage } from '../components';
import {
  DarkmodeSwitcherPositionEnd,
  LanguageSwitcherPositionEnd,
} from '../components/Theme/LayoutComponents';

import Illustration from '../assets/images/svg/illustrations/oc-chatting.svg';
import IllustrationLight from '../assets/images/svg/illustrations-light/oc-chatting.svg';

import Gitlab from '../assets/images/svg/brands/gitlab-gray.svg';
import Fitbit from '../assets/images/svg/brands/fitbit-gray.svg';
import Flow from '../assets/images/svg/brands/flow-xo-gray.svg';
import Layar from '../assets/images/svg/brands/layar-gray.svg';

const enableLogos = false;
const logos = enableLogos ? [Gitlab, Fitbit, Flow, Layar] : [];

/* function LanguageSelect() {
  const { languages, currentLanguage, changeLanguage } =
    useContext(LangContext);

  const options = Object.keys(languages).map((key) => ({
    value: key,
    label: languages[key],
  }));

  const formatOptionLabel = ({ value, label }) => (
    <div className="d-flex align-items-center">
      <ReactCountryFlag
        countryCode={value === 'en' ? 'us' : value}
        svg
        className="me-2"
      />
      <span>{label}</span>
    </div>
  );

  return (
    <Select
      options={options}
      value={options.find((opt) => opt.value === currentLanguage)}
      onChange={({ value }) => {
        changeLanguage(value);
      }}
      formatOptionLabel={formatOptionLabel}
      className="react-select-custom-container react-select-custom-transparent"
      classNamePrefix="react-select-custom"
      isSearchable={false}
    />
  );
} */

function Auth({ children }) {
  return (
    <div className="d-flex align-items-center min-h-100">
      <header className="position-absolute top-0 start-0 end-0 mt-3 mx-3">
        <div className="d-flex d-lg-none justify-content-between">
          <Link to="/">
            <Logo
              className="w-100"
              style={{ minWidth: '10rem', maxWidth: '10rem' }}
            />
          </Link>

          <div className="d-flex align-items-center">
            {/* <LanguageSelect /> */}
            <DarkmodeSwitcherPositionEnd
              dropup={false}
              variant="light"
              className="me-2"
            />
            <LanguageSwitcherPositionEnd dropup={false} variant="light" />
          </div>
        </div>
      </header>
      <main id="content" role="main" className="main pt-0">
        <Container fluid className="px-3">
          <Row>
            <Col
              lg="6"
              className="d-none d-lg-flex justify-content-center align-items-center min-vh-lg-100 position-relative bg-light px-0"
            >
              <div className="position-absolute top-0 start-0 end-0 mt-3 mx-3">
                <div className="d-none d-lg-flex justify-content-between align-items-center">
                  <Link to="/">
                    <Logo
                      className="w-100"
                      style={{ minWidth: '10rem', maxWidth: '10rem' }}
                    />
                  </Link>

                  <div className="d-flex align-items-center">
                    {/* <LanguageSelect /> */}
                    <DarkmodeSwitcherPositionEnd
                      dropup={false}
                      variant="light"
                      className="me-2"
                    />
                    <LanguageSwitcherPositionEnd
                      dropup={false}
                      variant="light"
                    />
                  </div>
                </div>
              </div>

              <div style={{ maxWidth: '23rem' }}>
                <div className="text-center mb-5">
                  <SmartImage
                    className="img-fluid"
                    src={Illustration}
                    darkSrc={IllustrationLight}
                    style={{ width: '12rem' }}
                  />
                </div>

                <div className="mb-5">
                  <h2 className="display-5">
                    <FormattedMessage id="app.auth.welcome.title" />
                  </h2>
                </div>

                <ListGroup
                  as="ul"
                  bsPrefix="list-checked"
                  variant="primary"
                  className="list-checked-lg list-py-2"
                >
                  <ListGroup.Item as="li" bsPrefix="list-checked-item">
                    <span className="d-block fw-semi-bold mb-1">
                      <FormattedMessage id="app.auth.welcome.messages.first.title" />
                    </span>
                    <FormattedMessage id="app.auth.welcome.messages.first.desc" />
                  </ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix="list-checked-item">
                    <span className="d-block fw-semi-bold mb-1">
                      <FormattedMessage id="app.auth.welcome.messages.second.title" />
                    </span>
                    <FormattedMessage id="app.auth.welcome.messages.second.desc" />
                  </ListGroup.Item>
                </ListGroup>

                <div className="row justify-content-between mt-5 gx-3">
                  {logos.map((item, index) => (
                    <div key={`${index.toString()}`} className="col">
                      <Image fluid src={item} />
                    </div>
                  ))}
                </div>
              </div>
            </Col>
            <Col
              lg="6"
              className="d-flex justify-content-center align-items-center min-vh-lg-100"
            >
              {children}
            </Col>
          </Row>
        </Container>
      </main>
    </div>
  );
}

Auth.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Auth;
