import React, {
  useMemo,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import { Table, Card, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import { useTable } from 'react-table';
import { FormattedMessage } from 'react-intl';
import Pagination from '../Pagination';
import { useAxiosQuery } from '../../hooks';
import RequestLoading, { LoadingPropTypes } from '../RequestLoading';
import RequestResult, {
  AnimationPropType,
  ImagePropType,
} from '../RequestResult';

const SmartTable = forwardRef(
  (
    {
      columns,
      requestUrl,
      requestParams,
      filters,
      hoverable,
      verticalAligned,
      perPageValues,
      noDataOptions,
      noResultOptions,
      onRequestSuccess,
      onRequestError,
      loadingOptions,
    },
    ref
  ) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(perPageValues[0]);

    const columnsMemo = useMemo(() => columns, [columns]);

    const {
      data: apiData,
      isLoading: apiLoading,
      error: apiError,
      refetch: apiFetch,
    } = useAxiosQuery({
      url: requestUrl,
      dataWithCount: true,
      params: {
        ...requestParams,
        page: currentPage,
        quantity: itemPerPage,
        ...filters,
      },
      onSuccess: (data) => {
        onRequestSuccess(data);
      },
      onError: (e) => {
        onRequestError(e);
      },
    });

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable({
        columns: columnsMemo,
        data: apiData?.rows || [],
        manualPagination: true,
      });

    const onPageChange = (pageNumber) => {
      if (pageNumber !== currentPage) {
        setCurrentPage(pageNumber);
      }
    };

    const onPerPageChange = (perPage) => {
      if (perPage !== itemPerPage) {
        setCurrentPage(1);
        setItemPerPage(perPage);
      }
    };

    useImperativeHandle(ref, () => ({
      reload: () => {
        apiFetch();
      },
    }));

    const resImg = (opts) => {
      if (typeof opts?.image === 'boolean' && opts?.image === false) {
        return false;
      }

      if (opts?.image) {
        return opts.image;
      }

      return undefined;
    };

    const resAnim = (opts) => {
      if (typeof opts?.animation === 'boolean' && opts?.animation === false) {
        return false;
      }

      if (opts?.animation) {
        return opts.animation;
      }

      return undefined;
    };

    return (
      <>
        <Card.Body
          className={`flex-shrink-1 ${
            !apiLoading && !apiError && apiData?.rows.length > 0 ? 'p-0' : ''
          }`}
        >
          <RequestLoading
            loading={apiLoading}
            size="lg"
            margin="5"
            {...loadingOptions}
          />
          <RequestResult type="error" message={apiError} />
          {!apiLoading &&
            !apiError &&
            apiData?.rows.length === 0 &&
            Object.keys(filters).length === 0 && (
              <RequestResult
                type={noDataOptions?.type || 'secondary'}
                title={noDataOptions?.title || 'warnings.emptyList.title'}
                message={noDataOptions?.message || 'warnings.emptyList.message'}
                image={resImg(noDataOptions)}
                animation={resAnim(noDataOptions)}
                dismissible={noDataOptions?.dismissible}
              />
            )}
          {!apiLoading &&
            !apiError &&
            apiData?.rows.length === 0 &&
            Object.keys(filters).length > 0 && (
              <RequestResult
                type={noResultOptions?.type || 'secondary'}
                title={
                  noResultOptions?.title || 'warnings.noResultsFound.title'
                }
                message={
                  noResultOptions?.message || 'warnings.noResultsFound.message'
                }
                image={resImg(noResultOptions)}
                animation={resAnim(noDataOptions)}
                dismissible={noResultOptions?.dismissible}
              />
            )}
        </Card.Body>

        {!apiLoading && !apiError && apiData?.rows.length > 0 && (
          <div
            className="table-responsive h-100 flex-grow-1"
            style={{ minHeight: 340 }}
          >
            <Table
              {...getTableProps()}
              borderless
              hover={hoverable}
              className={`table-thead-bordered card-table dataTable no-footer ${
                verticalAligned ? 'table-align-middle' : ''
              }`}
            >
              <thead className="thead-light">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps()}
                        className={`${
                          column.textAlign ? `text-${column.textAlign}` : ''
                        }`}
                      >
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td
                          {...cell.getCellProps()}
                          className={`${
                            cell.column.wrap ? '' : 'text-nowrap'
                          } ${
                            cell.column.textAlign
                              ? `text-${cell.column.textAlign}`
                              : ''
                          }`}
                          style={{
                            minWidth: cell.column.minWidth || undefined,
                            width: cell.column.width || undefined,
                          }}
                        >
                          {cell.column.disableNullControl ||
                          cell.value ||
                          cell.value === 0 ? (
                            cell.render('Cell')
                          ) : (
                            <FormattedMessage id="app.common.n/a" />
                          )}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        )}
        <Card.Footer>
          <Row className="justify-content-center justify-content-sm-between align-items-sm-center">
            <Col className="col-sm mb-2 mb-sm-0">
              <div className="d-flex justify-content-center justify-content-sm-start align-items-center">
                <span className="me-2">
                  <FormattedMessage id="app.common.showing" />:
                </span>

                <Select
                  menuPlacement="top"
                  options={perPageValues.map((x) => ({
                    value: x,
                    label: x,
                  }))}
                  defaultValue={{
                    value: perPageValues[0],
                    label: perPageValues[0],
                  }}
                  value={{ value: itemPerPage, label: itemPerPage }}
                  onChange={(item) => {
                    onPerPageChange(item.value);
                  }}
                  className="react-select-custom-container"
                  classNamePrefix="react-select-custom"
                  isSearchable={false}
                />

                <span className="text-secondary mx-2">
                  <FormattedMessage id="app.common.of" />
                </span>
                {apiData?.count}
              </div>
            </Col>

            <Col className="col-sm-auto">
              <div className="d-flex justify-content-center justify-content-sm-end">
                <Pagination
                  currentPage={currentPage}
                  totalRows={apiData?.count}
                  pageChangeHandler={onPageChange}
                  rowsPerPage={itemPerPage}
                />
              </div>
            </Col>
          </Row>
        </Card.Footer>
      </>
    );
  }
);

const ResultPropType = PropTypes.shape({
  type: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  image: PropTypes.oneOfType([ImagePropType, PropTypes.bool]),
  animation: PropTypes.oneOfType([AnimationPropType, PropTypes.bool]),
  dismissible: PropTypes.bool,
});

SmartTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  requestUrl: PropTypes.string.isRequired,
  requestParams: PropTypes.objectOf(PropTypes.any),
  filters: PropTypes.objectOf(PropTypes.any),
  hoverable: PropTypes.bool,
  verticalAligned: PropTypes.bool,
  perPageValues: PropTypes.arrayOf(PropTypes.number),
  noDataOptions: ResultPropType,
  noResultOptions: ResultPropType,
  onRequestSuccess: PropTypes.func,
  onRequestError: PropTypes.func,
  loadingOptions: PropTypes.shape(LoadingPropTypes),
};

SmartTable.defaultProps = {
  requestParams: {},
  filters: {},
  hoverable: false,
  verticalAligned: true,
  perPageValues: [10, 15, 20, 25],
  noDataOptions: undefined,
  noResultOptions: undefined,
  onRequestSuccess: () => {},
  onRequestError: () => {},
  loadingOptions: undefined,
};
export default SmartTable;
