import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { BillingOverview, BillingPayments } from './partials';

const sections = [
  {
    titleKey: 'overview',
    Component: () => <BillingOverview />,
  },

  {
    titleKey: 'payments',
    Component: () => (
      <Card.Body>
        <BillingPayments />
      </Card.Body>
    ),
  },
];

function Billing() {
  return (
    <Row className="justify-content-lg-center">
      <Col lg="9">
        <div className="d-flex flex-column w-100">
          {sections.map(({ titleKey, Component }, index) => (
            <Card className="mb-3 mb-lg-5" key={`${index.toString()}`}>
              <Card.Header>
                <Card.Title bsPrefix="card-header-title" as="h4">
                  <FormattedMessage id={`app.common.${titleKey}`} />
                </Card.Title>
              </Card.Header>
              <Component />
            </Card>
          ))}
        </div>
      </Col>
    </Row>
  );
}

export default Billing;
