import React from 'react';
import { PageHeader, SmartCalendar } from '../../../../components';
import Utils from '../../../../utils';
import Constants from '../../../../constants';

function Calendar() {
  const formatter = (item) => {
    const color = Utils.Color.getShadeHexColorFromHex(
      Constants.Colors.Palette[0],
      100
    );
    return {
      id: item.CalendarID,
      title: item.Title,
      start: item.Date ? item.Date[0] : item.DateStart,
      end: item.Date ? item.Date[1] : item.DateEnd,
      // className: 'bg-primary',
      backgroundColor: color,
      textColor: '#000',
      eventDescription: item.Description,
      eventVenue: item.UserName,
      eventAddress: item.Address,
      eventCategory: item.Keywords,
      // eventDistance: item.distance,
      eventLatitude: item.Latitude,
      eventLongitude: item.Longitude,
      icon: 'bi-calendar-event',
      // allDay: true,
      // guestsField: ['David Harrison'],
      image: item.Image,
      original: item,
    };
  };

  return (
    <div className="content container">
      <PageHeader
        className="d-block"
        title="calendar"
        breadcrumbData={{
          current: 'calendar',
        }}
      />
      <SmartCalendar
        paths={{
          list: '/calendar/list',
          create: '/calendar/insert',
          delete: '/calendar/delete',
          update: '/calendar/update',
        }}
        withRadiusFilter={false}
        formatter={formatter}
        // withCategoriesFilter={{ requestUrl: '/categories/list' }}
      />
    </div>
  );
}

export default Calendar;
