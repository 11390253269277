import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import {
  Row,
  Col,
  Card,
  Collapse,
  Button,
  Form,
  Badge,
  ButtonGroup,
  Dropdown,
  Image,
} from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { debounce } from 'lodash';
import { Link, useSearchParams } from 'react-router-dom';

import moment from 'moment';
import { Routes as AppRoutes } from '../../../../router/routeMapping';
import { useAxiosQuery, useFormModal, useToast } from '../../../../hooks';
import { HighlightedText, SmartForm, SmartTable } from '../../../../components';
import { yup } from '../../../../lib';
import Constants from '../../../../constants';

import PlaceHolderImg from '../../../../assets/images/placeholder.png';

const onImageError = (e) => {
  e.target.src = PlaceHolderImg;
};

const typeOptionLabel = ({ id }) => (
  <div className="d-flex">
    <Badge bg={`${Constants.Review.Types[id].color}`}>
      <i className={`${Constants.Review.Types[id].icon} fs-6 me-1`} />
      <FormattedMessage
        id={`app.common.${Constants.Review.Types[id].labelKey}`}
      />
    </Badge>
  </div>
);
const generateLink = (original) => {
  let linkPath = '';
  switch (original.RefTable) {
    case 'homes':
      linkPath = `${AppRoutes.protected.LISTINGS_DETAIL.path.replace(
        ':id',
        original.RefID
      )}`;
      break;
    case 'admin_operism.businesses':
      linkPath = `${AppRoutes.protected.SMALLBUSINESS_DETAIL.path.replace(
        ':id',
        original.RefID
      )}`;
      break;
    case 'realtor.realtor':
      linkPath = `${AppRoutes.protected.REALTOR_DETAIL.path.replace(
        ':id',
        original.RefID
      )}`;
      break;
    case 'Bidlaw.users':
      linkPath = `${AppRoutes.protected.LAWYER_DETAIL.path.replace(
        ':id',
        original.RefID
      )}`;
      break;
    case 'article':
      linkPath = `${AppRoutes.protected.LISTINGS.children.ARTICLE.path}?Id=${original.RefID}`;
      break;
    case 'schools':
      linkPath = `${AppRoutes.protected.LISTINGS.children.SCHOOL.path}?Id=${original.RefID}`;
      break;
    case 'poi':
      linkPath = `${AppRoutes.protected.LISTINGS.children.POI.path}?Id=${original.RefID}`;
      break;
    default:
      linkPath = `${AppRoutes.protected.LISTINGS.children.ZIP.path}?Id=${original.RefID}`;
      break;
  }
  return linkPath;
};

const priorityOptionLabel = ({ id }) => (
  <div className="d-flex">
    <Badge
      bg={`soft-${Constants.Review.Priority[id].color} text-${Constants.Review.Priority[id].color}`}
    >
      <i className={`${Constants.Review.Priority[id].icon} fs-6 me-1`} />
      <FormattedMessage
        id={`app.common.${Constants.Review.Priority[id].labelKey}`}
      />
    </Badge>
  </div>
);
const eventTypesOptionLabel = ({ id }) => (
  <div className="d-flex">
    <Badge
      bg={`soft-${Constants.EventTypes.Types[id].color} text-${Constants.EventTypes.Types[id].color}`}
    >
      <i className={`${Constants.EventTypes.Types[id].icon} fs-6 me-1`} />
      <FormattedMessage
        id={`app.common.${Constants.EventTypes.Types[id].labelKey}`}
      />
    </Badge>
  </div>
);
function getTitleByRefTable(RefTable) {
  switch (RefTable) {
    case 'homes':
      return 'Listing';
    case 'realtor.realtor':
      return 'Realtor';
    case 'admin_operism.businesses':
      return 'Business';
    case 'Bidlaw.users':
      return 'Lawyer';
    case 'bestplaces':
      return 'Neighborhood';
    case 'poi':
      return 'Point of Interest';
    case 'schools':
      return 'School';
    case 'gtfs_stops':
      return 'Transit Stop ';
    case 'article':
      return 'Project';
    default:
      return 'Information';
  }
}
function Favorites() {
  const tableRef = useRef();
  const filterFormRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filtersIsVisible, setFiltersIsVisible] = useState(false);
  const [filters, setFilters] = useState({});
  const { formatMessage } = useIntl();

  const { form } = useFormModal();
  const { showToast } = useToast();

  const { data: categoriesData, isLoading: categoriesDataIsLoading } =
    useAxiosQuery({
      url: '/categories/list',
      params: { quantity: 999999 },
    });

  const onActionRow = useCallback(
    async (id, type, original) => {
      const size = 'lg';
      const title = type;
      const confirmLabel = type;
      let requestUrl = '/reviews/insert';
      const requestParams = { RefID: id, RefTable: original.RefTable };
      let fetchOnStart;
      let nFields;
      let message;

      switch (type) {
        case 'addEvent':
          requestUrl = '/calendar/insert';
          nFields = [
            {
              cols: [
                {
                  key: 'title',
                  apiKey: 'Title',
                  schema: yup.string().required(),
                },
              ],
            },
            {
              cols: [
                {
                  key: 'date',
                  apiKey: 'Date',
                  type: 'dateRangePicker',
                  options: {
                    controller: {
                      initialSettings: { timePicker: true },
                      formatter: (value) => {
                        if (value) {
                          return [
                            moment(value[0]).format(
                              Constants.DateFormats.APIWithTime
                            ),
                            moment(value[1]).format(
                              Constants.DateFormats.APIWithTime
                            ),
                          ];
                        }
                        return value;
                      },
                    },
                  },
                  schema: yup.array().min(2).nullable().required(),
                },
              ],
            },
            {
              cols: [
                {
                  key: 'description',
                  apiKey: 'Description',
                  type: 'textarea',
                  schema: yup.string().nullable(),
                },
              ],
            },
            {
              cols: [
                {
                  key: 'EventType',
                  apiKey: 'EventType',
                  type: 'react-select',
                  options: {
                    controller: {
                      props: {
                        options: Object.values(Constants.EventTypes.Types),
                        getOptionValue: (option) => option.apiId,
                        formatOptionLabel: eventTypesOptionLabel,
                        isMulti: false,
                        isSearchable: false,
                        isClearable: true,
                      },
                    },
                  },
                  schema: yup.string().nullable(),
                },
              ],
            },
          ];
          break;
        case 'addNote':
          requestUrl = '/reviews/insert';
          nFields = [
            {
              cols: [
                {
                  key: 'comment',
                  apiKey: 'Comment',
                  type: 'textarea',
                  schema: yup.string().required(),
                },
              ],
            },
            {
              cols: [
                {
                  key: 'type',
                  apiKey: 'Rating',
                  type: 'react-select',
                  options: {
                    controller: {
                      props: {
                        options: Object.values(Constants.Review.Types),
                        getOptionValue: (option) => option.apiId,
                        formatOptionLabel: typeOptionLabel,
                        isMulti: false,
                        isSearchable: false,
                        isClearable: true,
                      },
                    },
                  },
                  schema: yup.string().nullable(),
                },
              ],
            },
            {
              cols: [
                {
                  key: 'priority',
                  apiKey: 'Priority',
                  type: 'react-select',
                  options: {
                    controller: {
                      props: {
                        options: Object.values(Constants.Review.Priority),
                        getOptionValue: (option) => option.apiId,
                        formatOptionLabel: priorityOptionLabel,
                        isMulti: false,
                        isSearchable: false,
                        isClearable: true,
                      },
                    },
                  },
                  schema: yup.string().nullable(),
                },
              ],
            },
          ];
          break;

        case 'addToFavorites':
          requestUrl = '/favorites/insert';
          message = '.';
          fetchOnStart = true;
          requestParams.is_favorite = 1;
          break;

        case 'removeFromFavorites':
          requestUrl = '/favorites/deleteWithRef';
          message = '';
          fetchOnStart = true;
          requestParams.is_favorite = 0;
          break;

        default:
          break;
      }

      const isSuccess = await form({
        size,
        title,
        confirmLabel,
        requestUrl,
        requestParams,
        fields: nFields,
        message,
        fetchOnStart,
      });

      if (isSuccess) {
        tableRef.current.reload();

        if (type === 'addToFavorites' || type === 'removeFromFavorites') {
          showToast({
            type: 'success',
            autohide: true,
            title: <FormattedMessage id="app.common.success" />,
            message: '',
          });
        }
      }
    },
    [form, showToast]
  );

  const columns = [
    {
      Header: <FormattedMessage id="app.common.photo" />,
      accessor: 'Ref.photo',
      Cell: useCallback(
        ({
          cell: {
            value,
            row: { original },
          },
        }) => (
          <div className="position-relative" style={{ width: 80 }}>
            <Link to={generateLink(original)} className="stretched-link">
              <Image
                src={value || PlaceHolderImg}
                onError={onImageError}
                style={{ height: 75, width: 100, objectFit: 'cover' }}
              />
              {original.revCount > 0 && (
                <div className="position-absolute bottom-0 end-0 zi-1">
                  <Badge bg="danger" pill className="fs-6">
                    {original.revCount}
                  </Badge>
                </div>
              )}
            </Link>
          </div>
        ),
        []
      ),
    },

    {
      Header: <FormattedMessage id="app.common.title" />,
      accessor: 'Ref.title',
      wrap: false,
      Cell: useCallback(
        ({ cell: { value } }) => (
          <HighlightedText text={value} searchText={filters?.search || null} />
        ),
        [filters?.search]
      ),
    },

    {
      Header: <FormattedMessage id="app.common.description" />,
      accessor: 'RefTable',
      wrap: true,
      Cell: ({ cell: { value } }) => getTitleByRefTable(value),
    },

    {
      accessor: 'RefID',
      Cell: useCallback(
        ({
          cell: {
            value,
            row: { original },
          },
        }) => {
          let linkPath = '';
          let label = 'listingsNearby';
          let icon = 'bi-search me-1';
          switch (original.RefTable) {
            case 'homes':
              icon = 'bi-eye me-1';
              label = 'view';
              linkPath = `${AppRoutes.protected.LISTINGS_DETAIL.path.replace(
                ':id',
                value
              )}`;
              break;
            case 'admin_operism.businesses':
              icon = 'bi-eye me-1';
              label = 'view';
              linkPath = `${AppRoutes.protected.SMALLBUSINESS_DETAIL.path.replace(
                ':id',
                value
              )}`;
              break;

            case 'realtor.realtor':
              icon = 'bi-eye me-1';
              label = 'view';
              linkPath = `${AppRoutes.protected.REALTOR_DETAIL.path.replace(
                ':id',
                value
              )}`;
              break;
            case 'Bidlaw.users':
              icon = 'bi-eye me-1';
              label = 'view';
              linkPath = `${AppRoutes.protected.LAWYER_DETAIL.path.replace(
                ':id',
                value
              )}`;
              break;
            case 'article':
              linkPath = `${AppRoutes.protected.LISTINGS.children.ARTICLE.path}?Id=${value}`;
              break;
            case 'schools':
              linkPath = `${AppRoutes.protected.LISTINGS.children.SCHOOL.path}?Id=${value}`;
              break;
            case 'poi':
              linkPath = `${AppRoutes.protected.LISTINGS.children.POI.path}?Id=${value}`;
              break;

            default:
              linkPath = `${AppRoutes.protected.LISTINGS.children.ZIP.path}?Id=${value}`;
              break;
          }

          return (
            <Dropdown align="end">
              <ButtonGroup>
                <Button variant="white" size="sm" as={Link} to={linkPath}>
                  <i className={icon} />
                  <FormattedMessage id={`app.common.${label}`} />
                </Button>
                <ButtonGroup>
                  <Dropdown.Toggle
                    variant="white"
                    size="sm"
                    className="btn-icon dropdown-toggle-empty"
                  />
                </ButtonGroup>
              </ButtonGroup>
              <Dropdown.Menu className="m-0" renderOnMount>
                <Dropdown.Header>
                  <FormattedMessage id="app.common.actions" />
                </Dropdown.Header>

                <Dropdown.Item
                  onClick={() => {
                    onActionRow(value, 'removeFromFavorites', original);
                  }}
                >
                  <i className="bi-heart dropdown-item-icon" />
                  <span>
                    <FormattedMessage id="app.common.removeFromFavorites" />
                  </span>
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={() => {
                    onActionRow(value, 'addEvent', original);
                  }}
                >
                  <i className="bi-calendar-plus dropdown-item-icon text-primary" />
                  <span className="text-primary">
                    <FormattedMessage id="app.common.newEvent" />
                  </span>
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    onActionRow(value, 'addNote', original);
                  }}
                >
                  <i className="bi-chat-right-text-fill dropdown-item-icon text-info" />
                  <span className="text-info">
                    <FormattedMessage id="app.common.newNote" />
                  </span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          );
        },
        [onActionRow]
      ),
    },
  ];

  const filterFields = useMemo(
    () => [
      {
        cols: [
          {
            key: 'ZIP',
            apiKey: 'zip',
          },
          {
            key: 'City',
            apiKey: 'citylabel',
          },

          {
            key: 'state',
            apiKey: 'state',
            type: 'react-select',
            options: {
              controller: {
                props: {
                  options: Object.values(Constants.States),
                  getOptionValue: (option) => `${option.abbreviation}`,
                  getOptionLabel: (option) => `${option.name}`,
                  isClearable: true,
                  isMulti: false,
                  isSearchable: true,
                },
              },
            },
            schema: yup.string().nullable(),
          },
        ],
      },
    ],
    [categoriesData, categoriesDataIsLoading]
  );

  const parseSearchParams = useCallback(() => {
    const params = {};
    searchParams.forEach((value, key) => {
      let isArray = false;
      filterFields.forEach((row) => {
        row.cols.forEach((col) => {
          if (
            col.key === key &&
            (col.options?.controller?.props?.isMulti || col.key === 'dateRange')
          ) {
            isArray = true;
          }
        });
      });

      if (!params[key]) {
        params[key] = !isArray ? value : [value];
      } else {
        params[key] = [
          ...(Array.isArray(params[key]) ? params[key] : [params[key]]),
          value,
        ];
      }
    });
    return params;
  }, [searchParams, filterFields]);

  const handleSearch = (text) => {
    const params = parseSearchParams(true);
    if (text) {
      params.search = text;
    } else {
      delete params.search;
    }
    setSearchParams(params);
  };

  const onSearch = debounce((text) => {
    handleSearch(text);
  }, 100);

  useEffect(() => {
    const params = parseSearchParams(true);
    const nParams = params.search ? { search: params.search } : {};
    filterFields.forEach((row) => {
      row.cols.forEach((col) => {
        if (params[col.key]) {
          nParams[col.apiKey || col.key] = params[col.key];
        }
      });
    });
    setFilters(nParams);
  }, [parseSearchParams, filterFields]);

  return (
    <div className="content container">
      <Row>
        <Col>
          <Card>
            <Card.Header className="card-header-content-md-between">
              <div className="mb-2 mb-md-0">
                <div className="input-group input-group-merge input-group-borderless">
                  <div className="input-group-prepend input-group-text">
                    <i className="bi-search" />
                  </div>
                  <Form.Control
                    type="search"
                    placeholder={formatMessage({
                      id: 'app.common.search',
                    })}
                    defaultValue={parseSearchParams().search || ''}
                    onChange={(e) => {
                      if (e.target.value) {
                        onSearch(e.target.value);
                      } else {
                        handleSearch(e.target.value);
                      }
                    }}
                  />
                </div>
              </div>

              <div className="d-grid d-sm-flex align-items-sm-center gap-2">
                <div id="datatableCounterInfo" style={{ display: 'none' }}>
                  <div className="d-flex align-items-center">
                    <span className="fs-5 me-3">
                      <span id="datatableCounter">0</span> Selected
                    </span>

                    <a className="btn btn-outline-danger btn-sm" href="#!">
                      <i className="bi-trash" /> Delete
                    </a>
                  </div>
                </div>

                <Button
                  variant="white"
                  size="sm"
                  className="dropdown-toggle"
                  onClick={() => {
                    setFiltersIsVisible(!filtersIsVisible);
                  }}
                >
                  <i className="bi-filter me-1" />
                  <FormattedMessage id="app.common.filters" />
                  {Object.keys(filters).filter((key) => key !== 'search')
                    .length > 0 && (
                    <Badge
                      bg="soft-dark"
                      className="text-dark rounded-circle ms-1"
                    >
                      {
                        Object.keys(filters).filter((key) => key !== 'search')
                          .length
                      }
                    </Badge>
                  )}
                </Button>
              </div>
            </Card.Header>

            <Collapse in={filtersIsVisible}>
              <div id="filter-collapse">
                <Card.Body>
                  <SmartForm
                    ref={filterFormRef}
                    fields={filterFields}
                    submitButtonText="applyFilters"
                    clearButtonText="clearFilters"
                    isFilterForm
                    disableApiKey
                    onSubmit={(formData) => {
                      const params = {};
                      if (formData) {
                        Object.keys(formData).forEach((key) => {
                          if (formData[key]) {
                            params[key] = formData[key];
                          }
                        });
                      }

                      if (parseSearchParams().search) {
                        params.search = parseSearchParams().search;
                      }

                      setSearchParams(params);
                    }}
                    defaultValues={parseSearchParams()}
                  />
                </Card.Body>
              </div>
            </Collapse>

            <SmartTable
              ref={tableRef}
              columns={columns}
              requestUrl="/favorites/list"
              requestParams={{ x: 3 }}
              filters={filters}
              hoverable
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Favorites;
