export const Status = {
  Available: { id: 'Available', labelKey: 'available', color: 'success' },
  Busy: { id: 'Busy', labelKey: 'busy', color: 'danger' },
  Away: { id: 'Away', labelKey: 'away', color: 'warning' },
  8: { id: 8, labelKey: 'active', color: 'success' },
  9: { id: 9, labelKey: 'inactive', color: 'danger' },
  10: { id: 10, labelKey: 'waitingConfirmation', color: 'info' },
  11: { id: 11, labelKey: 'waitingVerification', color: 'primary' },
};

export const Roles = {
  Guest: { id: 'Guest', labelKey: 'guest' },
  Admin: { id: 'Admin', labelKey: 'admin' },
};

export const Types = {
  1: {
    id: 1,
    labelKey: 'Client',
    color: 'primary',
    description: 'I Want to Find the Best Lawyer for My Budget',
  },
  2: {
    id: 2,
    labelKey: 'Posting Attorney / Firm',
    color: 'primary',
    description: 'I Want to Bid to Represent a New Client',
  },
  3: {
    id: 3,
    labelKey: 'Bidding Attorney / Firm',
    color: 'primary',
    description:
      'I Want to Offer a Job to a Court Appearance Attorney or Another Legal Professional',
  },
  4: {
    id: 4,
    labelKey: 'Temp Attorney',
    color: 'primary',
    description: `I'm a Temp Attorney, I provide temporary legal services or consultation as needed.`,
  },
  5: {
    id: 5,
    labelKey: 'Temp Paralegal',
    color: 'primary',
    description: `I'm a Temp Paralegal, I offer temporary paralegal services including legal research, documentation and more.`,
  },
  6: {
    id: 6,
    labelKey: 'Mediator',
    color: 'primary',
    description: `I'm a Mediator, I facilitate the resolution of disputes between parties as a neutral third party.`,
  },
  7: {
    id: 7,
    labelKey: 'Court Reporter',
    color: 'primary',
    description: `I'm a Court Reporter, I record court proceedings verbatim using stenotype machine or voice writing equipment.`,
  },
};
