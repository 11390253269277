import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card } from 'react-bootstrap';

import { useParams } from 'react-router-dom';
import { RequestLoading, RequestResult } from '../../../components';
import { useAxiosQuery } from '../../../hooks';
import ProfileForm from '../AI/MyProfiles/partials/ProfileForm';

export function InfoDataView({ id }) {
  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/profiles/select',
    preventFetch: !id,
    params: {
      id,
    },
  });

  // eslint-disable-next-line no-unused-vars
  const renderValue = (key, value, rowData) => {
    // console.log(rowData);
    let nValue = (
      <Col xs="auto">
        <h5>{value}</h5>
      </Col>
    );

    switch (key) {
      case 'xxx':
        nValue = <Col xs="auto" />;
        break;

      default:
        break;
    }

    return nValue;
  };

  return (
    <>
      <RequestLoading loading={apiLoading} size="lg" margin="5" />
      <RequestResult type="error" message={apiError} />

      {!apiLoading && !apiError && (
        <div className="content container">
          <Row>
            <Col xs="12">
              <ProfileForm
                paths={{
                  profileJson: '/app/data/profile.json', // get request only, no params
                  parameters: '/Parameters/list',
                  list: '/profile_parameter_points/list',
                  listParams: { profile_id: apiData.id },
                  create: '/profile_parameter_points/insert',
                  createParams: { profile_id: apiData.id },
                  delete: '/profile_parameter_points/delete',
                  // update: '/profile_parameter_points/update',
                }}
              />
            </Col>
            <Col>
              <Card>
                <Card.Header className="card-header-content-md-between">
                  <div className="mb-2 mb-md-0">
                    <div className="input-group input-group-merge input-group-borderless" />
                  </div>

                  <div className="d-grid d-sm-flex align-items-sm-center gap-2">
                    <div id="datatableCounterInfo" style={{ display: 'none' }}>
                      <div className="d-flex align-items-center">
                        <span className="fs-5 me-3">
                          <span id="datatableCounter">0</span> Selected
                        </span>

                        <a className="btn btn-outline-danger btn-sm" href="#!">
                          <i className="bi-trash" /> Delete
                        </a>
                      </div>
                    </div>
                  </div>
                </Card.Header>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}

InfoDataView.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

InfoDataView.defaultProps = {
  id: null,
};

function Info() {
  const { id } = useParams();

  return <InfoDataView id={id} />;
}

export default Info;
