import React, { createContext, useMemo, useReducer, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { orderBy } from 'lodash';
import { useAxiosQuery } from '../hooks';
import { RequestLoading, RequestResult } from '../components';
import Constants from '../constants';

const ProfileContext = createContext();

const initialState = {
  activeProfile: null,
  profiles: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_PROFILES':
      return {
        ...state,
        profiles: action.payload.profiles,
      };
    case 'UPDATE_ACTIVE_PROFILE':
      localStorage.setItem('activeProfileId', action.payload.profile.id);
      return {
        ...state,
        activeProfile: action.payload.profile,
      };

    case 'ADD_PROFILE': {
      localStorage.setItem('activeProfileId', action.payload.profile.id);

      const sortedProfiles = orderBy(
        [...state.profiles, action.payload.profile],
        ['locked'],
        ['desc']
      );

      return {
        ...state,
        profiles: sortedProfiles,
        activeProfile: action.payload.profile,
      };
    }
    case 'REMOVE_PROFILE': {
      const nProfiles = state.profiles.filter(
        (item) => item.id !== action.payload.profileId
      );
      let nActiveProfile = state.activeProfile;
      if (
        state.activeProfile.id === action.payload.profileId &&
        nProfiles.length > 0
      ) {
        [nActiveProfile] = nProfiles;
      }
      if (nProfiles.length === 0) {
        nActiveProfile = null;
      }
      return {
        ...state,
        profiles: [...nProfiles],
        activeProfile: nActiveProfile,
      };
    }
    case 'UPDATE_PROFILE': {
      const nProfiles = state.profiles.map((profile) => {
        if (profile.id === action.payload.profile.id) {
          return action.payload.profile;
        }
        return profile;
      });
      let nActiveProfile = state.activeProfile;
      if (state.activeProfile.id === action.payload.profile.id) {
        nActiveProfile = action.payload.profile;
      }
      return {
        ...state,
        profiles: [...nProfiles],
        activeProfile: nActiveProfile,
      };
    }
    default:
      return state;
  }
};

function ProfileProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const updateProfiles = useCallback(
    (profiles) => {
      dispatch({
        type: 'UPDATE_PROFILES',
        payload: {
          profiles,
        },
      });
    },
    [dispatch]
  );

  const updateActiveProfile = useCallback(
    (profile) => {
      dispatch({
        type: 'UPDATE_ACTIVE_PROFILE',
        payload: {
          profile,
        },
      });
    },
    [dispatch]
  );

  const addProfile = useCallback(
    (profile) => {
      dispatch({
        type: 'ADD_PROFILE',
        payload: {
          profile,
        },
      });
    },
    [dispatch]
  );

  const removeProfile = useCallback(
    (profileId) => {
      dispatch({
        type: 'REMOVE_PROFILE',
        payload: {
          profileId,
        },
      });
    },
    [dispatch]
  );

  const updateProfile = useCallback(
    (profile) => {
      dispatch({
        type: 'UPDATE_PROFILE',
        payload: {
          profile,
        },
      });
    },
    [dispatch]
  );

  const {
    isLoading: apiLoading,
    error: apiError,
    refetch: apiRefetch,
  } = useAxiosQuery({
    url: '/profiles/list',
    onSuccess: (apiData) => {
      const preData = Object.values(Constants.RealEstate.userProfiles).map(
        ({ id, labelKey: name, description }) => ({
          id,
          name,
          description,
          locked: true,
        })
      );
      const data = [...apiData, ...preData];

      updateProfiles(data);

      if (data.length > 0) {
        let savedActiveProfileId;
        try {
          savedActiveProfileId = JSON.parse(
            localStorage.getItem('activeProfileId') || null
          );
        } catch (e) {
          savedActiveProfileId = null;
        }

        let savedActiveProfile;
        if (savedActiveProfileId) {
          savedActiveProfile = data.find(
            (nItem) => nItem.id === savedActiveProfileId
          );
        }
        updateActiveProfile(savedActiveProfile || data[0]);
      }
    },
  });
  const value = useMemo(
    () => ({
      activeProfile: state.activeProfile,
      updateActiveProfile,
      profiles: state.profiles,
      updateProfiles,
      addProfile,
      removeProfile,
      updateProfile,
      fetchProfiles: apiRefetch,
    }),
    [
      state,
      updateActiveProfile,
      updateProfiles,
      addProfile,
      removeProfile,
      updateProfile,
      apiRefetch,
    ]
  );

  return (
    <ProfileContext.Provider value={value}>
      {(apiLoading || apiError) && (
        <Row className="justify-content-center my-5">
          <Col lg="8">
            <RequestLoading loading={apiLoading} size="lg" margin="5" />
            <RequestResult type="error" message={apiError} image />
          </Col>
        </Row>
      )}

      {!apiLoading && !apiError && children}
    </ProfileContext.Provider>
  );
}

ProfileProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { ProfileContext, ProfileProvider };
