import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Button, Card, Col, Nav, Row, Tab } from 'react-bootstrap';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Link } from 'react-router-dom';
import { useAxiosQuery } from '../../../../hooks';
import { RequestLoading, RequestResult } from '../../../../components';
import { Routes as AppRoutes } from '../../../../router/routeMapping';

function TabLast5Listings({ zip }) {
  const [activeType, setActiveType] = useState();
  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/dashboard/ListingsByType',
    preventFetch: !zip,
    params: { zip },
  });

  useEffect(() => {
    if (!activeType && apiData && Object.keys(apiData).length > 0) {
      setActiveType(Object.keys(apiData)[0]);
    }
  }, [activeType, apiData]);

  return (
    <>
      <RequestLoading loading={apiLoading} size="lg" margin="5" />
      <RequestResult type="error" message={apiError} />

      {!apiLoading && !apiError && apiData && Object.keys(apiData).length > 0 && (
        <Tab.Container activeKey={activeType}>
          <Card>
            <Card.Header>
              <Row className="justify-content-between align-items-center flex-grow-1">
                <Col md className="mb-3 mb-md-0 mb-lg-3 mb-xl-0">
                  <Card.Title bsPrefix="card-header-title" as="h4">
                    <FormattedMessage id="app.common.lastListings" />
                  </Card.Title>
                </Col>
                <Col xs="auto">
                  <Nav
                    variant="segment"
                    fill
                    as="ul"
                    activeKey={activeType}
                    onSelect={setActiveType}
                  >
                    {Object.keys(apiData).map((key) => {
                      const section = apiData[key];
                      return (
                        <Nav.Item key={key} as="li">
                          <Nav.Link eventKey={key}>
                            <FormattedMessage id={`app.common.${key}`} />
                            <span className="ms-1">{`(${section.total})`}</span>
                          </Nav.Link>
                        </Nav.Item>
                      );
                    })}
                  </Nav>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <Tab.Content>
                {Object.keys(apiData).map((key) => {
                  const section = apiData[key];
                  return (
                    <Tab.Pane key={key} eventKey={key}>
                      {section.total > 0 ? (
                        <Row className="d-flex">
                          {section.last5.map((item, index) =>
                            index < 4 ? (
                              <Col md="6" lg="3" key={item.id} className="mb-3">
                                <Card className="h-100">
                                  <Card.Img
                                    variant="top"
                                    src={item.ImageURL}
                                    style={{
                                      height: '10rem',
                                      objectFit: 'cover',
                                    }}
                                  />
                                  <Card.Body>
                                    <h4>
                                      <FormattedNumber
                                        value={item.price}
                                        // eslint-disable-next-line react/style-prop-object
                                        style="currency"
                                        currency={item.currency || 'USD'}
                                      />
                                    </h4>
                                    <span>{item.address}</span>
                                    <Link
                                      className="stretched-link"
                                      to={`${AppRoutes.protected.LISTINGS_DETAIL.path.replace(
                                        ':id',
                                        item.id
                                      )}`}
                                    />
                                  </Card.Body>
                                </Card>
                              </Col>
                            ) : null
                          )}
                        </Row>
                      ) : (
                        <RequestResult
                          type="secondary"
                          message="app.common.noListings"
                          image
                        />
                      )}
                    </Tab.Pane>
                  );
                })}
              </Tab.Content>
            </Card.Body>
            {apiData[activeType]?.total > 0 && (
              <Card.Footer className="text-center">
                <Button
                  as={Link}
                  to={`${AppRoutes.protected.LISTINGS.path}/${
                    activeType ? activeType.toLowerCase() : ''
                  }`}
                >
                  <FormattedMessage id="app.common.viewAll" />
                </Button>
              </Card.Footer>
            )}
          </Card>
        </Tab.Container>
      )}
    </>
  );
}

TabLast5Listings.propTypes = {
  zip: PropTypes.string.isRequired,
};

export default TabLast5Listings;
