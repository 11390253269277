import React, { useContext, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import ReactSelect from 'react-select';
import { Link } from 'react-router-dom';
import { PageHeader } from '../../components';
import CardCity from './ListingsDetail/partials/CardCity';
import { AuthContext } from '../../context/AuthContext';
import { useFormModal } from '../../hooks';
import { yup } from '../../lib';
import { Routes as AppRoutes } from '../../router/routeMapping';

const DEFAULT_ZIP = 90210;

function Overview() {
  const { formatMessage } = useIntl();
  const { user, update } = useContext(AuthContext);
  const { form } = useFormModal();
  const [activeZip, setActiveZip] = useState(
    user.zips?.[0]?.zip || DEFAULT_ZIP
  );

  const handleAddZip = async () => {
    const fields = [
      {
        cols: [
          {
            key: 'zip',
            apiKey: 'zip',
            type: 'maskedInput',
            options: {
              controller: {
                mask: '99999',
                maskOptions: {
                  autoUnmask: true,
                },
              },
            },
            schema: yup
              .string()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable()
              .required()
              .matches(
                /^[0-9]{5}$/,
                formatMessage({ id: 'app.common.Must be exactly 5 digits' })
              ),
          },
        ],
      },
    ];
    const formData = await form({
      size: 'sm',
      title: 'add',
      confirmLabel: 'add',
      requestUrl: '/rel_users_zip/insert',
      requestParams: {},
      fields,
      fetchOnStart: false,
    });

    if (formData?.Result) {
      const zipToAdd = formData.Result;
      update({ user: { ...user, zips: [...user.zips, zipToAdd] } });
      setActiveZip(zipToAdd.zip);
    }
  };

  return (
    <div className="content container">
      <PageHeader title="dashboard">
        <Row className="align-items-sm-center">
          <Col sm="auto" className="mb-2 mb-sm-0">
            <Button
              as={Link}
              variant="warning"
              size="sm"
              to={AppRoutes.protected.AI.children.MYPROFILES.path}
            >
              <i className="bi-robot me-1" />
              <FormattedMessage id="app.common.addProfile" />
            </Button>
          </Col>
          <Col sm="auto" className="mb-2 mb-sm-0">
            <Row className="align-items-center gx-0">
              <Col>
                <span className="text-secondary me-2">
                  <FormattedMessage id="app.common.zip" />:
                </span>
              </Col>

              <Col xs="auto">
                <div style={{ width: 150 }}>
                  <ReactSelect
                    isSearchable={false}
                    options={[...user.zips]}
                    getOptionLabel={(option) => `${option.zip}`}
                    getOptionValue={(option) => `${option.id}`}
                    className="react-select-custom-container"
                    classNamePrefix="react-select-custom"
                    value={user.zips.find((item) => item.zip === activeZip)}
                    onChange={({ zip }) => {
                      setActiveZip(zip);
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Col>
          <Col sm="auto" className="mb-2 mb-sm-0">
            <Button size="sm" onClick={handleAddZip}>
              <i className="bi-plus-lg me-1" />
              <FormattedMessage id="app.common.addZip" />
            </Button>
          </Col>
        </Row>
      </PageHeader>

      <Row>
        <Col lg="12" className="mb-3 mb-lg-5">
          <CardCity zip={activeZip} />
        </Col>
      </Row>
    </div>
  );
}

export default Overview;
