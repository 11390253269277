import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Badge } from 'react-bootstrap';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { EconomicIndicators } from '../../../constants/realEstate';
import { RequestLoading, RequestResult, Tooltip } from '../../../components';
import { useAxiosQuery } from '../../../hooks';
import CardCity from '../ListingsDetail/partials/CardCity';

const mapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';

function Info() {
  const { id } = useParams();
  const { formatMessage } = useIntl();
  const [zipValue, setZipValue] = useState('');
  const keysToCheck = [
    'is_absenteeOwner',
    'is_adjustableRate',
    'is_auction',
    'is_bankOwned',
    'is_cashBuyer',
    'is_cashSale',
    'is_corporateOwned',
    'is_death',
    'is_deathTransfer',
    'is_deedInLieu',
    'is_floodZone',
    'is_freeClear',
    'is_highEquity',
    'is_inherited',
    'is_inStateAbsenteeOwner',
    'is_investorBuyer',
    'is_lien',
    'is_MFH2to4',
    'is_MFH5plus',
    'is_mobileHome',
    'is_outOfStateAbsenteeOwner',
    'is_ownerOccupied',
    'is_preForeclosure',
    'is_privateLender',
    'is_sheriffsDeed',
    'is_spousalDeath',
    'is_taxLien',
    'is_trusteeSale',
    'is_vacant',
    'is_warrantyDeed',
    'is_quitClaim',
    'is_pool',
  ];
  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: 'properties/select',
    preventFetch: !id,
    params: { id },
  });

  useEffect(() => {
    if (apiData && apiData.zip) {
      setZipValue(apiData.zip);
    }
  }, [apiData]);

  return (
    <>
      <RequestLoading loading={apiLoading} size="lg" margin="5" />
      <RequestResult type="error" message={apiError} />

      {!apiLoading && !apiError && apiData && Object.keys(apiData).length > 0 && (
        <Row>
          <Col xs="12" className="mb-3 mb-lg-5">
            <Card className="overflow-hidden">
              <Row className="g-0">
                <Col lg="4">
                  <div className="position-relative">
                    <Card.Img
                      src={`https://maps.googleapis.com/maps/api/streetview?size=400x400&location=${apiData.latitude},${apiData.longitude}&fov=80&heading=70&pitch=0&key=${mapsApiKey}`}
                      className="w-100 h-100"
                      style={{
                        maxHeight: 300,
                        objectFit: 'cover',
                      }}
                    />
                    <Card.ImgOverlay
                      className="end-0 bottom-0"
                      style={{ left: 'auto', top: 'auto' }}
                    />
                  </div>
                </Col>
                <Col lg="8">
                  <Card.Body>
                    <div className="mb-3">
                      <div className="mb-3">
                        <span className="legend-indicator bg-info me-1" />
                        OFF MARKET
                      </div>
                      <h1 className="h4">
                        <span className="me-1">{apiData.full_address_new}</span>
                        <span className="fw-normal" />
                      </h1>
                    </div>
                    <div className="d-flex flex-column flex-md-row justify-content-between mb-5">
                      {apiData &&
                        apiData.estimatedValue &&
                        apiData.estimatedValue !== 0 && (
                          <div className="d-flex flex-column mb-3 mb-md-0 me-md-2">
                            <div className="h3">
                              <span className="text-muted">
                                <small>Est. value </small>
                              </span>
                              <FormattedNumber
                                value={apiData.estimatedValue}
                                // eslint-disable-next-line react/style-prop-object
                                style="currency"
                                currency="USD"
                                minimumFractionDigits={0}
                                maximumFractionDigits={0}
                              />
                            </div>
                          </div>
                        )}
                      {apiData.bedrooms > 0 && (
                        <div className="d-flex flex-column mb-3 mb-md-0 me-md-2">
                          <div className="h3">{apiData.bedrooms}</div>
                          <span>Beds</span>
                        </div>
                      )}
                      {apiData.bathrooms > 0 && (
                        <div className="d-flex flex-column mb-3 mb-md-0 me-md-2">
                          <div className="h3">{apiData.bathrooms}</div>
                          <span>Baths</span>
                        </div>
                      )}
                      {apiData.livingSquareFeet > 0 && (
                        <div className="d-flex flex-column">
                          <div className="h3">
                            {' '}
                            <FormattedNumber
                              value={apiData.livingSquareFeet}
                              // eslint-disable-next-line react/style-prop-object

                              minimumFractionDigits={0}
                              maximumFractionDigits={0}
                            />
                          </div>
                          <span>Sq Ft</span>
                        </div>
                      )}
                    </div>

                    <div className="mb-5">
                      {apiData &&
                        keysToCheck.map(
                          (key) =>
                            apiData[key] === 1 && (
                              <Tooltip
                                key={key}
                                content={formatMessage({
                                  id: `app.toolTips.${key}`,
                                })}
                              >
                                <Badge
                                  bg="light"
                                  text="body"
                                  className="me-2 mb-2 fs-6"
                                >
                                  <FormattedMessage id={`app.common.${key}`} />
                                </Badge>
                              </Tooltip>
                            )
                        )}
                    </div>
                  </Card.Body>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.estimatedValue" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.estimatedValue',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>
                <Card.Title as="h2" className="text-inherit">
                  <FormattedNumber
                    value={apiData.estimatedValue}
                    // eslint-disable-next-line react/style-prop-object
                    style="currency"
                    currency="USD"
                    minimumFractionDigits={0}
                    maximumFractionDigits={0}
                  />
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle as="h6">
                  <FormattedMessage id="app.common.propertyType" />
                </Card.Subtitle>
                <Card.Title as="h2" className="text-inherit">
                  {apiData.propertyType}
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          {apiData.lastSaleDate && apiData.lastSaleDate !== 0 && (
            <Col md="6" lg="3" className="mb-3 mb-lg-5">
              <Card className="card-hover-shadow h-100">
                <Card.Body>
                  <Card.Subtitle as="h6">
                    <FormattedMessage id="app.common.lastSaleDate" />
                  </Card.Subtitle>
                  <Card.Title as="h2" className="text-inherit">
                    {apiData.lastSaleDate}
                  </Card.Title>
                </Card.Body>
              </Card>
            </Col>
          )}

          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.age" />
                  <Tooltip content={formatMessage({ id: 'app.toolTips.age' })}>
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>
                <Card.Title as="h2" className="text-inherit">
                  {Number(moment().format('YYYY')) - apiData.yearBuilt}
                </Card.Title>
                <Badge
                  bg={`soft-${
                    Number(
                      Number(moment().format('YYYY')) - apiData.yearBuilt
                    ) < EconomicIndicators.US_AVERAGE_HOME_AGE
                      ? 'success'
                      : 'danger'
                  }`}
                  text={`${
                    Number(
                      Number(moment().format('YYYY')) - apiData.yearBuilt
                    ) < EconomicIndicators.US_AVERAGE_HOME_AGE
                      ? 'success'
                      : 'danger'
                  }`}
                >
                  {`The US average is ${EconomicIndicators.US_AVERAGE_HOME_AGE}.`}
                </Badge>
                {apiData.lastSalePrice && apiData.lastSalePrice !== 0 && (
                  <Badge bg="soft-info" text="info">
                    Median home price average is&nbsp;
                    <FormattedNumber
                      value={apiData.lastSalePrice}
                      // eslint-disable-next-line react/style-prop-object
                      style="currency"
                      currency="USD"
                      minimumFractionDigits={0}
                      maximumFractionDigits={0}
                    />
                    .
                  </Badge>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}

      <Row>
        <Col lg="12" className="mb-3 mb-lg-5">
          <CardCity zip={zipValue} />
        </Col>
      </Row>
    </>
  );
}

export default Info;
