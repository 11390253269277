import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import { ThemeContext } from '../context/ThemeContext';
import Utils from '../utils';
import RequestLoading from './RequestLoading';

function SmartLottie({ src, darkSrc, onError, options, ...props }) {
  const { theme } = useContext(ThemeContext);
  const [loading, setLoading] = useState(true);
  const [animationData, setAnimationData] = useState();

  const getUrl = (nSrc) => {
    if (Utils.String.checkUrlProtocol(nSrc)) {
      return nSrc;
    }

    let filename = nSrc;
    if (filename.indexOf('.json') < 0) {
      filename += '.json';
    }

    return `/assets/lotties/${filename}`;
  };

  useEffect(() => {
    setLoading(true);
    const activeSrc =
      darkSrc && theme === 'dark' ? getUrl(darkSrc) : getUrl(src);
    fetch(activeSrc)
      .then((response) => response.json())
      .then((data) => {
        setAnimationData(data);
        setLoading(false);
      })
      .catch((error) => {
        onError(error);
        setLoading(false);
      });
  }, [src, darkSrc, theme, onError]);

  if (loading) {
    return <RequestLoading loading={loading} margin="5" />;
  }

  return animationData ? (
    <div {...props}>
      <Lottie options={{ ...options, animationData }} />
    </div>
  ) : null;
}

SmartLottie.propTypes = {
  src: PropTypes.string.isRequired,
  darkSrc: PropTypes.string,
  onError: PropTypes.func,
  options: PropTypes.objectOf(PropTypes.any),
};

SmartLottie.defaultProps = {
  darkSrc: null,
  onError: () => {},
  options: {
    loop: true,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  },
};

export default SmartLottie;
