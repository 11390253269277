import React from 'react';
import { useParams } from 'react-router-dom';
import LineChartCardMarketDemand from '../dashboard/ListingsDetail/partials/LineChartCardMarketDemand';

function Reports() {
  const { token } = useParams();

  return <LineChartCardMarketDemand zip="90210" token={token} />;
}

export default Reports;
