import React, { forwardRef, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Nav, Tab } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

const SmartWizard = forwardRef(
  (
    { steps, withStepInfo, disabled, onChange, onStepClick, renderAll },
    ref
  ) => {
    const [activeStep, setActiveStep] = useState(0);
    const [unlockedSteps, setUnlockedSteps] = useState([0]);

    const handleStepChange = (key) => {
      setActiveStep(Number(key));
      const unlockItems = [];
      steps.forEach((item, i) => {
        if (i < Number(key)) {
          unlockItems.push(i);
        }
      });
      setUnlockedSteps(unlockItems);
      onChange(key);
      // setError(null);
    };

    const getUnlockedStatus = (key) => unlockedSteps.indexOf(key) > -1;

    const getStepIcon = (step) =>
      getUnlockedStatus(step) ? (
        <i className="bi-check-circle text-primary me-2" />
      ) : null;

    const getStepInfo = (step) =>
      withStepInfo && step.info ? <div>{step.info}</div> : null;

    const goToPrevNextStep = (dir) => {
      let nextIndex = activeStep + 1;
      if (dir === 'prev') {
        nextIndex = activeStep - 1;
      }

      const nextStep = steps[nextIndex];
      if (nextStep) {
        handleStepChange(nextIndex);
      }
    };

    useImperativeHandle(ref, () => ({
      next: () => {
        goToPrevNextStep('next');
      },
      prev: () => {
        goToPrevNextStep('prev');
      },
    }));

    return (
      <div>
        <Tab.Container activeKey={activeStep} onSelect={handleStepChange}>
          <ul className="step step-md step-icon-xs step-centered">
            {steps.map((step, index) => (
              <li
                key={`${index.toString()}`}
                className={`step-item ${
                  activeStep.toString() === index.toString()
                    ? 'active focus'
                    : ''
                }`}
              >
                <Nav.Link
                  eventKey={index.toString()}
                  bsPrefix="step-content-wrapper"
                  disabled={disabled || !getUnlockedStatus(index)}
                  onClick={(e) => {
                    e.preventDefault();
                    onStepClick(index.toString());
                  }}
                >
                  <span className="step-icon step-icon-soft-primary">
                    {index + 1}
                  </span>
                  <div
                    className={`step-content ${
                      getStepInfo(step)
                        ? ''
                        : 'd-flex align-items-center d-md-block'
                    }`}
                  >
                    <h6
                      className={`step-title ${
                        getStepInfo(step) ? '' : 'mb-0'
                      }`}
                    >
                      {getStepIcon(index)}
                      <FormattedMessage id={`app.common.${step.titleKey}`} />
                    </h6>
                    {getStepInfo(step)}
                  </div>
                </Nav.Link>
              </li>
            ))}
          </ul>

          <Row>
            <Col lg={steps[activeStep].size}>
              <Tab.Content>
                {steps.map(({ Component }, index) => (
                  <Tab.Pane
                    key={`${index.toString()}`}
                    eventKey={index.toString()}
                  >
                    {(renderAll || (!renderAll && activeStep >= index)) &&
                      Component}
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    );
  }
);

SmartWizard.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  withStepInfo: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onStepClick: PropTypes.func,
  renderAll: PropTypes.bool,
};

SmartWizard.defaultProps = {
  withStepInfo: false,
  disabled: false,
  onChange: () => {},
  onStepClick: () => {},
  renderAll: true,
};

export default SmartWizard;
