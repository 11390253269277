import React, { forwardRef, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { RequestLoading, RequestResult } from '../../../../components';
import { useAxiosMutation } from '../../../../hooks';

const FinalSaveModal = forwardRef(({ onSuccess }, ref) => {
  const [show, setShow] = useState(false);
  const {
    isLoading: apiLoading,
    error: apiError,
    mutate: apiFetch,
  } = useAxiosMutation({
    url: '/profiles/add_def',
  });

  const fetch = (wizardData) => {
    const formData = {
      ...wizardData,
    };

    apiFetch(formData, {
      onSuccess,
    });
  };

  useImperativeHandle(ref, () => ({
    fetch: (wizardData) => {
      setShow(true);
      fetch(wizardData);
    },
    close: () => {
      setShow(false);
    },
  }));

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
      }}
      size="sm"
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title as="h5">
          <FormattedMessage id="app.common.creatingAccount" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <RequestLoading loading={apiLoading} />
        <RequestResult type="error" message={apiError} />
      </Modal.Body>

      {apiError && (
        <Modal.Footer>
          <Button
            onClick={() => {
              setShow(false);
            }}
            variant="white"
          >
            <FormattedMessage id="app.common.cancel" />
          </Button>
          <Button onClick={fetch} variant="danger">
            <i className="bi-exclamation-triangle me-1" />
            <FormattedMessage id="app.common.tryAgain" />
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
});

FinalSaveModal.propTypes = {
  onSuccess: PropTypes.func,
};

FinalSaveModal.defaultProps = {
  onSuccess: () => {},
};

export default FinalSaveModal;
