import React, { useCallback, useContext, useRef } from 'react';
import {
  Row,
  Col,
  Card,
  Button,
  Badge,
  ButtonGroup,
  Dropdown,
} from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { lowerCase } from 'lodash';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Routes as AppRoutes } from '../../../../router/routeMapping';
import { useConfirmModal, useFormModal, useToast } from '../../../../hooks';
import { PageHeader, ShowMoreText, SmartTable } from '../../../../components';
import { yup } from '../../../../lib';
import Constants from '../../../../constants';
import { ProfileContext } from '../../../../context/ProfileContext';

const typeOptionLabel = ({ id }) => (
  <div className="d-flex">
    <Badge bg={`${Constants.Review.Types[id].color}`}>
      <i className={`${Constants.Review.Types[id].icon} fs-6 me-1`} />
      <FormattedMessage
        id={`app.common.${Constants.Review.Types[id].labelKey}`}
      />
    </Badge>
  </div>
);

const priorityOptionLabel = ({ id }) => (
  <div className="d-flex">
    <Badge
      bg={`soft-${Constants.Review.Priority[id].color} text-${Constants.Review.Priority[id].color}`}
    >
      <i className={`${Constants.Review.Priority[id].icon} fs-6 me-1`} />
      <FormattedMessage
        id={`app.common.${Constants.Review.Priority[id].labelKey}`}
      />
    </Badge>
  </div>
);
const eventTypesOptionLabel = ({ id }) => (
  <div className="d-flex">
    <Badge
      bg={`soft-${Constants.EventTypes.Types[id].color} text-${Constants.EventTypes.Types[id].color}`}
    >
      <i className={`${Constants.EventTypes.Types[id].icon} fs-6 me-1`} />
      <FormattedMessage
        id={`app.common.${Constants.EventTypes.Types[id].labelKey}`}
      />
    </Badge>
  </div>
);

function MyProfiles() {
  const tableRef = useRef();
  const { formatMessage } = useIntl();
  const { addProfile } = useContext(ProfileContext);

  const { confirm } = useConfirmModal({ confirmVariant: 'danger' });
  const { form } = useFormModal();
  const { showToast } = useToast();

  const fields = [
    {
      cols: [
        {
          key: 'name',
          apiKey: 'name',
          schema: yup.string().required(),
        },
      ],
    },
    {
      cols: [
        {
          key: 'description',
          apiKey: 'description',
          type: 'textarea',
          options: { controller: { props: { rows: 5 } } },
          schema: yup.string().required(),
        },
      ],
    },
  ];

  const onAddNew = async () => {
    const data = await form({
      title: 'newProfile',
      confirmLabel: 'create',
      requestUrl: '/profiles/insert',
      size: 'xl',
      fields,
    });

    if (data?.status && data?.Result) {
      tableRef.current.reload();

      addProfile(data?.Result);

      showToast({
        type: 'success',
        autohide: true,
        title: <FormattedMessage id="app.common.success" />,
        message: (
          <FormattedMessage
            id="app.common.theXWasCreatedSuccessfully"
            values={{
              x: lowerCase(formatMessage({ id: 'app.common.profiles' })),
            }}
          />
        ),
      });
    }
  };

  const onActionRow = useCallback(
    async (id, type) => {
      const size = 'lg';
      const title = type;
      const confirmLabel = type;
      let requestUrl = '/reviews/insert';
      const requestParams = { RefID: id, RefTable: 'bidlaw.users' };
      let fetchOnStart;
      let nFields;
      let message;

      switch (type) {
        case 'addEvent':
          requestUrl = '/calendar/insert';
          nFields = [
            {
              cols: [
                {
                  key: 'title',
                  apiKey: 'Title',
                  schema: yup.string().required(),
                },
              ],
            },
            {
              cols: [
                {
                  key: 'date',
                  apiKey: 'Date',
                  type: 'dateRangePicker',
                  options: {
                    controller: {
                      initialSettings: { timePicker: true },
                      formatter: (value) => {
                        if (value) {
                          return [
                            moment(value[0]).format(
                              Constants.DateFormats.APIWithTime
                            ),
                            moment(value[1]).format(
                              Constants.DateFormats.APIWithTime
                            ),
                          ];
                        }
                        return value;
                      },
                    },
                  },
                  schema: yup.array().min(2).nullable().required(),
                },
              ],
            },
            {
              cols: [
                {
                  key: 'description',
                  apiKey: 'Description',
                  type: 'textarea',
                  schema: yup.string().nullable(),
                },
              ],
            },
            {
              cols: [
                {
                  key: 'EventType',
                  apiKey: 'EventType',
                  type: 'react-select',
                  options: {
                    controller: {
                      props: {
                        options: Object.values(Constants.EventTypes.Types),
                        getOptionValue: (option) => option.apiId,
                        formatOptionLabel: eventTypesOptionLabel,
                        isMulti: false,
                        isSearchable: false,
                        isClearable: true,
                      },
                    },
                  },
                  schema: yup.string().nullable(),
                },
              ],
            },
          ];
          break;
        case 'addNote':
          requestUrl = '/reviews/insert';
          nFields = [
            {
              cols: [
                {
                  key: 'comment',
                  apiKey: 'Comment',
                  type: 'textarea',
                  schema: yup.string().required(),
                },
              ],
            },
            {
              cols: [
                {
                  key: 'type',
                  apiKey: 'Rating',
                  type: 'react-select',
                  options: {
                    controller: {
                      props: {
                        options: Object.values(Constants.Review.Types),
                        getOptionValue: (option) => option.apiId,
                        formatOptionLabel: typeOptionLabel,
                        isMulti: false,
                        isSearchable: false,
                        isClearable: true,
                      },
                    },
                  },
                  schema: yup.string().nullable(),
                },
              ],
            },
            {
              cols: [
                {
                  key: 'priority',
                  apiKey: 'Priority',
                  type: 'react-select',
                  options: {
                    controller: {
                      props: {
                        options: Object.values(Constants.Review.Priority),
                        getOptionValue: (option) => option.apiId,
                        formatOptionLabel: priorityOptionLabel,
                        isMulti: false,
                        isSearchable: false,
                        isClearable: true,
                      },
                    },
                  },
                  schema: yup.string().nullable(),
                },
              ],
            },
          ];
          break;

        case 'addToFavorites':
          requestUrl = '/favorites/insert';
          message = 'loading...';
          fetchOnStart = true;
          requestParams.is_favorite = 1;
          break;

        case 'removeFromFavorites':
          requestUrl = '/favorites/delete';
          message = 'loading...';
          fetchOnStart = true;
          requestParams.is_favorite = 0;
          break;

        default:
          break;
      }

      const isSuccess = await form({
        size,
        title,
        confirmLabel,
        requestUrl,
        requestParams,
        fields: nFields,
        message,
        fetchOnStart,
      });

      if (isSuccess) {
        tableRef.current.reload();

        if (type === 'addToFavorites' || type === 'removeFromFavorites') {
          showToast({
            type: 'success',
            autohide: true,
            title: 'x1',
            message: 'y2',
          });
        }
      }
    },
    [form, showToast]
  );

  const onDeleteRow = useCallback(
    async (id, title) => {
      const isSuccess = await confirm({
        message: (
          <FormattedMessage
            id="app.common.areYouSureToDeleteTheXY"
            values={{
              x: title,
              y: lowerCase(formatMessage({ id: 'app.common.Lawyer' })),
            }}
          />
        ),
        requestUrl: '/Lawyer/delete',
        requestParams: { id },
      });
      if (isSuccess) {
        tableRef.current.reload();

        showToast({
          type: 'success',
          autohide: true,
          title: <FormattedMessage id="app.common.success" />,
          message: (
            <FormattedMessage
              id="app.common.theXWasDeletedSuccessfully"
              values={{
                x: lowerCase(formatMessage({ id: 'app.common.Lawyer' })),
              }}
            />
          ),
        });
      }
    },
    [confirm, formatMessage, showToast]
  );

  const columns = [
    {
      Header: <FormattedMessage id="app.common.name" />,
      accessor: 'name',
      wrap: true,
      minWidth: '30%',
    },

    {
      Header: <FormattedMessage id="app.common.description" />,
      accessor: 'description',
      wrap: true,
      minWidth: '15%',
      Cell: useCallback(({ cell: { value } }) =>
        value ? (
          <ShowMoreText text={value} maxLine={2} basedOn="words" />
        ) : (
          <Badge bg="secondary">
            <FormattedMessage id="app.common.n/a" />
          </Badge>
        )
      ),
    },
    {
      Header: <FormattedMessage id="app.common.lastUpdate" />,
      accessor: 'last_update',
      wrap: true,
    },
    {
      accessor: 'id',
      Cell: useCallback(
        ({ cell: { value } }) => (
          <Dropdown align="end">
            <ButtonGroup>
              <Button
                variant="white"
                size="sm"
                as={Link}
                to={`${AppRoutes.protected.AI_DETAIL.path.replace(
                  ':id',
                  value
                )}`}
              >
                <i className="bi-magic me-1" />
                <FormattedMessage id="app.common.customize" />
              </Button>
              <ButtonGroup>
                <Dropdown.Toggle
                  variant="white"
                  size="sm"
                  className="btn-icon dropdown-toggle-empty"
                />
              </ButtonGroup>
            </ButtonGroup>
            <Dropdown.Menu className="m-0" renderOnMount>
              <Dropdown.Header>
                <FormattedMessage id="app.common.actions" />
              </Dropdown.Header>

              <Dropdown.Item
                onClick={() => {
                  onActionRow(value, 'addEvent');
                }}
              >
                <i className="bi-calendar-plus dropdown-item-icon text-primary" />
                <span className="text-primary">
                  <FormattedMessage id="app.common.newEvent" />
                </span>
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  onActionRow(value, 'addNote');
                }}
              >
                <i className="bi-chat-right-text-fill dropdown-item-icon text-info" />
                <span className="text-info">
                  <FormattedMessage id="app.common.newNote" />
                </span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ),
        [onDeleteRow, onActionRow]
      ),
    },
  ];

  return (
    <div className="content container">
      <PageHeader className="d-block" title="">
        <Button onClick={onAddNew}>
          <i className="bi-plus me-1" />
          <FormattedMessage id="app.common.newProfile" />
        </Button>
      </PageHeader>
      <Row>
        <Col>
          <Card>
            <Card.Header className="card-header-content-md-between">
              <div className="mb-2 mb-md-0">
                <div className="input-group input-group-merge input-group-borderless" />
              </div>

              <div className="d-grid d-sm-flex align-items-sm-center gap-2">
                <div id="datatableCounterInfo" style={{ display: 'none' }}>
                  <div className="d-flex align-items-center">
                    <span className="fs-5 me-3">
                      <span id="datatableCounter">0</span> Selected
                    </span>

                    <a className="btn btn-outline-danger btn-sm" href="#!">
                      <i className="bi-trash" /> Delete
                    </a>
                  </div>
                </div>
              </div>
            </Card.Header>

            <SmartTable
              ref={tableRef}
              columns={columns}
              requestUrl="/profiles/list"
              requestParams={{
                type: 'sale',
              }}
              hoverable
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default MyProfiles;
