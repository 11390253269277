import React from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import CardCity from '../dashboard/ListingsDetail/partials/CardCity';

function Newsletter() {
  const { zip } = useParams();

  return (
    <div className="content container">
      <Row>
        <Col>
          <CardCity zip={zip} />
        </Col>
      </Row>
    </div>
  );
}

export default Newsletter;
