import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import {
  Row,
  Col,
  Card,
  Collapse,
  Button,
  Form,
  Badge,
  ButtonGroup,
  Dropdown,
} from 'react-bootstrap';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { debounce, lowerCase } from 'lodash';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { Routes as AppRoutes } from '../../../../router/routeMapping';
import {
  useAxiosQuery,
  useConfirmModal,
  useFormModal,
  useToast,
} from '../../../../hooks';
import { SmartForm, SmartTable } from '../../../../components';
import { yup } from '../../../../lib';
import Constants from '../../../../constants';

const typeOptionLabel = ({ id }) => (
  <div className="d-flex">
    <Badge bg={`${Constants.Review.Types[id].color}`}>
      <i className={`${Constants.Review.Types[id].icon} fs-6 me-1`} />
      <FormattedMessage
        id={`app.common.${Constants.Review.Types[id].labelKey}`}
      />
    </Badge>
  </div>
);

const priorityOptionLabel = ({ id }) => (
  <div className="d-flex">
    <Badge
      bg={`soft-${Constants.Review.Priority[id].color} text-${Constants.Review.Priority[id].color}`}
    >
      <i className={`${Constants.Review.Priority[id].icon} fs-6 me-1`} />
      <FormattedMessage
        id={`app.common.${Constants.Review.Priority[id].labelKey}`}
      />
    </Badge>
  </div>
);
const eventTypesOptionLabel = ({ id }) => (
  <div className="d-flex">
    <Badge
      bg={`soft-${Constants.EventTypes.Types[id].color} text-${Constants.EventTypes.Types[id].color}`}
    >
      <i className={`${Constants.EventTypes.Types[id].icon} fs-6 me-1`} />
      <FormattedMessage
        id={`app.common.${Constants.EventTypes.Types[id].labelKey}`}
      />
    </Badge>
  </div>
);

function Shared() {
  const location = useLocation();
  const tableRef = useRef();
  const filterFormRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filtersIsVisible, setFiltersIsVisible] = useState(false);
  const [filters, setFilters] = useState({});
  const { formatMessage } = useIntl();

  const { confirm } = useConfirmModal({ confirmVariant: 'danger' });
  const { form } = useFormModal();
  const { showToast } = useToast();

  const pageType = location.pathname
    .slice(location.pathname.lastIndexOf('/'), location.pathname.length)
    .substring(1);

  const { data: categoriesData, isLoading: categoriesDataIsLoading } =
    useAxiosQuery({
      url: '/categories/list',
      params: { quantity: 999999 },
    });

  const onActionRow = useCallback(
    async (id, type) => {
      const size = 'lg';
      const title = type;
      const confirmLabel = type;
      let requestUrl = '/reviews/insert';
      const requestParams = { RefID: id, RefTable: 'homes' };
      let fetchOnStart;
      let nFields;
      let message;

      switch (type) {
        case 'addEvent':
          requestUrl = '/calendar/insert';
          nFields = [
            {
              cols: [
                {
                  key: 'title',
                  apiKey: 'Title',
                  schema: yup.string().required(),
                },
              ],
            },
            {
              cols: [
                {
                  key: 'date',
                  apiKey: 'Date',
                  type: 'dateRangePicker',
                  options: {
                    controller: {
                      initialSettings: { timePicker: true },
                      formatter: (value) => {
                        if (value) {
                          return [
                            moment(value[0]).format(
                              Constants.DateFormats.APIWithTime
                            ),
                            moment(value[1]).format(
                              Constants.DateFormats.APIWithTime
                            ),
                          ];
                        }
                        return value;
                      },
                    },
                  },
                  schema: yup.array().min(2).nullable().required(),
                },
              ],
            },
            {
              cols: [
                {
                  key: 'description',
                  apiKey: 'Description',
                  type: 'textarea',
                  schema: yup.string().nullable(),
                },
              ],
            },
            {
              cols: [
                {
                  key: 'EventType',
                  apiKey: 'EventType',
                  type: 'react-select',
                  options: {
                    controller: {
                      props: {
                        options: Object.values(Constants.EventTypes.Types),
                        getOptionValue: (option) => option.apiId,
                        formatOptionLabel: eventTypesOptionLabel,
                        isMulti: false,
                        isSearchable: false,
                        isClearable: true,
                      },
                    },
                  },
                  schema: yup.string().nullable(),
                },
              ],
            },
          ];
          break;
        case 'addNote':
          requestUrl = '/reviews/insert';
          nFields = [
            {
              cols: [
                {
                  key: 'comment',
                  apiKey: 'Comment',
                  type: 'textarea',
                  schema: yup.string().required(),
                },
              ],
            },
            {
              cols: [
                {
                  key: 'type',
                  apiKey: 'Rating',
                  type: 'react-select',
                  options: {
                    controller: {
                      props: {
                        options: Object.values(Constants.Review.Types),
                        getOptionValue: (option) => option.apiId,
                        formatOptionLabel: typeOptionLabel,
                        isMulti: false,
                        isSearchable: false,
                        isClearable: true,
                      },
                    },
                  },
                  schema: yup.string().nullable(),
                },
              ],
            },
            {
              cols: [
                {
                  key: 'priority',
                  apiKey: 'Priority',
                  type: 'react-select',
                  options: {
                    controller: {
                      props: {
                        options: Object.values(Constants.Review.Priority),
                        getOptionValue: (option) => option.apiId,
                        formatOptionLabel: priorityOptionLabel,
                        isMulti: false,
                        isSearchable: false,
                        isClearable: true,
                      },
                    },
                  },
                  schema: yup.string().nullable(),
                },
              ],
            },
          ];
          break;

        case 'addToFavorites':
          requestUrl = '/favorites/insert';
          message = 'loading...';
          fetchOnStart = true;
          requestParams.is_favorite = 1;
          break;

        case 'removeFromFavorites':
          requestUrl = '/favorites/delete';
          message = 'loading...';
          fetchOnStart = true;
          requestParams.is_favorite = 0;
          break;

        default:
          break;
      }

      const isSuccess = await form({
        size,
        title,
        confirmLabel,
        requestUrl,
        requestParams,
        fields: nFields,
        message,
        fetchOnStart,
      });

      if (isSuccess) {
        tableRef.current.reload();

        if (type === 'addToFavorites' || type === 'removeFromFavorites') {
          showToast({
            type: 'success',
            autohide: true,
            title: 'x1',
            message: 'y2',
          });
        }
      }
    },
    [form, showToast]
  );

  const onDeleteRow = useCallback(
    async (id, title) => {
      const isSuccess = await confirm({
        message: (
          <FormattedMessage
            id="app.common.areYouSureToDeleteTheXY"
            values={{
              x: title,
              y: lowerCase(formatMessage({ id: 'app.common.Lawyer' })),
            }}
          />
        ),
        requestUrl: '/Lawyer/delete',
        requestParams: { id },
      });
      if (isSuccess) {
        tableRef.current.reload();

        showToast({
          type: 'success',
          autohide: true,
          title: <FormattedMessage id="app.common.success" />,
          message: (
            <FormattedMessage
              id="app.common.theXWasDeletedSuccessfully"
              values={{
                x: lowerCase(formatMessage({ id: 'app.common.Lawyer' })),
              }}
            />
          ),
        });
      }
    },
    [confirm, formatMessage, showToast]
  );

  const columns = [
    {
      Header: <FormattedMessage id="app.common.address" />,
      accessor: 'full_address_new',
      wrap: false,
      minWidth: '30%',
      Cell: useCallback(
        ({
          cell: {
            value,
            row: { original },
          },
        }) => (
          <>
            {original.isFavorite === 1 && (
              <i className="bi-heart-fill text-danger me-1" />
            )}
            {value}
          </>
        ),
        []
      ),
    },
    {
      Header: <FormattedMessage id="app.common.propertyType" />,
      accessor: 'propertyType',
      wrap: true,
    },
    {
      Header: <FormattedMessage id="app.common.propertyUse" />,
      accessor: 'propertyUse',
      wrap: true,
    },

    {
      Header: <FormattedMessage id="app.common.yearBuilt" />,
      accessor: 'yearBuilt',
      wrap: true,
    },
    {
      Header: <FormattedMessage id="app.common.lastSoldDate" />,
      accessor: 'lastSaleDate',
      wrap: true,
    },
    {
      Header: <FormattedMessage id="app.common.lastSoldPrice" />,
      accessor: 'lastSalePrice',
      wrap: true,
      textAlign: 'end',
      Cell: useCallback(
        ({ cell: { value } }) => (
          <div className="text-end">
            {value === 0 ? (
              <span>N/A</span>
            ) : (
              <Badge bg="soft-info" className="text-info fs-6">
                <FormattedNumber
                  value={value}
                  // eslint-disable-next-line react/style-prop-object
                  style="currency"
                  currency="USD"
                  minimumFractionDigits={0}
                  maximumFractionDigits={0}
                />
              </Badge>
            )}
          </div>
        ),
        []
      ),
    },
    {
      Header: <FormattedMessage id="app.common.equityPercent" />,
      accessor: 'equityPercent',
      wrap: true,
      textAlign: 'end',
    },

    {
      Header: <FormattedMessage id="app.common.features" />,
      accessor: 'bedrooms',
      wrap: false,
      minWidth: '35%',
      Cell: useCallback(
        ({
          cell: {
            row: { original },
          },
        }) => (
          <div className="d-flex">
            {original.bedrooms !== undefined &&
              original.bedrooms !== null &&
              original.bedrooms !== 0 && (
                <span className="me-1">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#icon_bed)">
                      <path
                        d="M1.25 19.5416V16.3666"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18.75 13.1833H1.25V16.3667H18.75V13.1833Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3.63333 9.16669H16.3667C16.9988 9.16669 17.605 9.41779 18.0519 9.86475C18.4989 10.3117 18.75 10.9179 18.75 11.55V13.1417H1.25V11.5917C1.24446 11.2752 1.30202 10.9608 1.41932 10.6668C1.53661 10.3728 1.71129 10.1052 1.93316 9.87942C2.15502 9.65367 2.41963 9.47438 2.71154 9.35201C3.00345 9.22964 3.31681 9.16664 3.63333 9.16669V9.16669Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18.75 19.5416V16.3666"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5.22503 6.02502H8.40836C8.61738 6.02502 8.82436 6.06619 9.01747 6.14618C9.21058 6.22617 9.38604 6.34341 9.53384 6.49121C9.68164 6.63901 9.79888 6.81448 9.87887 7.00759C9.95886 7.2007 10 7.40767 10 7.61669V9.16669H3.63336V7.61669C3.63336 7.19456 3.80105 6.78971 4.09955 6.49121C4.39805 6.19272 4.80289 6.02502 5.22503 6.02502Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11.5917 6.02502H14.775C15.1971 6.02502 15.602 6.19272 15.9005 6.49121C16.199 6.78971 16.3667 7.19456 16.3667 7.61669V9.16669H10V7.61669C10 7.19456 10.1677 6.78971 10.4662 6.49121C10.7647 6.19272 11.1695 6.02502 11.5917 6.02502Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16.3584 2.84167H3.63336V9.20834H16.3584V2.84167Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3.63336 0.458313V2.84165"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16.3666 0.458313V2.84165"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="icon_bed">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>{' '}
                  {original.bedrooms}
                </span>
              )}

            {original.bathrooms !== undefined &&
              original.bathrooms !== null &&
              original.bathrooms !== 0 && (
                <span className="me-1">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#icon_bath)">
                      <path
                        d="M2.01666 11.6H17.9833V14C17.9833 15.2708 17.4785 16.4896 16.5799 17.3882C15.6813 18.2868 14.4625 18.7916 13.1917 18.7916H6.80833C5.5375 18.7916 4.31872 18.2868 3.42011 17.3882C2.5215 16.4896 2.01666 15.2708 2.01666 14V11.6Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13.9917 11.6H10V15.5916H13.9917V11.6Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M19.5834 11.6H0.416687"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2.01666 11.7667V3.60834C2.01666 2.97403 2.26864 2.3657 2.71717 1.91718C3.16569 1.46865 3.77402 1.21667 4.40833 1.21667H5.24166C5.87018 1.22544 6.46998 1.48128 6.91132 1.92885C7.35266 2.37643 7.60006 2.97977 7.6 3.60834V4.44167"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M9.20003 4.40833H6.00836"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="icon_bath">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>{' '}
                  {original.bathrooms}
                </span>
              )}
            {original.totalSqFt !== undefined &&
              original.totalSqFt !== null &&
              original.totalSqFt !== 0 && (
                <span className="me-1">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#icon_sqft)">
                      <path
                        d="M1.25 3.63333L3.63333 1.25L6.025 3.63333"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16.3667 13.975L18.75 16.3666L16.3667 18.75"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18.75 16.3667H3.63333V1.25"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6.81668 1.25H18.75V13.1833"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16.3667 1.25L3.63333 13.975"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18.75 3.63336L6.02499 16.3667"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11.5917 1.25L3.63333 9.20833"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18.75 8.40833L10.7917 16.3667"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="icon_sqft">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>{' '}
                  <FormattedNumber
                    value={original.totalSqFt}
                    // eslint-disable-next-line react/style-prop-object

                    minimumFractionDigits={0}
                    maximumFractionDigits={0}
                  />{' '}
                  sqft
                </span>
              )}
          </div>
        ),
        []
      ),
    },

    {
      accessor: 'id',
      Cell: useCallback(
        ({
          cell: {
            value,
            row: { original },
          },
        }) => (
          <Dropdown align="end">
            <ButtonGroup>
              <Button
                variant="white"
                size="sm"
                as={Link}
                to={`${AppRoutes.protected.FARMING_DETAIL.path.replace(
                  ':id',
                  value
                )}`}
              >
                <i className="bi-eye me-1" />
                <FormattedMessage id="app.common.view" />
              </Button>
              <ButtonGroup>
                <Dropdown.Toggle
                  variant="white"
                  size="sm"
                  className="btn-icon dropdown-toggle-empty"
                />
              </ButtonGroup>
            </ButtonGroup>
            <Dropdown.Menu className="m-0" renderOnMount>
              <Dropdown.Header>
                <FormattedMessage id="app.common.actions" />
              </Dropdown.Header>
              {original.isFavorite === '0' ? (
                <Dropdown.Item
                  onClick={() => {
                    onActionRow(value, 'addToFavorites');
                  }}
                >
                  <i className="bi-heart-fill dropdown-item-icon" />
                  <span>
                    <FormattedMessage id="app.common.addToFavorites" />
                  </span>
                </Dropdown.Item>
              ) : (
                <Dropdown.Item
                  onClick={() => {
                    onActionRow(value, 'removeFromFavorites');
                  }}
                >
                  <i className="bi-heart dropdown-item-icon" />
                  <span>
                    <FormattedMessage id="app.common.removeFromFavorites" />
                  </span>
                </Dropdown.Item>
              )}
              <Dropdown.Item
                onClick={() => {
                  onActionRow(value, 'addEvent');
                }}
              >
                <i className="bi-calendar-plus dropdown-item-icon text-primary" />
                <span className="text-primary">
                  <FormattedMessage id="app.common.newEvent" />
                </span>
              </Dropdown.Item>

              <Dropdown.Item
                onClick={() => {
                  onActionRow(value, 'addNote');
                }}
              >
                <i className="bi-chat-right-text-fill dropdown-item-icon text-info" />
                <span className="text-info">
                  <FormattedMessage id="app.common.newNote" />
                </span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ),
        [onDeleteRow, onActionRow]
      ),
    },
  ];

  const filterFields = useMemo(
    () => [
      {
        cols: [
          {
            key: 'state',
            apiKey: 'state',
            type: 'react-select',
            options: {
              controller: {
                props: {
                  options: Object.values(Constants.States),
                  getOptionValue: (option) => `${option.abbreviation}`,
                  getOptionLabel: (option) => `${option.name}`,
                  isClearable: true,
                  isMulti: false,
                  isSearchable: true,
                },
              },
            },
            schema: yup.string().nullable(),
          },
          {
            key: 'city',
            apiKey: 'community',
          },
          {
            key: 'zip',
            apiKey: 'zipCode',
          },
          {
            key: 'price',
            apiKey: 'price',
            type: 'slider',
            options: {
              controller: {
                props: {
                  range: {
                    min: 25000,
                    max: 10000000,
                  },
                  start: [25000, 10000000],
                  step: 10000,
                },
              },
            },
            schema: yup.array().required(),
          },
        ],
      },
      {
        cols: [
          {
            key: 'APN Number',
            apiKey: 'apnNumber',
          },
          {
            key: 'MLS Number',
            apiKey: 'mlsNumber',
          },
          {
            key: 'zonning',
            apiKey: 'zonning',
          },
        ],
      },
    ],
    [categoriesData, categoriesDataIsLoading]
  );

  const parseSearchParams = useCallback(() => {
    const params = {};
    searchParams.forEach((value, key) => {
      let isArray = false;
      filterFields.forEach((row) => {
        row.cols.forEach((col) => {
          if (
            col.key === key &&
            (col.options?.controller?.props?.isMulti || col.key === 'dateRange')
          ) {
            isArray = true;
          }
        });
      });

      if (!params[key]) {
        params[key] = !isArray ? value : [value];
      } else {
        params[key] = [
          ...(Array.isArray(params[key]) ? params[key] : [params[key]]),
          value,
        ];
      }
    });
    return params;
  }, [searchParams, filterFields]);

  const handleSearch = (text) => {
    const params = parseSearchParams(true);
    if (text) {
      params.search = text;
    } else {
      delete params.search;
    }
    setSearchParams(params);
  };

  const onSearch = debounce((text) => {
    handleSearch(text);
  }, 100);

  let tableRequestParams = {};
  switch (pageType) {
    case 'absentee-owners':
      {
        const params = parseSearchParams();
        tableRequestParams = {
          is_absenteeOwner: 1,
          a: params.id,
        };
      }
      break;
    case 'adjustable-loans':
      {
        const params = parseSearchParams();
        tableRequestParams = {
          a: params.id,
          is_adjustableRate: 1,
        };
      }
      break;
    case 'auctions':
      {
        const params = parseSearchParams();
        tableRequestParams = {
          a: params.id,
          is_auction: 1,
        };
      }
      break;
    case 'bank-owned':
      {
        const params = parseSearchParams();
        tableRequestParams = {
          a: params.id,
          is_bankOwned: 1,
        };
      }
      break;
    case 'cash-buyers':
      {
        const params = parseSearchParams();
        tableRequestParams = {
          a: params.id,
          is_cashBuyer: 1,
        };
      }
      break;
    case 'corporate-owned':
      tableRequestParams = {
        is_corporateOwned: 1,
      };
      break;
    case 'free-clear':
      tableRequestParams = {
        is_freeClear: 1,
      };
      break;
    case 'high-equity':
      tableRequestParams = {
        is_highEquity: 1,
      };
      break;
    case 'low-equity':
      tableRequestParams = {
        is_highEquity: 0,
      };
      break;
    case 'intrafamily-transfers':
      tableRequestParams = {
        is_inherited: 1,
      };
      break;
    case 'out-of-state':
      tableRequestParams = {
        is_outOfStateAbsenteeOwner: 1,
      };
      break;
    case 'preforeclosures':
      tableRequestParams = {
        is_preForeclosure: 1,
      };
      break;
    case 'probates':
      tableRequestParams = {
        is_deathTransfer: 1,
      };
      break;
    case 'tired-landlords':
      tableRequestParams = {
        is_lien: 1,
        is_taxLien: 1,
        is_sheriffsDeed: 1,
      };
      break;

    case 'vacant-homes':
      tableRequestParams = {
        is_vacant: 1,
        propertyType: 'SFR',
      };
      break;
    case 'vacant-lots':
      tableRequestParams = {
        is_vacant: 1,
        propertyType: 'LAND',
      };
      break;
    default:
      break;
  }

  useEffect(() => {
    const params = parseSearchParams(true);
    const nParams = params.search ? { search: params.search } : {};
    filterFields.forEach((row) => {
      row.cols.forEach((col) => {
        if (params[col.key]) {
          nParams[col.apiKey || col.key] = params[col.key];
        }
      });
    });
    setFilters(nParams);
  }, [parseSearchParams, filterFields]);

  return (
    <div className="content container">
      <Row>
        <Col>
          <Card>
            <Card.Header className="card-header-content-md-between">
              <div className="mb-2 mb-md-0">
                <div className="input-group input-group-merge input-group-borderless">
                  <div className="input-group-prepend input-group-text">
                    <i className="bi-search" />
                  </div>
                  <Form.Control
                    type="search"
                    placeholder={formatMessage({
                      id: 'app.common.search',
                    })}
                    defaultValue={parseSearchParams().search || ''}
                    onChange={(e) => {
                      if (e.target.value) {
                        onSearch(e.target.value);
                      } else {
                        handleSearch(e.target.value);
                      }
                    }}
                  />
                </div>
              </div>

              <div className="d-grid d-sm-flex align-items-sm-center gap-2">
                <div id="datatableCounterInfo" style={{ display: 'none' }}>
                  <div className="d-flex align-items-center">
                    <span className="fs-5 me-3">
                      <span id="datatableCounter">0</span> Selected
                    </span>

                    <a className="btn btn-outline-danger btn-sm" href="#!">
                      <i className="bi-trash" /> Delete
                    </a>
                  </div>
                </div>

                <Button
                  variant="white"
                  size="sm"
                  className="dropdown-toggle"
                  onClick={() => {
                    setFiltersIsVisible(!filtersIsVisible);
                  }}
                >
                  <i className="bi-filter me-1" />
                  <FormattedMessage id="app.common.filters" />
                  {Object.keys(filters).filter((key) => key !== 'search')
                    .length > 0 && (
                    <Badge
                      bg="soft-dark"
                      className="text-dark rounded-circle ms-1"
                    >
                      {
                        Object.keys(filters).filter((key) => key !== 'search')
                          .length
                      }
                    </Badge>
                  )}
                </Button>
              </div>
            </Card.Header>

            <Collapse in={filtersIsVisible}>
              <div id="filter-collapse">
                <Card.Body>
                  <SmartForm
                    ref={filterFormRef}
                    fields={filterFields}
                    submitButtonText="applyFilters"
                    clearButtonText="clearFilters"
                    isFilterForm
                    disableApiKey
                    onSubmit={(formData) => {
                      const params = {};
                      if (formData) {
                        Object.keys(formData).forEach((key) => {
                          if (formData[key]) {
                            params[key] = formData[key];
                          }
                        });
                      }

                      if (parseSearchParams().search) {
                        params.search = parseSearchParams().search;
                      }

                      setSearchParams(params);
                    }}
                    defaultValues={parseSearchParams()}
                  />
                </Card.Body>
              </div>
            </Collapse>

            <SmartTable
              ref={tableRef}
              columns={columns}
              requestUrl="/properties/list"
              requestParams={tableRequestParams}
              filters={filters}
              hoverable
              loadingOptions={{ message: 'app.common.loadingFarm' }}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Shared;
