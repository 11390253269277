import React from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { PageHeader } from '../../../components';
import { Routes as AppRoutes } from '../../../router/routeMapping';

const menuData = Object.values(AppRoutes.protected.ACCOUNT.children)
  .filter(({ hidden }) => !hidden)
  .map(({ title, path }) => ({ title, href: path }));

function Account() {
  const location = useLocation();
  const activePage = menuData.find((page) => page.href === location.pathname);

  return (
    <div className="content container">
      <PageHeader
        title={activePage?.title}
        menuData={menuData}
        breadcrumbData={{
          current: activePage?.title,
          parents: [
            {
              name: AppRoutes.protected.ACCOUNT.title,
              href: AppRoutes.protected.ACCOUNT.path,
            },
          ],
        }}
      />

      {location.pathname === AppRoutes.protected.ACCOUNT.path ? (
        <Navigate to={menuData[0].href} replace />
      ) : (
        <Outlet />
      )}
    </div>
  );
}

export default Account;
