import React, { useContext } from 'react';
import { Button, Dropdown, Image } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../../context/AuthContext';
import DefaultProfileImage from '../../../assets/images/default-profile-img.jpg';
import { Routes as AppRoutes } from '../../../router/routeMapping';
import Constants from '../../../constants';

function DropdownUser() {
  const { logout, user, pack, status } = useContext(AuthContext);

  const onImageError = (e) => {
    e.target.src = DefaultProfileImage;
  };

  return (
    <Dropdown align="end">
      <Dropdown.Toggle
        as="a"
        role="button"
        bsPrefix="navbar-dropdown-account-wrapper"
      >
        <div className="avatar avatar-sm avatar-circle">
          <Image
            src={user?.pp || DefaultProfileImage}
            className="avatar-img"
            onError={onImageError}
          />
          <span
            className={`avatar-status avatar-sm-status avatar-status-${Constants.User.Status[status].color}`}
          />
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu
        as="div"
        className="navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account"
        data-bs-dropdown-animation
        style={{
          width: '16rem',
        }}
      >
        <>
          <Dropdown.ItemText as="div">
            <div className="d-flex align-items-start">
              <div className="avatar avatar-sm avatar-circle">
                <Image
                  src={user?.photo || DefaultProfileImage}
                  className="avatar-img"
                  onError={onImageError}
                />
              </div>
              <div className="flex-grow-1 ms-3">
                <div className="d-flex flex-column">
                  <div>
                    <h5 className="mb-0">{`${user?.name || ''} ${
                      user?.surname || ''
                    }`}</h5>
                    <p className="card-text text-body">{user?.email}</p>
                  </div>

                  {pack.name !== 'Premium' && (
                    <div>
                      <Button
                        size="xs"
                        as={Link}
                        to={
                          AppRoutes.protected.ACCOUNT.children
                            .SUBSCRIPTION_PLANS.path
                        }
                        className="d-sm-none mt-2"
                      >
                        <i className="bi-arrow-up-circle me-1" />
                        <FormattedMessage id="app.common.upgradePlan" />
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Dropdown.ItemText>

          <Dropdown.Divider />

          <Dropdown.Header>
            <FormattedMessage id="app.common.user" />
          </Dropdown.Header>

          <Dropdown.Item as={Link} to={AppRoutes.protected.ACCOUNT.path}>
            <FormattedMessage id="app.common.settings" />
          </Dropdown.Item>

          <Dropdown.Divider />

          <Dropdown.Header>
            <FormattedMessage id="app.common.account" />
          </Dropdown.Header>

          <Dropdown.Item as={Link} to={AppRoutes.protected.ACCOUNT.path}>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                <div className="avatar avatar-sm avatar-dark avatar-circle">
                  <span className="avatar-initials">
                    {(
                      user?.client_title ||
                      `${user?.name || ''} ${user?.surname || ''}`
                    )
                      ?.match(/\b\w/g)
                      ?.join('')
                      .substring(0, 2)}
                  </span>
                </div>
              </div>
              <div className="flex-grow-1 ms-2 d-flex flex-column align-items-start">
                <h5 className="mb-2">
                  {user?.client_title ||
                    `${user?.name || ''} ${user?.surname || ''}`}
                </h5>
                <span className="badge bg-primary rounded-pill text-uppercase mb-1">
                  {pack?.name}
                </span>
                <span className="card-text">{user?.email}</span>
              </div>
            </div>
          </Dropdown.Item>

          <Dropdown.Item
            as={Link}
            to={AppRoutes.protected.ACCOUNT.children.ACCOUNT_BILLING.path}
          >
            <FormattedMessage id="app.common.billing" />
          </Dropdown.Item>

          <Dropdown align="end" className="d-none">
            <Dropdown.Toggle
              as="a"
              role="button"
              className="navbar-dropdown-submenu-item dropdown-item"
            >
              Customization
            </Dropdown.Toggle>
            <Dropdown.Menu
              renderOnMount
              className="navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-sub-menu"
            >
              <Dropdown.Item href="#!">Invite people</Dropdown.Item>
              <Dropdown.Item href="#!">
                Analytics
                <i className="bi-box-arrow-in-up-right" />
              </Dropdown.Item>
              <Dropdown.Item href="#!">
                Customize Front
                <i className="bi-box-arrow-in-up-right" />
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown.Divider />

          <Dropdown.Item onClick={logout}>
            <FormattedMessage id="app.common.signOut" />
          </Dropdown.Item>
        </>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default DropdownUser;
