import React, { useState } from 'react';
import { Alert, Button, Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { camelCase } from 'lodash';
import { Link } from 'react-router-dom';
import SmartImage from './SmartImage';
import SmartLottie from './SmartLottie';
import Illustration from '../assets/images/svg/illustrations/oc-thinking.svg';
import IllustrationLight from '../assets/images/svg/illustrations-light/oc-thinking.svg';
import Utils from '../utils';

const DEFAULT_IMAGE_SRC = Illustration;
const DEFAULT_IMAGE_DARK_SRC = IllustrationLight;
const DEFAULT_IMAGE_WIDTH = '20rem';
const DEFAULT_IMAGE_HEIGHT = '20rem';

const DEFAULT_ANIMATION_SRC = 'default-empty-state';
const DEFAULT_ANIMATION_DARK_SRC = 'default-empty-state';
const DEFAULT_ANIMATION_WIDTH = '20rem';
const DEFAULT_ANIMATION_HEIGHT = '20rem';

function Content({ message, type }) {
  const formatErrorString = (e) => {
    if (e.indexOf('errors.') < 0) {
      return e;
    }
    const arr = e.split('.');

    return `${arr[0]}.${arr[1]}.${
      arr[1] === 'api' ? arr[2] : camelCase(arr[2])
    }`;
  };

  const getIcon = () => {
    let icon = '';
    switch (type) {
      case 'error':
      case 'danger':
        icon = 'bi-exclamation-triangle-fill';
        break;

      case 'success':
        icon = 'bi-check-circle';
        break;

      default:
        icon = 'bi-exclamation-triangle-fill';
        break;
    }

    return icon;
  };

  return type === 'error' ? (
    <div className="d-flex">
      <div className="flex-shrink-0">
        <i className={getIcon()} />
      </div>
      <div className="flex-grow-1 ms-2">
        <FormattedMessage id="app.common.error" />:
        <span className="ms-1">
          <FormattedMessage
            id={formatErrorString(message.message || message)}
            defaultMessage={message.message || message}
          />
        </span>
      </div>
    </div>
  ) : (
    <div className="d-flex">
      <div className="flex-shrink-0">
        <i className={getIcon()} />
      </div>
      <div className="flex-grow-1 ms-2">
        <FormattedMessage id={message} defaultMessage={message} />
      </div>
    </div>
  );
}

Content.propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ message: PropTypes.string }),
  ]),
  type: PropTypes.oneOf([
    'primary',
    'secondary',
    'error',
    'danger',
    'warning',
    'info',
    'success',
    'light',
    'dark',
  ]),
};

Content.defaultProps = {
  message: null,
  type: 'success',
};

function RequestResult({
  message,
  title,
  type,
  image,
  animation,
  dismissible,
  action,
  ...props
}) {
  const [show, setShow] = useState(true);

  const getActionComponent = () => {
    if (!action) {
      return null;
    }
    const ActionComp = action?.type === 'button' ? Button : Alert.Link;

    const getTextColor = () => {
      let color;
      if (image || animation) {
        color = action.variant || type || 'body';
      } else {
        color = action.variant;
      }

      return `text-${color}`;
    };

    return (
      <ActionComp
        className={`mt-3 ${
          action.type === 'button' ? '' : `d-block ${getTextColor()}`
        }`}
        as={Link}
        to={action.link}
        target={
          Utils.String.checkUrlProtocol(action.link) ? '_blank' : undefined
        }
        rel={
          Utils.String.checkUrlProtocol(action.link)
            ? 'nofollow noreferrer'
            : undefined
        }
        size={action.type === 'button' ? action.size || 'sm' : undefined}
        variant={action.type === 'button' ? action.variant || type : undefined}
      >
        <FormattedMessage id={action.title} defaultMessage={action.title} />
      </ActionComp>
    );
  };

  if ((image || animation) && message && show) {
    return (
      <Card
        className={`${
          image?.withBorder || animation?.withBorder
            ? 'card-dashed'
            : 'card-ghost border-0'
        } card-centered`}
      >
        {title && (image?.withHeader || animation?.withHeader) && (
          <Card.Header className="card-header-content-between">
            <Card.Title bsPrefix="card-header-title" as="h4">
              <FormattedMessage id={title} defaultMessage={title} />
            </Card.Title>
            {dismissible && (
              <Button
                variant={`ghost-${type}`}
                className="btn-icon btn-sm btn-no-focus rounded-circle"
                onClick={() => setShow(false)}
              >
                <i className="bi-x-lg" />
              </Button>
            )}
          </Card.Header>
        )}
        <Card.Body>
          {dismissible && (!image?.withHeader || !animation?.withHeader) && (
            <Button
              variant={`ghost-${type}`}
              className="btn-icon btn-sm btn-no-focus rounded-circle align-self-end"
              onClick={() => setShow(false)}
            >
              <i className="bi-x-lg" />
            </Button>
          )}
          {image && (
            <div>
              {image.autoColor ? (
                <div
                  className={`avatar avatar-xxl mb-3 mw-100 bg-${type}`}
                  style={{
                    width: image.width || DEFAULT_IMAGE_WIDTH,
                    height: image.height || DEFAULT_IMAGE_HEIGHT,
                    WebkitMask: `url("${
                      image.src || DEFAULT_IMAGE_SRC
                    }") no-repeat center`,
                    WebkitMaskSize: 'contain',
                    mask: `url("${
                      image.src || DEFAULT_IMAGE_SRC
                    }") no-repeat center`,
                    maskSize: 'contain',
                  }}
                />
              ) : (
                <SmartImage
                  src={image.src || DEFAULT_IMAGE_SRC}
                  darkSrc={image.darkSrc || image.src || DEFAULT_IMAGE_DARK_SRC}
                  className="avatar avatar-xxl mb-3 mw-100"
                  style={{
                    width: image.width || DEFAULT_IMAGE_WIDTH,
                    height: image.height || DEFAULT_IMAGE_HEIGHT,
                  }}
                />
              )}
            </div>
          )}
          {animation && (
            <SmartLottie
              src={animation.src || DEFAULT_ANIMATION_SRC}
              darkSrc={
                animation.darkSrc || animation.src || DEFAULT_ANIMATION_DARK_SRC
              }
              className="avatar avatar-xxl mb-3 mw-100"
              style={{
                width: animation.width || DEFAULT_ANIMATION_WIDTH,
                height: animation.height || DEFAULT_ANIMATION_HEIGHT,
                background: 'none',
              }}
            />
          )}

          {title && (!image?.withHeader || !animation?.withHeader) && (
            <div className={`h2 mb-3 text-${type}`}>
              <FormattedMessage id={title} defaultMessage={title} />
            </div>
          )}
          <div className={`h5 text-${type}`}>
            <Content type={type} message={message} />
          </div>
          {getActionComponent()}
        </Card.Body>
      </Card>
    );
  }

  if (!image && !animation && message && show) {
    return (
      <Alert
        variant={type === 'error' ? 'danger' : type}
        onClose={() => setShow(false)}
        dismissible={dismissible}
        {...props}
      >
        {title && (
          <Alert.Heading as="h5">
            <FormattedMessage id={title} defaultMessage={title} />
          </Alert.Heading>
        )}
        <Content type={type} message={message} />
        {getActionComponent()}
      </Alert>
    );
  }

  return null;
}

export const ImagePropType = PropTypes.shape({
  src: PropTypes.node,
  darkSrc: PropTypes.node,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  autoColor: PropTypes.bool,
  withHeader: PropTypes.bool,
  withBorder: PropTypes.bool,
});

export const AnimationPropType = PropTypes.shape({
  src: PropTypes.node,
  darkSrc: PropTypes.node,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  withHeader: PropTypes.bool,
  withBorder: PropTypes.bool,
});

const ColorPropType = PropTypes.oneOf([
  'primary',
  'secondary',
  'error',
  'danger',
  'warning',
  'info',
  'success',
  'light',
  'dark',
]);

RequestResult.propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ message: PropTypes.string }),
  ]),
  title: PropTypes.string,
  type: ColorPropType,
  image: PropTypes.oneOfType([ImagePropType, PropTypes.bool]),
  animation: PropTypes.oneOfType([AnimationPropType, PropTypes.bool]),
  dismissible: PropTypes.bool,
  action: PropTypes.shape({
    type: PropTypes.oneOf(['link', 'button']),
    link: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    size: PropTypes.oneOf(['xs', 'sm', 'lg']),
    variant: ColorPropType,
  }),
};

RequestResult.defaultProps = {
  message: null,
  title: null,
  type: 'success',
  image: undefined,
  animation: undefined,
  dismissible: false,
  action: null,
};

export default RequestResult;
