const TransportationTypes = [
  { name: 'Boat', abbreviation: 'Boat' },
  { name: 'Bus', abbreviation: 'Bus' },
  { name: 'Circulator', abbreviation: 'Circulator' },
  { name: 'Coach', abbreviation: 'Coach' },
  { name: 'Express Bus', abbreviation: 'Express Bus' },
  { name: 'Ferry', abbreviation: 'Ferry' },
  { name: 'Rail', abbreviation: 'Rail' },
  { name: 'Shuttle', abbreviation: 'Shuttle' },
  {
    name: 'Social Service Transportation',
    abbreviation: 'Social Service Transportation',
  },
  { name: 'Streetcar', abbreviation: 'Streetcar' },
  { name: 'Subway', abbreviation: 'Subway' },
  { name: 'Train', abbreviation: 'Train' },
  { name: 'Tram', abbreviation: 'Tram' },
  { name: 'Trolley', abbreviation: 'Trolley' },
  { name: 'Van', abbreviation: 'Van' },
  { name: 'Water Taxi', abbreviation: 'Water Taxi' },
];

export default TransportationTypes;
