import React from 'react';
import { useParams } from 'react-router-dom';
import { SmartGallery } from '../../../components';

// const baseURL = process.env.REACT_APP_BASE_URL || '';

function Gallery() {
  const { id } = useParams();

  return (
    <SmartGallery
      paths={{
        list: '/Rel_homes_images/list',
        listParams: { IDHomes: id },
        // create: '/images/insert',
        // createParams: { IDHomes: id },
        delete: '/images/delete',
      }}
      params={{ RefTable: 'homes' }}
    />
  );
}

export default Gallery;
