import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Card, Badge } from 'react-bootstrap';

import { FormattedMessage, FormattedNumber } from 'react-intl';

import { SmartTable } from '../../../components';

function HistoryTaxes() {
  const { id } = useParams();

  const columns = [
    {
      Header: <FormattedMessage id="app.common.year" />,
      accessor: 'year',
      wrap: true,
      Cell: useCallback(
        ({
          cell: {
            value,
            row: { original },
          },
        }) => (
          <>
            {original.isFavorite === 1 && (
              <i className="bi-heart-fill text-danger me-1" />
            )}
            {original.revCount > 0 && (
              <Badge bg="danger" pill className="me-1 mb-1 fs-6">
                {original.revCount}
              </Badge>
            )}
            {value}
          </>
        ),
        []
      ),
    },
    {
      Header: <FormattedMessage id="app.common.propertyTax" />,
      accessor: 'taxes',
      wrap: true,
      textAlign: 'end',
      Cell: useCallback(
        ({
          cell: {
            value,
            row: { original },
          },
        }) => (
          <div className="text-end">
            <FormattedNumber
              value={value}
              // eslint-disable-next-line react/style-prop-object
              style="currency"
              currency="USD"
              minimumFractionDigits={0}
              maximumFractionDigits={0}
            />
            <Badge
              bg={`soft-${
                original.taxPercentChange > 0 ? 'success' : 'danger'
              }`}
              text={`${original.taxPercentChange > 0 ? 'success' : 'danger'}`}
              className="ms-1"
            >
              <i
                className={`me-1
                  ${
                    original.taxPercentChange > 0
                      ? 'bi-graph-up-arrow'
                      : 'bi-graph-down-arrow'
                  }
                `}
              />
              {`${original.taxPercentChange}%`}
            </Badge>
          </div>
        ),
        []
      ),
    },
    {
      Header: <FormattedMessage id="app.common.land" />,
      accessor: 'land',
      wrap: true,
      textAlign: 'end',
      Cell: useCallback(
        ({ cell: { value } }) => (
          <div className="text-end">
            <FormattedNumber
              value={value}
              // eslint-disable-next-line react/style-prop-object
              style="currency"
              currency="USD"
              minimumFractionDigits={0}
              maximumFractionDigits={0}
            />
          </div>
        ),
        []
      ),
    },
    {
      Header: <FormattedMessage id="app.common.additions" />,
      accessor: 'additions',
      wrap: true,
      textAlign: 'end',
      Cell: useCallback(
        ({ cell: { value } }) => (
          <div className="text-end">
            <FormattedNumber
              value={value}
              // eslint-disable-next-line react/style-prop-object
              style="currency"
              currency="USD"
              minimumFractionDigits={0}
              maximumFractionDigits={0}
            />
          </div>
        ),
        []
      ),
    },
    {
      Header: <FormattedMessage id="app.common.assessedValue" />,
      accessor: 'id',
      wrap: true,
      textAlign: 'end',
      Cell: useCallback(
        ({
          cell: {
            row: { original },
          },
        }) => (
          <div className="text-end">
            <FormattedNumber
              value={original.land + original.additions}
              // eslint-disable-next-line react/style-prop-object
              style="currency"
              currency="USD"
              minimumFractionDigits={0}
              maximumFractionDigits={0}
            />
          </div>
        ),
        []
      ),
    },
  ];

  return (
    <Row>
      <Col>
        <Card>
          <Card.Header>
            <Card.Title bsPrefix="card-header-title" as="h4">
              <FormattedMessage id="app.common.details" />
            </Card.Title>
          </Card.Header>
          {id && (
            <SmartTable
              columns={columns}
              requestUrl="/Rel_homes_Taxes/list"
              requestParams={{ IDHomes: id, radius: 3, quantity: 10 }}
            />
          )}
        </Card>
      </Col>
    </Row>
  );
}

export default HistoryTaxes;
