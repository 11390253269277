import React, { useCallback, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import {
  Row,
  Col,
  Card,
  Button,
  ButtonGroup,
  Dropdown,
  Badge,
  Image,
} from 'react-bootstrap';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import moment from 'moment';
import { Routes as AppRoutes } from '../../../router/routeMapping';

import { HighlightedText, SmartTable } from '../../../components';
import { yup } from '../../../lib';
import Constants from '../../../constants';
import PlaceHolderImg from '../../../assets/images/placeholder.png';
import { useToast, useFormModal } from '../../../hooks';

const onImageError = (e) => {
  e.target.src = PlaceHolderImg;
};
const priorityOptionLabel = ({ id }) => (
  <div className="d-flex">
    <Badge
      bg={`soft-${Constants.Review.Priority[id].color} text-${Constants.Review.Priority[id].color}`}
    >
      <i className={`${Constants.Review.Priority[id].icon} fs-6 me-1`} />
      <FormattedMessage
        id={`app.common.${Constants.Review.Priority[id].labelKey}`}
      />
    </Badge>
  </div>
);
const typeOptionLabel = ({ id }) => (
  <div className="d-flex">
    <Badge bg={`${Constants.Review.Types[id].color}`}>
      <i className={`${Constants.Review.Types[id].icon} fs-6 me-1`} />
      <FormattedMessage
        id={`app.common.${Constants.Review.Types[id].labelKey}`}
      />
    </Badge>
  </div>
);
const eventTypesOptionLabel = ({ id }) => (
  <div className="d-flex">
    <Badge
      bg={`soft-${Constants.EventTypes.Types[id].color} text-${Constants.EventTypes.Types[id].color}`}
    >
      <i className={`${Constants.EventTypes.Types[id].icon} fs-6 me-1`} />
      <FormattedMessage
        id={`app.common.${Constants.EventTypes.Types[id].labelKey}`}
      />
    </Badge>
  </div>
);
function BusinessDetailListing() {
  const tableRef = useRef();
  const { id } = useParams();
  const { showToast } = useToast();

  const { form } = useFormModal();

  const onActionRow = useCallback(
    async (idx, type) => {
      const size = 'lg';
      const title = type;
      const confirmLabel = type;
      let requestUrl = '/reviews/insert';
      const requestParams = { RefID: id, RefTable: 'homes' };
      let fetchOnStart;
      let nFields;
      let message;

      switch (type) {
        case 'addEvent':
          requestUrl = '/calendar/insert';
          nFields = [
            {
              cols: [
                {
                  key: 'title',
                  apiKey: 'Title',
                  schema: yup.string().required(),
                },
              ],
            },
            {
              cols: [
                {
                  key: 'date',
                  apiKey: 'Date',
                  type: 'dateRangePicker',
                  options: {
                    controller: {
                      initialSettings: { timePicker: true },
                      formatter: (value) => {
                        if (value) {
                          return [
                            moment(value[0]).format(
                              Constants.DateFormats.APIWithTime
                            ),
                            moment(value[1]).format(
                              Constants.DateFormats.APIWithTime
                            ),
                          ];
                        }
                        return value;
                      },
                    },
                  },
                  schema: yup.array().min(2).nullable().required(),
                },
              ],
            },
            {
              cols: [
                {
                  key: 'description',
                  apiKey: 'Description',
                  type: 'textarea',
                  schema: yup.string().nullable(),
                },
              ],
            },
            {
              cols: [
                {
                  key: 'EventType',
                  apiKey: 'EventType',
                  type: 'react-select',
                  options: {
                    controller: {
                      props: {
                        options: Object.values(Constants.EventTypes.Types),
                        getOptionValue: (option) => option.apiId,
                        formatOptionLabel: eventTypesOptionLabel,
                        isMulti: false,
                        isSearchable: false,
                        isClearable: true,
                      },
                    },
                  },
                  schema: yup.string().nullable(),
                },
              ],
            },
          ];
          break;
        case 'addNote':
          requestUrl = '/reviews/insert';
          nFields = [
            {
              cols: [
                {
                  key: 'comment',
                  apiKey: 'Comment',
                  type: 'textarea',
                  schema: yup.string().required(),
                },
              ],
            },
            {
              cols: [
                {
                  key: 'type',
                  apiKey: 'Rating',
                  type: 'react-select',
                  options: {
                    controller: {
                      props: {
                        options: Object.values(Constants.Review.Types),
                        getOptionValue: (option) => option.apiId,
                        formatOptionLabel: typeOptionLabel,
                        isMulti: false,
                        isSearchable: false,
                        isClearable: true,
                      },
                    },
                  },
                  schema: yup.string().nullable(),
                },
              ],
            },
            {
              cols: [
                {
                  key: 'priority',
                  apiKey: 'Priority',
                  type: 'react-select',
                  options: {
                    controller: {
                      props: {
                        options: Object.values(Constants.Review.Priority),
                        getOptionValue: (option) => option.apiId,
                        formatOptionLabel: priorityOptionLabel,
                        isMulti: false,
                        isSearchable: false,
                        isClearable: true,
                      },
                    },
                  },
                  schema: yup.string().nullable(),
                },
              ],
            },
          ];
          break;

        case 'addToFavorites':
          requestUrl = '/favorites/insert';
          message = 'loading...';
          fetchOnStart = true;
          requestParams.is_favorite = 1;
          break;

        case 'removeFromFavorites':
          requestUrl = '/favorites/delete';
          message = 'loading...';
          fetchOnStart = true;
          requestParams.is_favorite = 0;
          break;

        default:
          break;
      }

      const isSuccess = await form({
        size,
        title,
        confirmLabel,
        requestUrl,
        requestParams,
        fields: nFields,
        message,
        fetchOnStart,
      });

      if (isSuccess) {
        tableRef.current.reload();

        if (type === 'addToFavorites' || type === 'removeFromFavorites') {
          showToast({
            type: 'success',
            autohide: true,
            title: 'x1',
            message: 'y2',
          });
        }
      }
    },
    [form, showToast]
  );
  const columns = [
    {
      Header: <FormattedMessage id="app.common.logo" />,
      accessor: 'ImageURL',
      Cell: useCallback(
        ({
          cell: {
            value,
            row: { original },
          },
        }) => (
          <div className="position-relative">
            <Image
              src={value || PlaceHolderImg}
              onError={onImageError}
              style={{ height: 100, width: 150, objectFit: 'cover' }}
            />
            {original.revCount > 0 && (
              <div className="position-absolute bottom-0 end-0 zi-1">
                <Badge bg="danger" pill className="me-1 mb-1 fs-6">
                  {original.revCount}
                </Badge>
              </div>
            )}
          </div>
        ),
        []
      ),
    },
    {
      Header: <FormattedMessage id="app.common.title" />,
      accessor: 'title',
      wrap: true,
      minWidth: '30%',
      Cell: useCallback(
        ({
          cell: {
            value,
            row: { original },
          },
        }) => (
          <>
            {original.isFavorite === 1 && (
              <i className="bi-heart-fill text-danger me-1" />
            )}
            {value}
          </>
        ),
        []
      ),
    },

    {
      Header: <FormattedMessage id="app.common.price" />,
      accessor: 'price',
      wrap: true,
      textAlign: 'end',
      Cell: useCallback(
        ({ cell: { value } }) => (
          <div className="text-end">
            <Badge bg="soft-success" className="text-success fs-6">
              <FormattedNumber
                value={value}
                // eslint-disable-next-line react/style-prop-object
                style="currency"
                currency="USD"
                minimumFractionDigits={0}
                maximumFractionDigits={0}
              />
            </Badge>
          </div>
        ),
        []
      ),
    },
    {
      Header: <FormattedMessage id="app.common.distance" />,
      accessor: 'distance',
      wrap: true,
      Cell: useCallback(({ cell: { value } }) => `${value.toFixed(2)} mi`, []),
    },
    {
      Header: <FormattedMessage id="app.common.buildingType" />,
      accessor: 'BuildingType',
      wrap: true,
      Cell: useCallback(
        ({ cell: { value } }) => (
          <Badge
            bg={`${Constants.RealEstate.Types[value].color}`}
            className="fs-6"
          >
            <FormattedMessage
              id={`${Constants.RealEstate.Types[value].labelKey}`}
            />
          </Badge>
        ),
        []
      ),
    },
    {
      Header: <FormattedMessage id="app.common.listingDate" />,
      accessor: 'listingDate',
      wrap: true,
      Cell: useCallback(({ cell: { value } }) => (
        <HighlightedText text={value} />
      )),
    },
    {
      Header: <FormattedMessage id="app.common.status" />,
      accessor: 'status',
      wrap: true,
      Cell: useCallback(({ cell: { value } }) => (
        <HighlightedText text={value} />
      )),
    },
    {
      Header: <FormattedMessage id="app.common.yearBuilt" />,
      accessor: 'yearBuilt',
      wrap: true,
      Cell: useCallback(
        ({ cell: { value } }) => (
          <Badge bg="soft-success" className="text-black fs-6">
            <span className=" bg-success" />
            <i className="bi bi-calendar-check me-1" />
            <FormattedMessage id={value} />
          </Badge>
        ),
        []
      ),
    },

    {
      Header: <FormattedMessage id="app.common.lotsize" />,
      accessor: 'lotsize',
      wrap: true,
      Cell: useCallback(({ cell: { value } }) => (
        <HighlightedText text={value} />
      )),
    },

    {
      accessor: 'id',
      Cell: useCallback(
        ({
          cell: {
            value,
            row: { original },
          },
        }) => (
          <Dropdown align="end">
            <ButtonGroup>
              <Button
                variant="white"
                size="sm"
                as={Link}
                to={`${AppRoutes.protected.LISTINGS_DETAIL.path.replace(
                  ':id',
                  value
                )}`}
              >
                <i className="bi-eye me-1" />
                <FormattedMessage id="app.common.view" />
              </Button>
              <ButtonGroup>
                <Dropdown.Toggle
                  variant="white"
                  size="sm"
                  className="btn-icon dropdown-toggle-empty"
                />
              </ButtonGroup>
            </ButtonGroup>
            <Dropdown.Menu className="m-0" renderOnMount>
              <Dropdown.Header>
                <FormattedMessage id="app.common.actions" />
              </Dropdown.Header>
              {original.isFavorite === 0 ? (
                <Dropdown.Item
                  onClick={() => {
                    onActionRow(value, 'addToFavorites');
                  }}
                >
                  <i className="bi-heart-fill dropdown-item-icon" />
                  <span>
                    <FormattedMessage id="app.common.addToFavorites" />
                  </span>
                </Dropdown.Item>
              ) : (
                <Dropdown.Item
                  onClick={() => {
                    onActionRow(value, 'removeFromFavorites');
                  }}
                >
                  <i className="bi-heart dropdown-item-icon" />
                  <span>
                    <FormattedMessage id="app.common.removeFromFavorites" />
                  </span>
                </Dropdown.Item>
              )}
              <Dropdown.Item
                onClick={() => {
                  onActionRow(value, 'addEvent');
                }}
              >
                <i className="bi-calendar-plus dropdown-item-icon text-primary" />
                <span className="text-primary">
                  <FormattedMessage id="app.common.newEvent" />
                </span>
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  onActionRow(value, 'addNote');
                }}
              >
                <i className="bi-chat-right-text-fill dropdown-item-icon text-info" />
                <span className="text-info">
                  <FormattedMessage id="app.common.newNote" />
                </span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ),
        [onActionRow]
      ),
    },
  ];

  return (
    <Row>
      <Col>
        <Card>
          <Card.Header>
            <Card.Title bsPrefix="card-header-title" as="h4">
              <FormattedMessage id="app.common.details" />
            </Card.Title>
          </Card.Header>
          {id && (
            <SmartTable
              columns={columns}
              requestUrl="/homes/get_by_business_id"
              requestParams={{ id, radius: 3, quantity: 10 }}
            />
          )}
        </Card>
      </Col>
    </Row>
  );
}

export default BusinessDetailListing;
