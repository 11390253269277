import React, { useEffect } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
  Navigate,
  useLocation,
  Outlet,
} from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import AuthRoute from './AuthRoute';
import PublicRoute from './PublicRoute';
import * as PublicComponents from '../pages/shared';
import * as AuthComponents from '../pages/auth';
import * as DashboardComponents from '../pages/dashboard';
import {
  Routes as AppRoutes,
  authBasePath,
  protectedAreaBasePath,
  publicAreaBasePath,
} from './routeMapping';
import PrivateRouteRequireAccess from './PrivateRouteRequireAccess';
import Constants from '../constants';
import { ToastProvider } from '../context/ToastContext';
import { ModalProvider } from '../context/ModalContext';
import { GoogleMapsProvider } from '../context/GoogleMapsContext';

function RemoveTrailingSlash() {
  const location = useLocation();

  // If the last character of the url is '/'
  if (location.pathname.match('/.*/$')) {
    return (
      <Navigate
        replace
        to={{
          pathname: location.pathname.replace(/\/+$/, ''),
          search: location.search,
        }}
      />
    );
  }
  return null;
}

function ScrollToTop(props) {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return props.children;
}

function Wrapper() {
  return (
    <ToastProvider>
      <ModalProvider>
        <GoogleMapsProvider>
          <ScrollToTop>
            <RemoveTrailingSlash />
            <Outlet />
          </ScrollToTop>
        </GoogleMapsProvider>
      </ModalProvider>
    </ToastProvider>
  );
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Wrapper />}>
      <Route path={authBasePath} element={<AuthRoute />}>
        {Object.keys(AppRoutes.auth).map((key) => {
          const { path, children, componentName } = AppRoutes.auth[key];
          if (!componentName || !AuthComponents[componentName]) {
            return null;
          }
          const Component = AuthComponents[componentName];
          return (
            <Route key={key} path={path} element={<Component />}>
              {children && (
                <>
                  {Object.keys(children).map((cKey) => {
                    const { path: sPath, componentName: sComponentName } =
                      children[cKey];
                    if (!sComponentName || !AuthComponents[sComponentName]) {
                      return null;
                    }
                    const SComponent = AuthComponents[sComponentName];
                    return (
                      <Route key={cKey} path={sPath} element={<SComponent />} />
                    );
                  })}
                  <Route
                    path="*"
                    element={
                      <Navigate to={AppRoutes.public.NOT_FOUND.path} replace />
                    }
                  />
                </>
              )}
            </Route>
          );
        })}
        <Route
          path="*"
          element={<Navigate to={AppRoutes.public.NOT_FOUND.path} replace />}
        />
      </Route>

      <Route path={protectedAreaBasePath} element={<PrivateRoute />}>
        {Object.keys(AppRoutes.protected).map((key) => {
          const { path, children, componentName, roles } =
            AppRoutes.protected[key];

          if (!componentName || !DashboardComponents[componentName]) {
            return null;
          }
          const Component = DashboardComponents[componentName];
          return (
            <Route
              key={key}
              path={path}
              element={
                <PrivateRouteRequireAccess
                  roles={roles || [Constants.User.Roles.Admin.id]}
                >
                  <Component />
                </PrivateRouteRequireAccess>
              }
            >
              {children && (
                <>
                  {Object.keys(children).map((cKey) => {
                    const {
                      path: sPath,
                      componentName: sComponentName,
                      roles: sRoles,
                    } = children[cKey];
                    if (
                      !sComponentName ||
                      !DashboardComponents[sComponentName]
                    ) {
                      return null;
                    }
                    const SComponent = DashboardComponents[sComponentName];
                    return (
                      <Route
                        key={cKey}
                        path={sPath}
                        element={
                          <PrivateRouteRequireAccess
                            roles={sRoles || [Constants.User.Roles.Admin.id]}
                          >
                            <SComponent />
                          </PrivateRouteRequireAccess>
                        }
                      />
                    );
                  })}
                  <Route
                    path="*"
                    element={
                      <Navigate to={AppRoutes.public.NOT_FOUND.path} replace />
                    }
                  />
                </>
              )}
            </Route>
          );
        })}
        <Route
          path="*"
          element={<Navigate to={AppRoutes.public.NOT_FOUND.path} replace />}
        />
      </Route>

      <Route path={publicAreaBasePath} element={<PublicRoute />}>
        {Object.keys(AppRoutes.public).map((key) => {
          const { path, children, componentName } = AppRoutes.public[key];
          if (!componentName || !PublicComponents[componentName]) {
            return null;
          }
          const Component = PublicComponents[componentName];
          return (
            <Route key={key} path={path} element={<Component />}>
              {children && (
                <>
                  {Object.keys(children).map((cKey) => {
                    const { path: sPath, componentName: sComponentName } =
                      children[cKey];
                    if (!sComponentName || !PublicComponents[sComponentName]) {
                      return null;
                    }
                    const SComponent = PublicComponents[sComponentName];
                    return (
                      <Route key={cKey} path={sPath} element={<SComponent />} />
                    );
                  })}
                  <Route
                    path="*"
                    element={
                      <Navigate to={AppRoutes.public.NOT_FOUND.path} replace />
                    }
                  />
                </>
              )}
            </Route>
          );
        })}
        <Route
          path="*"
          element={<Navigate to={AppRoutes.public.NOT_FOUND.path} replace />}
        />
      </Route>
    </Route>
  )
);

function Router() {
  return <RouterProvider router={router} />;
}

export default Router;
