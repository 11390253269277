import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Col, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { yup } from '../../../../lib';
import { SmartForm } from '../../../../components';
import Constants from '../../../../constants';

const profileOptionLabel = ({ labelKey, description }) => (
  <div className="d-flex flex-column">
    <div className="text-primary">
      <strong>
        <FormattedMessage
          id={`app.common.${labelKey}`}
          defaultMessage={labelKey}
        />
      </strong>
    </div>
    <div className="small text-wrap">{description}</div>
  </div>
);

function BusinessInfoStep({ onNext, onPrev }) {
  const fields = [
    {
      cols: [
        {
          key: 'profile',
          type: 'react-select',
          options: {
            controller: {
              props: {
                options: Object.values(Constants.RealEstate.userProfiles),
                getOptionValue: (option) => option.id,
                formatOptionLabel: profileOptionLabel,
                isMulti: false,
                isSearchable: false,
                isClearable: false,
              },
            },
          },
          schema: yup.string().required(),
        },
      ],
    },
  ];

  const onSubmit = (data) => {
    onNext(data);
  };

  return (
    <Row className="justify-content-sm-center py-10">
      <Col sm="8">
        <Alert variant="soft-primary" className="mb-5">
          <div className="d-flex">
            <div className="flex-shrink-0">
              <i className="bi-exclamation-triangle-fill" />
            </div>
            <div className="flex-grow-1 ms-2">
              <FormattedMessage id="app.toolTips.defineProfile" />
            </div>
          </div>
        </Alert>
        <SmartForm
          fields={fields}
          onSubmit={onSubmit}
          isWizard
          onWizardPrev={onPrev}
          submitButtonText="next"
          footerAlign="between"
        />
      </Col>
    </Row>
  );
}

BusinessInfoStep.propTypes = {
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
};

BusinessInfoStep.defaultProps = {
  onNext: () => {},
  onPrev: () => {},
};

export default BusinessInfoStep;
