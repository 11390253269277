import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { PageHeader, SmartFileManager } from '../../../../components';

const baseURL = process.env.REACT_APP_BASE_URL || '';

function FileManager() {
  return (
    <div className="content container">
      <PageHeader
        className="d-block"
        title="fileManager"
        breadcrumbData={{
          current: 'fileManager',
        }}
      />
      <Row>
        <Col>
          <SmartFileManager
            paths={{
              folders: {
                list: '/directories/list',
                create: '/directories/insert',
                delete: '/directories/delete',
              },
              files: {
                list: '/files/list',
                create: '/files/insert',
                delete: '/files/delete',
                preview: `${baseURL.replace('/service', '')}/files/`,
                download: `${baseURL}/file/download?filename=`,
              },
            }}
          />
        </Col>
      </Row>
    </div>
  );
}

export default FileManager;
