import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Image, Spinner } from 'react-bootstrap';
// import { useEffectOnce } from 'react-use';
import { isString } from 'lodash';
import DefaultPlaceholderImg from '../assets/images/placeholder.png';

function FormImageInput({
  disabled,
  onChange,
  value,
  isInvalid,
  isValid,
  size,
  variant,
  // defaultValue,
  readOnly,
  className,
  children,
  objectFit,
  placeholder,
  hideControls,
}) {
  const inputRef = useRef();
  const [preview, setPreview] = useState();
  const [previewIsLoading, setPreviewIsLoading] = useState(false);
  const placeholderImg = placeholder || DefaultPlaceholderImg;

  const onImageError = (e) => {
    e.target.src = placeholderImg;
  };

  const handleFileChange = (e) => {
    if (e.target.files?.[0]) {
      const objectUrl = URL.createObjectURL(e.target.files[0]);
      setPreview(objectUrl);
      onChange(e.target.files[0]);
    } else {
      onChange(null);
    }
  };

  useEffect(() => {
    const setPreviewFromUrl = async () => {
      setPreviewIsLoading(true);

      try {
        const response = await fetch(value);
        const contentType = response.headers.get('content-type');
        const blob = await response.blob();
        const filename = value
          .substring(value.lastIndexOf('/') + 1)
          .split('?')[0];
        const file = new File([blob], filename, { contentType });
        const objectUrl = URL.createObjectURL(file);
        setPreview(objectUrl);
        setPreviewIsLoading(false);
      } catch {
        setPreview(null);
        setPreviewIsLoading(false);
      }
    };

    if (isString(value)) {
      setPreviewFromUrl(value);
    }
  }, [value]);

  return (
    <div
      className={`avatar ${
        variant === 'circle' ? 'avatar-circle' : 'rounded-2'
      } avatar-${size} ${!previewIsLoading ? 'avatar-uploader' : ''} ${
        isInvalid ? 'is-invalid' : ''
      } ${isValid ? 'is-valid' : ''} ${
        className || 'form-control d-block p-0 m-0 bg-transparent'
      }`}
    >
      {previewIsLoading ? (
        <div>
          <div
            className={`position-absolute bg-dark opacity-75 top-0 bottom-0 end-0 start-0 ${
              variant === 'circle' ? 'rounded-circle' : 'rounded'
            }`}
          />
          <div className="position-absolute top-50 start-50 translate-middle">
            <Spinner animation="border" variant="white" size="sm" />
          </div>
        </div>
      ) : (
        <>
          <label htmlFor="uploader" className="h-100">
            <Image
              src={preview || placeholderImg}
              className="avatar-img"
              onError={onImageError}
              style={{ objectFit: preview ? objectFit : 'cover' }}
            />

            <input
              ref={inputRef}
              type="file"
              className="avatar-uploader-input"
              accept="image/jpg, image/jpeg, image/png"
              onChange={handleFileChange}
              id="uploader"
              disabled={disabled || readOnly}
              readOnly={readOnly}
            />

            {!disabled && !readOnly && !hideControls && (
              <span
                className={`avatar-uploader-trigger ${
                  variant === 'circle' ? '' : 'pe-1 pb-1'
                }`}
              >
                <i
                  className={`bi-pencil-fill avatar-uploader-icon shadow-sm ${
                    variant === 'circle' ? '' : 'rounded-1'
                  }`}
                />
              </span>
            )}
          </label>
          {!disabled && !readOnly && !hideControls && (
            <div>
              {preview && (
                <span
                  className={`clear avatar-uploader-trigger top-0 bottom-100 ${
                    variant === 'circle' ? '' : 'pe-1 pt-1'
                  }`}
                  role="button"
                  tabIndex={0}
                  aria-hidden="true"
                  onClick={() => {
                    onChange(null);
                    setPreview();
                  }}
                >
                  <i
                    className={`bi-x avatar-uploader-icon shadow-sm bg-danger ${
                      variant === 'circle' ? '' : 'rounded-1'
                    }`}
                  />
                </span>
              )}
            </div>
          )}
          {children}
        </>
      )}
    </div>
  );
}

FormImageInput.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  isInvalid: PropTypes.bool,
  isValid: PropTypes.bool,
  size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
  variant: PropTypes.oneOf(['rounded', 'circle']),
  /* defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(File),
  ]), */
  readOnly: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  objectFit: PropTypes.oneOf([
    'contain',
    'cover',
    'fill',
    'none',
    'scale-down',
  ]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(File)]),
  placeholder: PropTypes.string,
  hideControls: PropTypes.bool,
};

FormImageInput.defaultProps = {
  disabled: false,
  onChange: () => {},
  isInvalid: null,
  isValid: null,
  size: 'xxl',
  variant: 'rounded',
  // defaultValue: undefined,
  readOnly: false,
  className: '',
  children: null,
  objectFit: 'contain',
  value: undefined,
  placeholder: undefined,
  hideControls: false,
};

export default FormImageInput;
