import React from 'react';

import { DoubleLineContainer } from '../Theme/Navbar';

import { Routes as AppRoutes } from '../../router/routeMapping';

const menuData = [];

Object.keys(AppRoutes.protected).forEach((key) => {
  const {
    title,
    path: href,
    icon,
    children,
    hidden,
  } = AppRoutes.protected[key];

  if (hidden) {
    return;
  }

  const item = { title, href };

  if (icon) {
    item.icon = icon;
  }

  if (children) {
    item.submenu = [];
    Object.keys(children).forEach((cKey) => {
      const {
        title: sTitle,
        path: sHref,
        icon: sIcon,
        hidden: sHidden,
      } = children[cKey];
      const subItem = { title: sTitle, href: sHref };

      if (sIcon) {
        subItem.icon = sIcon;
      }

      if (!sHidden) {
        item.submenu.push(subItem);
      }
    });
  }
  menuData.push(item);
});

/*
const menuData = [
  {
    title: 'Dashboards',
    icon: 'bi-house-door',
    submenu: [
      {
        title: 'Default',
        href: Routes.DASHBOARD,
      },
      {
        title: 'Alternative',
        href: Routes.DASHBOARD,
      },
    ],
  },
  {
    title: 'Pages',
    icon: 'bi-files-alt',
    submenu: [
      {
        title: 'Users',
        submenu: [
          {
            title: 'Overview',
            href: Routes.DASHBOARD,
          },
          {
            title: 'Leaderboard',
            href: Routes.DASHBOARD,
          },
          {
            title: 'Add User',
            href: Routes.DASHBOARD,
          },
        ],
      },
      {
        title: 'User Profile',
        submenu: [
          {
            title: 'Profile',
            href: Routes.DASHBOARD,
          },
          {
            title: 'Teams',
            href: Routes.DASHBOARD,
          },
        ],
      },
      {
        title: 'API Keys',
        href: Routes.DASHBOARD,
      },
    ],
  },
  {
    title: 'Apps',
    icon: 'bi-app-indicator',
    submenu: [
      {
        title: 'Canban',
        href: Routes.DASHBOARD,
      },
      {
        title: 'Calendar',
        href: Routes.DASHBOARD,
      },
      {
        title: 'Invoice Generator',
        href: Routes.DASHBOARD,
      },
      {
        title: 'File Manager',
        href: Routes.DASHBOARD,
      },
    ],
  },
  { title: 'Layouts', href: Routes.DASHBOARD, icon: 'bi-grid-1x2' },
  { title: 'Docs', href: Routes.DASHBOARD, icon: 'bi-book' },
  {
    title: 'Reputation Manager',
    href: Routes.REPUTATION_MANAGER_SUMMARY,
    icon: 'bi-star',
  },
]; */

function DashboardNavbar() {
  return <DoubleLineContainer menuData={menuData} />;
}

export default DashboardNavbar;
