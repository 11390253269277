import React from 'react';
import { Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

function RequestLoading({
  loading,
  animation,
  size,
  align,
  margin,
  message,
  ...props
}) {
  return loading ? (
    <div
      className={`text- m-${margin} d-flex align-items-${align} justify-content-center`}
      {...props}
    >
      <Spinner animation={animation} size={size} />
      {message && (
        <span className="ms-2">
          <FormattedMessage id={message} defaultMessage={message} />
        </span>
      )}
    </div>
  ) : null;
}

export const LoadingPropTypes = {
  loading: PropTypes.bool,
  animation: PropTypes.string,
  size: PropTypes.string,
  align: PropTypes.oneOf(['center', 'start', 'end']),
  margin: PropTypes.oneOf(['0', '1', '2', '3', '4', '5']),
  message: PropTypes.string,
};

RequestLoading.propTypes = LoadingPropTypes;

RequestLoading.defaultProps = {
  loading: false,
  animation: 'border',
  size: 'sm',
  align: 'center',
  margin: '3',
  message: null,
};

export default RequestLoading;
