import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Card, ListGroup } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import { DoughnutChart } from '../../../../components/Charts';
import Utils from '../../../../utils';
import { ThemeContext } from '../../../../context/ThemeContext';
import { useAxiosQuery } from '../../../../hooks';
import { RequestLoading, RequestResult } from '../../../../components';
import Constants from '../../../../constants';

const options = {
  rotation: -120,
  circumference: 240,
  layout: {
    padding: {
      left: 10,
      right: 10,
      top: 10,
      bottom: 10,
    },
  },
  plugins: {
    tooltip: {
      enabled: false,
    },
    legend: {
      display: false,
    },
  },
};

function ScoreList({ id, height }) {
  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/profile_home_points/list2',
    preventFetch: !id,
    params: {
      home_id: id,
    },
  });

  return (
    <div className="mt-4">
      <RequestLoading loading={apiLoading} margin="5" />
      <RequestResult type="error" message={apiError} />
      {!apiLoading && !apiError && apiData?.length === 0 && (
        <RequestResult type="secondary" message="app.common.noData" />
      )}
      {!apiLoading && !apiError && apiData?.length > 0 && (
        <SimpleBar style={{ height }}>
          <ListGroup variant="flush">
            {apiData.map((item) => (
              <ListGroup.Item className="d-flex align-items-center px-0">
                <div className="h5 mb-0">
                  <FormattedMessage
                    id={`app.common.${
                      Constants.RealEstate.userProfiles[item.profile_id]
                        ?.labelKey || 'n/a'
                    }`}
                  />
                </div>
                <div className="ms-auto">{item.point}</div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </SimpleBar>
      )}
    </div>
  );
}

ScoreList.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  height: PropTypes.number,
};

ScoreList.defaultProps = {
  height: 300,
};

function ScoreChartCard({ id, value, height }) {
  const { theme } = useContext(ThemeContext);

  const getColor = () => {
    let color = Utils.Chart.dangerColor;

    if (value >= 50 && value < 85) {
      color = Utils.Chart.warningColor;
    } else if (value >= 85) {
      color = Utils.Chart.successColor;
    }

    return color;
  };

  const backgroundColor = getColor();

  return (
    <Card className="card-hover-shadow h-100">
      <Card.Body>
        <Card.Subtitle as="h6">
          <FormattedMessage id="app.common.score" />
        </Card.Subtitle>
        <div
          className="d-flex flex-column justify-content-center align-items-center text-center"
          style={{ height: '12rem' }}
        >
          <div className="position-relative w-100">
            <DoughnutChart
              data={{
                labels: ['', ''],
                datasets: [
                  {
                    backgroundColor: [
                      backgroundColor,
                      theme === 'dark'
                        ? Utils.Chart.lightGridColor
                        : Utils.Chart.darkGridColor,
                    ],
                    data: [value, 100 - value],
                    borderWidth: 4,
                    borderColor:
                      theme === 'dark'
                        ? Utils.Chart.darkBgColor
                        : Utils.Chart.lightBgColor,
                  },
                ],
              }}
              options={options}
            />
            <div className="chartjs-doughnut-custom-stat">
              <span className="h1 display-1">{value}</span>
            </div>
          </div>
        </div>
        <div>
          <ScoreList id={id} height={height} />
        </div>
      </Card.Body>
    </Card>
  );
}

ScoreChartCard.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  value: PropTypes.number.isRequired,
  height: PropTypes.number,
};

ScoreChartCard.defaultProps = {
  height: 300,
};

export default ScoreChartCard;
