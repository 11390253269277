import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Card } from 'react-bootstrap';
import FsLightbox from 'fslightbox-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { lowerCase } from 'lodash';
import PlaceHolderImg from '../../assets/images/placeholder.png';
import { useAxiosQuery, useConfirmModal, useFormModal } from '../../hooks';
import RequestLoading from '../RequestLoading';
import RequestResult from '../RequestResult';
import { yup } from '../../lib';
import SmartImage from '../SmartImage';
import Illustration from '../../assets/images/svg/illustrations/oc-megaphone.svg';
import IllustrationLight from '../../assets/images/svg/illustrations-light/oc-megaphone.svg';

const onImageError = (e) => {
  e.target.src = PlaceHolderImg;
};

function SmartGallery({ paths, params, uploadOnly, multiple }) {
  const { confirm } = useConfirmModal({ confirmVariant: 'danger' });
  const { form } = useFormModal();
  const { formatMessage } = useIntl();
  const [items, setItems] = useState();

  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  });

  const { isLoading: apiLoading, error: apiError } = useAxiosQuery({
    url: paths.list,
    params: {
      ...params,
      ...paths.listParams,
    },
    onSuccess: (data) => {
      setItems(data);
    },
  });

  const openLightboxOnSlide = (number) => {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number,
    });
  };

  const onDeleteItem = async (id, url) => {
    const requestUrl = paths.delete;
    const requestParams = {
      ...params,
      ...paths.deleteParams,
      id,
    };
    const filename = url.replace(/^.*[\\/]/, '');

    const isSuccess = await confirm({
      message: (
        <FormattedMessage
          id="app.common.areYouSureToDeleteTheXY"
          values={{
            x: filename,
            y: lowerCase(formatMessage({ id: `app.common.image` })),
          }}
        />
      ),
      requestUrl,
      requestParams,
    });

    if (isSuccess) {
      setItems((prevFiles) => prevFiles.filter((item) => item.id !== id));
    }
  };

  const onUploadItem = async () => {
    const fields = [
      {
        cols: [
          {
            key: 'file',
            type: 'dropzone',
            schema: yup.mixed().requiredFile(),
            options: {
              controller: {
                options: {
                  multiple,
                },
              },
            },
          },
        ],
      },
      /* {
        cols: [
          {
            key: 'file2',
            type: 'dropzoneAlt',
            schema: yup.mixed().requiredFile(),
            options: {
              controller: { options: { uploadMultiple: true, maxFiles: 5 } },
            },
          },
        ],
      }, */
    ];

    const requestParams = {
      ...params,
      ...paths.createParams,
    };

    const formData = await form({
      size: 'lg',
      title: 'uploadFiles',
      confirmLabel: 'upload',
      requestUrl: paths.create,
      requestParams,
      fields,
      fetchOnStart: false,
    });

    if (formData) {
      setItems((prevFiles) => [...prevFiles, ...formData.Result]);
    }
  };

  const onAddFromUrl = async () => {
    const fields = [
      {
        cols: [
          {
            key: 'url',
            apiKey: 'ImageURL',
            type: 'text',
            schema: yup.string().customUrl().required(),
          },
        ],
      },
    ];

    const requestParams = {
      ...params,
      ...paths.createParams,
    };

    const formData = await form({
      size: 'md',
      title: 'addFromUrl',
      confirmLabel: 'add',
      requestUrl: paths.create,
      requestParams,
      fields,
      fetchOnStart: false,
    });

    if (formData) {
      setItems((prevFiles) => [...prevFiles, formData.Result]);
    }
  };

  return (
    <>
      <RequestLoading loading={apiLoading} size="lg" margin="5" />
      <RequestResult type="error" message={apiError} />
      {!apiLoading &&
        !apiError &&
        items &&
        (items.length > 0 ? (
          <>
            <Row>
              {paths.create && (
                <Col sm="6" lg="4" xl="3" className="mb-3 mb-lg-5">
                  <Card className="card-dashed shadow-none card-centered card-sm h-100">
                    <Card.Body>
                      <div className="d-flex flex-column align-items-center">
                        <Button onClick={onUploadItem} className="mt-3">
                          <i className="bi-cloud-arrow-up-fill me-1" />
                          <FormattedMessage id="app.common.upload" />
                        </Button>
                        {!uploadOnly && (
                          <>
                            <p className="mt-3">or</p>
                            <Button
                              variant="link"
                              onClick={onAddFromUrl}
                              className="p-0"
                            >
                              <i className="bi-link me-1" />
                              <FormattedMessage id="app.common.addFromUrl" />
                            </Button>
                          </>
                        )}
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              )}
              {items.map((item, index) => (
                <Col
                  key={`thumb_${index.toString()}`}
                  sm="6"
                  lg="4"
                  xl="3"
                  className="mb-3 mb-lg-5"
                >
                  <Card className="card-sm h-100">
                    <div className="ratio ratio-4x3">
                      <Card.Img
                        onClick={() => {
                          openLightboxOnSlide(index + 1);
                        }}
                        variant="top"
                        src={item.ThumbURL || item.ImageURL}
                        style={{ cursor: 'pointer', objectFit: 'contain' }}
                        onError={onImageError}
                        className={`h-100 ${
                          paths.delete ? '' : 'card-img-bottom'
                        }`}
                      />
                    </div>
                    {paths.delete && (
                      <Card.Body>
                        <Row className="col-divider text-center">
                          <Col>
                            <Button
                              variant="link"
                              className="p-0 text-body"
                              onClick={() => {
                                openLightboxOnSlide(index + 1);
                              }}
                            >
                              <i className="bi-eye" />
                            </Button>
                          </Col>
                          <Col>
                            <Button
                              variant="link"
                              className="p-0 text-danger"
                              onClick={() => {
                                onDeleteItem(item.id, item.ImageURL);
                              }}
                            >
                              <i className="bi-trash" />
                            </Button>
                          </Col>
                        </Row>
                      </Card.Body>
                    )}
                  </Card>
                </Col>
              ))}
            </Row>
            <FsLightbox
              exitFullscreenOnClose
              toggler={lightboxController.toggler}
              slide={lightboxController.slide}
              sources={items.map((item) => item.ImageURL)}
              types={[...new Array(items.length).fill('image')]}
            />
          </>
        ) : (
          <Card className="card-dashed shadow-none card-centered">
            <Card.Body>
              <div className="mb-3">
                <SmartImage
                  className="avatar avatar-xl"
                  src={Illustration}
                  darkSrc={IllustrationLight}
                />
              </div>
              <p>
                <FormattedMessage id="app.common.noFilesFound" />
              </p>
              {paths.create && (
                <div className="d-flex flex-column align-items-center">
                  <Button onClick={onUploadItem} className="mt-3">
                    <i className="bi-cloud-arrow-up-fill me-1" />
                    <FormattedMessage id="app.common.upload" />
                  </Button>
                  {!uploadOnly && (
                    <>
                      <p className="mt-3">or</p>
                      <Button
                        variant="link"
                        onClick={onAddFromUrl}
                        className="p-0"
                      >
                        <i className="bi-link me-1" />
                        <FormattedMessage id="app.common.addFromUrl" />
                      </Button>
                    </>
                  )}
                </div>
              )}
            </Card.Body>
          </Card>
        ))}
    </>
  );
}

SmartGallery.propTypes = {
  paths: PropTypes.shape({
    list: PropTypes.string,
    listParams: PropTypes.objectOf(PropTypes.any),
    create: PropTypes.string,
    createParams: PropTypes.objectOf(PropTypes.any),
    delete: PropTypes.string,
    deleteParams: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
  params: PropTypes.objectOf(PropTypes.any),
  uploadOnly: PropTypes.bool,
  multiple: PropTypes.bool,
};

SmartGallery.defaultProps = {
  params: {},
  uploadOnly: false,
  multiple: true,
};

export default SmartGallery;
