import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import useAxiosQuery from '../hooks/useAxiosQuery';

function FormAsyncSelect({
  size,
  requestUrl,
  requestParams,
  requestType,
  requestInputKey,
  requestResultParser,
  disabled,
  value,
  onChange,
  options,
  isInvalid,
  isValid,
  readOnly,
  zIndex,
  placeholder,
}) {
  const [inputValue, setInputValue] = useState('');

  const {
    isLoading: apiLoading,
    data: apiData,
    // error: apiError,
    // refetch: apiFetch,
  } = useAxiosQuery({
    url: requestUrl,
    requestType,
    preventFetch: requestInputKey ? !inputValue : false,
    params: requestInputKey
      ? {
          ...requestParams,
          [requestInputKey]: inputValue,
        }
      : { ...requestParams },
    select: (data) => requestResultParser(data),
  });

  const onInputChange = (val, { action }) => {
    // onBlur => setInputValue to last selected value
    // if (action === "input-blur") {
    //   setInputValue(value ? value.label : "");
    // }
    // onInputChange => update inputValue

    if (action === 'input-change') {
      setInputValue(val);
    }
  };

  const defaultGetOptionValue = (obj) => obj.value;
  const getOptionValue = options.getOptionValue || defaultGetOptionValue;
  const getVal = (val) => {
    let valueObject = options.isMulti ? [] : null;
    const opts = apiData || [];
    if ((val || val === 0) && opts.length > 0) {
      valueObject = options.isMulti
        ? opts.filter((x) => val.includes(getOptionValue(x)))
        : opts.find((x) => getOptionValue(x) === val);
    }
    return valueObject;
  };
  const handleOnChange = (valueObject) => {
    let val = null;
    if (valueObject) {
      val = options.isMulti
        ? valueObject.map((item) => getOptionValue(item))
        : getOptionValue(valueObject);
    }
    onChange(val);
  };

  return (
    <ReactSelect
      className={`react-select-custom-container react-select-custom-form-item react-select-custom-${size} ${
        isInvalid ? 'is-invalid' : ''
      } ${isValid ? 'is-valid' : ''} ${
        readOnly ? 'react-select-custom-transparentWithBorder' : ''
      }`}
      styles={{
        menu: (baseStyles) => ({
          ...baseStyles,
          zIndex,
        }),
      }}
      classNamePrefix="react-select-custom"
      value={getVal(value)}
      onChange={handleOnChange}
      // defaultValue={getVal(defaultValue) || undefined}
      options={apiData || []}
      onInputChange={requestInputKey && onInputChange}
      isLoading={apiLoading}
      isDisabled={disabled || readOnly}
      placeholder={placeholder}
      components={
        readOnly
          ? {
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }
          : undefined
      }
      {...options}
    />
  );
}

FormAsyncSelect.propTypes = {
  size: PropTypes.oneOf(['sm', 'lg']),
  requestUrl: PropTypes.string,
  requestParams: PropTypes.objectOf(PropTypes.any),
  requestType: PropTypes.oneOf(['get', 'post']),
  requestInputKey: PropTypes.string,
  requestResultParser: PropTypes.func,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
    PropTypes.number,
  ]),
  onChange: PropTypes.func,
  options: PropTypes.objectOf(PropTypes.any),
  isInvalid: PropTypes.bool,
  isValid: PropTypes.bool,
  readOnly: PropTypes.bool,
  zIndex: PropTypes.number,
  placeholder: PropTypes.string,
};

FormAsyncSelect.defaultProps = {
  size: 'lg',
  requestUrl: null,
  requestParams: {},
  requestType: 'post',
  requestInputKey: 'input',
  requestResultParser: () => {},
  disabled: false,
  value: undefined,
  onChange: () => {},
  options: {},
  isInvalid: null,
  isValid: null,
  readOnly: false,
  zIndex: 1,
  placeholder: '',
};

export default FormAsyncSelect;
