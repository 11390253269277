import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { DashboardNavbar } from '../components/Navbars';
import { DashboardFooter } from '../components/Footers';
import { useDocumentTitle } from '../hooks';
import {
  Routes as AppRoutes,
  protectedAreaBasePath,
} from '../router/routeMapping';
import { AuthContext } from '../context/AuthContext';
import { AccessProvider } from '../context/AccessContext';
import { ProfileProvider } from '../context/ProfileContext';

const clientId = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID || '';

const forcePath = AppRoutes.protected.ACCOUNT_SETUP.path;

function AccessContextWrapper({ children }) {
  const { user } = useContext(AuthContext);

  const getLink = (val) => (
    <Alert.Link as={Link} to={forcePath}>
      {val}
    </Alert.Link>
  );

  return (
    <AccessProvider
      forcePath={forcePath}
      forcePathMessage={
        <FormattedMessage
          id="app.common.forcePathInfoMessage"
          values={{
            a: getLink,
          }}
        />
      }
      returnPath={protectedAreaBasePath}
      allowedPaths={[
        AppRoutes.protected.ACCOUNT.path,
        ...Object.values(AppRoutes.protected.ACCOUNT.children).map(
          ({ path }) => path
        ),
      ]}
      hasAccess={user?.zips?.length > 0}
    >
      {children}
    </AccessProvider>
  );
}

AccessContextWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

function Dashboard({ children }) {
  useDocumentTitle();

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <ProfileProvider>
        <AccessContextWrapper>
          <div className="footer-offset">
            <DashboardNavbar />
            <main id="content" role="main" className="main">
              {children}
              <DashboardFooter />
            </main>
          </div>
        </AccessContextWrapper>
      </ProfileProvider>
    </GoogleOAuthProvider>
  );
}

Dashboard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Dashboard;
