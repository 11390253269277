import React, { useCallback } from 'react';
import {
  Row,
  Col,
  Card,
  Button,
  Badge,
  ButtonGroup,
  Dropdown,
} from 'react-bootstrap';
import { useParams, Link } from 'react-router-dom';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Routes as AppRoutes } from '../../../router/routeMapping';

import { SmartTable } from '../../../components';

function Properties() {
  const { id } = useParams();

  const columns = [
    {
      Header: <FormattedMessage id="app.common.address" />,
      accessor: 'full_address_new',
      wrap: false,
      minWidth: '30%',
      Cell: useCallback(
        ({
          cell: {
            value,
            row: { original },
          },
        }) => (
          <>
            {original.isFavorite === 1 && (
              <i className="bi-heart-fill text-danger me-1" />
            )}
            {value}
          </>
        ),
        []
      ),
    },
    {
      Header: <FormattedMessage id="app.common.propertyType" />,
      accessor: 'propertyType',
      wrap: true,
    },
    {
      Header: <FormattedMessage id="app.common.propertyUse" />,
      accessor: 'propertyUse',
      wrap: true,
    },

    {
      Header: <FormattedMessage id="app.common.yearBuilt" />,
      accessor: 'yearBuilt',
      wrap: true,
    },
    {
      Header: <FormattedMessage id="app.common.lastSoldDate" />,
      accessor: 'lastSaleDate',
      wrap: true,
    },
    {
      Header: <FormattedMessage id="app.common.lastSoldPrice" />,
      accessor: 'lastSalePrice',
      wrap: true,
      textAlign: 'end',
      Cell: useCallback(
        ({ cell: { value } }) => (
          <div className="text-end">
            {value === 0 ? (
              <span>N/A</span>
            ) : (
              <Badge bg="soft-info" className="text-info fs-6">
                <FormattedNumber
                  value={value}
                  // eslint-disable-next-line react/style-prop-object
                  style="currency"
                  currency="USD"
                  minimumFractionDigits={0}
                  maximumFractionDigits={0}
                />
              </Badge>
            )}
          </div>
        ),
        []
      ),
    },
    {
      Header: <FormattedMessage id="app.common.equityPercent" />,
      accessor: 'equityPercent',
      wrap: true,
      textAlign: 'end',
    },

    {
      Header: <FormattedMessage id="app.common.features" />,
      accessor: 'bedrooms',
      wrap: false,
      minWidth: '35%',
      Cell: useCallback(
        ({
          cell: {
            row: { original },
          },
        }) => (
          <div className="d-flex">
            {original.bedrooms !== undefined &&
              original.bedrooms !== null &&
              original.bedrooms !== 0 && (
                <span className="me-1">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#icon_bed)">
                      <path
                        d="M1.25 19.5416V16.3666"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18.75 13.1833H1.25V16.3667H18.75V13.1833Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3.63333 9.16669H16.3667C16.9988 9.16669 17.605 9.41779 18.0519 9.86475C18.4989 10.3117 18.75 10.9179 18.75 11.55V13.1417H1.25V11.5917C1.24446 11.2752 1.30202 10.9608 1.41932 10.6668C1.53661 10.3728 1.71129 10.1052 1.93316 9.87942C2.15502 9.65367 2.41963 9.47438 2.71154 9.35201C3.00345 9.22964 3.31681 9.16664 3.63333 9.16669V9.16669Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18.75 19.5416V16.3666"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5.22503 6.02502H8.40836C8.61738 6.02502 8.82436 6.06619 9.01747 6.14618C9.21058 6.22617 9.38604 6.34341 9.53384 6.49121C9.68164 6.63901 9.79888 6.81448 9.87887 7.00759C9.95886 7.2007 10 7.40767 10 7.61669V9.16669H3.63336V7.61669C3.63336 7.19456 3.80105 6.78971 4.09955 6.49121C4.39805 6.19272 4.80289 6.02502 5.22503 6.02502Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11.5917 6.02502H14.775C15.1971 6.02502 15.602 6.19272 15.9005 6.49121C16.199 6.78971 16.3667 7.19456 16.3667 7.61669V9.16669H10V7.61669C10 7.19456 10.1677 6.78971 10.4662 6.49121C10.7647 6.19272 11.1695 6.02502 11.5917 6.02502Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16.3584 2.84167H3.63336V9.20834H16.3584V2.84167Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3.63336 0.458313V2.84165"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16.3666 0.458313V2.84165"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="icon_bed">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>{' '}
                  {original.bedrooms}
                </span>
              )}
            {original.bathrooms !== undefined &&
              original.bathrooms !== null &&
              original.bathrooms !== 0 && (
                <span className="me-1">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#icon_bath)">
                      <path
                        d="M2.01666 11.6H17.9833V14C17.9833 15.2708 17.4785 16.4896 16.5799 17.3882C15.6813 18.2868 14.4625 18.7916 13.1917 18.7916H6.80833C5.5375 18.7916 4.31872 18.2868 3.42011 17.3882C2.5215 16.4896 2.01666 15.2708 2.01666 14V11.6Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13.9917 11.6H10V15.5916H13.9917V11.6Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M19.5834 11.6H0.416687"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2.01666 11.7667V3.60834C2.01666 2.97403 2.26864 2.3657 2.71717 1.91718C3.16569 1.46865 3.77402 1.21667 4.40833 1.21667H5.24166C5.87018 1.22544 6.46998 1.48128 6.91132 1.92885C7.35266 2.37643 7.60006 2.97977 7.6 3.60834V4.44167"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M9.20003 4.40833H6.00836"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="icon_bath">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>{' '}
                  {original.bathrooms}
                </span>
              )}

            {original.livingSquareFeet !== undefined &&
              original.livingSquareFeet !== null &&
              original.livingSquareFeet !== 0 && (
                <span className="me-1">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#icon_sqft)">
                      <path
                        d="M1.25 3.63333L3.63333 1.25L6.025 3.63333"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16.3667 13.975L18.75 16.3666L16.3667 18.75"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18.75 16.3667H3.63333V1.25"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6.81668 1.25H18.75V13.1833"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16.3667 1.25L3.63333 13.975"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18.75 3.63336L6.02499 16.3667"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11.5917 1.25L3.63333 9.20833"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18.75 8.40833L10.7917 16.3667"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="icon_sqft">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>{' '}
                  <FormattedNumber
                    value={original.totalSqFt}
                    // eslint-disable-next-line react/style-prop-object

                    minimumFractionDigits={0}
                    maximumFractionDigits={0}
                  />{' '}
                  sqft
                </span>
              )}
          </div>
        ),
        []
      ),
    },

    {
      accessor: 'id',
      Cell: useCallback(
        ({ cell: { value } }) => (
          <Dropdown align="end">
            <ButtonGroup>
              <Button
                variant="white"
                size="sm"
                as={Link}
                to={`${AppRoutes.protected.FARMING_DETAIL.path.replace(
                  ':id',
                  value
                )}`}
              >
                <i className="bi-eye me-1" />
                <FormattedMessage id="app.common.view" />
              </Button>
            </ButtonGroup>
          </Dropdown>
        ),
        []
      ),
    },
  ];

  return (
    <Row>
      <Col>
        <Card>
          <Card.Header>
            <Card.Title bsPrefix="card-header-title" as="h4">
              <FormattedMessage id="app.common.properties" />
            </Card.Title>
          </Card.Header>
          {id && (
            <SmartTable
              columns={columns}
              requestUrl="/properties/list"
              requestParams={{ owner_id: id }}
            />
          )}
        </Card>
      </Col>
    </Row>
  );
}

export default Properties;
