import React, { useCallback } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { SmartTable } from '../../../components';

function Social() {
  const { id } = useParams();

  const columns = [
    {
      Header: <FormattedMessage id="app.common.platform" />,
      accessor: 'platform',
    },
    {
      Header: <FormattedMessage id="app.common.web" />,
      accessor: 'url',
      Cell: useCallback(
        ({ cell: { value } }) => (
          <div
            className="d-inline-block text-truncate"
            style={{ maxWidth: 220 }}
          >
            <a href={value} target="_blank" rel="nofollow noreferrer">
              <i className="bi bi-box-arrow-up-right me-1" />
              {value}
            </a>
          </div>
        ),
        []
      ),
      wrap: true,
    },
  ];

  return (
    <Row>
      <Col>
        <Card>
          <Card.Header>
            <Card.Title bsPrefix="card-header-title" as="h4">
              <FormattedMessage id="app.common.social" />
            </Card.Title>
          </Card.Header>
          {id && (
            <SmartTable
              columns={columns}
              requestUrl="/realestate/list_social"
              requestParams={{ id }}
            />
          )}
        </Card>
      </Col>
    </Row>
  );
}

export default Social;
