import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { SmartTable } from '../../../components';

function Amenities() {
  const { id } = useParams();

  const columns = [
    {
      Header: <FormattedMessage id="app.common.amenities" />,
      accessor: 'name',
    },
  ];

  return (
    <Row>
      <Col>
        <Card>
          <Card.Header>
            <Card.Title bsPrefix="card-header-title" as="h4">
              <FormattedMessage id="app.common.amenities" />
            </Card.Title>
          </Card.Header>
          {id && (
            <SmartTable
              columns={columns}
              requestUrl="/biz/list_amenities"
              requestParams={{ id }}
            />
          )}
        </Card>
      </Col>
    </Row>
  );
}

export default Amenities;
