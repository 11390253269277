const Languages = [
  {
    name: 'English',
    abbreviation: '351',
  },
  {
    name: 'Spanish',
    abbreviation: '460',
  },
  {
    name: 'Arabic',
    abbreviation: '318',
  },
  {
    name: 'Armenian',
    abbreviation: '320',
  },
  {
    name: 'Chinese',
    abbreviation: '341',
  },

  {
    name: 'French',
    abbreviation: '358',
  },
  {
    name: 'German',
    abbreviation: '362',
  },
  {
    name: 'Hebrew',
    abbreviation: '369',
  },
  {
    name: 'Hindi',
    abbreviation: '371',
  },
  {
    name: 'Italian',
    abbreviation: '382',
  },
  {
    name: 'Japanese',
    abbreviation: '384',
  },
  {
    name: 'Korean',
    abbreviation: '397',
  },
  {
    name: 'Persian',
    abbreviation: '437',
  },
  {
    name: 'Portuguese',
    abbreviation: '440',
  },
  {
    name: 'Russian',
    abbreviation: '445',
  },

  {
    name: 'Turkish',
    abbreviation: '475',
  },
  {
    name: 'Vietnamese',
    abbreviation: '485',
  },
];

export default Languages;
