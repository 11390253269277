import React from 'react';
import { useParams } from 'react-router-dom';
import { Badge } from 'react-bootstrap';
import { FormattedNumber } from 'react-intl';
import { SmartSteps } from '../../../components';

function HistorySales() {
  const { id } = useParams();

  const getBadge = (pricePercentageChange) => {
    let color = 'success';
    let icon = 'bi-graph-up';
    if (pricePercentageChange < 0) {
      color = 'danger';
      icon = 'bi-graph-down';
    }
    return (
      <Badge bg={`soft-${color}`} text={color} className="p-1 ms-2">
        <i className={icon} />
        {pricePercentageChange}
      </Badge>
    );
  };
  const getStepIcon = (pricePercentageChange) => {
    let icon = 'bi-graph-up';
    if (pricePercentageChange < 0) {
      icon = 'bi-graph-down';
    }
    return icon;
  };

  const getStepColor = (pricePercentageChange) => {
    let color = 'success';
    if (pricePercentageChange < 0) {
      color = 'danger';
    }
    return `soft-${color}`;
  };

  const formatter = (data) => {
    const {
      id: itemId,
      price,
      description,
      source,
      eventDate,
      pricePercentageChange,
    } = data;

    return {
      original: data,
      id: itemId,
      title: description,
      content: (
        <>
          <i className="bi-calendar-event me-1" /> {eventDate}
          <br />
          <FormattedNumber
            value={price}
            // eslint-disable-next-line react/style-prop-object
            style="currency"
            currency="USD"
            minimumFractionDigits={0}
            maximumFractionDigits={0}
          />
          {pricePercentageChange ? getBadge(pricePercentageChange) : null}
          <br />
          {source} <br />
        </>
      ),
      color: pricePercentageChange ? getStepColor(pricePercentageChange) : null,
      icon: pricePercentageChange ? getStepIcon(pricePercentageChange) : null,
    };
  };

  return (
    <SmartSteps
      paths={{
        list: '/Rel_homes_SaleHistory/list',
        listParams: { IDHomes: id },
        // create: '/reviews/insert',
        // createParams: { RefID: id },
        // delete: '/reviews/delete',
        // update: '/reviews/update',
      }}
      // params={{ RefTable: 'users' }}
      type="dot"
      formatter={formatter}
    />
  );
}

export default HistorySales;
