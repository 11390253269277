import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ChartJs } from '../../lib';
// import { ThemeContext } from '../../context/ThemeContext';
import { LangContext } from '../../context/LangContext';
// import Utils from '../../utils';

function WordCloudChart({
  data,
  options,
  wrapperClassName,
  chartClassName,
  height,
  plugins,
}) {
  // const { theme } = useContext(ThemeContext);
  const { currentLanguage } = useContext(LangContext);

  return (
    <div className={wrapperClassName} style={{ height }}>
      <ChartJs.Charts.Chart
        type="wordCloud"
        className={chartClassName}
        data={data}
        options={options}
        lang={currentLanguage}
        plugins={plugins}
      />
    </div>
  );
}

WordCloudChart.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  options: PropTypes.objectOf(PropTypes.any),
  wrapperClassName: PropTypes.string,
  chartClassName: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  plugins: PropTypes.shape({
    legend: PropTypes.shape({ display: PropTypes.bool }),
  }),
};

WordCloudChart.defaultProps = {
  options: {
    title: {
      display: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    maxRotation: 0,
    minRotation: 0,
  },
  wrapperClassName: 'chartjs-custom w-100',
  chartClassName: 'analytics-line-large',
  height: '20rem',
  plugins: {},
};

export default WordCloudChart;
