import React, {
  useRef,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';
import Constants from '../constants';
import { ThemeContext } from '../context/ThemeContext';

const DEFAULT_CENTER = { lat: 34.044575741341845, lng: -118.25247202528848 };
const DEFAULT_MAP_STYLE = [
  {
    featureType: 'administrative',
    elementType: 'geometry',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
];

const DEFAULT_MAP_STYLE_DARK = [
  {
    elementType: 'geometry',
    stylers: [
      {
        color: '#212121',
      },
    ],
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#212121',
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'administrative.country',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#bdbdbd',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#181818',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#1b1b1b',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#2c2c2c',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#8a8a8a',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry',
    stylers: [
      {
        color: '#373737',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [
      {
        color: '#3c3c3c',
      },
    ],
  },
  {
    featureType: 'road.highway.controlled_access',
    elementType: 'geometry',
    stylers: [
      {
        color: '#4e4e4e',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#000000',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#3d3d3d',
      },
    ],
  },
];

const MapComponent = forwardRef(
  (
    {
      initialCenter,
      initialMarks,
      initialHeatmapLayer,
      initialZoom,
      withCircle,
      markerOnClick,
      circleOnChange,
      ...props
    },
    ref
  ) => {
    const { theme } = useContext(ThemeContext);

    const mapElement = useRef();
    const mapInstance = useRef();
    const circleRef = useRef();
    const heatmapRef = useRef();
    const initialMarkersRef = useRef([]);

    const center = useMemo(
      () =>
        !initialCenter && initialMarks?.length > 0
          ? { lat: initialMarks[0].lat, lng: initialMarks[0].lng }
          : initialCenter,
      [initialMarks, initialCenter]
    );

    const handleCircleBoundsChange = useCallback(() => {
      circleOnChange(circleRef.current.getRadius());
    }, [circleOnChange]);

    const handleCircle = useCallback(() => {
      if (withCircle) {
        const radius = withCircle;

        if (!circleRef.current) {
          circleRef.current = new window.google.maps.Circle({
            strokeColor: Constants.Colors.Theme.Primary,
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: Constants.Colors.Theme.Primary,
            fillOpacity: 0.35,
            center,
            radius,
            editable: true,
          });

          circleRef.current.addListener(
            'bounds_changed',
            handleCircleBoundsChange
          );

          circleRef.current.setMap(mapInstance.current);
        }

        circleRef.current.setRadius(radius);
        circleRef.current.setCenter(center);

        const bounds = circleRef.current.getBounds();
        mapInstance.current.fitBounds(bounds);
        mapInstance.current.panToBounds(bounds);

        setTimeout(() => {
          const n1 = bounds?.Ua?.lo ? bounds.Ua.lo : 0;
          const n2 = mapInstance.current?.getBounds()?.Ua?.lo
            ? mapInstance.current?.getBounds().Ua.lo
            : 0;
          if (Math.floor(n1) !== Math.floor(n2)) {
            mapInstance.current.fitBounds(bounds);
            mapInstance.current.panToBounds(bounds);
          }
        }, 3000);
      }
    }, [withCircle, center, handleCircleBoundsChange]);

    const handleMarkers = useCallback(() => {
      initialMarkersRef.current.forEach((marker) => {
        marker.setMap(null);
      });

      initialMarkersRef.current = [];

      if (initialMarks?.length > 0) {
        initialMarks.forEach((item, i) => {
          const {
            lat,
            lng,
            id,
            color,
            customIcon,
            label,
            iconText,
            iconTextFill,
            selected,
          } = item;
          const nColor = color || Constants.Colors.Theme.Danger;
          const nIconWidth = customIcon?.width || 48;
          const nIconHeight = customIcon?.height || 48;
          const svgString =
            customIcon?.svgString ||
            `<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M24.0233 41.1847C20.6947 38.3378 10 28.5994 10 21C10 12 16 6 25 6C34 6 40 13.5 40 21C40 27.3634 29.2018 38.0462 25.929 41.1378C25.3952 41.6421 24.5813 41.662 24.0233 41.1847ZM30 19C30 21.7614 27.7614 24 25 24C22.2386 24 20 21.7614 20 19C20 16.2386 22.2386 14 25 14C27.7614 14 30 16.2386 30 19Z"
                  fill="${nColor}"
                />
                ${
                  iconText
                    ? `<text x="51%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="${
                        iconTextFill || '#ffffff'
                      }" style="font-family:Arial; font-weight:bold;">${iconText}</text>`
                    : ''
                }
              </svg>`;
          const marker = new window.google.maps.Marker({
            icon: {
              anchor: new window.google.maps.Point(
                nIconWidth / 2,
                nIconHeight / 2
              ),
              url: `data:image/svg+xml;charset=utf-8,${encodeURIComponent(
                svgString
              )}`,
            },
            label,
            zIndex: 99000 - i,
            initialIndex: 99000 - i,
            position: { lat, lng },
            map: mapInstance.current,
            id,
            selected,
          });

          const handleClick = ({ domEvent, latLng }) => {
            markerOnClick({ item, domEvent, latLng });
          };

          marker.addListener('click', handleClick);

          initialMarkersRef.current.push(marker);
        });
      }
    }, [initialMarks, markerOnClick]);

    const handleVisualization = useCallback(() => {
      if (initialHeatmapLayer?.data?.length > 0) {
        const { data, ...options } = initialHeatmapLayer;
        const hmData = initialHeatmapLayer.data.map(({ lat, lng, weight }) => ({
          location: new window.google.maps.LatLng(lat, lng),
          weight,
        }));

        const pointArray = new window.google.maps.MVCArray(hmData);

        if (!heatmapRef.current) {
          heatmapRef.current =
            new window.google.maps.visualization.HeatmapLayer({
              data: pointArray,
              ...options,
            });

          heatmapRef.current.setMap(mapInstance.current);
        }
      }
    }, [initialHeatmapLayer]);

    useEffect(() => {
      if (!mapInstance.current) {
        mapInstance.current = new window.google.maps.Map(mapElement.current, {
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false,
          styles: theme === 'dark' ? DEFAULT_MAP_STYLE_DARK : DEFAULT_MAP_STYLE,
          center,
          zoom: initialZoom,
        });
      }

      mapInstance.current.setCenter(center);

      handleCircle();
      handleMarkers();
      handleVisualization();

      return () => {
        initialMarkersRef.current.forEach((marker) => {
          window.google.maps.event.clearListeners(marker, 'click');
        });

        if (circleRef.current) {
          window.google.maps.event.clearListeners(
            circleRef.current,
            'bounds_changed'
          );
        }
      };
    }, [
      initialZoom,
      handleCircle,
      handleMarkers,
      handleVisualization,
      center,
      theme,
    ]);

    useEffect(() => {
      mapInstance?.current?.setOptions({
        styles: theme === 'dark' ? DEFAULT_MAP_STYLE_DARK : DEFAULT_MAP_STYLE,
      });
    }, [theme]);

    const focusToItem = (item) => {
      if (circleRef.current) {
        circleRef.current.setOptions({ strokeOpacity: 0.2, fillOpacity: 0.1 });
      }

      const currentBounds = mapInstance.current?.getBounds();

      initialMarkersRef.current.forEach((marker) => {
        if (marker.get('id') !== item.id) {
          marker.setOpacity(0.2);
          marker.setZIndex(
            marker.get('selected')
              ? window.google.maps.Marker.MAX_ZINDEX - 1
              : marker.get('initialIndex')
          );
        } else {
          marker.setOpacity(1);
          marker.setZIndex(window.google.maps.Marker.MAX_ZINDEX);

          const point = new window.google.maps.LatLng(item.lat, item.lng);

          if (currentBounds && !currentBounds.contains(point)) {
            mapInstance.current.setCenter({
              lat: item.lat,
              lng: item.lng,
            });
          }
        }
      });
    };

    const unfocus = () => {
      initialMarkersRef.current.forEach((marker) => {
        marker.setOpacity(1);
        marker.setZIndex(
          marker.get('selected')
            ? window.google.maps.Marker.MAX_ZINDEX
            : marker.get('initialIndex')
        );
      });
      if (circleRef.current) {
        circleRef.current.setOptions({ strokeOpacity: 0.8, fillOpacity: 0.35 });
      }
    };

    const resetMap = () => {
      mapInstance.current.setCenter(center);
      mapInstance.current.setZoom(initialZoom);
    };

    useImperativeHandle(ref, () => ({
      focusToItem: (item) => {
        focusToItem(item);
      },
      unfocus: () => {
        unfocus();
      },
      resetMap: () => {
        resetMap();
      },
    }));

    return <div ref={mapElement} id="map" {...props} />;
  }
);

const mapPropTypes = {
  initialCenter: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  initialMarks: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  initialHeatmapLayer: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
        weight: PropTypes.number,
      })
    ).isRequired,
    dissipating: PropTypes.bool,
    gradient: PropTypes.arrayOf(PropTypes.string),
    maxIntensity: PropTypes.number,
    opacity: PropTypes.number,
    radius: PropTypes.number,
  }),
  initialZoom: PropTypes.number,
  withCircle: PropTypes.number,
  markerOnClick: PropTypes.func,
  circleOnChange: PropTypes.func,
};

const mapDefaultProps = {
  initialCenter: DEFAULT_CENTER,
  initialMarks: [],
  initialHeatmapLayer: null,
  initialZoom: 14,
  withCircle: 0,
  markerOnClick: () => {},
  circleOnChange: () => {},
};

MapComponent.propTypes = mapPropTypes;

MapComponent.defaultProps = mapDefaultProps;

const GoogleMap = forwardRef(
  (
    {
      height,
      initialMarks,
      initialHeatmapLayer,
      initialCenter,
      initialZoom,
      withCircle,
      className,
      markerOnClick,
      circleOnChange,
      isLoading,
    },
    ref
  ) => (
    <div className="position-relative">
      {isLoading && (
        <div className="position-absolute bg-dark opacity-75 top-0 bottom-0 end-0 start-0 rounded zi-1">
          <div className="position-absolute top-50 start-50 translate-middle">
            <Spinner animation="border" variant="white" />
          </div>
        </div>
      )}
      <MapComponent
        ref={ref}
        initialCenter={initialCenter}
        initialZoom={initialZoom}
        initialMarks={initialMarks}
        initialHeatmapLayer={initialHeatmapLayer}
        withCircle={withCircle}
        className={className}
        markerOnClick={markerOnClick}
        circleOnChange={circleOnChange}
        style={{ width: '100%', height }}
      />
    </div>
  )
);

GoogleMap.propTypes = {
  height: PropTypes.number,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  ...mapPropTypes,
};

GoogleMap.defaultProps = {
  height: 200,
  className: 'rounded',
  isLoading: false,
  ...mapDefaultProps,
};

export default GoogleMap;
