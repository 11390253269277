import React from 'react';
import { Badge, Card, Image, ListGroup } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { SmartSteps } from '../../../../components';
import Constants from '../../../../constants';
import { Routes as AppRoutes } from '../../../../router/routeMapping';

function getTitleByRefTable(RefTable) {
  switch (RefTable) {
    case 'homes':
      return 'Listing';
    case 'realtor.realtor':
      return 'Realtor';
    case 'admin_operism.businesses':
      return 'Business';
    case 'Bidlaw.users':
      return 'Lawyer';
    case 'bestplaces':
      return 'Neighborhood';
    case 'poi':
      return 'Point of Interest';
    case 'schools':
      return 'School';
    case 'gtfs_stops':
      return 'Transit Stop ';
    case 'article':
      return 'Project';
    default:
      return 'Information';
  }
}
function Notes() {
  const formatter = (data) => {
    let priority;
    if (data.Priority) {
      switch (Math.round(data.Priority)) {
        case 1:
        case 2:
          priority = Constants.Review.Priority.Low;
          break;

        case 4:
        case 5:
          priority = Constants.Review.Priority.High;
          break;

        case 3:
          priority = Constants.Review.Priority.Medium;
          break;

        default:
          priority = null;
          break;
      }
    }

    let type;
    if (data.Rating) {
      switch (Math.round(data.Rating)) {
        case 1:
        case 2:
          type = Constants.Review.Types.Negative;
          break;

        case 4:
        case 5:
          type = Constants.Review.Types.Positive;
          break;

        case 3:
          type = Constants.Review.Types.Neutral;
          break;

        default:
          type = null;
          break;
      }
    }

    const {
      UserName,
      UserSurname,
      Comment,
      Date,
      ReviewID,
      UserPP,
      Ref,
      RefID,
      RefTable,
    } = data;

    let link = `${AppRoutes.protected.LISTINGS_DETAIL.children.CITY.path}`;
    switch (RefTable) {
      case 'homes':
        link = `${AppRoutes.protected.LISTINGS_DETAIL.children.INFO.path.replace(
          ':id',
          RefID
        )}`;
        break;
      case 'realtor.realtor':
        link = `${AppRoutes.protected.REALTOR_DETAIL.children.INFO.path.replace(
          ':id',
          RefID
        )}`;
        break;
      case 'admin_operism.businesses':
        link = `${AppRoutes.protected.SMALLBUSINESS_DETAIL.children.INFO.path.replace(
          ':id',
          RefID
        )}`;
        break;
      case 'Bidlaw.users':
        link = `${AppRoutes.protected.LAWYER_DETAIL.children.INFO.path.replace(
          ':id',
          RefID
        )}`;
        break;
      case 'bestplaces':
        link = `${AppRoutes.protected.LISTINGS.children.ZIP.path}?Id=${RefID}`;

        break;
      case 'poi':
        link = `${AppRoutes.protected.LISTINGS.children.POI.path}?Id=${RefID}`;

        break;
      case 'schools':
        link = `${AppRoutes.protected.LISTINGS.children.SCHOOL.path}?Id=${RefID}`;
        break;
      case 'gtfs_stops':
        link = `${AppRoutes.protected.LISTINGS.children.STOPS.path}?Id=${RefID}`;

        break;
      case 'article':
        link = `${AppRoutes.protected.LISTINGS.children.STOPS.path}?Id=${RefID}`;

        break;
      default:
        link = `${AppRoutes.protected.LISTINGS_DETAIL.children.CITY.path}`;
        break;
    }

    const formatted = {
      original: data,
      id: ReviewID,
      title: `${UserName} ${UserSurname}`,
      content: (
        <>
          <ListGroup className="list-group-sm mb-2">
            <ListGroup.Item className="list-group-item-light">
              {getTitleByRefTable(RefTable) !== 'Information' && (
                <Card
                  className="mb-3 overflow-hidden"
                  style={{ maxWidth: '30rem' }}
                >
                  <div className="row g-0">
                    <div className="col-md-4">
                      <Image
                        fluid
                        src={Ref.photo}
                        className="h-100"
                        style={{ objectFit: 'cover' }}
                      />
                    </div>
                    <div className="col-md-8">
                      <Card.Body>
                        <Card.Title as="h5">{Ref.title}</Card.Title>
                        <Card.Text>{getTitleByRefTable(RefTable)}</Card.Text>
                      </Card.Body>
                    </div>
                  </div>
                  <Link to={link} className="stretched-link" />
                </Card>
              )}
              <span className="d-block fs-6 text-dark">{Comment}</span>
            </ListGroup.Item>
          </ListGroup>
          <div className="d-flex flex-column flex-sm-row justify-content-between">
            <div>
              {priority && (
                <Badge
                  bg={`soft-${priority.color}`}
                  text={priority.color}
                  className="mt-2 me-1"
                >
                  <i className={`${priority.icon} me-1`} />
                  <FormattedMessage id={`app.common.${priority.labelKey}`} />
                </Badge>
              )}
              {type && (
                <Badge bg={`${type.color}`} className="mt-2">
                  <i className={`${type.icon} me-1`} />
                  <FormattedMessage id={`app.common.${type.labelKey}`} />
                </Badge>
              )}
            </div>
            <div className="mt-2">
              <span className="small text-muted">{moment(Date).fromNow()}</span>
            </div>
          </div>
        </>
      ),
      color: priority?.color ? `soft-${priority?.color}` : undefined,
      icon: UserPP,
    };

    return formatted;
  };

  return (
    <div className="content container">
      <SmartSteps
        paths={{
          list: '/reviews/list',
          create: '/reviews/insert',
          delete: '/reviews/delete',
          update: '/reviews/update',
        }}
        formatter={formatter}
        type="avatar"
      />
    </div>
  );
}

export default Notes;
