import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Nav,
  ListGroup,
  Card,
  Image,
  Dropdown,
} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import UnknownIcon from '../assets/images/svg/brands/google-docs-icon.svg';
import PdfIcon from '../assets/images/svg/brands/pdf-icon.svg';
import ImageIcon from '../assets/images/svg/components/placeholder-img-format.svg';
import CsvIcon from '../assets/images/svg/components/placeholder-csv-format.svg';
import Utils from '../utils';
import { LangContext } from '../context/LangContext';

const viewTypes = [
  { icon: 'bi-grid', value: 'grid' },
  { icon: 'bi-view-list', value: 'list' },
];

const iconMap = {
  unknown: UnknownIcon,
  pdf: PdfIcon,
  jpg: ImageIcon,
  jpeg: ImageIcon,
  png: ImageIcon,
  gif: ImageIcon,
  bmp: ImageIcon,
  csv: CsvIcon,
};

const dummyFile = {
  FileID: 1,
  FileName: 'shh-240kasa-haziran-1920x1080px.jpg',
  FileSize: 1096959,
  FileType: 'jpg',
  MimeType: 1,
  Duration: 0,
  Width: 1920,
  Height: 1080,
  DateCreated: '2022-10-25',
  SHA1: 1,
  IDDirectories: 0,
  ContentType: 2,
  Status: 1,
  RefTable: 'homes',
  RefID: 601,
};

function ItemMenu() {
  return (
    <Dropdown.Menu className="m-0" renderOnMount>
      <Dropdown.Header>
        <FormattedMessage id="app.common.settings" />
      </Dropdown.Header>
      <Dropdown.Item
        onClick={(ev) => {
          ev.preventDefault();
        }}
      >
        <i className="bi-download dropdown-item-icon" />
        <span>
          <FormattedMessage id="app.common.download" />
        </span>
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item
        onClick={(ev) => {
          ev.preventDefault();
        }}
      >
        <i className="bi-trash dropdown-item-icon" />
        <span>
          <FormattedMessage id="app.common.delete" />
        </span>
      </Dropdown.Item>
    </Dropdown.Menu>
  );
}

function FileManager({ files }) {
  const { currentLanguage } = useContext(LangContext);
  const [viewType, setViewType] = useState(viewTypes[0].value);

  return (
    <>
      <Row xs="1" sm="2" md="3" lg="4" className="mb-5">
        <Col>asd 1</Col>
        <Col>asd 2</Col>
        <Col>asd 3</Col>
        <Col>asd 4</Col>
      </Row>
      <Row className="align-items-center mb-3 mb-lg-5">
        <Col>
          <h2 className="h4 mb-0">Files</h2>
        </Col>
        <Col xs="auto">
          <Nav variant="segment" activeKey={viewType} onSelect={setViewType}>
            {viewTypes.map((item) => (
              <Nav.Item key={item.value}>
                <Nav.Link eventKey={item.value}>
                  <i className={item.icon} />
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Col>
      </Row>

      {viewType === 'grid' ? (
        <Row xs="1" sm="2" md="3" lg="4">
          {files.map((item, index) => (
            <Col key={`file_${index.toString()}`} className="mb-3 mb-lg-5">
              <Card className="card-sm card-hover-shadow card-header-borderless h-100 text-center">
                <Card.Header className="card-header-content-between border-0">
                  <span className="small">
                    {Utils.String.humanFileSize(dummyFile.FileSize)}
                  </span>
                  <Dropdown align="end">
                    <Dropdown.Toggle
                      bsPrefix="btn"
                      variant="ghost-secondary"
                      size="sm"
                      className="btn-icon rounded-circle card-dropdown-btn"
                    >
                      <i className="bi-three-dots-vertical" />
                    </Dropdown.Toggle>
                    <ItemMenu />
                  </Dropdown>
                </Card.Header>
                <Card.Body>
                  <Image
                    className="avatar avatar-4x3"
                    src={iconMap[dummyFile.FileType] || iconMap.unknown}
                  />
                </Card.Body>
                <Card.Body>
                  <Card.Title as="h5">{dummyFile.FileName}</Card.Title>
                  <p className="small" lang={currentLanguage}>
                    <FormattedMessage
                      id="app.common.createdN"
                      values={{ n: moment(dummyFile.DateCreated).fromNow() }}
                    />
                  </p>
                </Card.Body>
                <a href="#!" className="stretched-link" />
              </Card>
            </Col>
          ))}
        </Row>
      ) : (
        <ListGroup>
          {files.map((item, index) => (
            <ListGroup.Item key={`file_${index.toString()}`}>
              <Row className="align-items-center">
                <Col xs="auto">
                  <Image
                    className="avatar avatar-xs avatar-4x3"
                    src={iconMap[dummyFile.FileType] || iconMap.unknown}
                  />
                </Col>
                <Col>
                  <h5 className="mb-0">
                    <a className="text-dark" href="#!">
                      {dummyFile.FileName}
                    </a>
                  </h5>
                  <ul className="list-inline list-separator small text-body">
                    <li className="list-inline-item" lang={currentLanguage}>
                      <FormattedMessage
                        id="app.common.createdN"
                        values={{
                          n: moment(dummyFile.DateCreated).fromNow(),
                        }}
                      />
                    </li>
                    <li className="list-inline-item">
                      {Utils.String.humanFileSize(dummyFile.FileSize)}
                    </li>
                  </ul>
                </Col>
                <Col xs="auto">
                  <Dropdown align="end">
                    <Dropdown.Toggle bsPrefix="btn" variant="white" size="sm">
                      <span className="d-none d-sm-inline-block me-1">
                        <FormattedMessage id="app.common.more" />
                      </span>
                      <i className="bi-chevron-down" />
                    </Dropdown.Toggle>
                    <ItemMenu />
                  </Dropdown>
                </Col>
              </Row>
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}
    </>
  );
}

FileManager.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      thumb: PropTypes.string,
    })
  ),
};

FileManager.defaultProps = {
  files: [],
};

export default FileManager;
