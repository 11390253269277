import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Dropdown } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { RequestLoading, RequestResult, Tooltip } from '../../../../components';
import { LineChart } from '../../../../components/Charts';
import Constants from '../../../../constants';
import Utils from '../../../../utils';
import { useAxiosQuery } from '../../../../hooks';
import { ThemeContext } from '../../../../context/ThemeContext';

function LineChartCardMedianDayOnMarket({ zip }) {
  const { formatNumber } = useIntl();
  const { formatMessage } = useIntl();
  const { theme } = useContext(ThemeContext);
  const [activeMetric, setActiveMetric] = useState(
    Constants.RealEstate.metricTypes.All.id
  );

  const getFormattedNumber = (v) =>
    formatNumber(v, {
      maximumFractionDigits: 0,
    });

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (ctx) => getFormattedNumber(ctx.raw),
        },
      },
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
          displayFormats: {
            day: Constants.DateFormats.APP.Moment.Common,
          },
        },
      },
      y: {
        ticks: {
          callback: (v) => getFormattedNumber(v),
        },
      },
    },
  };

  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: 'zip_metrics/list',
    preventFetch: !zip || !activeMetric,
    params: { zip, type: 'median_days_on_market', home_type: activeMetric },
  });

  const getLineData = () => {
    const withGradient = true;
    const labels = apiData.map((item) => item.date);
    const datasets = [
      {
        data: apiData.map((item) => Number(item.value)),
        ...Utils.Chart.getLineChartOptions(
          0,
          theme,
          withGradient
            ? {
                fill: 'start',
                backgroundColor: (context) => {
                  const { ctx } = context.chart;
                  const gradient = ctx.createLinearGradient(0, 0, 0, 400);
                  gradient.addColorStop(0, Constants.Colors.Palette[0]);
                  gradient.addColorStop(
                    1,
                    Utils.Color.getRGBAColorFromHex(
                      Constants.Colors.Palette[2],
                      0.1
                    )
                  );
                  return gradient;
                },
                tension: 0.4,
              }
            : {
                borderWidth: 2,
                pointRadius: 4,
                pointHoverRadius: 4,
              }
        ),
      },
    ];
    return {
      labels,
      datasets,
    };
  };
  return (
    <Card className="h-100">
      <Card.Header className="card-header-content-between">
        <Card.Title
          bsPrefix="card-header-title"
          as="h4"
          className="d-flex justify-content-between"
        >
          <FormattedMessage id="app.common.marketInsightsMedianDaysOnMarket" />
          <Tooltip
            content={formatMessage({
              id: 'app.toolTips.marketInsightsMedianDaysOnMarket',
            })}
          >
            <i className="bi-info-circle-fill  ms-2" />
          </Tooltip>
        </Card.Title>
        <Dropdown align="end" onSelect={setActiveMetric}>
          <Dropdown.Toggle
            as={Button}
            size="sm"
            variant="white"
            className="d-flex align-items-center"
          >
            <span
              className="legend-indicator me-1"
              style={{
                backgroundColor:
                  Constants.RealEstate.metricTypes[activeMetric].color,
              }}
            />

            <FormattedMessage
              id={`app.common.${Constants.RealEstate.metricTypes[activeMetric].labelKey}`}
            />
          </Dropdown.Toggle>
          <Dropdown.Menu className="m-0" renderOnMount>
            {Object.keys(Constants.RealEstate.metricTypes).map((key) => {
              const item = Constants.RealEstate.metricTypes[key];
              return (
                <Dropdown.Item
                  key={item.id}
                  eventKey={item.id}
                  className="d-flex align-items-center"
                >
                  <span
                    className="legend-indicator me-1"
                    style={{ backgroundColor: item.color }}
                  />
                  <FormattedMessage id={`app.common.${item.labelKey}`} />
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </Card.Header>
      <Card.Body>
        <RequestLoading loading={apiLoading} margin="5" />
        <RequestResult type="error" message={apiError} />
        {!apiLoading && !apiError && Object.keys(apiData)?.length === 0 && (
          <RequestResult type="secondary" message="app.common.noData" />
        )}
        {!apiLoading && !apiError && Object.keys(apiData)?.length > 0 && (
          <div className="h-100 d-flex flex-column justify-content-center align-items-center">
            <LineChart data={getLineData()} options={options} />
          </div>
        )}
      </Card.Body>
    </Card>
  );
}

LineChartCardMedianDayOnMarket.propTypes = {
  zip: PropTypes.string.isRequired,
};

export default LineChartCardMedianDayOnMarket;
