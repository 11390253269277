import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Card,
  Badge,
  ProgressBar,
  Modal,
  Alert,
  Button,
} from 'react-bootstrap';
import { useIntl, FormattedMessage, FormattedNumber } from 'react-intl';
import { Routes as AppRoutes } from '../../../../router/routeMapping';
import {
  RequestLoading,
  RequestResult,
  Tooltip,
  FormattedHtml,
} from '../../../../components';
import DoughnutChartCard from './DoughnutChartCard';
import DoughnutChartCardRace from './DoughnutChartCardRace';
import HalfDoughnutChartCardVoting from './HalfDoughnutChartCardVoting';
import HalfDoughnutChartCard from './HalfDoughnutChartCard';
import MapListingsByZip from './MapListingsByZip';
import BarChartCard from './BarChartCard';
import {
  getRiskColor,
  getProgressVariant,
  getCompetitiveness,
} from '../../../../helpers/helpers';
import { useAxiosQuery } from '../../../../hooks';
import { EconomicIndicators } from '../../../../constants/realEstate';
import LineChartCard from './LineChartCard';
import LineChartCardMarketInventory from './LineChartCardMarketInventory';
import LineChartCardMedianDayOnMarket from './LineChartCardMedianDayOnMarket';
import TableRealEstateUSANumbers from './TableRealEstateUSANumbers';
import TableNearbyNumbers from './TableNearbyNumbers';
import TabLast5Listings from './TabLast5Listings';
import TableNewListings from './TableNewListings';
import WordCloud from './WordCloud';
import ScoreChartCardCity from './ScoreChartCardCity';
import QorsQImage from '../../../../assets/images/qors-q.png';

function InfoSection() {
  const [infoShow, setInfoShow] = useState(true);
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <Row className="position-relative g-0 mb-5 zi-0">
        <Col md="6">
          <Card
            className="card-ghost border-0 rounded-0 h-100 overflow-hidden"
            style={{ backgroundColor: '#182132' }}
          >
            <Card.Img
              variant="top"
              src={QorsQImage}
              className="position-absolute w-50 zi-0"
              style={{ bottom: -180, left: -50, opacity: 0.1 }}
            />
            <Card.Body className="text-center position-relative zi-1 py-5">
              <Card.Title as="h1" className="text-white mb-4">
                Don&apos;t guess, Invest with
                <span className="ms-1 position-relative">
                  Confidence!
                  <svg
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 376 74"
                    xmlSpace="preserve"
                    style={{ width: 140, top: 20, left: -3 }}
                    className="position-absolute"
                  >
                    <g>
                      <g>
                        <path
                          fill="#ed4c78"
                          d="M19.1,59.7c20.3-6.3,41.5-10.4,62.4-14c52.7-9.1,106.4-12.6,159.7-10.7c38.9,1.4,77.7,5.8,115.9,13.5
			c4.7,0.9,6.7-6.3,2-7.2c-64.5-13-130.7-16.9-196.4-12.6c-41.8,2.7-83.6,8.4-124.3,18.1c-7.2,1.7-14.4,3.5-21.5,5.7
			C12.5,53.9,14.5,61.1,19.1,59.7L19.1,59.7z"
                        />
                      </g>
                    </g>
                  </svg>
                </span>
              </Card.Title>
              <Card.Text as="h4" className="text-white fw-normal mb-4">
                Create a profile with your preferences to supercharge your
                <br />
                AI score for targeted investments.
                <br />
                Hundreds of criteria at your fingertips.
              </Card.Text>
              <Card.Text as="h5" className="text-warning fw-normal mb-4">
                Add profiles based on your plan, and upgrade for more options.
                <br />
                Use the wizard for personalized criteria.
                <br />
                More details mean sharper analysis.
              </Card.Text>

              <Link to={AppRoutes.protected.AI.path} className="link ms-1">
                <Button variant="danger">Add or Customize Your Profile</Button>
              </Link>
            </Card.Body>
          </Card>
        </Col>
        <Col md="6">
          <Card
            className="card-ghost border-0 rounded-0 h-100 overflow-hidden"
            style={{ backgroundColor: '#0c1526' }}
          >
            <Card.Img
              variant="top"
              src={QorsQImage}
              className="position-absolute w-50 zi-0"
              style={{ top: -60, right: -70, opacity: 0.1 }}
            />
            <Card.Body className="text-center position-relative zi-1 py-5">
              <Card.Title as="h1" className="text-danger mb-4">
                Refine Your Strategy with QORS
              </Card.Title>
              <Card.Text as="h3" className="text-white fst-italic mb-4">
                Access ZIP and property-spesific analyses effortlessly
              </Card.Text>
              <Card.Text as="h4" className="text-white fw-normal mb-4">
                Customize your profile for a tailored dashboard,
                <br />
                updating analyses and property listings based on
                <br />
                your criteria.
              </Card.Text>
              <Card.Text as="h5" className="text-warning fw-normal">
                Explore properties for exclusive property-specific insights.
                <br />
                Upgrade your plan to add more ZIPs and enhance
                <br />
                your investment potential.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Alert
        variant="soft-secondary"
        className="mb-5 d-none"
        onClose={() => setInfoShow(false)}
        dismissible={infoShow}
      >
        <Alert.Heading
          as="h5"
          className={!infoShow ? 'mb-0' : undefined}
          role={!infoShow ? 'button' : undefined}
          onClick={() => {
            if (!infoShow) {
              setInfoShow(true);
            }
          }}
        >
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <i className="bi-info-circle-fill" />
            </div>
            <div className="ms-2">
              <FormattedMessage id="app.common.aboutThisTool" />
            </div>
          </div>
        </Alert.Heading>
        {infoShow && (
          <>
            <h1>QORS ZIP ANALYSIS</h1>

            <FormattedHtml
              id="app.helpers.floorPlans.dashboard.info"
              // values={{ h1: (v) => formatInfo(v, 'h1') }} // bunu custom bişi yapmak için kullan.. atıyorum h1 altında boşluk az geldi.. o zaman..
            />
          </>
        )}
      </Alert>
      <Modal
        show={modalShow}
        onHide={() => {
          setModalShow(false);
        }}
        size="xl"
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="ratio ratio-16x9">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/1y2vzxP9Syo?si=E0VGhGubedb4_2PI"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
function CardCity({ zip }) {
  const { formatMessage } = useIntl();
  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/bestplaces/get_city_by_zip',
    preventFetch: !zip,
    params: { zip },
  });

  return (
    <>
      <RequestLoading loading={apiLoading} size="lg" margin="5" />
      <RequestResult type="error" message={apiError} />

      {!apiLoading && !apiError && apiData?.length > 0 && (
        <Row>
          <Col xs="12">
            <InfoSection />
          </Col>
          <Col lg="6" className="mb-3 mb-lg-5">
            <Row className="h-100 d-flex justify-content-between">
              <Col lg="6" className="mb-3">
                <Card className="card-hover-shadow h-100">
                  <Card.Body>
                    <Card.Subtitle
                      as="h6"
                      className="d-flex justify-content-between"
                    >
                      <FormattedMessage id="app.common.zip" />
                    </Card.Subtitle>

                    <Card.Title as="h2" className="text-inherit">
                      {apiData[0].zip}
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="6" className="mb-3">
                <Card className="card-hover-shadow h-100">
                  <Card.Body>
                    <Card.Subtitle
                      as="h6"
                      className="d-flex justify-content-between"
                    >
                      <FormattedMessage id="app.common.city" />
                    </Card.Subtitle>

                    <Card.Title as="h2" className="text-inherit">
                      {apiData[0].city}
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="6" className="mb-3">
                <Card className="card-hover-shadow h-100">
                  <Card.Body>
                    <Card.Subtitle
                      as="h6"
                      className="d-flex justify-content-between"
                    >
                      <FormattedMessage id="app.common.state" />
                    </Card.Subtitle>

                    <Card.Title as="h2" className="text-inherit">
                      {apiData[0].state}
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="6" className="mb-3">
                <Card className="card-hover-shadow h-100">
                  <Card.Body>
                    <Card.Subtitle
                      as="h6"
                      className="d-flex justify-content-between"
                    >
                      <FormattedMessage id="app.common.medianPricePerSqft" />
                      <Tooltip
                        content={formatMessage({
                          id: 'app.toolTips.medianPricePerSqft',
                        })}
                      >
                        <i className="bi-info-circle-fill" />
                      </Tooltip>
                    </Card.Subtitle>

                    <Card.Title as="h2" className="text-inherit">
                      {apiData[0].median_listing_price_per_square_foot === 0 ||
                      apiData[0].median_listing_price_per_square_foot ==
                        null ? (
                        'N/A'
                      ) : (
                        <FormattedNumber
                          value={
                            apiData[0].median_listing_price_per_square_foot
                          }
                          // eslint-disable-next-line react/style-prop-object
                          style="currency"
                          currency="USD"
                          minimumFractionDigits={0}
                          maximumFractionDigits={0}
                        />
                      )}
                    </Card.Title>

                    <Badge
                      bg={`soft-${
                        Number(
                          apiData[0].median_listing_price_per_square_foot_mm
                        ) > -1
                          ? 'success'
                          : 'danger'
                      }`}
                      text={`${
                        Number(
                          apiData[0].median_listing_price_per_square_foot_mm
                        ) > -1
                          ? 'success'
                          : 'danger'
                      }`}
                    >
                      Monthly change &nbsp;
                      <FormattedNumber
                        value={
                          apiData[0].median_listing_price_per_square_foot_mm
                        }
                        // eslint-disable-next-line react/style-prop-object
                        minimumFractionDigits={0}
                        maximumFractionDigits={0}
                      />
                      %
                    </Badge>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="6" className="mb-3 mb-lg-0">
                <Card className="card-hover-shadow h-100">
                  <Card.Body>
                    <Card.Subtitle
                      as="h6"
                      className="d-flex justify-content-between"
                    >
                      <FormattedMessage id="app.common.totalListingCount" />
                      <Tooltip
                        content={formatMessage({
                          id: 'app.toolTips.totalListingCount',
                        })}
                      >
                        <i className="bi-info-circle-fill" />
                      </Tooltip>
                    </Card.Subtitle>

                    <Card.Title as="h2" className="text-inherit">
                      {apiData[0].total_listing_count}
                    </Card.Title>
                    <Badge
                      bg={`soft-${
                        Number(apiData[0].total_listing_count_mm) > 0
                          ? 'success'
                          : 'danger'
                      }`}
                      text={`${
                        Number(apiData[0].total_listing_count_mm) > 0
                          ? 'success'
                          : 'danger'
                      }`}
                    >
                      Monthly change &nbsp;
                      <FormattedNumber
                        value={apiData[0].total_listing_count_mm}
                        // eslint-disable-next-line react/style-prop-object
                        minimumFractionDigits={0}
                        maximumFractionDigits={0}
                      />
                      %
                    </Badge>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="6" className="mb-3 mb-lg-0">
                <Card className="card-hover-shadow h-100">
                  <Card.Body>
                    <Card.Subtitle
                      as="h6"
                      className="d-flex justify-content-between"
                    >
                      <FormattedMessage id="app.common.hotnessRank" />
                      <Tooltip
                        content={formatMessage({
                          id: 'app.toolTips.hotnessRank',
                        })}
                      >
                        <i className="bi-info-circle-fill" />
                      </Tooltip>
                    </Card.Subtitle>

                    <Card.Title as="h2" className="text-inherit">
                      <FormattedNumber
                        value={apiData[0].hotness_rank}
                        // eslint-disable-next-line react/style-prop-object

                        minimumFractionDigits={0}
                        maximumFractionDigits={0}
                      />
                    </Card.Title>
                    <Badge
                      bg={`soft-${
                        Number(apiData[0].hotness_rank_mm) > -1
                          ? 'success'
                          : 'danger'
                      }`}
                      text={`${
                        Number(apiData[0].hotness_rank_mm) > -1
                          ? 'success'
                          : 'danger'
                      }`}
                    >
                      Monthly change &nbsp;
                      <FormattedNumber
                        value={apiData[0].hotness_rank_mm}
                        // eslint-disable-next-line react/style-prop-object
                        minimumFractionDigits={0}
                        maximumFractionDigits={0}
                      />
                    </Badge>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col lg="6" className="mb-3 mb-lg-5">
            <WordCloud zip={apiData[0].zip} />
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <ScoreChartCardCity
              value={apiData[0].AIRate}
              zip={apiData[0].zip}
            />
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Row className="h-100 justify-content-between">
              <Col className="mb-3">
                <Card className="card-hover-shadow h-100">
                  <Card.Body>
                    <Card.Subtitle
                      as="h6"
                      className="d-flex justify-content-between"
                    >
                      <FormattedMessage id="app.common.competitiveness" />
                      <Tooltip
                        content={formatMessage({
                          id: 'app.toolTips.competitiveness',
                        })}
                      >
                        <i className="bi-info-circle-fill" />
                      </Tooltip>
                    </Card.Subtitle>

                    <Card.Title
                      as="h2"
                      className="text-inherit"
                      style={{ fontSize: '4em' }}
                    >
                      {apiData[0].competitive_score === 0 ||
                      apiData[0].competitive_score == null ? (
                        'N/A'
                      ) : (
                        <FormattedNumber
                          value={apiData[0].competitive_score}
                          // eslint-disable-next-line react/style-prop-object

                          minimumFractionDigits={0}
                          maximumFractionDigits={0}
                        />
                      )}
                    </Card.Title>

                    <Badge
                      className="text-wrap text-start"
                      bg={`soft-${
                        getCompetitiveness(apiData[0].competitive_title).color
                      }`}
                      text={`${
                        getCompetitiveness(apiData[0].competitive_title).color
                      }`}
                    >
                      <i className="bi-info-circle-fill me-1" />
                      {getCompetitiveness(apiData[0].competitive_title).text}
                    </Badge>
                  </Card.Body>
                </Card>
              </Col>
              <Col className="mb-3">
                <Card className="card-hover-shadow h-100">
                  <Card.Body>
                    <Card.Subtitle
                      as="h6"
                      className="d-flex justify-content-between"
                    >
                      <FormattedMessage id="app.common.competitiveness" />
                      <Tooltip
                        content={formatMessage({
                          id: 'app.toolTips.competitiveness',
                        })}
                      >
                        <i className="bi-info-circle-fill" />
                      </Tooltip>
                    </Card.Subtitle>

                    <Card.Title as="h2" className="text-inherit">
                      {apiData[0].competitive_title === 0 ||
                      apiData[0].competitive_title == null
                        ? 'N/A'
                        : apiData[0].competitive_title}
                    </Card.Title>

                    <Badge
                      className="text-wrap text-start"
                      bg={`soft-${
                        getCompetitiveness(apiData[0].competitive_title).color
                      }`}
                      text={`${
                        getCompetitiveness(apiData[0].competitive_title).color
                      }`}
                    >
                      <i className="bi-info-circle-fill me-1" />
                      {getCompetitiveness(apiData[0].competitive_title).text}
                    </Badge>
                  </Card.Body>
                </Card>
              </Col>
              <Col className="mb-3">
                <Card className="card-hover-shadow h-100">
                  <Card.Body>
                    <Card.Subtitle
                      as="h6"
                      className="d-flex justify-content-between"
                    >
                      <FormattedMessage id="app.common.averageSellingDay" />
                      <Tooltip
                        content={formatMessage({
                          id: 'app.toolTips.averageSellingDay',
                        })}
                      >
                        <i className="bi-info-circle-fill" />
                      </Tooltip>
                    </Card.Subtitle>

                    <Card.Title
                      as="h2"
                      className="text-inherit"
                      style={{ fontSize: '4em' }}
                    >
                      {apiData[0].average_selling_day === 0 ||
                      apiData[0].average_selling_day == null ? (
                        'N/A'
                      ) : (
                        <FormattedNumber
                          value={apiData[0].average_selling_day}
                          // eslint-disable-next-line react/style-prop-object
                          minimumFractionDigits={0}
                          maximumFractionDigits={0}
                        />
                      )}
                    </Card.Title>
                    {apiData[0].average_selling_day === 0 ||
                    apiData[0].average_selling_day == null ? (
                      ''
                    ) : (
                      <Badge bg="soft-danger" text="danger">
                        <i className="bi-fire me-1" />
                        The hot homes go pending in around{' '}
                        {apiData[0].hot_average_selling_day} days
                      </Badge>
                    )}
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card className="card-hover-shadow h-100">
                  <Card.Body>
                    <Card.Subtitle
                      as="h6"
                      className="d-flex justify-content-between"
                    >
                      <FormattedMessage id="app.common.averagePriceChange" />
                      <Tooltip
                        content={formatMessage({
                          id: 'app.toolTips.averagePriceChange',
                        })}
                      >
                        <i className="bi-info-circle-fill" />
                      </Tooltip>
                    </Card.Subtitle>

                    <Card.Title
                      as="h2"
                      className="text-inherit"
                      style={{ fontSize: '3em' }}
                    >
                      {apiData[0].average_price_change === 0 ||
                      apiData[0].average_price_change == null ? (
                        'N/A'
                      ) : (
                        <FormattedNumber
                          value={apiData[0].average_price_change}
                          // eslint-disable-next-line react/style-prop-object
                          minimumFractionDigits={0}
                          maximumFractionDigits={0}
                        />
                      )}
                    </Card.Title>

                    {apiData[0].average_price_change === 0 ||
                    apiData[0].average_price_change == null ? (
                      ''
                    ) : (
                      <Badge bg="soft-danger" text="danger">
                        <i className="bi-fire me-1" />
                        Hot homes can sell for about{' '}
                        {apiData[0].hot_average_price_change}% above list price
                      </Badge>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col lg="6" className="mb-3 mb-lg-5">
            <MapListingsByZip zip={apiData[0].zip} />
          </Col>
          <Col xs="12">
            <h3 className="mb-3">
              <FormattedMessage id="app.common.housingStats" />
            </h3>
          </Col>{' '}
          <Col lg="6" className="mb-3 mb-lg-5">
            <LineChartCardMarketInventory zip={apiData[0].zip} />
          </Col>
          <Col lg="6" className="mb-3 mb-lg-5">
            <LineChartCardMedianDayOnMarket zip={apiData[0].zip} />
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.rentersPercent" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.rentersPercent',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>
                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].rentersPercent === 0 ||
                  apiData[0].rentersPercent == null ? (
                    'N/A'
                  ) : (
                    <FormattedNumber
                      value={apiData[0].rentersPercent}
                      // eslint-disable-next-line react/style-prop-object
                      minimumFractionDigits={0}
                      maximumFractionDigits={0}
                    />
                  )}
                  %
                </Card.Title>

                <Badge bg="soft-info" text="info">
                  <i className="bi-info-circle-fill me-1" />
                  {`The US average is ${EconomicIndicators.US_AVERAGE_RENTERS_PERCENT}.%`}
                </Badge>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.medianRentPrice" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.medianRentPrice',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>
                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].MedianRentPrice === 0 ||
                  apiData[0].MedianRentPrice == null ? (
                    'N/A'
                  ) : (
                    <FormattedNumber
                      value={apiData[0].MedianRentPrice}
                      // eslint-disable-next-line react/style-prop-object
                      style="currency"
                      currency="USD"
                      minimumFractionDigits={0}
                      maximumFractionDigits={0}
                    />
                  )}
                </Card.Title>

                <Badge
                  bg={`soft-${
                    Number(apiData[0].MedianRentPrice) >
                    EconomicIndicators.US_MEDIAN_RENT_PRICE
                      ? 'success'
                      : 'danger'
                  }`}
                  text={`${
                    Number(apiData[0].MedianRentPrice) >
                    EconomicIndicators.US_MEDIAN_RENT_PRICE
                      ? 'success'
                      : 'danger'
                  }`}
                >
                  The US average is&nbsp;
                  <FormattedNumber
                    value={EconomicIndicators.US_MEDIAN_RENT_PRICE}
                    // eslint-disable-next-line react/style-prop-object
                    style="currency"
                    currency="USD"
                    minimumFractionDigits={0}
                    maximumFractionDigits={0}
                  />
                  .
                </Badge>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.housesAndApartmens" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.housesAndApartmens',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>
                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].housesAndApartmens === 0 ||
                  apiData[0].housesAndApartmens == null ? (
                    'N/A'
                  ) : (
                    <FormattedNumber
                      value={apiData[0].housesAndApartmens}
                      // eslint-disable-next-line react/style-prop-object
                      minimumFractionDigits={0}
                      maximumFractionDigits={0}
                    />
                  )}
                  %
                </Card.Title>

                <Badge bg="soft-info" text="info">
                  <i className="bi-info-circle-fill me-1" />
                  houses and apartments are are available to rent
                </Badge>
              </Card.Body>
            </Card>
          </Col>
          <Col lg="6" className="mb-3 mb-lg-5">
            <BarChartCard field="demand_score" postalcode={apiData[0].zip} />
          </Col>
          <Col lg="6" className="mb-3 mb-lg-5">
            <BarChartCard field="hotness_score" postalcode={apiData[0].zip} />
          </Col>
          <Col xs="12">
            <h3 className="mb-3">
              <FormattedMessage id="app.common.shortTermAnalysis" />
            </h3>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.averageDailyRate" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.averageDailyRate',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>
                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].airbnb_average_daily_rate === 0 ||
                  apiData[0].airbnb_average_daily_rate == null ? (
                    'N/A'
                  ) : (
                    <FormattedNumber
                      value={apiData[0].airbnb_average_daily_rate}
                      // eslint-disable-next-line react/style-prop-object
                      style="currency"
                      currency="USD"
                      minimumFractionDigits={0}
                      maximumFractionDigits={0}
                    />
                  )}
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.roomDailyAveragePrice" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.roomDailyAveragePrice',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>
                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].AirbnbRoomAveragePrice === 0 ||
                  apiData[0].AirbnbRoomAveragePrice == null ? (
                    'N/A'
                  ) : (
                    <FormattedNumber
                      value={apiData[0].AirbnbRoomAveragePrice}
                      // eslint-disable-next-line react/style-prop-object
                      style="currency"
                      currency="USD"
                      minimumFractionDigits={0}
                      maximumFractionDigits={0}
                    />
                  )}
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.entireHomeDailyAveragePrice" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.entireHomeDailyAveragePrice',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>
                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].AirbnbEntireHomeAveragePrice === 0 ||
                  apiData[0].AirbnbEntireHomeAveragePrice == null ? (
                    'N/A'
                  ) : (
                    <FormattedNumber
                      value={apiData[0].AirbnbEntireHomeAveragePrice}
                      // eslint-disable-next-line react/style-prop-object
                      style="currency"
                      currency="USD"
                      minimumFractionDigits={0}
                      maximumFractionDigits={0}
                    />
                  )}
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.shortTermRentalNearby" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.shortTermRentalNearby',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>
                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].AirbnbAverageStay === 0 ||
                  apiData[0].AirbnbAverageStay == null ? (
                    'N/A'
                  ) : (
                    <FormattedNumber
                      value={apiData[0].AirbnbAverageStay}
                      // eslint-disable-next-line react/style-prop-object

                      minimumFractionDigits={0}
                      maximumFractionDigits={0}
                    />
                  )}
                  +
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.airbBNBscore" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.airBNBscore',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>

                <Card.Title as="h2" className="text-inherit">
                  <ProgressBar
                    className="table-progress"
                    variant={getProgressVariant(apiData[0].airbnb_score)}
                    now={apiData[0].airbnb_score}
                  />
                  {apiData[0].airbnb_score ? apiData[0].airbnb_score : 'N/A'}
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.annualRevenueProjection" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.annualRevenueProjection',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>
                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].airbnb_annual_revenue === 0 ||
                  apiData[0].airbnb_annual_revenue == null ? (
                    'N/A'
                  ) : (
                    <FormattedNumber
                      value={apiData[0].airbnb_annual_revenue}
                      // eslint-disable-next-line react/style-prop-object
                      style="currency"
                      currency="USD"
                      minimumFractionDigits={0}
                      maximumFractionDigits={0}
                    />
                  )}
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.averageOccupancyRate" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.averageOccupancyRate',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>
                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].airbnb_average_occupancy === 0 ||
                  apiData[0].airbnb_average_occupancy == null ? (
                    'N/A'
                  ) : (
                    <FormattedNumber
                      value={apiData[0].airbnb_average_occupancy}
                      // eslint-disable-next-line react/style-prop-object
                      minimumFractionDigits={0}
                      maximumFractionDigits={0}
                    />
                  )}
                  %
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col lg="6" className="mb-3 mb-lg-5">
            <TableNewListings zip={apiData[0].zip} />
          </Col>
          <Col lg="6" className="mb-3 mb-lg-5">
            <LineChartCard zip={apiData[0].zip} />
          </Col>
          <Col xs="12">
            <h3 className="mb-3">
              <FormattedMessage id="app.common.economy" />
            </h3>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.jobMarketIncrease" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.jobMarketIncrease',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>
                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].PercentMarketIncrease === 0 ||
                  apiData[0].PercentMarketIncrease == null ? (
                    'N/A'
                  ) : (
                    <FormattedNumber
                      value={apiData[0].PercentMarketIncrease}
                      // eslint-disable-next-line react/style-prop-object
                      minimumFractionDigits={0}
                      maximumFractionDigits={0}
                    />
                  )}
                  %
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.next10YearsJobGrowth" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.next10YearsJobGrowth',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>
                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].Next10YearsJobGrowth === 0 ||
                  apiData[0].Next10YearsJobGrowth == null ? (
                    'N/A'
                  ) : (
                    <FormattedNumber
                      value={apiData[0].Next10YearsJobGrowth}
                      // eslint-disable-next-line react/style-prop-object
                      minimumFractionDigits={0}
                      maximumFractionDigits={0}
                    />
                  )}
                  %
                </Card.Title>

                <Badge
                  bg={`soft-${
                    Number(apiData[0].Next10YearsJobGrowth) >
                    EconomicIndicators.US_AVERAGE_NEXT10YEARSJOBGROWTH
                      ? 'success'
                      : 'danger'
                  }`}
                  text={`${
                    Number(apiData[0].Next10YearsJobGrowth) >
                    EconomicIndicators.US_AVERAGE_NEXT10YEARSJOBGROWTH
                      ? 'success'
                      : 'danger'
                  }`}
                >
                  {`The US average is ${EconomicIndicators.US_AVERAGE_NEXT10YEARSJOBGROWTH}%.`}
                </Badge>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.saleriesIncome" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.saleriesIncome',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>
                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].saleriesIncome === 0 ||
                  apiData[0].saleriesIncome == null ? (
                    'N/A'
                  ) : (
                    <FormattedNumber
                      value={apiData[0].saleriesIncome}
                      // eslint-disable-next-line react/style-prop-object
                      style="currency"
                      currency="USD"
                      minimumFractionDigits={0}
                      maximumFractionDigits={0}
                    />
                  )}
                </Card.Title>

                <Badge
                  bg={`soft-${
                    Number(apiData[0].saleriesIncome) >
                    EconomicIndicators.US_AVERAGE_INCOME
                      ? 'success'
                      : 'danger'
                  }`}
                  text={`${
                    Number(apiData[0].saleriesIncome) >
                    EconomicIndicators.US_AVERAGE_INCOME
                      ? 'success'
                      : 'danger'
                  }`}
                >
                  The US average is&nbsp;
                  <FormattedNumber
                    value={EconomicIndicators.US_AVERAGE_INCOME}
                    // eslint-disable-next-line react/style-prop-object
                    style="currency"
                    currency="USD"
                    minimumFractionDigits={0}
                    maximumFractionDigits={0}
                  />
                  &nbsp;a year.
                </Badge>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.recentJobGrowth" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.recentJobGrowth',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>
                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].RecentJobGrowth === 0 ||
                  apiData[0].RecentJobGrowth == null ? (
                    'N/A'
                  ) : (
                    <FormattedNumber
                      value={apiData[0].RecentJobGrowth}
                      // eslint-disable-next-line react/style-prop-object

                      minimumFractionDigits={0}
                      maximumFractionDigits={0}
                    />
                  )}
                </Card.Title>

                <Badge
                  bg={`soft-${
                    Number(apiData[0].RecentJobGrowth) >
                    EconomicIndicators.US_AVERAGE_JOBMARKET_INCREASE
                      ? 'success'
                      : 'danger'
                  }`}
                  text={`${
                    Number(apiData[0].RecentJobGrowth) >
                    EconomicIndicators.US_AVERAGE_JOBMARKET_INCREASE
                      ? 'success'
                      : 'danger'
                  }`}
                >
                  {`The US average is ${EconomicIndicators.US_AVERAGE_JOBMARKET_INCREASE}%.`}
                </Badge>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.salesTaxRate" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.salesTaxRate',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>

                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].salesTaxRate} %
                </Card.Title>
                <Badge
                  bg={`soft-${
                    Number(apiData[0].salesTaxRate) <
                    EconomicIndicators.US_AVERAGE_SALES_TAX
                      ? 'success'
                      : 'danger'
                  }`}
                  text={`${
                    Number(apiData[0].salesTaxRate) <
                    EconomicIndicators.US_AVERAGE_SALES_TAX
                      ? 'success'
                      : 'danger'
                  }`}
                >
                  {`The US average is ${EconomicIndicators.US_AVERAGE_SALES_TAX}.`}
                </Badge>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.incomeTaxRate" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.incomeTaxRate',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>

                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].incomeTaxRate}%
                </Card.Title>
                <Badge
                  bg={`soft-${
                    Number(apiData[0].incomeTaxRate) <
                    EconomicIndicators.US_AVERAGE_INCOME_TAX
                      ? 'success'
                      : 'danger'
                  }`}
                  text={`${
                    Number(apiData[0].incomeTaxRate) <
                    EconomicIndicators.US_AVERAGE_INCOME_TAX
                      ? 'success'
                      : 'danger'
                  }`}
                >
                  {`The US average is ${EconomicIndicators.US_AVERAGE_INCOME_TAX}.`}
                </Badge>
              </Card.Body>
            </Card>
          </Col>
          <Col xs="12">
            <h3 className="mb-3">
              <FormattedMessage id="app.common.demography" />
            </h3>
          </Col>
          <Col lg="3" className="mb-3 mb-lg-5">
            <HalfDoughnutChartCard zip={apiData[0].zip} />
          </Col>
          <Col lg="3" className="mb-3 mb-lg-5">
            <DoughnutChartCardRace zip={apiData[0].zip} />
          </Col>
          <Col lg="3" className="mb-3 mb-lg-5">
            <DoughnutChartCard zip={apiData[0].zip} />
          </Col>
          <Col lg="3" className="mb-3 mb-lg-5">
            <HalfDoughnutChartCardVoting zip={apiData[0].zip} />
          </Col>
          <Col xs="12">
            <h3 className="mb-3">
              <FormattedMessage id="app.common.health" />
            </h3>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.healthCostIndex" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.healthCostIndex',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>

                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].healtCostIndex === 0 ||
                  apiData[0].healtCostIndex == null ? (
                    'N/A'
                  ) : (
                    <FormattedNumber
                      value={apiData[0].healtCostIndex}
                      // eslint-disable-next-line react/style-prop-object
                      minimumFractionDigits={0}
                      maximumFractionDigits={0}
                    />
                  )}
                </Card.Title>

                <Badge
                  bg={`soft-${
                    Number(apiData[0].healtCostIndex) <
                    EconomicIndicators.US_AVERAGE_HEALTH_COST_INDEX
                      ? 'success'
                      : 'danger'
                  }`}
                  text={`${
                    Number(apiData[0].healtCostIndex) <
                    EconomicIndicators.US_AVERAGE_HEALTH_COST_INDEX
                      ? 'success'
                      : 'danger'
                  }`}
                >
                  {`The US average is ${EconomicIndicators.US_AVERAGE_HEALTH_COST_INDEX}.`}
                </Badge>
              </Card.Body>
            </Card>
          </Col>{' '}
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.waterQualityIndex" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.waterQualityIndex',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>

                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].waterQualityIndex === 0 ||
                  apiData[0].waterQualityIndex == null ? (
                    'N/A'
                  ) : (
                    <FormattedNumber
                      value={apiData[0].waterQualityIndex}
                      // eslint-disable-next-line react/style-prop-object
                      minimumFractionDigits={0}
                      maximumFractionDigits={0}
                    />
                  )}
                </Card.Title>

                <Badge
                  bg={`soft-${
                    Number(apiData[0].waterQualityIndex) >
                    EconomicIndicators.US_AVERAGE_WATER_QUALITY_INDEX
                      ? 'success'
                      : 'danger'
                  }`}
                  text={`${
                    Number(apiData[0].waterQualityIndex) >
                    EconomicIndicators.US_AVERAGE_WATER_QUALITY_INDEX
                      ? 'success'
                      : 'danger'
                  }`}
                >
                  {`The US average is ${EconomicIndicators.US_AVERAGE_WATER_QUALITY_INDEX}.`}
                </Badge>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.superFundIndex" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.superFundIndex',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>
                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].superfundIndex === 0 ||
                  apiData[0].superfundIndex == null ? (
                    'N/A'
                  ) : (
                    <FormattedNumber
                      value={apiData[0].superfundIndex}
                      // eslint-disable-next-line react/style-prop-object

                      minimumFractionDigits={0}
                      maximumFractionDigits={0}
                    />
                  )}
                </Card.Title>

                <Badge
                  bg={`soft-${
                    Number(apiData[0].superfundIndex) >
                    EconomicIndicators.US_AVERAGE_SUPERFUND_INDEX
                      ? 'success'
                      : 'danger'
                  }`}
                  text={`${
                    Number(apiData[0].superfundIndex) >
                    EconomicIndicators.US_AVERAGE_SUPERFUND_INDEX
                      ? 'success'
                      : 'danger'
                  }`}
                >
                  {`The US average is ${EconomicIndicators.US_AVERAGE_SUPERFUND_INDEX}.`}
                </Badge>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.airQualityIndex" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.airQualityIndex',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>

                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].airQualityIndex === 0 ||
                  apiData[0].airQualityIndex == null ? (
                    'N/A'
                  ) : (
                    <FormattedNumber
                      value={apiData[0].airQualityIndex}
                      // eslint-disable-next-line react/style-prop-object

                      minimumFractionDigits={0}
                      maximumFractionDigits={0}
                    />
                  )}
                </Card.Title>

                <Badge
                  bg={`soft-${
                    Number(apiData[0].airQualityIndex) >
                    EconomicIndicators.US_AVERAGE_AIR_QUALITY_INDEX
                      ? 'success'
                      : 'danger'
                  }`}
                  text={`${
                    Number(apiData[0].airQualityIndex) >
                    EconomicIndicators.US_AVERAGE_AIR_QUALITY_INDEX
                      ? 'success'
                      : 'danger'
                  }`}
                >
                  {`The US average is ${EconomicIndicators.US_AVERAGE_AIR_QUALITY_INDEX}.`}
                </Badge>
              </Card.Body>
            </Card>
          </Col>
          <Col xs="12">
            <h3 className="mb-3">
              <FormattedMessage id="app.common.riskFactors" />
            </h3>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.flood" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.flood',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>

                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].flood_factor}
                </Card.Title>
                <Badge
                  bg={`soft-${getRiskColor(apiData[0].flood_factor)}`}
                  text={`${getRiskColor(apiData[0].flood_factor)}`}
                  className="text-wrap text-start"
                >
                  <i className="bi-info-circle-fill me-1" />
                  {apiData[0].flood}
                </Badge>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.fire" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.fire',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>

                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].fire_factor}
                </Card.Title>
                <Badge
                  bg={`soft-${getRiskColor(apiData[0].fire_factor)}`}
                  text={`${getRiskColor(apiData[0].fire_factor)}`}
                  className="text-wrap text-start"
                >
                  <i className="bi-info-circle-fill me-1" />
                  {apiData[0].fire}
                </Badge>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.wind" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.wind',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>

                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].wind_factor}
                </Card.Title>
                <Badge
                  bg={`soft-${getRiskColor(apiData[0].wind_factor)}`}
                  text={`${getRiskColor(apiData[0].wind_factor)}`}
                  className="text-wrap text-start"
                >
                  <i className="bi-info-circle-fill me-1" />
                  {apiData[0].wind}
                </Badge>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.heat" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.heat',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>

                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].heat_factor}
                </Card.Title>
                <Badge
                  bg={`soft-${getRiskColor(apiData[0].heat_factor)}`}
                  text={`${getRiskColor(apiData[0].heat_factor)}`}
                  className="text-wrap text-start"
                >
                  <i className="bi-info-circle-fill me-1" />
                  {apiData[0].heat}
                </Badge>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.earthquakes" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.earthquakes',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>
                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].earthquakes}
                </Card.Title>
                <Badge
                  bg="soft-info"
                  text="info"
                  className="text-wrap text-start"
                >
                  <i className="bi-info-circle-fill me-1" />
                  In the last 20 years of magnitude greater then 5 on the
                  Richter Scale
                </Badge>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.wildfires" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.wildfires',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>

                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].wildfires}
                </Card.Title>
                <Badge
                  bg="soft-info"
                  text="info"
                  className="text-wrap text-start"
                />
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.tornadoes" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.tornadoes',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>

                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].tornadoes}
                </Card.Title>
                <Badge
                  bg="soft-info"
                  text="info"
                  className="text-wrap text-start"
                >
                  <i className="bi-info-circle-fill me-1" />
                  In the last 20 yrs, ≤2 (EF-Scale)
                </Badge>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.storms" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.storms',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>

                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].storms}
                </Card.Title>
                <Badge
                  bg="soft-info"
                  text="info"
                  className="text-wrap text-start"
                />
              </Card.Body>
            </Card>
          </Col>
          <Col lg="12" className="mb-3 mb-lg-5">
            <TabLast5Listings zip={apiData[0].zip} />
          </Col>
          <Col lg="12" className="mb-3 mb-lg-5">
            <TableRealEstateUSANumbers zip={apiData[0].zip} />
          </Col>
          <Col lg="12" className="mb-3 mb-lg-5">
            <TableNearbyNumbers zip={apiData[0].zip} />
          </Col>
        </Row>
      )}
    </>
  );
}

CardCity.propTypes = {
  zip: PropTypes.string.isRequired,
};

export default CardCity;
