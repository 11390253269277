import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Col, Row, Badge, Image, Card, ListGroup } from 'react-bootstrap';
import { RequestLoading, RequestResult } from '../../components';
import { useAxiosQuery } from '../../hooks';
import PlaceHolderImg from '../../assets/images/placeholder.png';
import { Routes as AppRoutes } from '../../router/routeMapping';

const onImageError = (e) => {
  e.target.src = PlaceHolderImg;
};
function Search() {
  const [searchParams] = useSearchParams();

  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/dashboard/search',
    preventFetch: !searchParams?.get('search'),
    params: {
      text: searchParams?.get('search'),
    },
  });

  const formatItem = (type, data) => {
    let result = '';
    switch (type) {
      case 'properties':
        result = (
          <div className="d-flex flex-column">
            <span className="text-primary">{data.full_address_new}</span>
            <span className="small">
              {data.propertyUse || (
                <FormattedMessage id="app.common.noPropertyUse" />
              )}
            </span>
            <div className="d-flex">
              {data.bedrooms !== undefined &&
                data.bedrooms !== null &&
                data.bedrooms !== 0 && (
                  <span className="me-1">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#icon_bed)">
                        <path
                          d="M1.25 19.5416V16.3666"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M18.75 13.1833H1.25V16.3667H18.75V13.1833Z"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M3.63333 9.16669H16.3667C16.9988 9.16669 17.605 9.41779 18.0519 9.86475C18.4989 10.3117 18.75 10.9179 18.75 11.55V13.1417H1.25V11.5917C1.24446 11.2752 1.30202 10.9608 1.41932 10.6668C1.53661 10.3728 1.71129 10.1052 1.93316 9.87942C2.15502 9.65367 2.41963 9.47438 2.71154 9.35201C3.00345 9.22964 3.31681 9.16664 3.63333 9.16669V9.16669Z"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M18.75 19.5416V16.3666"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M5.22503 6.02502H8.40836C8.61738 6.02502 8.82436 6.06619 9.01747 6.14618C9.21058 6.22617 9.38604 6.34341 9.53384 6.49121C9.68164 6.63901 9.79888 6.81448 9.87887 7.00759C9.95886 7.2007 10 7.40767 10 7.61669V9.16669H3.63336V7.61669C3.63336 7.19456 3.80105 6.78971 4.09955 6.49121C4.39805 6.19272 4.80289 6.02502 5.22503 6.02502Z"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M11.5917 6.02502H14.775C15.1971 6.02502 15.602 6.19272 15.9005 6.49121C16.199 6.78971 16.3667 7.19456 16.3667 7.61669V9.16669H10V7.61669C10 7.19456 10.1677 6.78971 10.4662 6.49121C10.7647 6.19272 11.1695 6.02502 11.5917 6.02502Z"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M16.3584 2.84167H3.63336V9.20834H16.3584V2.84167Z"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M3.63336 0.458313V2.84165"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M16.3666 0.458313V2.84165"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="icon_bed">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>{' '}
                    {data.bedrooms}
                  </span>
                )}
              {data.bathrooms !== undefined &&
                data.bathrooms !== null &&
                data.bathrooms !== 0 && (
                  <span className="me-1">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#icon_bath)">
                        <path
                          d="M2.01666 11.6H17.9833V14C17.9833 15.2708 17.4785 16.4896 16.5799 17.3882C15.6813 18.2868 14.4625 18.7916 13.1917 18.7916H6.80833C5.5375 18.7916 4.31872 18.2868 3.42011 17.3882C2.5215 16.4896 2.01666 15.2708 2.01666 14V11.6Z"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M13.9917 11.6H10V15.5916H13.9917V11.6Z"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M19.5834 11.6H0.416687"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M2.01666 11.7667V3.60834C2.01666 2.97403 2.26864 2.3657 2.71717 1.91718C3.16569 1.46865 3.77402 1.21667 4.40833 1.21667H5.24166C5.87018 1.22544 6.46998 1.48128 6.91132 1.92885C7.35266 2.37643 7.60006 2.97977 7.6 3.60834V4.44167"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.20003 4.40833H6.00836"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="icon_bath">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>{' '}
                    {data.bathrooms}
                  </span>
                )}

              {data.livingSquareFeet !== undefined &&
                data.livingSquareFeet !== null &&
                data.livingSquareFeet !== 0 && (
                  <span className="me-1">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#icon_sqft)">
                        <path
                          d="M1.25 3.63333L3.63333 1.25L6.025 3.63333"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M16.3667 13.975L18.75 16.3666L16.3667 18.75"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M18.75 16.3667H3.63333V1.25"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M6.81668 1.25H18.75V13.1833"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M16.3667 1.25L3.63333 13.975"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M18.75 3.63336L6.02499 16.3667"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M11.5917 1.25L3.63333 9.20833"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M18.75 8.40833L10.7917 16.3667"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="icon_sqft">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>{' '}
                    <FormattedNumber
                      value={data.livingSquareFeet}
                      // eslint-disable-next-line react/style-prop-object
                    />{' '}
                    sqft
                  </span>
                )}
            </div>
            <Link
              className="stretched-link"
              to={`${AppRoutes.protected.FARMING_DETAIL.path.replace(
                ':id',
                data.id
              )}`}
            />
          </div>
        );
        break;
      case 'bestplaces':
        result = (
          <div className="d-flex flex-column">
            <span className="text-primary">
              {data.citylabel}
              {data.hotness_score > 70 && (
                <Badge className="ms-1" bg="soft-danger" text="danger">
                  <i className="bi-fire me-1" />
                  {Number(data.hotness_score).toFixed(0)}
                </Badge>
              )}
              {data.hotness_score > 0 && (
                <Badge className="ms-1" bg="soft-info" text="info">
                  <i className="bi-graph-up me-1" />
                  {Number(data.demand_score).toFixed(0)}
                </Badge>
              )}
            </span>
            <span className="small">
              {data.zip || <FormattedMessage id="app.common.noZip" />}
            </span>
            <span className="small">
              {data.state_code || (
                <FormattedMessage id="app.common.state_code" />
              )}
            </span>
            <Link
              className="stretched-link"
              to={`${AppRoutes.protected.CITY_DETAIL.path.replace(
                ':zip',
                data.zip
              )}`}
            />
          </div>
        );
        break;
      case 'homes':
        result = (
          <div className="d-flex">
            <div className="position-relative" style={{ width: 100 }}>
              <Image
                src={data.ImageURL || PlaceHolderImg}
                onError={onImageError}
                style={{ height: 60, width: 90, objectFit: 'cover' }}
              />
            </div>
            <div className="d-flex flex-column">
              <span className="text-primary">{data.addressNew}</span>
              <div>
                <span className="text-secondary">
                  <FormattedNumber
                    value={data.price}
                    // eslint-disable-next-line react/style-prop-object
                    style="currency"
                    currency="USD"
                    minimumFractionDigits={0}
                    maximumFractionDigits={0}
                  />
                </span>{' '}
                <span className="small">
                  {data.propertyType || (
                    <FormattedMessage id="app.common.noPropertyType" />
                  )}
                  {' - '}
                  {data.BuildingType || (
                    <FormattedMessage id="app.common.noBuildingType" />
                  )}
                </span>
                <div className="d-flex">
                  {data.bedrooms && data.bedrooms !== 0 && (
                    <span className="me-1">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#icon_bed)">
                          <path
                            d="M1.25 19.5416V16.3666"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M18.75 13.1833H1.25V16.3667H18.75V13.1833Z"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M3.63333 9.16669H16.3667C16.9988 9.16669 17.605 9.41779 18.0519 9.86475C18.4989 10.3117 18.75 10.9179 18.75 11.55V13.1417H1.25V11.5917C1.24446 11.2752 1.30202 10.9608 1.41932 10.6668C1.53661 10.3728 1.71129 10.1052 1.93316 9.87942C2.15502 9.65367 2.41963 9.47438 2.71154 9.35201C3.00345 9.22964 3.31681 9.16664 3.63333 9.16669V9.16669Z"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M18.75 19.5416V16.3666"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M5.22503 6.02502H8.40836C8.61738 6.02502 8.82436 6.06619 9.01747 6.14618C9.21058 6.22617 9.38604 6.34341 9.53384 6.49121C9.68164 6.63901 9.79888 6.81448 9.87887 7.00759C9.95886 7.2007 10 7.40767 10 7.61669V9.16669H3.63336V7.61669C3.63336 7.19456 3.80105 6.78971 4.09955 6.49121C4.39805 6.19272 4.80289 6.02502 5.22503 6.02502Z"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M11.5917 6.02502H14.775C15.1971 6.02502 15.602 6.19272 15.9005 6.49121C16.199 6.78971 16.3667 7.19456 16.3667 7.61669V9.16669H10V7.61669C10 7.19456 10.1677 6.78971 10.4662 6.49121C10.7647 6.19272 11.1695 6.02502 11.5917 6.02502Z"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M16.3584 2.84167H3.63336V9.20834H16.3584V2.84167Z"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M3.63336 0.458313V2.84165"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M16.3666 0.458313V2.84165"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="icon_bed">
                            <rect width="20" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>{' '}
                      {data.bedrooms}
                    </span>
                  )}
                  {data.bathrooms && data.bathrooms !== 0 && (
                    <span className="me-1">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#icon_bath)">
                          <path
                            d="M2.01666 11.6H17.9833V14C17.9833 15.2708 17.4785 16.4896 16.5799 17.3882C15.6813 18.2868 14.4625 18.7916 13.1917 18.7916H6.80833C5.5375 18.7916 4.31872 18.2868 3.42011 17.3882C2.5215 16.4896 2.01666 15.2708 2.01666 14V11.6Z"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M13.9917 11.6H10V15.5916H13.9917V11.6Z"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M19.5834 11.6H0.416687"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M2.01666 11.7667V3.60834C2.01666 2.97403 2.26864 2.3657 2.71717 1.91718C3.16569 1.46865 3.77402 1.21667 4.40833 1.21667H5.24166C5.87018 1.22544 6.46998 1.48128 6.91132 1.92885C7.35266 2.37643 7.60006 2.97977 7.6 3.60834V4.44167"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M9.20003 4.40833H6.00836"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="icon_bath">
                            <rect width="20" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>{' '}
                      {data.bathrooms}
                    </span>
                  )}

                  {data.totalSqFt && data.totalSqFt !== 0 && (
                    <span className="me-1">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#icon_sqft)">
                          <path
                            d="M1.25 3.63333L3.63333 1.25L6.025 3.63333"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M16.3667 13.975L18.75 16.3666L16.3667 18.75"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M18.75 16.3667H3.63333V1.25"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M6.81668 1.25H18.75V13.1833"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M16.3667 1.25L3.63333 13.975"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M18.75 3.63336L6.02499 16.3667"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M11.5917 1.25L3.63333 9.20833"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M18.75 8.40833L10.7917 16.3667"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="icon_sqft">
                            <rect width="20" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>{' '}
                      <FormattedNumber
                        value={data.totalSqFt}
                        // eslint-disable-next-line react/style-prop-object
                      />{' '}
                      sqft
                    </span>
                  )}
                </div>
              </div>
            </div>
            <Link
              className="stretched-link"
              to={`${AppRoutes.protected.LISTINGS_DETAIL.path.replace(
                ':id',
                data.id
              )}`}
            />
          </div>
        );
        break;
      case 'realtor':
        result = (
          <div className="d-flex">
            <div className="position-relative" style={{ width: 80 }}>
              <Image
                src={`https://${data.photo_url}` || PlaceHolderImg}
                onError={onImageError}
                style={{ height: 50, width: 50, objectFit: 'cover' }}
                className="rounded-circle"
              />
            </div>
            <div className="d-flex flex-column">
              <span className="text-primary">{data.title}</span>
              <span className="text-info">{data.licence_number}</span>
              <span className="small">
                {data.zip} {data.state}
              </span>
            </div>
            <Link
              className="stretched-link"
              to={`${AppRoutes.protected.REALTOR_DETAIL.path.replace(
                ':id',
                data.id
              )}`}
            />
          </div>
        );
        break;

      case 'lawyers':
        result = (
          <div className="d-flex">
            <div className="position-relative" style={{ width: 80 }}>
              <Image
                src={
                  `https://s.bidlaw.io/files/${data.Photo}` || PlaceHolderImg
                }
                onError={onImageError}
                style={{ height: 50, width: 50, objectFit: 'cover' }}
                className="rounded-circle"
              />
            </div>
            <div className="d-flex flex-column">
              <span className="text-primary">{data.Title}</span>
              <span className="text-info">{data.licence_number}</span>
              <span className="small">
                {data.City} {data.Zip} {data.State}
              </span>
            </div>
            <Link
              className="stretched-link"
              to={`${AppRoutes.protected.LAWYER_DETAIL.path.replace(
                ':id',
                data.UserID
              )}`}
            />
          </div>
        );
        break;
      case 'owner':
        result = (
          <div className="d-flex">
            <div className="position-relative" style={{ width: 80 }}>
              <Image
                src={
                  `https://s.bidlaw.io/files/${data.Photo}` || PlaceHolderImg
                }
                onError={onImageError}
                style={{ height: 50, width: 50, objectFit: 'cover' }}
                className="rounded-circle"
              />
            </div>
            <div className="d-flex flex-column">
              <span className="text-primary">{data.name}</span>
              <span className="text-info">{data.address}</span>
              <span className="small">
                {data.city} {data.zip} {data.state}
              </span>
            </div>
            <Link
              className="stretched-link"
              to={`${AppRoutes.protected.OWNER_DETAIL.path.replace(
                ':id',
                data.owner_id
              )}`}
            />
          </div>
        );
        break;
      case 'poi':
        result = (
          <div className="d-flex">
            <div className="d-flex flex-column">
              <span className="text-primary">{data.title}</span>
              <span className="text-info">{data.address}</span>

              <span className="small">{data.category}</span>
            </div>
            <Link
              className="stretched-link"
              to={`${AppRoutes.protected.LISTINGS.children.POI.path}?Id=${data.id}`}
            />
          </div>
        );
        break;
      default:
        result = '';
        break;
    }

    return result;
  };

  return (
    <div className="content container">
      <RequestLoading loading={apiLoading} size="lg" margin="5" />
      <RequestResult type="error" message={apiError} image />

      <h1 className="page-header-title mb-4">
        <FormattedMessage id="app.common.searchResults" />
        <span className="ms-1">{`"${searchParams?.get('search')}"`}</span>
      </h1>

      {!apiLoading &&
        !apiError &&
        apiData &&
        Object.keys(apiData).length === 0 && (
          <RequestResult type="secondary" message="app.common.noData" />
        )}
      {!apiLoading && !apiError && apiData && Object.keys(apiData).length > 0 && (
        <Row>
          {Object.keys(apiData).map((key) => {
            const results = apiData[key];
            return (
              <Col xs="12" key={key} className="mb-3 mb-lg-5">
                <Card>
                  <Card.Header>
                    <Card.Title bsPrefix="card-header-title" as="h4">
                      <FormattedMessage id={`app.common.search${key}`} />
                    </Card.Title>
                  </Card.Header>
                  <Card.Body className="p-0">
                    {results.length === 0 ? (
                      <RequestResult
                        type="soft-gray"
                        message="app.common.noResultsFound"
                      />
                    ) : (
                      <ListGroup variant="flush">
                        {results.map((item) => (
                          <ListGroup.Item
                            key={item.id}
                            className="position-relative"
                          >
                            {formatItem(key, item)}
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      )}
    </div>
  );
}

export default Search;
