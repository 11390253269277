import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import SmartImage from './SmartImage';
import Illustration from '../assets/images/svg/illustrations/oc-unlock.svg';
import IllustrationLight from '../assets/images/svg/illustrations-light/oc-unlock.svg';

function RequiredRoleInfo() {
  return (
    <div className="py-10">
      <Row className="justify-content-sm-center text-center">
        <Col sm="6" md="4">
          <SmartImage
            className="img-fluid mb-5"
            src={Illustration}
            darkSrc={IllustrationLight}
          />
        </Col>
      </Row>
      <Row className="justify-content-sm-center text-center">
        <Col sm="8" md="6">
          <h1>
            <FormattedMessage id="warnings.requiredRoleInfo.title" />
          </h1>
          <p>
            <FormattedMessage id="warnings.requiredRoleInfo.message" />
          </p>
        </Col>
      </Row>
    </div>
  );
}

export default RequiredRoleInfo;
