import React from 'react';

import PropTypes from 'prop-types';
import { Row, Col, Card } from 'react-bootstrap';
import { useIntl, FormattedMessage, FormattedNumber } from 'react-intl';
import { useAxiosQuery } from '../../../../hooks';
import { RequestLoading, RequestResult, Tooltip } from '../../../../components';

function TableNearbyNumbers({ zip }) {
  const { formatMessage } = useIntl();
  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/dashboard/NumbersByZip',
    preventFetch: !zip,
    params: { zip, radius: 10 },
  });

  return (
    <>
      <RequestLoading loading={apiLoading} size="lg" margin="5" />
      <RequestResult type="error" message={apiError} />

      {!apiLoading && !apiError && apiData && Object.keys(apiData).length > 0 && (
        <Row>
          <Col xs="12">
            <h3 className="mb-3">
              <FormattedMessage id="app.common.nearby" />
            </h3>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.busStops" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.busStops',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>
                <Card.Title as="h2" className="text-inherit">
                  <FormattedNumber
                    value={apiData.gtfs_stops}
                    // eslint-disable-next-line react/style-prop-object
                    minimumFractionDigits={0}
                    maximumFractionDigits={0}
                  />
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.schools" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.schools',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>
                <Card.Title as="h2" className="text-inherit">
                  <FormattedNumber
                    value={apiData.schools}
                    // eslint-disable-next-line react/style-prop-object
                    minimumFractionDigits={0}
                    maximumFractionDigits={0}
                  />
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.poi" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.poi',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>
                <Card.Title as="h2" className="text-inherit">
                  <FormattedNumber
                    value={apiData.poi}
                    // eslint-disable-next-line react/style-prop-object
                    minimumFractionDigits={0}
                    maximumFractionDigits={0}
                  />
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.businesses" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.businesses',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>
                <Card.Title as="h2" className="text-inherit">
                  <FormattedNumber
                    value={apiData.businesses}
                    // eslint-disable-next-line react/style-prop-object
                    minimumFractionDigits={0}
                    maximumFractionDigits={0}
                  />
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>{' '}
        </Row>
      )}
    </>
  );
}

TableNearbyNumbers.propTypes = {
  zip: PropTypes.string.isRequired,
};

export default TableNearbyNumbers;
