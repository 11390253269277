import * as yup from 'yup';

import { isString } from 'lodash';
import Constants from '../constants';

yup.setLocale(Constants.YupLocale);

const rUrl =
  // eslint-disable-next-line
  /^(((https?|ftp):)?\/\/)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

yup.addMethod(yup.string, 'customUrl', () =>
  yup.string().test('customUrl', null, (value, p) => {
    const { path, createError } = p;

    if (!value || value.match(rUrl)) {
      return true;
    }

    return createError({
      path,
      message: { key: 'app.yup.string.url', values: { path } },
    });
  })
);

const isValidFile = (value) => {
  if (value instanceof FileList && Object.keys(value).length > 0) {
    return true;
  }

  if (value instanceof File) {
    return true;
  }

  if (Array.isArray(value) && value.every((v) => v instanceof File)) {
    return true;
  }

  return false;
};

yup.addMethod(yup.mixed, 'requiredFileOrUrl', () =>
  yup.mixed().test('requiredFile', null, (value, p) => {
    const { path, createError } = p;

    if ((isString(value) && value.match(rUrl)) || isValidFile(value)) {
      return true;
    }

    return createError({
      path,
      message: { key: 'app.yup.mixed.required', values: { path } },
    });
  })
);

yup.addMethod(yup.mixed, 'requiredFile', () =>
  yup.mixed().test('requiredFile', null, (value, p) => {
    const { path, createError } = p;

    if (isValidFile(value)) {
      return true;
    }

    return createError({
      path,
      message: { key: 'app.yup.mixed.required', values: { path } },
    });
  })
);

export { yup };
