import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, Image } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { useParams } from 'react-router-dom';
import {
  RequestLoading,
  RequestResult,
  ShowMoreText,
  GoogleMap,
} from '../../../components';
import { useAxiosQuery } from '../../../hooks';
import DefaultProfileImage from '../../../assets/images/default-profile-img.jpg';
import PlaceHolderImg from '../../../assets/images/placeholder.png';

const imageRootPath = 'https://bidlaw.b-cdn.net';
const onImageError = (e) => {
  e.target.src = PlaceHolderImg;
};

const formatAdmissions = (admissions) => {
  // admissions dizisini kontrol edin
  if (!admissions || admissions.length === 0) {
    return 'N/A';
  }
  // Dizideki AdmissonsLabel değerlerini birleştirin
  return admissions.map((admission) => admission.AdmissonsLabel).join(', ');
};

const formatExpertises = (expertises) => {
  if (!expertises || expertises.length === 0) {
    return 'N/A';
  }
  return expertises.map((expertise) => expertise.ExpertiseIDNew).join(', ');
};

export function InfoDataView({ id }) {
  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/lawyers/select',
    preventFetch: !id,
    params: {
      id,
    },
  });

  // eslint-disable-next-line no-unused-vars
  const renderValue = (key, value, rowData) => {
    // console.log(rowData);
    let nValue = (
      <Col xs="auto">
        <h5>{value}</h5>
      </Col>
    );

    switch (key) {
      case 'xxx':
        nValue = <Col xs="auto" />;
        break;

      default:
        break;
    }

    return nValue;
  };

  const imagePath = apiData.user?.Photo
    ? `${imageRootPath}/${apiData.user?.Photo}`
    : DefaultProfileImage;

  return (
    <>
      <RequestLoading loading={apiLoading} size="lg" margin="5" />
      <RequestResult type="error" message={apiError} />

      {!apiLoading && !apiError && (
        <Row>
          <Col lg="7" className="mb-3 mb-lg-5">
            <Card className="h-100">
              <Card.Header className="card-header-content-between">
                <Card.Title
                  bsPrefix="card-header-title"
                  as="h4"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.info" />
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col md className="mb-4 mb-md-0">
                    <div className="mb-4">
                      <Card.Subtitle>
                        <FormattedMessage id="app.common.address" />
                      </Card.Subtitle>
                      {apiData.user?.AddressOld
                        ? apiData.user?.AddressOld
                        : 'N/A'}
                    </div>
                    <div className="mb-4">
                      <Card.Subtitle>
                        <FormattedMessage id="app.common.phone" />
                      </Card.Subtitle>
                      {apiData.user?.Phone ? (
                        <a href={`tel:${apiData.user?.Phone}`}>
                          {apiData.user?.Phone}
                        </a>
                      ) : (
                        'N/A'
                      )}
                    </div>
                    <div className="mb-4">
                      <Card.Subtitle>
                        <FormattedMessage id="app.common.email" />
                      </Card.Subtitle>
                      {apiData.user?.Email ? (
                        <a href={`mailto:${apiData.user?.Email}`}>
                          {apiData.user?.Email}
                        </a>
                      ) : (
                        'N/A'
                      )}
                    </div>

                    <div className="mb-4">
                      <Card.Subtitle>
                        <FormattedMessage id="app.common.admissions" />
                      </Card.Subtitle>
                      {/* Admissons verilerini formatlayıp gösterin */}
                      <span>
                        {formatAdmissions(apiData.Rel_Users_Admissons)}
                      </span>
                    </div>
                    <div className="mb-4">
                      <Card.Subtitle>
                        <FormattedMessage id="app.common.expertises" />
                      </Card.Subtitle>
                      <ShowMoreText
                        text={formatExpertises(apiData.Rel_Users_Expertises)}
                        maxLine={2}
                        basedOn="words"
                      />
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col lg="5">
            <Row>
              <Col xs="12" className="mb-3 mb-lg-5">
                <Card className="h-100">
                  <Card.Header className="card-header-content-between">
                    <Card.Title
                      bsPrefix="card-header-title"
                      as="h4"
                      className="d-flex justify-content-between"
                    >
                      <FormattedMessage id="app.common.lawyer" />
                    </Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col md className="mb-4 mb-md-0">
                        <div className="d-flex">
                          <div className="avatar avatar-lg avatar-circle">
                            <Image
                              src={imagePath || DefaultProfileImage}
                              onError={onImageError}
                              // style={{ height: 50, width: 50, objectFit: 'cover' }}
                              className="avatar-img"
                            />

                            {apiData?.IsVerified === 1 && (
                              <span className="avatar-status avatar-lg-status avatar-status-primary">
                                <i className="bi-check-lg" />
                              </span>
                            )}
                          </div>
                          <div className="d-flex flex-column ms-2">
                            <h4>{apiData.user?.Title}</h4>
                            <span>
                              {apiData.user?.City} / {apiData.user?.State}
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs="12" className="mb-3 mb-lg-5">
                <Card className="h-100">
                  <Card.Header className="card-header-content-between">
                    <Card.Title
                      bsPrefix="card-header-title"
                      as="h4"
                      className="d-flex justify-content-between"
                    >
                      <FormattedMessage id="app.common.location" />
                    </Card.Title>
                  </Card.Header>

                  <Card.Body>
                    <GoogleMap
                      initialZoom={12}
                      initialMarks={[
                        {
                          lat: Number(apiData.user?.Latitude),
                          lng: Number(apiData.user?.Longitude),
                        },
                      ]}
                      initialCenter={{
                        lat: Number(apiData.user?.Latitude),
                        lng: Number(apiData.user?.Longitude),
                      }}
                    />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>

          <Col lg="12" className="mb-3 mb-lg-5">
            <Card className="h-100">
              <Card.Header className="card-header-content-between">
                <Card.Title
                  bsPrefix="card-header-title"
                  as="h4"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.description" />
                </Card.Title>
              </Card.Header>

              <Card.Body>
                <ShowMoreText
                  text={apiData.user?.About}
                  maxLine={3}
                  basedOn="words"
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
}

InfoDataView.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

InfoDataView.defaultProps = {
  id: null,
};

function Info() {
  const { id } = useParams();

  return <InfoDataView id={id} />;
}

export default Info;
