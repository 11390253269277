const buildingTypesResidential = [
  {
    name: 'Condo',
    abbreviation: 'Condo',
  },
  {
    name: 'Land',
    abbreviation: 'Land',
  },
  {
    name: 'ResidentialIncome',
    abbreviation: 'ResidentialIncome',
  },
  {
    name: 'Single Family',
    abbreviation: 'Single Family',
  },
];

export default buildingTypesResidential;
