import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import { AuthContext } from '../context/AuthContext';
import {
  NoAccessInfo,
  PackageExpiredInfo,
  RequiredRoleInfo,
} from '../components';
import { Routes as AppRoutes } from './routeMapping';

const isDev = false;
const isDebug = false;

function PrivateRouteRequireAccess({ roles, children }) {
  const { user, role, pack } = useContext(AuthContext);
  const location = useLocation();
  // eslint-disable-next-line no-unused-vars
  const { id } = useParams();
  const params = useParams();

  let userHasRequiredRole = !!(user && roles.includes(role));

  let packageExpired = !pack.usage || moment(user.exp_date).isBefore(moment());

  let hasAccess = false;
  const [, , level1] = location.pathname.split('/');
  let [, , , level2] = location.pathname.split('/');

  if (
    params &&
    Object.keys(params).length > 0 &&
    level2 &&
    Object.values(params).includes(level2)
  ) {
    level2 = null;
  }

  const allowedPaths = [
    AppRoutes.protected.OVERVIEW.path,
    AppRoutes.protected.ACCOUNT.path,
    ...Object.values(AppRoutes.protected.ACCOUNT.children).map(
      ({ path }) => path
    ),
    AppRoutes.protected.USER.path,
    /* ...Object.values(AppRoutes.protected.USER.children).map(({ path }) => path),
    AppRoutes.protected.PROPERTY_FIRST_CREATE.path,
    AppRoutes.protected.PROPERTY_CREATE.path,
    AppRoutes.protected.PROPERTY_EDIT.path.replace(':id', id),
    ...Object.values(AppRoutes.protected.PROPERTY_EDIT.children).map(
      ({ path }) => path.replace(':id', id)
    ), 
    AppRoutes.protected.MESSAGES.path,
    AppRoutes.protected.MESSAGES_DETAIL.path.replace(':id', id),
    AppRoutes.protected.NOTIFICATIONS.path, */
  ];

  if (allowedPaths.indexOf(location.pathname) > -1) {
    hasAccess = true;
  } else {
    hasAccess = level2
      ? !!pack.usage?.[level1]?.[level2]
      : !!pack.usage?.[level1];
  }

  if (isDev) {
    if (isDebug) {
      console.log('roles:', roles);
      if (level2) {
        console.log(
          `access: ${level1} > ${level2} > ${pack.usage?.[level1]?.[level2]}`
        );
      } else {
        console.log(`access: ${level1} > ${pack.usage?.[level1]}`);
      }

      console.log('userHasRequiredRole:', userHasRequiredRole);
      console.log('packageExpired:', packageExpired);
      console.log('hasAccess:', hasAccess);
    }

    userHasRequiredRole = true;
    packageExpired = false;
    hasAccess = true;
  }

  if (
    packageExpired &&
    location.pathname !==
      AppRoutes.protected.ACCOUNT.children.SUBSCRIPTION_PLANS.path
  ) {
    return <PackageExpiredInfo />;
  }

  if (!hasAccess) {
    return <NoAccessInfo />;
  }

  if (!userHasRequiredRole) {
    return <RequiredRoleInfo />;
  }

  return children;
}

PrivateRouteRequireAccess.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.node.isRequired,
};

export default PrivateRouteRequireAccess;
