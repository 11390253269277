import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import {
  Row,
  Col,
  Card,
  Collapse,
  Button,
  Form,
  Badge,
  ButtonGroup,
  Dropdown,
} from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { debounce } from 'lodash';
import { Link, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { Routes as AppRoutes } from '../../../../router/routeMapping';
import { useAxiosQuery, useFormModal, useToast } from '../../../../hooks';
import { HighlightedText, SmartForm, SmartTable } from '../../../../components';
import { yup } from '../../../../lib';
import Constants from '../../../../constants';

const typeOptionLabel = ({ id }) => (
  <div className="d-flex">
    <Badge bg={`${Constants.Review.Types[id].color}`}>
      <i className={`${Constants.Review.Types[id].icon} fs-6 me-1`} />
      <FormattedMessage
        id={`app.common.${Constants.Review.Types[id].labelKey}`}
      />
    </Badge>
  </div>
);

const priorityOptionLabel = ({ id }) => (
  <div className="d-flex">
    <Badge
      bg={`soft-${Constants.Review.Priority[id].color} text-${Constants.Review.Priority[id].color}`}
    >
      <i className={`${Constants.Review.Priority[id].icon} fs-6 me-1`} />
      <FormattedMessage
        id={`app.common.${Constants.Review.Priority[id].labelKey}`}
      />
    </Badge>
  </div>
);
const eventTypesOptionLabel = ({ id }) => (
  <div className="d-flex">
    <Badge
      bg={`soft-${Constants.EventTypes.Types[id].color} text-${Constants.EventTypes.Types[id].color}`}
    >
      <i className={`${Constants.EventTypes.Types[id].icon} fs-6 me-1`} />
      <FormattedMessage
        id={`app.common.${Constants.EventTypes.Types[id].labelKey}`}
      />
    </Badge>
  </div>
);

function Owners() {
  const tableRef = useRef();
  const filterFormRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filtersIsVisible, setFiltersIsVisible] = useState(false);
  const [filters, setFilters] = useState({});
  const { formatMessage } = useIntl();

  const { form } = useFormModal();
  const { showToast } = useToast();

  const { data: categoriesData, isLoading: categoriesDataIsLoading } =
    useAxiosQuery({
      url: '/realestate/list_categories',
      params: { quantity: 999999 },
    });

  const onActionRow = useCallback(
    async (id, type) => {
      const size = 'lg';
      const title = type;
      const confirmLabel = type;
      let requestUrl = '/reviews/insert';
      const requestParams = { RefID: id, RefTable: 'realestateusa.owner' };
      let fetchOnStart;
      let nFields;
      let message;

      switch (type) {
        case 'addEvent':
          requestUrl = '/calendar/insert';
          nFields = [
            {
              cols: [
                {
                  key: 'title',
                  apiKey: 'Title',
                  schema: yup.string().required(),
                },
              ],
            },
            {
              cols: [
                {
                  key: 'date',
                  apiKey: 'Date',
                  type: 'dateRangePicker',
                  options: {
                    controller: {
                      initialSettings: { timePicker: true },
                      formatter: (value) => {
                        if (value) {
                          return [
                            moment(value[0]).format(
                              Constants.DateFormats.APIWithTime
                            ),
                            moment(value[1]).format(
                              Constants.DateFormats.APIWithTime
                            ),
                          ];
                        }
                        return value;
                      },
                    },
                  },
                  schema: yup.array().min(2).nullable().required(),
                },
              ],
            },
            {
              cols: [
                {
                  key: 'description',
                  apiKey: 'Description',
                  type: 'textarea',
                  schema: yup.string().nullable(),
                },
              ],
            },
            {
              cols: [
                {
                  key: 'EventType',
                  apiKey: 'EventType',
                  type: 'react-select',
                  options: {
                    controller: {
                      props: {
                        options: Object.values(Constants.EventTypes.Types),
                        getOptionValue: (option) => option.apiId,
                        formatOptionLabel: eventTypesOptionLabel,
                        isMulti: false,
                        isSearchable: false,
                        isClearable: true,
                      },
                    },
                  },
                  schema: yup.string().nullable(),
                },
              ],
            },
          ];
          break;
        case 'addNote':
          requestUrl = '/reviews/insert';
          nFields = [
            {
              cols: [
                {
                  key: 'comment',
                  apiKey: 'Comment',
                  type: 'textarea',
                  schema: yup.string().required(),
                },
              ],
            },
            {
              cols: [
                {
                  key: 'type',
                  apiKey: 'Rating',
                  type: 'react-select',
                  options: {
                    controller: {
                      props: {
                        options: Object.values(Constants.Review.Types),
                        getOptionValue: (option) => option.apiId,
                        formatOptionLabel: typeOptionLabel,
                        isMulti: false,
                        isSearchable: false,
                        isClearable: true,
                      },
                    },
                  },
                  schema: yup.string().nullable(),
                },
              ],
            },
            {
              cols: [
                {
                  key: 'priority',
                  apiKey: 'Priority',
                  type: 'react-select',
                  options: {
                    controller: {
                      props: {
                        options: Object.values(Constants.Review.Priority),
                        getOptionValue: (option) => option.apiId,
                        formatOptionLabel: priorityOptionLabel,
                        isMulti: false,
                        isSearchable: false,
                        isClearable: true,
                      },
                    },
                  },
                  schema: yup.string().nullable(),
                },
              ],
            },
          ];
          break;

        case 'addToFavorites':
          requestUrl = '/favorites/insert';
          message = 'loading...';
          fetchOnStart = true;
          requestParams.is_favorite = 1;
          break;

        case 'removeFromFavorites':
          requestUrl = '/favorites/delete';
          message = 'loading...';
          fetchOnStart = true;
          requestParams.is_favorite = 0;
          break;

        default:
          break;
      }

      const isSuccess = await form({
        size,
        title,
        confirmLabel,
        requestUrl,
        requestParams,
        fields: nFields,
        message,
        fetchOnStart,
      });

      if (isSuccess) {
        tableRef.current.reload();

        if (type === 'addToFavorites' || type === 'removeFromFavorites') {
          showToast({
            type: 'success',
            autohide: true,
            title: 'x1',
            message: 'y2',
          });
        }
      }
    },
    [form, showToast]
  );

  const columns = [
    {
      Header: <FormattedMessage id="app.common.name" />,
      accessor: 'name',
      wrap: true,
      minWidth: '30%',
      Cell: useCallback(
        ({
          cell: {
            value,
            row: { original },
          },
        }) => (
          <>
            {original.isFavorite === '1' && (
              <i className="bi-heart-fill text-danger me-1" />
            )}
            {original.revCount > 0 && (
              <Badge bg="danger" pill className="me-1 mb-1 fs-6">
                {original.revCount}
              </Badge>
            )}
            {value}
          </>
        ),
        []
      ),
    },

    {
      Header: <FormattedMessage id="app.common.type" />,
      accessor: 'type',
      wrap: true,
      Cell: useCallback(
        ({ cell: { value } }) => (
          <HighlightedText text={value} searchText={filters?.search || null} />
        ),
        [filters?.search]
      ),
    },
    {
      Header: <FormattedMessage id="app.common.city" />,
      accessor: 'city',
      wrap: true,
      Cell: useCallback(
        ({ cell: { value } }) => (
          <HighlightedText text={value} searchText={filters?.search || null} />
        ),
        [filters?.search]
      ),
    },
    {
      Header: <FormattedMessage id="app.common.zip" />,
      accessor: 'zip',
      wrap: true,
      Cell: useCallback(
        ({ cell: { value } }) => (
          <HighlightedText text={value} searchText={filters?.search || null} />
        ),
        [filters?.search]
      ),
    },
    {
      Header: <FormattedMessage id="app.common.state" />,
      accessor: 'state',
      wrap: true,
      Cell: useCallback(
        ({ cell: { value } }) => (
          <HighlightedText text={value} searchText={filters?.search || null} />
        ),
        [filters?.search]
      ),
    },

    {
      accessor: 'owner_id',
      Cell: useCallback(
        ({
          cell: {
            value,
            row: { original },
          },
        }) => (
          <Dropdown align="end">
            <ButtonGroup>
              <Button
                variant="white"
                size="sm"
                as={Link}
                to={`${AppRoutes.protected.OWNER_DETAIL.path.replace(
                  ':id',
                  value
                )}`}
              >
                <i className="bi-eye me-1" />
                <FormattedMessage id="app.common.view" />
              </Button>
              <ButtonGroup>
                <Dropdown.Toggle
                  variant="white"
                  size="sm"
                  className="btn-icon dropdown-toggle-empty"
                />
              </ButtonGroup>
            </ButtonGroup>
            <Dropdown.Menu className="m-0" renderOnMount>
              <Dropdown.Header>
                <FormattedMessage id="app.common.actions" />
              </Dropdown.Header>
              <Dropdown.Item
                as={Link}
                to={`${AppRoutes.protected.LISTINGS.children.ZIP.path}?Id=${original.zip}`}
              >
                <i className="bi-search me-1 dropdown-item-icon" />
                <span>
                  <FormattedMessage id="app.common.listingsNearby" />
                </span>
              </Dropdown.Item>
              {original.isFavorite === 0 ? (
                <Dropdown.Item
                  onClick={() => {
                    onActionRow(value, 'addToFavorites');
                  }}
                >
                  <i className="bi-heart-fill dropdown-item-icon" />
                  <span>
                    <FormattedMessage id="app.common.addToFavorites" />
                  </span>
                </Dropdown.Item>
              ) : (
                <Dropdown.Item
                  onClick={() => {
                    onActionRow(value, 'removeFromFavorites');
                  }}
                >
                  <i className="bi-heart dropdown-item-icon" />
                  <span>
                    <FormattedMessage id="app.common.removeFromFavorites" />
                  </span>
                </Dropdown.Item>
              )}
              <Dropdown.Item
                onClick={() => {
                  onActionRow(value, 'addEvent');
                }}
              >
                <i className="bi-calendar-plus dropdown-item-icon text-primary" />
                <span className="text-primary">
                  <FormattedMessage id="app.common.newEvent" />
                </span>
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  onActionRow(value, 'addNote');
                }}
              >
                <i className="bi-chat-right-text-fill dropdown-item-icon text-info" />
                <span className="text-info">
                  <FormattedMessage id="app.common.newNote" />
                </span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ),
        [onActionRow]
      ),
    },
  ];

  const filterFields = useMemo(
    () => [
      {
        cols: [
          {
            key: 'Name',
            apiKey: 'name',
          },
        ],
      },
    ],
    [categoriesData, categoriesDataIsLoading]
  );

  const parseSearchParams = useCallback(() => {
    const params = {};
    searchParams.forEach((value, key) => {
      let isArray = false;
      filterFields.forEach((row) => {
        row.cols.forEach((col) => {
          if (
            col.key === key &&
            (col.options?.controller?.props?.isMulti || col.key === 'dateRange')
          ) {
            isArray = true;
          }
        });
      });

      if (!params[key]) {
        params[key] = !isArray ? value : [value];
      } else {
        params[key] = [
          ...(Array.isArray(params[key]) ? params[key] : [params[key]]),
          value,
        ];
      }
    });
    return params;
  }, [searchParams, filterFields]);

  const handleSearch = (text) => {
    const params = parseSearchParams(true);
    if (text) {
      params.search = text;
    } else {
      delete params.search;
    }
    setSearchParams(params);
  };

  const onSearch = debounce((text) => {
    handleSearch(text);
  }, 100);

  useEffect(() => {
    const params = parseSearchParams(true);
    const nParams = params.search ? { search: params.search } : {};
    filterFields.forEach((row) => {
      row.cols.forEach((col) => {
        if (params[col.key]) {
          nParams[col.apiKey || col.key] = params[col.key];
        }
      });
    });
    setFilters(nParams);
  }, [parseSearchParams, filterFields]);

  return (
    <Row>
      <Col>
        <Card>
          <Card.Header className="card-header-content-md-between">
            <div className="mb-2 mb-md-0">
              <div className="input-group input-group-merge input-group-borderless">
                <div className="input-group-prepend input-group-text">
                  <i className="bi-search" />
                </div>
                <Form.Control
                  type="search"
                  placeholder={formatMessage({
                    id: 'app.common.search',
                  })}
                  defaultValue={parseSearchParams().search || ''}
                  onChange={(e) => {
                    if (e.target.value) {
                      onSearch(e.target.value);
                    } else {
                      handleSearch(e.target.value);
                    }
                  }}
                />
              </div>
            </div>

            <div className="d-grid d-sm-flex align-items-sm-center gap-2">
              <div id="datatableCounterInfo" style={{ display: 'none' }}>
                <div className="d-flex align-items-center">
                  <span className="fs-5 me-3">
                    <span id="datatableCounter">0</span> Selected
                  </span>

                  <a className="btn btn-outline-danger btn-sm" href="#!">
                    <i className="bi-trash" /> Delete
                  </a>
                </div>
              </div>

              <Button
                variant="white"
                size="sm"
                className="dropdown-toggle"
                onClick={() => {
                  setFiltersIsVisible(!filtersIsVisible);
                }}
              >
                <i className="bi-filter me-1" />
                <FormattedMessage id="app.common.filters" />
                {Object.keys(filters).filter((key) => key !== 'search').length >
                  0 && (
                  <Badge
                    bg="soft-dark"
                    className="text-dark rounded-circle ms-1"
                  >
                    {
                      Object.keys(filters).filter((key) => key !== 'search')
                        .length
                    }
                  </Badge>
                )}
              </Button>
            </div>
          </Card.Header>

          <Collapse in={filtersIsVisible}>
            <div id="filter-collapse">
              <Card.Body>
                <SmartForm
                  ref={filterFormRef}
                  fields={filterFields}
                  submitButtonText="applyFilters"
                  clearButtonText="clearFilters"
                  isFilterForm
                  disableApiKey
                  onSubmit={(formData) => {
                    const params = {};
                    if (formData) {
                      Object.keys(formData).forEach((key) => {
                        if (formData[key]) {
                          params[key] = formData[key];
                        }
                      });
                    }

                    if (parseSearchParams().search) {
                      params.search = parseSearchParams().search;
                    }

                    setSearchParams(params);
                  }}
                  defaultValues={parseSearchParams()}
                />
              </Card.Body>
            </div>
          </Collapse>

          <SmartTable
            ref={tableRef}
            columns={columns}
            requestUrl="/property_owners/list"
            requestParams={{ UserType: 3, Status: 1 }}
            filters={filters}
            hoverable
          />
        </Card>
      </Col>
    </Row>
  );
}

export default Owners;
