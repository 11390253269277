import React from 'react';
import { Outlet, Navigate, useLocation, useParams } from 'react-router-dom';
import { PageHeader, RequestLoading, RequestResult } from '../../../components';
import { Routes as AppRoutes } from '../../../router/routeMapping';
import { useAxiosQuery } from '../../../hooks';

function OwnerDetail() {
  const location = useLocation();
  const { id } = useParams();

  const menuData = Object.values(AppRoutes.protected.OWNER_DETAIL.children).map(
    ({ title, path }) => ({ title, href: path.replace(':id', id) })
  );

  const activePage = menuData.find((page) => page.href === location.pathname);

  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/property_owners/select',
    preventFetch: !id,
    params: {
      owner_id: id,
    },
  });

  return (
    <div className="content container">
      <RequestLoading loading={apiLoading} size="lg" margin="5" />
      <RequestResult type="error" message={apiError} />

      {!apiLoading && !apiError && apiData && (
        <PageHeader
          title={activePage?.title}
          menuData={menuData}
          breadcrumbData={{
            current: activePage?.title,
            parents: [
              {
                name: AppRoutes.protected.BUSINESSES.title,
                href: AppRoutes.protected.BUSINESSES.path,
              },
              {
                name: apiData?.name,
                href: AppRoutes.protected.BUSINESSES.path.replace(':id', id),
              },
            ],
          }}
        />
      )}

      {location.pathname ===
      AppRoutes.protected.OWNER_DETAIL.path.replace(':id', id) ? (
        <Navigate to={menuData[0].href} replace />
      ) : (
        !apiLoading && !apiError && apiData && <Outlet />
      )}
    </div>
  );
}

export default OwnerDetail;
