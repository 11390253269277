import React, {
  useState,
  useReducer,
  useEffect,
  createContext,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import Constants from '../constants';

const AuthContext = createContext();

const initialState = {
  isAuthenticated: false,
  user: null,
  token: null,
  role: Constants.User.Roles.Guest.id,
  pack: null,
  status: Constants.User.Status.Available.id,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      localStorage.setItem('user', JSON.stringify(action.payload.user));
      localStorage.setItem('token', JSON.stringify(action.payload.token));
      localStorage.setItem('role', action.payload.role);
      localStorage.setItem('pack', JSON.stringify(action.payload.pack));
      localStorage.setItem('userStatus', action.payload.status);

      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        token: action.payload.token,
        role: action.payload.role,
        pack: action.payload.pack,
        status: action.payload.status,
      };
    case 'UPDATE':
      localStorage.setItem('user', JSON.stringify(action.payload.user));
      return {
        ...state,
        user: action.payload.user,
      };
    case 'UPDATE_USER_STATUS':
      localStorage.setItem('userStatus', action.payload.status);
      return {
        ...state,
        status: action.payload.status,
      };
    case 'LOGOUT':
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('role');
      localStorage.removeItem('pack');
      localStorage.removeItem('userStatus');
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [loading, setLoading] = useState(true);

  const login = (payload) => {
    dispatch({
      type: 'LOGIN',
      payload,
    });
  };

  const logout = () => {
    dispatch({
      type: 'LOGOUT',
    });
  };

  const update = (payload) => {
    dispatch({
      type: 'UPDATE',
      payload,
    });
  };

  const updateUserStatus = (status) => {
    dispatch({
      type: 'UPDATE_USER_STATUS',
      payload: {
        status,
      },
    });
  };

  useEffect(() => {
    const status =
      localStorage.getItem('userStatus') || Constants.User.Status.Available.id;

    let user;
    let token;
    const role = localStorage.getItem('role') || Constants.User.Roles.Guest.id;
    let pack;

    try {
      user = JSON.parse(localStorage.getItem('user') || null);
      token = JSON.parse(localStorage.getItem('token') || null);
      // role = JSON.parse(localStorage.getItem('role') || null);
      pack = JSON.parse(localStorage.getItem('pack') || null);
    } catch (e) {
      dispatch({
        type: 'LOGOUT',
      });
    }

    if (user && token && role && pack) {
      dispatch({
        type: 'LOGIN',
        payload: {
          user,
          token,
          role,
          pack,
          status,
        },
      });
    }
    setLoading(false);
  }, []);

  const value = useMemo(
    () => ({
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      token: state.token,
      role: state.role,
      pack: state.pack,
      status: state.status,
      login,
      logout,
      update,
      updateUserStatus,
    }),
    [state]
  );

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AuthContext, AuthProvider };
