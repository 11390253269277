import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { RequestLoading, RequestResult, Tooltip } from '../../../../components';
import { LineChart } from '../../../../components/Charts';
import Constants from '../../../../constants';
import Utils from '../../../../utils';
import { useAxiosQuery } from '../../../../hooks';
import { ThemeContext } from '../../../../context/ThemeContext';

function LineChartCardMarketInventory({ zip }) {
  const { formatNumber } = useIntl();
  const { formatMessage } = useIntl();
  const { theme } = useContext(ThemeContext);

  const getFormattedNumber = (v) =>
    formatNumber(v, {
      maximumFractionDigits: 0,
    });

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (ctx) => getFormattedNumber(ctx.raw),
        },
      },
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
          displayFormats: {
            day: Constants.DateFormats.APP.Moment.Common,
          },
        },
      },
      y: {
        ticks: {
          callback: (v) => getFormattedNumber(v),
        },
      },
    },
  };

  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: 'Market_Inventory/list',
    preventFetch: !zip,
    params: { zip, type: 'price_increased_count' },
  });

  const getLineData = () => {
    const withGradient = true;
    const labels = apiData.map((item) => item.month_date_yyyymm); // Güncellendi

    console.log(apiData);
    const datasets = [
      {
        data: apiData.map((item) => Number(item.price_increased_count)), // Güncellendi
        ...Utils.Chart.getLineChartOptions(
          0,
          theme,
          withGradient
            ? {
                fill: 'start',
                backgroundColor: (context) => {
                  const { ctx } = context.chart;
                  const gradient = ctx.createLinearGradient(0, 0, 0, 400);
                  gradient.addColorStop(0, Constants.Colors.Palette[0]);
                  gradient.addColorStop(
                    1,
                    Utils.Color.getRGBAColorFromHex(
                      Constants.Colors.Palette[2],
                      0.1
                    )
                  );
                  return gradient;
                },
                tension: 0.4,
              }
            : {
                borderWidth: 2,
                pointRadius: 4,
                pointHoverRadius: 4,
              }
        ),
      },
    ];
    return {
      labels,
      datasets,
    };
  };
  return (
    <Card className="h-100">
      <Card.Header className="card-header-content-between">
        <Card.Title
          bsPrefix="card-header-title"
          as="h4"
          className="d-flex justify-content-between"
        >
          <FormattedMessage id="app.common.marketPriceIncreaseCount" />
          <Tooltip
            content={formatMessage({
              id: 'app.toolTips.marketPriceIncreaseCount',
            })}
          >
            <i className="bi-info-circle-fill  ms-2" />
          </Tooltip>
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <RequestLoading loading={apiLoading} margin="5" />
        <RequestResult type="error" message={apiError} />
        {!apiLoading && !apiError && Object.keys(apiData)?.length === 0 && (
          <RequestResult type="secondary" message="app.common.noData" />
        )}
        {!apiLoading && !apiError && Object.keys(apiData)?.length > 0 && (
          <div className="h-100 d-flex flex-column justify-content-center align-items-center">
            <LineChart data={getLineData()} options={options} />
          </div>
        )}
      </Card.Body>
    </Card>
  );
}

LineChartCardMarketInventory.propTypes = {
  zip: PropTypes.string.isRequired,
};

export default LineChartCardMarketInventory;
