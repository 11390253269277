import React, { useContext } from 'react';
import { yup } from '../../../../lib';
import { SmartForm } from '../../../../components';
import { AuthContext } from '../../../../context/AuthContext';

function SettingsBasicInfo() {
  const { user } = useContext(AuthContext);

  const fields = [
    {
      cols: [
        {
          key: 'firstname',
          apiKey: 'name',
          schema: yup.string().required(),
        },
      ],
    },
    {
      cols: [
        {
          key: 'lastname',
          apiKey: 'surname',
          schema: yup.string().required(),
        },
      ],
    },
    {
      cols: [
        {
          key: 'email',
          options: { controller: { props: { disabled: true } } },
        },
      ],
    },
  ];

  return (
    <div>
      <SmartForm
        isHorizontal
        fields={fields}
        submitButtonText="saveChanges"
        requestUrl="/users/update"
        requestParams={{ user_type: 0 }}
        defaultValues={
          user
            ? {
                firstname: user.name,
                lastname: user.surname,
                email: user.email,
              }
            : {}
        }
      />
    </div>
  );
}

export default SettingsBasicInfo;
