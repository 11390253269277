import React, { useContext, useEffect, useState } from 'react';

import { Navbar, Container, Nav, Button } from 'react-bootstrap';

import PropTypes from 'prop-types';

import { Link, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useWindowSize } from 'react-use';
import {
  NavbarSingleLogoLight,
  SearchLight,
  DropdownNotificationsGhostLight,
  // DropdownAppsGhostLight,
  DarkmodeSwitcherPositionEnd,
  DropdownUser,
  NavbarNavMenu,
  OffcanvasActivity,
  LanguageSwitcherPositionEnd,
} from '../LayoutComponents';
import { AuthContext } from '../../../context/AuthContext';
import { AccessContext } from '../../../context/AccessContext';
import { Routes as AppRoutes } from '../../../router/routeMapping';
import NavbarHelp from '../../NavbarHelp';
import NavbarProfileSelect from '../../NavbarProfileSelect';

function DoubleLineContainer({ menuData }) {
  const location = useLocation();
  const { width } = useWindowSize();
  const [expanded, setExpanded] = useState(false);
  const [showOffcanvasActivity, setShowOffcanvasActivity] = useState(false);

  const handleOffcanvasActivityClose = () => setShowOffcanvasActivity(false);
  // const handleOffcanvasActivityShow = () => setShowOffcanvasActivity(true);

  const { hasAccess } = useContext(AccessContext);
  const { pack } = useContext(AuthContext);

  useEffect(() => {
    if (window.innerWidth <= 960) {
      setExpanded(false);
    }
  }, [location]);

  return (
    <>
      <Navbar
        as="header"
        expand="lg"
        className="navbar-bordered navbar-spacer-y-0 flex-lg-column"
        variant=""
        expanded={expanded}
        onToggle={setExpanded}
      >
        <div className="navbar-dark w-100 bg-dark py-2">
          <Container>
            <div className="navbar-nav-wrap">
              <NavbarSingleLogoLight />
              <div className="navbar-nav-wrap-content-start d-none d-lg-block">
                <div className="d-flex">
                  {width >= 992 && <NavbarProfileSelect />}
                  {width >= 992 && (
                    <div className="ms-2">
                      <SearchLight />
                    </div>
                  )}
                </div>
              </div>
              <div className="navbar-nav-wrap-content-end">
                <Nav as="ul">
                  <Nav.Item as="li" className="d-none d-md-inline-block">
                    <DropdownNotificationsGhostLight />
                  </Nav.Item>
                  {/* <Nav.Item as="li" className="d-none d-sm-inline-block">
                    <DropdownAppsGhostLight />
                  </Nav.Item> */}
                  {/* <Nav.Item as="li" className="d-none d-sm-inline-block">
                    <Button
                      variant="ghost-light"
                      className="btn-icon rounded-circle"
                      onClick={handleOffcanvasActivityShow}
                    >
                      <i className="bi-x-diamond" />
                    </Button>
                  </Nav.Item> */}
                  <Nav.Item as="li">
                    <DarkmodeSwitcherPositionEnd dropup={false} />
                  </Nav.Item>
                  <Nav.Item as="li">
                    <LanguageSwitcherPositionEnd dropup={false} />
                  </Nav.Item>
                  <Nav.Item as="li">
                    <NavbarHelp />
                  </Nav.Item>
                  {pack.name !== 'Premium' && (
                    <Nav.Item as="li" className="d-none d-sm-block">
                      <Button
                        size="sm"
                        as={Link}
                        to={
                          AppRoutes.protected.ACCOUNT.children
                            .SUBSCRIPTION_PLANS.path
                        }
                      >
                        <i className="bi-arrow-up-circle me-1" />
                        <FormattedMessage id="app.common.upgradePlan" />
                      </Button>
                    </Nav.Item>
                  )}
                  <Nav.Item as="li">
                    <DropdownUser />
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Navbar.Toggle
                      aria-controls="navbarDoubleLineContainerNavDropdown"
                      aria-expanded={expanded}
                    >
                      <span className="navbar-toggler-default">
                        <i className="bi-list" />
                      </span>
                      <span className="navbar-toggler-toggled">
                        <i className="bi-x" />
                      </span>
                    </Navbar.Toggle>
                  </Nav.Item>
                </Nav>
              </div>
            </div>

            {width < 992 && <NavbarProfileSelect isMobile />}
            {width < 992 && (
              <div className="d-lg-none mt-2">
                <SearchLight isMobile />
              </div>
            )}
          </Container>
        </div>

        {hasAccess && (
          <Container>
            <nav className="js-mega-menu flex-grow-1">
              <Navbar.Collapse id="navbarDoubleLineContainerNavDropdown">
                <NavbarNavMenu menuData={menuData} />
              </Navbar.Collapse>
            </nav>
          </Container>
        )}
      </Navbar>

      <OffcanvasActivity
        show={showOffcanvasActivity}
        onHide={handleOffcanvasActivityClose}
      />
    </>
  );
}

const MenuItemProps = {
  title: PropTypes.string.isRequired,
  href: PropTypes.string,
  icon: PropTypes.string,
};

const MenuItemShape = {
  ...MenuItemProps,
  submenu: PropTypes.arrayOf(PropTypes.shape(MenuItemProps)),
};

DoubleLineContainer.propTypes = {
  menuData: PropTypes.arrayOf(PropTypes.shape(MenuItemShape)).isRequired,
};

export default DoubleLineContainer;
