import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { RequestLoading, RequestResult, Tooltip } from '../../../../components';
import { DoughnutChart } from '../../../../components/Charts';
import { useAxiosQuery } from '../../../../hooks';
import Utils from '../../../../utils';
import { ThemeContext } from '../../../../context/ThemeContext';
import Constants from '../../../../constants';

const options = {
  rotation: -90,
  circumference: 180,
  layout: {
    padding: {
      left: 10,
      right: 10,
      top: 10,
      bottom: 10,
    },
  },
  plugins: {
    tooltip: {
      enabled: false,
    },
    legend: {
      labels: {
        generateLabels: (chart) => {
          const { data } = chart;
          if (data.labels.length && data.datasets.length) {
            const {
              labels: { pointStyle },
            } = chart.legend.options;

            const total = chart.data.datasets[0].data.reduce(
              (acc, obj) => acc + obj,
              0
            );

            return data.labels.map((label, i) => {
              const meta = chart.getDatasetMeta(0);
              const style = meta.controller.getStyle(i);

              return {
                text: `${label}  (${Math.round(
                  (chart.data.datasets[0].data[i] * 100) / total
                )}%)`,
                fillStyle: style.backgroundColor,
                strokeStyle: style.borderColor,
                lineWidth: style.borderWidth,
                pointStyle,
                hidden: !chart.getDataVisibility(i),

                index: i,
              };
            });
          }
          return [];
        },
      },
    },
    datalabels: {
      padding: {
        left: 8,
        right: 8,
        top: 4,
        bottom: 4,
      },
      display: 'auto',
      anchor: 'center',
      formatter: (value, ctx) => {
        const label = ctx.chart.data.labels[ctx.dataIndex];
        const dataArr = ctx.chart.data.datasets[0].data;
        const total = dataArr.reduce((acc, obj) => acc + obj, 0);
        return `${label} (${Math.round((value * 100) / total)}%)`;
      },
    },
  },
};

function HalfDoughnutChartCardOwnerType({ zip }) {
  const { theme } = useContext(ThemeContext);
  const { formatMessage } = useIntl();
  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/emlak/get_chart_data',
    preventFetch: false,
    params: { zip, DataType: 'OwnerType' },
  });

  const getData = (data) => {
    const datasetObj = {
      backgroundColor: [],
      data: [],
      borderWidth: 4,
      borderColor:
        theme === 'dark' ? Utils.Chart.darkBgColor : Utils.Chart.lightBgColor,
      datalabels: { color: [] },
    };

    const labels = [];

    data.forEach((item, index) => {
      datasetObj.data.push(Number(item.Val));
      datasetObj.datalabels.color.push(Constants.Colors.Palette[index]);
      datasetObj.backgroundColor.push(Constants.Colors.Palette[index]);
      labels.push(item.Title);
    });

    return { labels, datasets: [datasetObj] };
  };

  return (
    <Card className="h-100">
      <Card.Header>
        <Card.Title
          bsPrefix="card-header-title"
          as="h4"
          className="d-flex justify-content-between"
        >
          <FormattedMessage id="app.common.ownerType" />
          <Tooltip
            content={formatMessage({
              id: 'app.toolTips.ownerType',
            })}
          >
            <i className="bi-info-circle-fill  ms-2" />
          </Tooltip>
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <RequestLoading loading={apiLoading} margin="5" />
        <RequestResult type="error" message={apiError} />
        {!apiLoading && !apiError && apiData?.length === 0 && (
          <RequestResult type="secondary" message="app.common.noData" />
        )}
        {!apiLoading && !apiError && apiData?.length > 0 && (
          <div className="h-100 d-flex flex-column justify-content-center align-items-center">
            <DoughnutChart
              data={getData(apiData)}
              options={options}
              plugins={{ datalabels: true }}
            />
          </div>
        )}
      </Card.Body>
    </Card>
  );
}

HalfDoughnutChartCardOwnerType.propTypes = {
  zip: PropTypes.string.isRequired,
};

export default HalfDoughnutChartCardOwnerType;
