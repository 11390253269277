import React from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { PageHeader } from '../../../components';
import { Routes as AppRoutes } from '../../../router/routeMapping';

const menuData = Object.values(AppRoutes.protected.LOCATION.children).map(
  ({ title, path }) => ({ title, href: path })
);

function Location() {
  const location = useLocation();
  const activePage = menuData.find((page) => page.href === location.pathname);

  return (
    <div className="content container">
      <PageHeader
        title={activePage?.title}
        menuData={menuData}
        breadcrumbData={{
          current: activePage?.title,
          parents: [
            {
              name: AppRoutes.protected.LOCATION.title,
              href: AppRoutes.protected.LOCATION.path,
            },
          ],
        }}
      />

      {location.pathname === AppRoutes.protected.LOCATION.path ? (
        <Navigate to={menuData[0].href} replace />
      ) : (
        <Outlet />
      )}
    </div>
  );
}

export default Location;
