export const publicAreaBasePath = '';
export const protectedAreaBasePath = '/dashboard';
export const authBasePath = '/auth';

/**
 * @description Define supported routes
 */
export const Routes = {
  public: {
    REPORTS: {
      title: 'reports',
      path: `${publicAreaBasePath}/reports/:token`,
      componentName: 'Reports',
    },
    NEWSLETTEr: {
      title: 'reports',
      path: `${publicAreaBasePath}/newsletter/:zip`,
      componentName: 'Newsletter',
    },
    NOT_FOUND: { title: 'notFound', path: '/404', componentName: 'NotFound' },
    UNAUTHORIZED: {
      title: 'unauthorized',
      path: '/unauthorized',
      componentName: 'Unauthorized',
    },
  },
  auth: {
    LOGIN: {
      title: 'login',
      path: `${authBasePath}/login`,
      componentName: 'Login',
    },
    FORGOT_PASSWORD: {
      title: 'forgotPassword',
      path: `${authBasePath}/forgot-password`,
      componentName: 'ForgotPassword',
    },

    VERIFY_EMAIL: {
      title: 'verifyEmail',
      path: `${authBasePath}/verify-email`,
      componentName: 'VerifyEmail',
    },
    VERIFY_CODE: {
      title: 'verifyCode',
      path: `${authBasePath}/verify-code`,
      componentName: 'VerifyCode',
    },
  },
  protected: {
    OVERVIEW: {
      title: 'dashboard',
      icon: 'bi-house-door',
      path: `${protectedAreaBasePath}/overview`,
      componentName: 'Overview',
      hidden: true,
    },
    SEARCH: {
      title: 'search',
      icon: 'bi-search',
      path: `${protectedAreaBasePath}/search`,
      componentName: 'Search',
      hidden: true,
    },
    ACCOUNT_SETUP: {
      title: 'accountSetup',
      icon: 'bi-house-door',
      path: `${protectedAreaBasePath}/account-setup`,
      componentName: 'AccountSetup',
      hidden: true,
    },
    ACCOUNT: {
      title: 'account',
      icon: 'bi-person',
      path: `${protectedAreaBasePath}/account`,
      componentName: 'Account',
      hidden: true,
      children: {
        ACCOUNT_SETTINGS: {
          title: 'settings',
          path: `${protectedAreaBasePath}/account/settings`,
          componentName: 'AccountSettings',
        },

        ACCOUNT_BILLING: {
          title: 'billing',
          path: `${protectedAreaBasePath}/account/billing`,
          componentName: 'AccountBilling',
        },
        SUBSCRIPTION_PLANS: {
          title: 'subscriptionPlans',
          icon: 'bi-bell',
          path: `${protectedAreaBasePath}/account/subscription-plans`,
          componentName: 'AccountSubscriptionPlans',
          hidden: true,
        },
      },
    },
    AI: {
      title: 'RE AI',
      icon: 'bi-robot',
      path: `${protectedAreaBasePath}/AI`,
      componentName: 'AI',
      hidden: false,
      children: {
        MYPROFILES: {
          title: 'RE Criteria Set',
          icon: 'bi-magic',
          path: `${protectedAreaBasePath}/AI/my-profiles`,
          componentName: 'MyProfiles',
        },
      },
    },

    LISTINGS: {
      title: 'Listings',
      icon: 'bi-card-list',
      path: `${protectedAreaBasePath}/listings`,
      componentName: 'Listings',
      hidden: false,
      children: {
        ALL_LISTINGS: {
          title: 'All Listings',
          icon: 'bi-archive',
          path: `${protectedAreaBasePath}/listings/all-listings`,
          componentName: 'SharedListing',
        },
        NEW_LISTINGS: {
          title: 'New Listings',
          icon: 'bi-fire',
          path: `${protectedAreaBasePath}/listings/new-listings`,
          componentName: 'SharedListing',
        },
        RESIDENTIAL: {
          title: 'Residential',
          icon: 'bi-people',
          path: `${protectedAreaBasePath}/listings/residential`,
          componentName: 'SharedListing',
        },
        COMMERCIAL: {
          title: 'Commercial',
          icon: 'bi-hospital',
          path: `${protectedAreaBasePath}/listings/commercial`,
          componentName: 'SharedListing',
        },
        LAND: {
          title: 'Land',
          icon: 'bi-grid-3x3',
          path: `${protectedAreaBasePath}/listings/land`,
          componentName: 'SharedListing',
        },
        RECENTLY_SOLD: {
          title: 'Recently Sold',
          icon: 'bi-house-lock',
          path: `${protectedAreaBasePath}/listings/recently-sold`,
          componentName: 'SharedListing',
        },
        AI_PICKED: {
          title: 'AI Picked',
          icon: 'bi-robot',
          path: `${protectedAreaBasePath}/listings/ai-picked`,
          componentName: 'SharedListing',
        },
        POI: {
          title: 'POI',
          icon: 'bi-map',
          path: `${protectedAreaBasePath}/listings/poi`,
          componentName: 'SharedListing',
          hidden: true,
        },
        BUSINESS: {
          title: 'BUSINESS',
          icon: 'bi-shop',
          path: `${protectedAreaBasePath}/listings/business`,
          componentName: 'SharedListing',
          hidden: true,
        },
        SCHOOL: {
          title: 'School',
          icon: 'bi-book',
          path: `${protectedAreaBasePath}/listings/school`,
          componentName: 'SharedListing',
          hidden: true,
        },
        STOPS: {
          title: 'Stops',
          icon: 'bi-bus-front',
          path: `${protectedAreaBasePath}/listings/stops`,
          componentName: 'SharedListing',
          hidden: true,
        },
        ZIP: {
          title: 'Zip',
          icon: 'bi-postage',
          path: `${protectedAreaBasePath}/listings/zip`,
          componentName: 'SharedListing',
          hidden: true,
        },
        ARTICLE: {
          title: 'Projects',
          icon: 'bi-buildings',
          path: `${protectedAreaBasePath}/listings/article`,
          componentName: 'SharedListing',
          hidden: true,
        },
      },
    },

    LISTINGS_DETAIL: {
      title: 'Detail',
      icon: 'bi-box',
      path: `${protectedAreaBasePath}/listings-detail/:id`,
      componentName: 'ListingsDetail',
      hidden: true,
      children: {
        INFO: {
          title: 'info',
          path: `${protectedAreaBasePath}/listings-detail/:id/info`,
          componentName: 'ListingsDetailInfo',
        },

        GALLERY: {
          title: 'gallery',
          path: `${protectedAreaBasePath}/listings-detail/:id/gallery`,
          componentName: 'ListingsDetailGallery',
        },
        COMPS: {
          title: 'Comparables',
          path: `${protectedAreaBasePath}/listings-detail/:id/comps`,
          componentName: 'ListingsDetailComps',
        },

        CITY: {
          title: 'Neighborhood',
          path: `${protectedAreaBasePath}/listings-detail/:id/neighborhood`,
          componentName: 'ListingsDetailCity',
        },
        HISTORY: {
          title: 'Sales History',
          path: `${protectedAreaBasePath}/listings-detail/:id/history`,
          componentName: 'ListingsDetailHistory',
        },
        HISTORYTAX: {
          title: 'Tax History',
          path: `${protectedAreaBasePath}/listings-detail/:id/historytax`,
          componentName: 'ListingsDetailHistoryTax',
        },

        POI: {
          title: 'POI',
          path: `${protectedAreaBasePath}/listings-detail/:id/poi`,
          componentName: 'ListingsDetailPOI',
        },

        PROJECTS: {
          title: 'Projects',
          path: `${protectedAreaBasePath}/listings-detail/:id/projects`,
          componentName: 'ListingsDetailProjects',
        },

        SCHOOLS: {
          title: 'Schools',
          path: `${protectedAreaBasePath}/listings-detail/:id/schools`,
          componentName: 'ListingsDetailSchools',
        },

        MYLOCATIONS: {
          title: 'My Locations',
          path: `${protectedAreaBasePath}/listings-detail/:id/mylocation`,
          componentName: 'ListingsDetailMyLocations',
        },
        BUSINESSES: {
          title: 'Neighborhood Businesses',
          path: `${protectedAreaBasePath}/listings-detail/:id/businesses`,
          componentName: 'ListingsDetailBusinesses',
        },
        CALENDAR: {
          title: 'calendar',
          path: `${protectedAreaBasePath}/listings-detail/:id/calendar`,
          componentName: 'ListingsDetailCalendar',
        },

        NOTES: {
          title: 'notes',
          path: `${protectedAreaBasePath}/listings-detail/:id/notes`,
          componentName: 'ListingsDetailNotes',
        },
        FILES: {
          title: 'files',
          path: `${protectedAreaBasePath}/listings-detail/:id/files`,
          componentName: 'ListingsDetailFiles',
        },
      },
    },
    LAWYER_DETAIL: {
      title: 'Detail',
      icon: 'bi-box',
      path: `${protectedAreaBasePath}/lawyer-detail/:id`,
      componentName: 'LawyerDetail',
      hidden: true,
      children: {
        INFO: {
          title: 'info',
          path: `${protectedAreaBasePath}/lawyer-detail/:id/info`,
          componentName: 'LawyerDetailInfo',
        },

        CALENDAR: {
          title: 'calendar',
          path: `${protectedAreaBasePath}/lawyer-detail/:id/calendar`,
          componentName: 'LawyerDetailCalendar',
        },

        NOTES: {
          title: 'notes',
          path: `${protectedAreaBasePath}/lawyer-detail/:id/notes`,
          componentName: 'LawyerDetailNotes',
        },
        FILES: {
          title: 'files',
          path: `${protectedAreaBasePath}/lawyer-detail/:id/files`,
          componentName: 'LawyerDetailFiles',
        },
      },
    },
    AI_DETAIL: {
      title: 'Detail',
      icon: 'bi-box',
      path: `${protectedAreaBasePath}/ai-detail/:id`,
      componentName: 'AIDetail',
      hidden: true,
      children: {
        INFO: {
          title: 'info',
          path: `${protectedAreaBasePath}/ai-detail/:id/info`,
          componentName: 'AIDetailInfo',
        },

        CALENDAR: {
          title: 'calendar',
          path: `${protectedAreaBasePath}/ai-detail/:id/calendar`,
          componentName: 'AIDetailCalendar',
        },

        NOTES: {
          title: 'notes',
          path: `${protectedAreaBasePath}/ai-detail/:id/notes`,
          componentName: 'AIDetailNotes',
        },
        FILES: {
          title: 'files',
          path: `${protectedAreaBasePath}/ai-detail/:id/files`,
          componentName: 'AIDetailFiles',
        },
      },
    },
    CONTACT_DETAIL: {
      title: 'Detail',
      icon: 'bi-box',
      path: `${protectedAreaBasePath}/contact-detail/:id`,
      componentName: 'ContactDetail',
      hidden: true,
      children: {
        INFO: {
          title: 'info',
          path: `${protectedAreaBasePath}/contact-detail/:id/info`,
          componentName: 'ContactDetailInfo',
        },

        CALENDAR: {
          title: 'calendar',
          path: `${protectedAreaBasePath}/contact-detail/:id/calendar`,
          componentName: 'ContactDetailCalendar',
        },

        NOTES: {
          title: 'notes',
          path: `${protectedAreaBasePath}/contact-detail/:id/notes`,
          componentName: 'ContactDetailNotes',
        },
        FILES: {
          title: 'files',
          path: `${protectedAreaBasePath}/contact-detail/:id/files`,
          componentName: 'ContactDetailFiles',
        },
      },
    },
    OWNER_DETAIL: {
      title: 'Detail',
      icon: 'bi-box',
      path: `${protectedAreaBasePath}/owner-detail/:id`,
      componentName: 'OwnerDetail',
      hidden: true,
      children: {
        INFO: {
          title: 'info',
          path: `${protectedAreaBasePath}/owner-detail/:id/info`,
          componentName: 'OwnerDetailInfo',
        },
        PROPERTIES: {
          title: 'properties',
          path: `${protectedAreaBasePath}/owner-detail/:id/properties`,
          componentName: 'OwnerDetailProperties',
        },
        CALENDAR: {
          title: 'calendar',
          path: `${protectedAreaBasePath}/owner-detail/:id/calendar`,
          componentName: 'OwnerDetailCalendar',
        },

        NOTES: {
          title: 'notes',
          path: `${protectedAreaBasePath}/owner-detail/:id/notes`,
          componentName: 'OwnerDetailNotes',
        },
        FILES: {
          title: 'files',
          path: `${protectedAreaBasePath}/owner-detail/:id/files`,
          componentName: 'OwnerDetailFiles',
        },
      },
    },
    FARMING: {
      title: 'Off-Market',
      icon: 'bi-gem',
      path: `${protectedAreaBasePath}/farming`,
      componentName: 'Farming',
      hidden: false,
      children: {
        ABSENTEE_OWNERS: {
          title: 'Absentee Owners',
          icon: 'bi-app',
          path: `${protectedAreaBasePath}/farming/absentee-owners`,
          componentName: 'SharedFarming',
        },
        ADJUSTABLE_LOANS: {
          title: 'Adjustable Loans',
          icon: 'bi-app',
          path: `${protectedAreaBasePath}/farming/adjustable-loans`,
          componentName: 'SharedFarming',
        },
        AUCTIONS: {
          title: 'Auctions',
          icon: 'bi-app',
          path: `${protectedAreaBasePath}/farming/auctions`,
          componentName: 'SharedFarming',
        },
        BANK_OWNED: {
          title: 'Bank Owned (REOs)',
          icon: 'bi-app',
          path: `${protectedAreaBasePath}/farming/bank-owned`,
          componentName: 'SharedFarming',
        },
        CASH_BUYERS: {
          title: 'Cash Buyers',
          icon: 'bi-app',
          path: `${protectedAreaBasePath}/farming/cash-buyers`,
          componentName: 'SharedFarming',
        },
        CORPORATE_OWNED: {
          title: 'Corporate Owned',
          icon: 'bi-app',
          path: `${protectedAreaBasePath}/farming/corporate-owned`,
          componentName: 'SharedFarming',
        },
        FREE_CLEAR: {
          title: 'Free & Clear',
          icon: 'bi-app',
          path: `${protectedAreaBasePath}/farming/free-clear`,
          componentName: 'SharedFarming',
        },
        HIGH_EQUITY: {
          title: 'High Equity',
          icon: 'bi-app',
          path: `${protectedAreaBasePath}/farming/high-equity`,
          componentName: 'SharedFarming',
        },
        LOW_EQUITY: {
          title: 'Low Equity',
          icon: 'bi-app',
          path: `${protectedAreaBasePath}/farming/low-equity`,
          componentName: 'SharedFarming',
        },
        INTRAFAMILY_TRANSFERS: {
          title: 'Intrafamily Transfers',
          icon: 'bi-app',
          path: `${protectedAreaBasePath}/farming/intrafamily-transfers`,
          componentName: 'SharedFarming',
        },
        OUT_OF_STATE_OWNERS: {
          title: 'Out of State Owners',
          icon: 'bi-app',
          path: `${protectedAreaBasePath}/farming/out-of-state`,
          componentName: 'SharedFarming',
        },
        PREFORECLOSURES: {
          title: 'Preforeclosures',
          icon: 'bi-app',
          path: `${protectedAreaBasePath}/farming/preforeclosures`,
          componentName: 'SharedFarming',
        },
        PROBATES: {
          title: 'Probates',
          icon: 'bi-app',
          path: `${protectedAreaBasePath}/farming/probates`,
          componentName: 'SharedFarming',
        },
        TIRED_LANDLORDS: {
          title: 'Tired Landlords',
          icon: 'bi-app',
          path: `${protectedAreaBasePath}/farming/tired-landlords`,
          componentName: 'SharedFarming',
        },
        VACANT_HOMES: {
          title: 'Vacant Homes',
          icon: 'bi-app',
          path: `${protectedAreaBasePath}/farming/vacant-homes`,
          componentName: 'SharedFarming',
        },
        VACANT_LOTS: {
          title: 'Vacant Lots',
          icon: 'bi-app',
          path: `${protectedAreaBasePath}/farming/vacant-lots`,
          componentName: 'SharedFarming',
        },
      },
    },
    BUSINESSES: {
      title: 'LeadGenerator',
      icon: 'bi-briefcase',
      path: `${protectedAreaBasePath}/businesses`,
      componentName: 'Businesses',
      hidden: false,
      children: {
        REALTORS: {
          title: 'Realtors',
          icon: 'bi-houses',
          path: `${protectedAreaBasePath}/businesses/realtors`,
          componentName: 'Realtors',
        },
        LAWYERS: {
          title: 'Lawyers',
          icon: 'bi-people',
          path: `${protectedAreaBasePath}/businesses/lawyers`,
          componentName: 'Lawyers',
        },
        OWNERS: {
          title: 'Owners',
          icon: 'bi-person-vcard',
          path: `${protectedAreaBasePath}/businesses/owners`,
          componentName: 'Owners',
        },
        CONTACTS: {
          title: 'Contacts',
          icon: 'bi-bookmark-star',
          path: `${protectedAreaBasePath}/businesses/contacts`,
          componentName: 'Contacts',
        },
      },
    },
    SMALLBUSINESS_DETAIL: {
      title: 'Detail',
      icon: 'bi-box',
      path: `${protectedAreaBasePath}/business-detail/:id`,
      componentName: 'BusinessDetail',
      hidden: true,
      children: {
        INFO: {
          title: 'info',
          path: `${protectedAreaBasePath}/business-detail/:id/info`,
          componentName: 'BusinessDetailInfo',
        },
        LISTINGSNEARBY: {
          title: 'Listings',
          path: `${protectedAreaBasePath}/business-detail/:id/listing`,
          componentName: 'BusinessDetailListing',
        },
        SERVICES: {
          title: 'Services',
          path: `${protectedAreaBasePath}/business-detail/:id/services`,
          componentName: 'BusinessDetailServices',
        },
        AMENITIES: {
          title: 'Amenities',
          path: `${protectedAreaBasePath}/business-detail/:id/amenities`,
          componentName: 'BusinessDetailAmenities',
        },
        HIGHLIGHTS: {
          title: 'Highlights',
          path: `${protectedAreaBasePath}/business-detail/:id/highlights`,
          componentName: 'BusinessDetailHighlights',
        },
        CALENDAR: {
          title: 'calendar',
          path: `${protectedAreaBasePath}/business-detail/:id/calendar`,
          componentName: 'BusinessDetailCalendar',
        },
        NOTES: {
          title: 'notes',
          path: `${protectedAreaBasePath}/business-detail/:id/notes`,
          componentName: 'BusinessDetailNotes',
        },
        FILES: {
          title: 'files',
          path: `${protectedAreaBasePath}/business-detail/:id/files`,
          componentName: 'BusinessDetailFiles',
        },
      },
    },
    REALTOR_DETAIL: {
      title: 'Realtor Detail',
      icon: 'bi-box',
      path: `${protectedAreaBasePath}/realtor-detail/:id`,
      componentName: 'RealtorDetail',
      hidden: true,
      children: {
        INFO: {
          title: 'info',
          path: `${protectedAreaBasePath}/realtor-detail/:id/info`,
          componentName: 'RealtorDetailInfo',
        },
        SPEICALIZATIONS: {
          title: 'Specializations',
          path: `${protectedAreaBasePath}/realtor-detail/:id/specializations`,
          componentName: 'RealtorDetailSpecializations',
        },
        AREASERVED: {
          title: 'Area Served',
          path: `${protectedAreaBasePath}/realtor-detail/:id/areaserved`,
          componentName: 'RealtorDetailAreaServed',
        },
        SOCIAL: {
          title: 'Social',
          path: `${protectedAreaBasePath}/realtor-detail/:id/social`,
          componentName: 'RealtorDetailSocial',
        },
        CALENDAR: {
          title: 'calendar',
          path: `${protectedAreaBasePath}/realtor-detail/:id/calendar`,
          componentName: 'RealtorDetailCalendar',
        },
        NOTES: {
          title: 'notes',
          path: `${protectedAreaBasePath}/realtor-detail/:id/notes`,
          componentName: 'RealtorDetailNotes',
        },
        FILES: {
          title: 'files',
          path: `${protectedAreaBasePath}/realtor-detail/:id/files`,
          componentName: 'RealtorDetailFiles',
        },
      },
    },
    FARMING_DETAIL: {
      title: 'Farming Detail',
      icon: 'bi-box',
      path: `${protectedAreaBasePath}/farming-detail/:id`,
      componentName: 'FarmingDetail',
      hidden: true,
      children: {
        INFO: {
          title: 'info',
          path: `${protectedAreaBasePath}/farming-detail/:id/info`,
          componentName: 'FarmingDetailInfo',
        },
        OWNERS: {
          title: 'owner',
          path: `${protectedAreaBasePath}/farming-detail/:id/owners`,
          componentName: 'FarmingDetailOwners',
        },
      },
    },
    CITY_DETAIL: {
      title: 'City Detail',
      icon: 'bi-box',
      path: `${protectedAreaBasePath}/city-detail/:zip`,
      componentName: 'CityDetail',
      hidden: true,
      children: {
        INFO: {
          title: 'info',
          path: `${protectedAreaBasePath}/city-detail/:zip/info`,
          componentName: 'CityDetailInfo',
        },
      },
    },
    LOCATION: {
      title: 'Geo',
      icon: 'bi-map',
      path: `${protectedAreaBasePath}/location`,
      componentName: 'Location',
      hidden: false,
      children: {
        POI: {
          title: 'POI',
          icon: 'bi-pin-map-fill',
          path: `${protectedAreaBasePath}/location/poi`,
          componentName: 'POI',
        },
        SCHOOLS: {
          title: 'Schools',
          icon: 'bi-journals',
          path: `${protectedAreaBasePath}/location/schools`,
          componentName: 'Schools',
        },
        SMALLBUSINESS: {
          title: 'Businesses',
          icon: 'bi-shop',
          path: `${protectedAreaBasePath}/location/smallbusiness`,
          componentName: 'SmallBusiness',
        },
        STOPS: {
          title: 'Stops',
          icon: 'bi-bus-front',
          path: `${protectedAreaBasePath}/location/stops`,
          componentName: 'GTFS',
        },
        NEIGHBORHOOD: {
          title: 'Neighborhood',
          icon: 'bi-signpost',
          path: `${protectedAreaBasePath}/location/neighborhood`,
          componentName: 'Neighborhood',
        },
        PROJECTS: {
          title: 'Projects',
          icon: 'bi-buildings',
          path: `${protectedAreaBasePath}/location/projects`,
          componentName: 'Projects',
        },
      },
    },
    UTILS: {
      title: 'Utils',
      icon: 'bi-front',
      path: `${protectedAreaBasePath}/utils`,
      componentName: 'Utils',
      hidden: false,
      children: {
        CALENDAR: {
          title: 'calendar',
          icon: 'bi-calendar-event',
          path: `${protectedAreaBasePath}/utils/calendar`,
          componentName: 'Calendar',
        },
        NOTES: {
          title: 'notes',
          icon: 'bi-chat-right-text-fill',
          path: `${protectedAreaBasePath}/utils/notes`,
          componentName: 'Notes',
        },
        FAVORITES: {
          title: 'Favorites',
          icon: 'bi-heart-fill',
          path: `${protectedAreaBasePath}/utils/favorites`,
          componentName: 'Favorites',
        },
        FILE_MANAGER: {
          title: 'fileManager',
          icon: 'bi-files',
          path: `${protectedAreaBasePath}/utils/file-manager`,
          componentName: 'FileManager',
        },
      },
    },

    USER: {
      title: 'user',
      icon: 'bi-person',
      path: `${protectedAreaBasePath}/user`,
      componentName: 'User',
      hidden: true,
      children: {
        USER_SETTINGS: {
          title: 'settings',
          path: `${protectedAreaBasePath}/user/settings`,
          componentName: 'UserSettings',
        },
      },
    },
    GENERIC_DETAIL: {
      title: 'genericDetail',
      icon: 'bi-box',
      path: `${protectedAreaBasePath}/generic-detail/:id`,
      componentName: 'GenericDetail',
      hidden: true,
      children: {
        TABLE: {
          title: 'table',
          path: `${protectedAreaBasePath}/generic-detail/:id/table`,
          componentName: 'GenericDetailTable',
        },
        GALLERY: {
          title: 'gallery',
          path: `${protectedAreaBasePath}/generic-detail/:id/gallery`,
          componentName: 'GenericDetailGallery',
        },
        CHART: {
          title: 'chart',
          path: `${protectedAreaBasePath}/generic-detail/:id/chart`,
          componentName: 'GenericDetailChart',
        },
        TIMELINE: {
          title: 'timeline',
          path: `${protectedAreaBasePath}/generic-detail/:id/timeline`,
          componentName: 'GenericDetailTimeline',
        },
        FILES: {
          title: 'files',
          path: `${protectedAreaBasePath}/generic-detail/:id/files`,
          componentName: 'GenericDetailFiles',
        },
        FORM: {
          title: 'form',
          path: `${protectedAreaBasePath}/generic-detail/:id/form`,
          componentName: 'GenericDetailForm',
        },
      },
    },
  },
};
