import React, { useContext, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Link } from 'react-router-dom';
import { FormattedHtml } from '../../../../components';
import { AuthContext } from '../../../../context/AuthContext';
import Constants from '../../../../constants';

function SubscriptionPlansTable() {
  const { pack } = useContext(AuthContext);
  const [pricingType, setPricingType] = useState(
    Constants.Billing.SubscriptionTypes.monthly.active
      ? Constants.Billing.SubscriptionTypes.monthly
      : Constants.Billing.SubscriptionTypes.yearly
  );

  const getUl = (val) => <ul className="list-unstyled mt-4">{val}</ul>;

  const getLiWithCheck = (val) => (
    <li className="mt-2 d-flex align-items-center">{val}</li>
  );

  const getIcon = (val) => (
    <i
      className={`${
        val[0] === 'check' ? 'text-primary' : 'text-danger'
      }  bi-${val}-lg me-2`}
    />
  );

  const subscriptionPlans = Constants.Billing.SubscriptionPlans;

  const currentPlanIndex = Constants.Billing.SubscriptionPlans.findIndex(
    (item) => item.apiKey === pack.name
  );

  if (currentPlanIndex > 0) {
    subscriptionPlans.splice(0, currentPlanIndex);
  }

  return (
    <>
      {Constants.Billing.SubscriptionTypes.monthly.active &&
        Constants.Billing.SubscriptionTypes.yearly.active && (
          <div className="d-flex justify-content-center mb-5">
            <Form.Check
              type="switch"
              className="form-switch-between d-flex align-items-center"
            >
              <Form.Check.Label>
                <FormattedMessage
                  id={`app.common.${Constants.Billing.SubscriptionTypes.monthly.id}`}
                />
              </Form.Check.Label>
              <Form.Check.Input
                checked={
                  pricingType.id ===
                  Constants.Billing.SubscriptionTypes.yearly.id
                }
                onChange={() => {
                  setPricingType(
                    pricingType.id !==
                      Constants.Billing.SubscriptionTypes.yearly.id
                      ? Constants.Billing.SubscriptionTypes.yearly
                      : Constants.Billing.SubscriptionTypes.monthly
                  );
                }}
              />
              <Form.Check.Label>
                <FormattedMessage
                  id={`app.common.${Constants.Billing.SubscriptionTypes.yearly.id}`}
                />
              </Form.Check.Label>
            </Form.Check>
          </div>
        )}
      <Row className="justify-content-center">
        {subscriptionPlans.map((item) => {
          const {
            id,
            apiKey,
            prices,
            isBilledYearly,
            paymentLink,
            currency,
            forceMonthlyPrice,
          } = item;

          return (
            <Col lg="4" key={id}>
              <Card
                className={`card-lg form-check form-check-select-stretched h-100 zi-1 ${
                  pack.name === apiKey ? 'checked' : ''
                }`}
              >
                <Card.Header className="text-center">
                  {pack.name === apiKey && (
                    <>
                      <input
                        type="radio"
                        className="form-check-input"
                        name="billingPricingRadio"
                        id={apiKey}
                        value={apiKey}
                        defaultChecked
                      />
                      <label className="form-check-label" htmlFor={apiKey} />
                    </>
                  )}
                  <Card.Subtitle as="span">
                    <FormattedMessage
                      id={`app.subscriptionPlans.${id}.title`}
                    />
                  </Card.Subtitle>
                  <Card.Title as="h2" className="display-3 text-dark">
                    <FormattedNumber
                      value={
                        forceMonthlyPrice
                          ? prices.monthly
                          : prices[pricingType.id]
                      }
                      // eslint-disable-next-line react/style-prop-object
                      style="currency"
                      currency={currency}
                      maximumFractionDigits={0}
                    />
                    <span className="fs-6 text-muted ms-1">
                      /
                      <span className="ms-1 text-lowercase">
                        <FormattedMessage
                          id={`app.common.${
                            forceMonthlyPrice
                              ? Constants.Billing.SubscriptionTypes.monthly
                                  .short
                              : pricingType.short
                          }`}
                        />
                      </span>
                    </span>
                  </Card.Title>
                  {isBilledYearly && (
                    <Card.Text>
                      <FormattedMessage id="app.common.billedYearly" />
                    </Card.Text>
                  )}
                </Card.Header>
                <Card.Body>
                  <FormattedHtml
                    id={`app.subscriptionPlans.${id}.info`}
                    values={{ ul: getUl, li: getLiWithCheck, i: getIcon }}
                  />
                </Card.Body>
                <Card.Footer className="border-0 text-center">
                  <div className="d-grid mb-2">
                    {pack.name === apiKey ? (
                      <Button
                        variant="primary"
                        className="form-check-select-stretched-btn"
                      >
                        <FormattedMessage id="app.common.currentPlan" />
                      </Button>
                    ) : (
                      <Button
                        variant="white"
                        className="form-check-select-stretched-btn"
                        as={Link}
                        to={paymentLink}
                        target="_blank"
                      >
                        <FormattedMessage id="app.common.selectPlan" />
                      </Button>
                    )}
                  </div>
                </Card.Footer>
              </Card>
            </Col>
          );
        })}
      </Row>
    </>
  );
}

export default SubscriptionPlansTable;
