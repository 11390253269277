import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Accordion, Card } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import {
  RequestLoading,
  RequestResult,
  SmartTable,
} from '../../../../components';
import Constants from '../../../../constants';
import { useAxiosQuery } from '../../../../hooks';

function InfoPropertyDataCard({ id }) {
  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/Rel_homes_Details/list',
    preventFetch: !id,
    params: {
      IDHomes: id,
    },
    select: (data) => {
      const modifiedData = data.reduce((acc, row) => {
        const { valueKey, ...rest } = row;
        acc[valueKey] = acc[valueKey] || [];
        acc[valueKey].push(rest);
        return acc;
      }, {});

      console.log(modifiedData);

      return modifiedData;
    },
  });

  const columns = [
    {
      Header: <FormattedMessage id="app.common.Data" />,
      accessor: 'valueKey',
      Cell: useCallback(({ cell: { value } }) => {
        const icon = Constants.RealEstate.dataIcons[value];
        return (
          <>
            {icon && (
              <i
                className={`${icon.icon} me-1`}
                style={{ color: icon.color }}
              />
            )}
            {value}
          </>
        );
      }, []),
    },
    {
      Header: <FormattedMessage id="app.common.value" />,
      accessor: 'value',
    },
  ];

  return (
    <Card>
      <Card.Header>
        <Card.Title bsPrefix="card-header-title" as="h4">
          <FormattedMessage id="app.common.details" />
        </Card.Title>
      </Card.Header>
      <RequestLoading loading={apiLoading} size="lg" margin="5" />
      <RequestResult type="error" message={apiError} />

      {!apiLoading && !apiError && Object.keys(apiData)?.length > 0 && (
        <Accordion defaultActiveKey="0">
          {Object.keys(apiData).map((key, index) => (
            <Accordion.Item
              key={`${index.toString()}`}
              eventKey={`${index.toString()}`}
              className="rounded-0"
            >
              <Accordion.Header>{key}</Accordion.Header>
              <Accordion.Body>
                <div className="pt-2">
                  {apiData[key].map(({ value }) => value).join(', ')}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      )}
      <div className="d-none">
        {id && (
          <SmartTable
            columns={columns}
            requestUrl="/Rel_homes_Details/list"
            requestParams={{ IDHomes: id }}
          />
        )}
      </div>
    </Card>
  );
}

InfoPropertyDataCard.propTypes = {
  id: PropTypes.string.isRequired,
};

export default InfoPropertyDataCard;
