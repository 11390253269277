import React, { useContext, useState } from 'react';
import { Card, Image } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { AuthContext } from '../../../../context/AuthContext';
import DefaultProfileImage from '../../../../assets/images/default-profile-img.jpg';
import DefaultProfileHeaderImage from '../../../../assets/images/default-profile-header-img.jpg';
import { useAxiosMutation } from '../../../../hooks';
import { FormImageInput, Tooltip } from '../../../../components';
import Utils from '../../../../utils';

function SettingsPhoto() {
  const { formatMessage } = useIntl();
  const { user, update } = useContext(AuthContext);
  const [file, setFile] = useState(user?.photo);

  const {
    isLoading: apiLoading,
    error: apiError,
    mutate: apiFetch,
  } = useAxiosMutation({
    url: '/users/uploadPhoto',
    params: { id: user.id },
  });

  const uploadFile = (f) => {
    const req = Utils.Object.toFormdata({ file: f || null });
    apiFetch(req, {
      onSuccess: (data) => {
        update({ user: { ...user, photo: data.photo } });
        setFile(data.photo);
      },
    });
  };

  const handleFileChange = (f) => {
    if (f) {
      uploadFile(f);
      setFile(f);
    } else {
      uploadFile();
      setFile(null);
    }
  };

  return (
    <Card className="mb-4">
      <div className="profile-cover">
        <div className="profile-cover-img-wrapper">
          <Image
            src={DefaultProfileHeaderImage}
            className="profile-cover-img"
          />
        </div>
      </div>
      <FormImageInput
        variant="circle"
        className="profile-cover-avatar"
        value={file}
        onChange={handleFileChange}
        objectFit="cover"
        placeholder={DefaultProfileImage}
        disabled={apiLoading}
        hideControls={!!apiError}
      >
        <div>
          {apiLoading && !apiError && (
            <span
              className="avatar-uploader-trigger d-flex align-items-center justify-content-center bg-primary"
              style={{ width: 38.5, height: 38.5 }}
            >
              <span className="spinner-border w-50 h-50 text-white" />
            </span>
          )}
          {!apiLoading && apiError && (
            <span
              role="button"
              tabIndex={0}
              aria-hidden="true"
              onClick={() => {
                uploadFile(file);
              }}
              className="avatar-uploader-trigger"
            >
              <Tooltip
                content={formatMessage({ id: 'app.common.somethingWentWrong' })}
                placement="right"
              >
                <i className="bi-exclamation-lg avatar-uploader-icon shadow-sm bg-danger text-white" />
              </Tooltip>
            </span>
          )}
        </div>
      </FormImageInput>
    </Card>
  );
}

export default SettingsPhoto;
