import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Dropdown, Card, Button, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { kebabCase } from 'lodash';
import { ProfileContext } from '../context/ProfileContext';
import { Routes as AppRoutes } from '../router/routeMapping';

function DropdownFooter() {
  return (
    <Dropdown.Item
      as={Link}
      to={AppRoutes.protected.AI.children.MYPROFILES.path}
      className="text-center"
    >
      <FormattedMessage id="app.common.addNewProfile" />
    </Dropdown.Item>
  );
}

function NavbarProfileSelect({ variant, isMobile, ...props }) {
  const { profiles, activeProfile, updateActiveProfile } =
    useContext(ProfileContext) || {};

  const onChange = (v) => {
    const founded = profiles.find((item) => item.id === v.id);
    updateActiveProfile(founded);
  };

  return (
    <div {...props}>
      {activeProfile && (
        <Dropdown>
          {isMobile ? (
            <Dropdown.Toggle
              variant="light"
              size="sm"
              className="w-100 mt-3"
              /* onClick={() => {
                setShowOnMobile(true);
              }} */
            >
              <span className="d-inline-block text-truncate text-start w-100">
                {activeProfile?.name}
              </span>
            </Dropdown.Toggle>
          ) : (
            <Dropdown.Toggle variant="light">
              <span
                className="d-inline-block text-truncate text-start"
                style={{ width: '14rem' }}
              >
                {activeProfile?.name}
              </span>
            </Dropdown.Toggle>
          )}

          <Dropdown.Menu
            renderOnMount
            rootCloseEvent="mousedown"
            className={`dropdown-card navbar-dropdown-menu-borderless ${
              isMobile ? 'w-100' : ''
            }`}
          >
            {/* <div className="d-flex justify-content-end d-lg-none mb-2">
              <Button
                variant=""
                size="sm"
                className="btn-icon me-2"
                onClick={() => {
                  setShowOnMobile(false);
                }}
              >
                <i className="bi-x-lg" />
              </Button>
            </div> */}
            <div className="card-body-height">
              {profiles.map((item, index) => (
                <Dropdown.Item
                  as="div"
                  key={item.id}
                  active={activeProfile.id === item.id}
                  className={`m-0 rounded-0 ${index === 0 && 'rounded-top'}`}
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <Button
                      variant="link"
                      className="flex-grow-1 me-2 text-start p-1"
                      style={{ maxWidth: '16rem' }}
                      onClick={() => {
                        if (activeProfile.id === item.id) {
                          return;
                        }
                        onChange(item);
                      }}
                    >
                      <h5 className="d-flex align-items-start text-inherit mb-0">
                        <span className="text-wrap">{item.name}</span>
                        {activeProfile.id === item.id && (
                          <Badge
                            bg="soft-success"
                            className="ms-1 text-success"
                          >
                            <FormattedMessage id="app.common.active" />
                          </Badge>
                        )}
                      </h5>
                      <span className="d-inline-block text-body small text-truncate w-100">
                        {item.description}
                      </span>
                    </Button>
                    {!item.locked && (
                      <div>
                        <Button
                          as={Link}
                          size="xs"
                          to={AppRoutes.protected.AI_DETAIL.children.INFO.path.replace(
                            ':id',
                            kebabCase(item.id)
                          )}
                        >
                          <i className="bi-pencil me-1" />
                          <FormattedMessage id="app.common.edit" />
                        </Button>
                      </div>
                    )}
                  </div>
                </Dropdown.Item>
              ))}
            </div>
            <Card.Footer className="p-3">
              <Dropdown.Item as={DropdownFooter} />
            </Card.Footer>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </div>
  );
}

NavbarProfileSelect.propTypes = {
  variant: PropTypes.oneOf(['primary', 'light']),
  isMobile: PropTypes.bool,
};

NavbarProfileSelect.defaultProps = {
  variant: null,
  isMobile: false,
};

export default NavbarProfileSelect;
