const schoolScore = [
  {
    name: '1',
    abbreviation: '1',
  },
  {
    name: '2',
    abbreviation: '2',
  },
  {
    name: '3',
    abbreviation: '3',
  },
  {
    name: '4',
    abbreviation: '4',
  },
  {
    name: '5',
    abbreviation: '5',
  },
  {
    name: '6',
    abbreviation: '6',
  },
  {
    name: '7',
    abbreviation: '7',
  },
  {
    name: '8',
    abbreviation: '8',
  },
  {
    name: '9',
    abbreviation: '9',
  },
  {
    name: '10',
    abbreviation: '10',
  },
  {
    name: 'N/A',
    abbreviation: '',
  },
];

export default schoolScore;
