import React from 'react';
import { useParams } from 'react-router-dom';
import { SmartCalendar } from '../../../components';

function Calendar() {
  const { id } = useParams();

  return (
    <SmartCalendar
      paths={{
        list: '/calendar/list',
        listParams: { RefID: id },
        create: '/calendar/insert',
        createParams: { RefID: id },
        delete: '/calendar/delete',
        update: '/calendar/update',
      }}
      params={{ RefTable: 'realestateusa.owner' }}
      // withCategoriesFilter={{ requestUrl: '/categories/list' }}
    />
  );
}

export default Calendar;
