import React from 'react';
import { Row, Col, Card, Badge, ProgressBar } from 'react-bootstrap';
import { useIntl, FormattedMessage, FormattedNumber } from 'react-intl';
import { useParams } from 'react-router-dom';
import { EconomicIndicators } from '../../../constants/realEstate';
import { RequestLoading, RequestResult, Tooltip } from '../../../components';
import { useAxiosQuery } from '../../../hooks';
import WordCloud from '../ListingsDetail/partials/WordCloud';
import LineChartCard from '../ListingsDetail/partials/LineChartCard';
import LineChartCardHomesSold from '../ListingsDetail/partials/LineChartCardHomesSold';
import LineChartCardMedianDayOnMarket from '../ListingsDetail/partials/LineChartCardMedianDayOnMarket';
import LineChartCardMarketRents from '../ListingsDetail/partials/LineChartCardMarketRents';
import DoughnutChartCard from '../ListingsDetail/partials/DoughnutChartCard';
import DoughnutChartCardRace from '../ListingsDetail/partials/DoughnutChartCardRace';
import HalfDoughnutChartCard from '../ListingsDetail/partials/HalfDoughnutChartCard';
import HalfDoughnutChartCardLivingSqFt from '../ListingsDetail/partials/HalfDoughnutChartCardLivingSqFt';
import HalfDoughnutChartCardOccupiedBy from '../ListingsDetail/partials/HalfDoughnutChartCardOccupiedBy';
import DoughnutChartCardYearBuilt from '../ListingsDetail/partials/DoughnutChartCardYearBuilt';
import DoughnutChartCardPropertyType from '../ListingsDetail/partials/DoughnutChartCardPropertyType';
import DoughnutChartCardBathroom from '../ListingsDetail/partials/DoughnutChartCardBathroom';
import DoughnutChartCardBedroom from '../ListingsDetail/partials/DoughnutChartCardBedroom';

import HalfDoughnutChartCardVoting from '../ListingsDetail/partials/HalfDoughnutChartCardVoting';
import HalfDoughnutChartCardGender from '../ListingsDetail/partials/HalfDoughnutChartCardGender';

import HalfDoughnutChartCardOwnerType from '../ListingsDetail/partials/HalfDoughnutChartCardOwnerType';
import HalfDoughnutChartCardEquity from '../ListingsDetail/partials/HalfDoughnutChartCardEquity';

import LineChartCardSaleToListPrice from '../ListingsDetail/partials/LineChartCardSaleToListPrice';
import LineChartCardHomeSoldAboveList from '../ListingsDetail/partials/LineChartCardHomeSoldAboveList';

function City() {
  const { zip } = useParams();
  const { formatMessage } = useIntl();
  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/bestplaces/get_city_by_zip',
    preventFetch: !zip,
    params: {
      zip,
    },
  });
  const getProgressVariant = (v) => {
    if (v >= 76 && v <= 100) {
      return 'danger';
    }
    if (v >= 50 && v <= 75) {
      return 'warning';
    }
    if (v >= 0 && v <= 49) {
      return 'info';
    }

    throw new Error(`Geçersiz değer: ${v}`);
  };
  function getCompetitiveness(value) {
    const result = {
      text: 'N/A',
      color: 'info',
    };

    switch (value) {
      case 'Not Very Competitive':
        result.text =
          'Homes sit longer on the market with reduced buyer interest.';
        result.color = 'info';
        break;
      case 'Somewhat Competitive':
        result.text = 'Homes sell at or slightly below list prices.';
        result.color = 'warning';
        break;
      case 'Most Competitive':
        result.text =
          'Buyers vastly outnumber listings, leading to bidding wars.';
        result.color = 'danger';
        break;
      case 'Very Competitive':
        result.text = 'homes selling quickly, often at listing prices.';
        result.color = 'danger';
        break;
      default:
        break;
    }

    return result;
  }
  function getRiskColor(riskLevel) {
    switch (riskLevel) {
      case 'Extreme':
        return 'danger';
      case 'Major':
        return 'danger';
      case 'Minor':
        return 'warning';
      case 'Moderate':
        return 'success';
      case 'Severe':
        return 'danger';
      default:
        return 'info';
    }
  }

  return (
    <>
      <RequestLoading loading={apiLoading} size="lg" margin="5" />
      <RequestResult type="error" message={apiError} />

      {!apiLoading && !apiError && apiData?.length > 0 && (
        <Row>
          <Col xs="12">
            <h3 className="mb-3">
              <FormattedMessage id="app.common.overview" />
            </h3>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle as="h6">
                  <FormattedMessage id="app.common.zip" />
                </Card.Subtitle>
                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].zip}
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.city" />
                </Card.Subtitle>
                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].citylabel}
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle as="h6">
                  <FormattedMessage id="app.common.country" />
                </Card.Subtitle>
                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].country}
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle as="h6">
                  <FormattedMessage id="app.common.state" />
                </Card.Subtitle>
                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].state}
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.population" />
                  <Tooltip
                    content={formatMessage({ id: 'app.toolTips.population' })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>
                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].population}
                </Card.Title>
                <Badge bg="soft-success" text="success">
                  <i className="bi-graph-up me-1" />
                  {apiData[0].PercentPopulationChange}
                </Badge>
                <span className="text-body fs-6 ms-1">
                  {apiData[0].PopulationYear}
                </span>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.costOfLiving" />
                  <Tooltip
                    content={formatMessage({ id: 'app.toolTips.costOfLiving' })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>
                <Card.Title as="h2" className="text-inherit">
                  <FormattedNumber
                    value={apiData[0].costOfLiving}
                    // eslint-disable-next-line react/style-prop-object

                    minimumFractionDigits={0}
                    maximumFractionDigits={0}
                  />
                  %
                </Card.Title>
                <Badge
                  bg={`soft-${
                    Number(apiData[0].costOfLiving) <
                    EconomicIndicators.US_AVERAGE_COST_OF_LIVING
                      ? 'success'
                      : 'danger'
                  }`}
                  text={`${
                    Number(apiData[0].costOfLiving) <
                    EconomicIndicators.US_AVERAGE_COST_OF_LIVING
                      ? 'success'
                      : 'danger'
                  }`}
                >
                  The US average is&nbsp;
                  <FormattedNumber
                    value={EconomicIndicators.US_AVERAGE_COST_OF_LIVING}
                    // eslint-disable-next-line react/style-prop-object

                    minimumFractionDigits={0}
                    maximumFractionDigits={0}
                  />
                  %
                </Badge>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.medianHouseHoldIncome" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.medianHouseHoldIncome',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>
                <Card.Title as="h2" className="text-inherit">
                  <FormattedNumber
                    value={apiData[0].medianIncome}
                    // eslint-disable-next-line react/style-prop-object
                    style="currency"
                    currency="USD"
                    minimumFractionDigits={0}
                    maximumFractionDigits={0}
                  />
                </Card.Title>
                <Badge
                  bg={`soft-${
                    Number(apiData[0].medianIncome) >
                    EconomicIndicators.US_AVERAGE
                      ? 'success'
                      : 'danger'
                  }`}
                  text={`${
                    Number(apiData[0].medianIncome) >
                    EconomicIndicators.US_AVERAGE
                      ? 'success'
                      : 'danger'
                  }`}
                >
                  The US average is&nbsp;
                  <FormattedNumber
                    value={EconomicIndicators.US_AVERAGE}
                    // eslint-disable-next-line react/style-prop-object
                    style="currency"
                    currency="USD"
                    minimumFractionDigits={0}
                    maximumFractionDigits={0}
                  />
                  &nbsp;a year.
                </Badge>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.unemploymentRate" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.unemploymentRate',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>
                <Card.Title as="h2" className="text-inherit">
                  {`${apiData[0].unemploymentRate}%`}
                </Card.Title>
                <Badge
                  bg={`soft-${
                    Number(apiData[0].unemploymentRate) <
                    EconomicIndicators.US_UNEMPLOYMENT_RATE
                      ? 'success'
                      : 'danger'
                  }`}
                  text={`${
                    Number(apiData[0].unemploymentRate) <
                    EconomicIndicators.US_UNEMPLOYMENT_RATE
                      ? 'success'
                      : 'danger'
                  }`}
                >
                  {`The US average is ${EconomicIndicators.US_UNEMPLOYMENT_RATE}%`}
                </Badge>
              </Card.Body>
            </Card>
          </Col>
          {apiData[0].zip && (
            <>
              <Col lg="6" className="mb-3 mb-lg-5">
                <DoughnutChartCard zip={apiData[0].zip} />
              </Col>
              <Col lg="6" className="mb-3 mb-lg-5">
                <WordCloud zip={apiData[0].zip} />
              </Col>
              <Col xs="12">
                <h3 className="mb-3">
                  <FormattedMessage id="app.common.housingStats" />
                </h3>
              </Col>
              <Col md="6" lg="3" className="mb-3 mb-lg-5">
                <Card className="card-hover-shadow h-100">
                  <Card.Body>
                    <Card.Subtitle
                      as="h6"
                      className="d-flex justify-content-between"
                    >
                      <FormattedMessage id="app.common.medianHomePrice" />
                      <Tooltip
                        content={formatMessage({
                          id: 'app.toolTips.medianHomePrice',
                        })}
                      >
                        <i className="bi-info-circle-fill" />
                      </Tooltip>
                    </Card.Subtitle>

                    <Card.Title as="h2" className="text-inherit">
                      <FormattedNumber
                        value={apiData[0].medianHomePrice}
                        // eslint-disable-next-line react/style-prop-object
                        style="currency"
                        currency="USD"
                        minimumFractionDigits={0}
                        maximumFractionDigits={0}
                      />
                    </Card.Title>
                    <Badge
                      bg={`soft-${
                        Number(apiData[0].medianHomePrice) <
                        EconomicIndicators.US_UMEDIAN_HOME_PRICE
                          ? 'success'
                          : 'danger'
                      }`}
                      text={`${
                        Number(apiData[0].medianHomePrice) <
                        EconomicIndicators.US_UMEDIAN_HOME_PRICE
                          ? 'success'
                          : 'danger'
                      }`}
                    >
                      The US average is&nbsp;
                      <FormattedNumber
                        value={EconomicIndicators.US_UMEDIAN_HOME_PRICE}
                        // eslint-disable-next-line react/style-prop-object
                        style="currency"
                        currency="USD"
                        minimumFractionDigits={0}
                        maximumFractionDigits={0}
                      />
                      .
                    </Badge>
                  </Card.Body>
                </Card>
              </Col>
              <Col md="6" lg="3" className="mb-3 mb-lg-5">
                <Card className="card-hover-shadow h-100">
                  <Card.Body>
                    <Card.Subtitle
                      as="h6"
                      className="d-flex justify-content-between"
                    >
                      <FormattedMessage id="app.common.averageAgeOfHomes" />
                      <Tooltip
                        content={formatMessage({
                          id: 'app.toolTips.averageAgeOfHomes',
                        })}
                      >
                        <i className="bi-info-circle-fill" />
                      </Tooltip>
                    </Card.Subtitle>

                    <Card.Title as="h2" className="text-inherit">
                      {apiData[0].homeYearsOld}
                    </Card.Title>
                    <Badge
                      bg={`soft-${
                        Number(apiData[0].homeYearsOld) <
                        EconomicIndicators.US_AVERAGE_HOME_AGE
                          ? 'success'
                          : 'danger'
                      }`}
                      text={`${
                        Number(apiData[0].homeYearsOld) <
                        EconomicIndicators.US_AVERAGE_HOME_AGE
                          ? 'success'
                          : 'danger'
                      }`}
                    >
                      {`The US average is ${EconomicIndicators.US_AVERAGE_HOME_AGE}.`}
                    </Badge>
                  </Card.Body>
                </Card>
              </Col>
              <Col md="6" lg="3" className="mb-3 mb-lg-5">
                <Card className="card-hover-shadow h-100">
                  <Card.Body>
                    <Card.Subtitle
                      as="h6"
                      className="d-flex justify-content-between"
                    >
                      <FormattedMessage id="app.common.rentersPercent" />
                      <Tooltip
                        content={formatMessage({
                          id: 'app.toolTips.rentersPercent',
                        })}
                      >
                        <i className="bi-info-circle-fill" />
                      </Tooltip>
                    </Card.Subtitle>

                    <Card.Title as="h2" className="text-inherit">
                      {apiData[0].rentersPercent}%
                    </Card.Title>
                    <Badge bg="soft-info" text="info">
                      <i className="bi-info-circle-fill me-1" />
                      {`The US average is ${EconomicIndicators.US_AVERAGE_RENTERS_PERCENT}.%`}
                    </Badge>
                  </Card.Body>
                </Card>
              </Col>
              <Col md="6" lg="3" className="mb-3 mb-lg-5">
                <Card className="card-hover-shadow h-100">
                  <Card.Body>
                    <Card.Subtitle
                      as="h6"
                      className="d-flex justify-content-between"
                    >
                      <FormattedMessage id="app.common.medianRentPrice" />
                      <Tooltip
                        content={formatMessage({
                          id: 'app.toolTips.medianRentPrice',
                        })}
                      >
                        <i className="bi-info-circle-fill" />
                      </Tooltip>
                    </Card.Subtitle>

                    <Card.Title as="h2" className="text-inherit">
                      <FormattedNumber
                        value={apiData[0].MedianRentPrice}
                        // eslint-disable-next-line react/style-prop-object
                        style="currency"
                        currency="USD"
                        minimumFractionDigits={0}
                        maximumFractionDigits={0}
                      />
                    </Card.Title>
                    <Badge
                      bg={`soft-${
                        Number(apiData[0].MedianRentPrice) >
                        EconomicIndicators.US_UMEDIAN_RENT_PRICE
                          ? 'success'
                          : 'danger'
                      }`}
                      text={`${
                        Number(apiData[0].MedianRentPrice) >
                        EconomicIndicators.US_UMEDIAN_RENT_PRICE
                          ? 'success'
                          : 'danger'
                      }`}
                    >
                      The US average is&nbsp;
                      <FormattedNumber
                        value={EconomicIndicators.US_UMEDIAN_RENT_PRICE}
                        // eslint-disable-next-line react/style-prop-object
                        style="currency"
                        currency="USD"
                        minimumFractionDigits={0}
                        maximumFractionDigits={0}
                      />
                      .
                    </Badge>
                  </Card.Body>
                </Card>
              </Col>{' '}
              <Col md="6" lg="3" className="mb-3 mb-lg-5">
                <Card className="card-hover-shadow h-100">
                  <Card.Body>
                    <Card.Subtitle
                      as="h6"
                      className="d-flex justify-content-between"
                    >
                      <FormattedMessage id="app.common.homeAppreciation" />
                      <Tooltip
                        content={formatMessage({
                          id: 'app.toolTips.homeAppreciation',
                        })}
                      >
                        <i className="bi-info-circle-fill" />
                      </Tooltip>
                    </Card.Subtitle>

                    <Card.Title as="h2" className="text-inherit">
                      {apiData[0].homeAppreciation}%
                    </Card.Title>
                    <Badge
                      bg={`soft-${
                        Number(apiData[0].homeAppreciation) >
                        EconomicIndicators.US_AVERAGE_HOME_APPRECIATION
                          ? 'success'
                          : 'danger'
                      }`}
                      text={`${
                        Number(apiData[0].homeAppreciation) >
                        EconomicIndicators.US_AVERAGE_HOME_APPRECIATION
                          ? 'success'
                          : 'danger'
                      }`}
                    >
                      {`The US average is ${EconomicIndicators.US_AVERAGE_HOME_APPRECIATION}%.`}
                    </Badge>
                  </Card.Body>
                </Card>
              </Col>{' '}
              <Col md="6" lg="3" className="mb-3 mb-lg-5">
                <Card className="card-hover-shadow h-100">
                  <Card.Body>
                    <Card.Subtitle
                      as="h6"
                      className="d-flex justify-content-between"
                    >
                      <FormattedMessage id="app.common.homeAppreciationLast5years" />
                      <Tooltip
                        content={formatMessage({
                          id: 'app.toolTips.homeAppreciationLast5years',
                        })}
                      >
                        <i className="bi-info-circle-fill" />
                      </Tooltip>
                    </Card.Subtitle>

                    <Card.Title as="h2" className="text-inherit">
                      {apiData[0].HomeAppreciationLast5years}%
                    </Card.Title>
                    <Badge
                      bg={`soft-${
                        Number(apiData[0].HomeAppreciationLast5years) >
                        EconomicIndicators.US_AVERAGE_HOME_APPRECIATION_LAST5
                          ? 'success'
                          : 'danger'
                      }`}
                      text={`${
                        Number(apiData[0].HomeAppreciationLast5years) >
                        EconomicIndicators.US_AVERAGE_HOME_APPRECIATION_LAST5
                          ? 'success'
                          : 'danger'
                      }`}
                    >
                      {`The US average is ${EconomicIndicators.US_AVERAGE_HOME_APPRECIATION_LAST5}%.`}
                    </Badge>
                  </Card.Body>
                </Card>
              </Col>{' '}
              <Col md="6" lg="3" className="mb-3 mb-lg-5">
                <Card className="card-hover-shadow h-100">
                  <Card.Body>
                    <Card.Subtitle
                      as="h6"
                      className="d-flex justify-content-between"
                    >
                      <FormattedMessage id="app.common.homeAppreciationLast10years" />
                      <Tooltip
                        content={formatMessage({
                          id: 'app.toolTips.homeAppreciationLast10years',
                        })}
                      >
                        <i className="bi-info-circle-fill" />
                      </Tooltip>
                    </Card.Subtitle>

                    <Card.Title as="h2" className="text-inherit">
                      {apiData[0].HomeAppreciationLast10years}%
                    </Card.Title>
                    <Badge
                      bg={`soft-${
                        Number(apiData[0].HomeAppreciationLast10years) >
                        EconomicIndicators.US_AVERAGE_HOME_APPRECIATION_LAST10
                          ? 'success'
                          : 'danger'
                      }`}
                      text={`${
                        Number(apiData[0].HomeAppreciationLast10years) >
                        EconomicIndicators.US_AVERAGE_HOME_APPRECIATION_LAST10
                          ? 'success'
                          : 'danger'
                      }`}
                    >
                      {`The US average is ${EconomicIndicators.US_AVERAGE_HOME_APPRECIATION_LAST10}%.`}
                    </Badge>
                  </Card.Body>
                </Card>
              </Col>
              <Col md="6" lg="3" className="mb-3 mb-lg-5">
                <Card className="card-hover-shadow h-100">
                  <Card.Body>
                    <Card.Subtitle
                      as="h6"
                      className="d-flex justify-content-between"
                    >
                      <FormattedMessage id="app.common.housesAndApartmens" />
                      <Tooltip
                        content={formatMessage({
                          id: 'app.toolTips.housesAndApartmens',
                        })}
                      >
                        <i className="bi-info-circle-fill" />
                      </Tooltip>
                    </Card.Subtitle>

                    <Card.Title as="h2" className="text-inherit">
                      {apiData[0].housesAndApartmens}%
                    </Card.Title>
                    <Badge bg="soft-info" text="info">
                      <i className="bi-info-circle-fill me-1" />
                      houses and apartments are are available to rent
                    </Badge>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="6" className="mb-3 mb-lg-5">
                <HalfDoughnutChartCardLivingSqFt zip={apiData[0].zip} />
              </Col>
              <Col lg="6" className="mb-3 mb-lg-5">
                <HalfDoughnutChartCardOccupiedBy zip={apiData[0].zip} />
              </Col>
              <Col lg="6" className="mb-3 mb-lg-5">
                <DoughnutChartCardYearBuilt zip={apiData[0].zip} />
              </Col>{' '}
              <Col lg="6" className="mb-3 mb-lg-5">
                <DoughnutChartCardPropertyType zip={apiData[0].zip} />
              </Col>
              <Col lg="6" className="mb-3 mb-lg-5">
                <HalfDoughnutChartCardOwnerType zip={apiData[0].zip} />
              </Col>
              <Col lg="6" className="mb-3 mb-lg-5">
                <HalfDoughnutChartCardEquity zip={apiData[0].zip} />
              </Col>
              <Col lg="6" className="mb-3 mb-lg-5">
                <DoughnutChartCardBedroom zip={apiData[0].zip} />
              </Col>
              <Col lg="6" className="mb-3 mb-lg-5">
                <DoughnutChartCardBathroom zip={apiData[0].zip} />
              </Col>
              <Col xs="12">
                <h3 className="mb-3">
                  <FormattedMessage id="app.common.marketInsights" />
                </h3>
              </Col>
              <Col lg="6" className="mb-3 mb-lg-5">
                <LineChartCard zip={apiData[0].zip} />
              </Col>
              <Col lg="6" className="mb-3 mb-lg-5">
                <LineChartCardMarketRents zip={apiData[0].zip} />
              </Col>
              <Col lg="6" className="mb-3 mb-lg-5">
                <LineChartCardHomesSold zip={apiData[0].zip} />
              </Col>
              <Col lg="6" className="mb-3 mb-lg-5">
                <LineChartCardMedianDayOnMarket zip={apiData[0].zip} />
              </Col>
              <Col xs="12">
                <h3 className="mb-3">
                  <FormattedMessage id="app.common.Demand" />
                </h3>
              </Col>
              <Col md="6" lg="3" className="mb-3 mb-lg-5">
                <Card className="card-hover-shadow h-100">
                  <Card.Body>
                    <Card.Subtitle
                      as="h6"
                      className="d-flex justify-content-between"
                    >
                      <FormattedMessage id="app.common.score" />
                      <Tooltip
                        content={formatMessage({
                          id: 'app.toolTips.score',
                        })}
                      >
                        <i className="bi-info-circle-fill" />
                      </Tooltip>
                    </Card.Subtitle>

                    <Card.Title as="h2" className="text-inherit">
                      <ProgressBar
                        className="table-progress"
                        variant={getProgressVariant(
                          apiData[0].competitive_score
                        )}
                        now={apiData[0].competitive_score}
                      />
                      {apiData[0].competitive_score}
                    </Card.Title>
                    <Badge
                      bg={`soft-${getProgressVariant(
                        apiData[0].competitive_score
                      )}`}
                      text={`${getProgressVariant(
                        apiData[0].competitive_score
                      )}`}
                    >
                      <i className="bi-info-circle-fill me-1" />
                      {apiData[0].competitive_title}
                    </Badge>
                  </Card.Body>
                </Card>
              </Col>
              <Col md="6" lg="3" className="mb-3 mb-lg-5">
                <Card className="card-hover-shadow h-100">
                  <Card.Body>
                    <Card.Subtitle
                      as="h6"
                      className="d-flex justify-content-between"
                    >
                      <FormattedMessage id="app.common.competitiveness" />
                      <Tooltip
                        content={formatMessage({
                          id: 'app.toolTips.competitiveness',
                        })}
                      >
                        <i className="bi-info-circle-fill" />
                      </Tooltip>
                    </Card.Subtitle>

                    <Card.Title as="h2" className="text-inherit">
                      {apiData[0].competitive_title}
                    </Card.Title>

                    <Badge
                      bg={`soft-${
                        getCompetitiveness(apiData[0].competitive_title).color
                      }`}
                      text={`${
                        getCompetitiveness(apiData[0].competitive_title).color
                      }`}
                    >
                      <i className="bi-info-circle-fill me-1" />
                      {getCompetitiveness(apiData[0].competitive_title).text}
                    </Badge>
                  </Card.Body>
                </Card>
              </Col>
              <Col md="6" lg="3" className="mb-3 mb-lg-5">
                <Card className="card-hover-shadow h-100">
                  <Card.Body>
                    <Card.Subtitle
                      as="h6"
                      className="d-flex justify-content-between"
                    >
                      <FormattedMessage id="app.common.averageSellingDay" />
                      <Tooltip
                        content={formatMessage({
                          id: 'app.toolTips.averageSellingDay',
                        })}
                      >
                        <i className="bi-info-circle-fill" />
                      </Tooltip>
                    </Card.Subtitle>

                    <Card.Title as="h2" className="text-inherit">
                      {apiData[0].average_selling_day}
                    </Card.Title>
                    <Badge bg="soft-danger" text="danger">
                      <i className="bi-fire me-1" />
                      The hot homes go pending in around{' '}
                      {apiData[0].hot_average_selling_day} days
                    </Badge>
                  </Card.Body>
                </Card>
              </Col>
              <Col md="6" lg="3" className="mb-3 mb-lg-5">
                <Card className="card-hover-shadow h-100">
                  <Card.Body>
                    <Card.Subtitle
                      as="h6"
                      className="d-flex justify-content-between"
                    >
                      <FormattedMessage id="app.common.averagePriceChange" />
                      <Tooltip
                        content={formatMessage({
                          id: 'app.toolTips.averagePriceChange',
                        })}
                      >
                        <i className="bi-info-circle-fill" />
                      </Tooltip>
                    </Card.Subtitle>

                    <Card.Title as="h2" className="text-inherit">
                      {apiData[0].average_price_change}%
                    </Card.Title>
                    <Badge bg="soft-danger" text="danger">
                      <i className="bi-fire me-1" />
                      Hot homes can sell for about{' '}
                      {apiData[0].hot_average_price_change}% above list price
                    </Badge>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="6" className="mb-3 mb-lg-5">
                <LineChartCardSaleToListPrice zip={apiData[0].zip} />
              </Col>
              <Col lg="6" className="mb-3 mb-lg-5">
                <LineChartCardHomeSoldAboveList zip={apiData[0].zip} />
              </Col>
              <Col xs="12">
                <h3 className="mb-3">
                  <FormattedMessage id="app.common.airbnb_score" />
                </h3>{' '}
              </Col>
              <Col md="6" lg="3" className="mb-3 mb-lg-5">
                <Card className="card-hover-shadow h-100">
                  <Card.Body>
                    <Card.Subtitle
                      as="h6"
                      className="d-flex justify-content-between"
                    >
                      <FormattedMessage id="app.common.airbBNBscore" />
                      <Tooltip
                        content={formatMessage({
                          id: 'app.toolTips.airBNBscore',
                        })}
                      >
                        <i className="bi-info-circle-fill" />
                      </Tooltip>
                    </Card.Subtitle>

                    <Card.Title as="h2" className="text-inherit">
                      <ProgressBar
                        className="table-progress"
                        variant={getProgressVariant(apiData[0].airbnb_score)}
                        now={apiData[0].airbnb_score}
                      />
                      {apiData[0].airbnb_score}
                    </Card.Title>
                    <Badge
                      bg={`soft-${getProgressVariant(apiData[0].airbnb_score)}`}
                      text={`${getProgressVariant(apiData[0].airbnb_score)}`}
                    >
                      <i className="bi-info-circle-fill me-1" />
                      {apiData[0].airbnb_score}
                    </Badge>
                  </Card.Body>
                </Card>
              </Col>
              <Col md="6" lg="3" className="mb-3 mb-lg-5">
                <Card className="card-hover-shadow h-100">
                  <Card.Body>
                    <Card.Subtitle
                      as="h6"
                      className="d-flex justify-content-between"
                    >
                      <FormattedMessage id="app.common.annualRevenueProjection" />
                      <Tooltip
                        content={formatMessage({
                          id: 'app.toolTips.annualRevenueProjection',
                        })}
                      >
                        <i className="bi-info-circle-fill" />
                      </Tooltip>
                    </Card.Subtitle>
                    <Card.Title as="h2" className="text-inherit">
                      <FormattedNumber
                        value={apiData[0].airbnb_annual_revenue}
                        // eslint-disable-next-line react/style-prop-object
                        style="currency"
                        currency="USD"
                        minimumFractionDigits={0}
                        maximumFractionDigits={0}
                      />
                    </Card.Title>
                    <Badge
                      bg={`soft-${getProgressVariant(apiData[0].airbnb_score)}`}
                      text={`${getProgressVariant(apiData[0].airbnb_score)}`}
                    >
                      <i className="bi-info-circle-fill me-1" />
                      {apiData[0].airbnb_score}
                    </Badge>
                  </Card.Body>
                </Card>
              </Col>
              <Col md="6" lg="3" className="mb-3 mb-lg-5">
                <Card className="card-hover-shadow h-100">
                  <Card.Body>
                    <Card.Subtitle
                      as="h6"
                      className="d-flex justify-content-between"
                    >
                      <FormattedMessage id="app.common.averageOccupancyRate" />
                      <Tooltip
                        content={formatMessage({
                          id: 'app.toolTips.averageOccupancyRate',
                        })}
                      >
                        <i className="bi-info-circle-fill" />
                      </Tooltip>
                    </Card.Subtitle>

                    <Card.Title as="h2" className="text-inherit">
                      {apiData[0].airbnb_average_occupancy}%
                    </Card.Title>
                    <Badge
                      bg={`soft-${getProgressVariant(apiData[0].airbnb_score)}`}
                      text={`${getProgressVariant(apiData[0].airbnb_score)}`}
                    >
                      <i className="bi-info-circle-fill me-1" />
                      {apiData[0].airbnb_score}
                    </Badge>
                  </Card.Body>
                </Card>
              </Col>
              <Col md="6" lg="3" className="mb-3 mb-lg-5">
                <Card className="card-hover-shadow h-100">
                  <Card.Body>
                    <Card.Subtitle
                      as="h6"
                      className="d-flex justify-content-between"
                    >
                      <FormattedMessage id="app.common.averageDailyRate" />
                      <Tooltip
                        content={formatMessage({
                          id: 'app.toolTips.averageDailyRate',
                        })}
                      >
                        <i className="bi-info-circle-fill" />
                      </Tooltip>
                    </Card.Subtitle>

                    <Card.Title as="h2" className="text-inherit">
                      <FormattedNumber
                        value={apiData[0].airbnb_average_daily_rate}
                        // eslint-disable-next-line react/style-prop-object
                        style="currency"
                        currency="USD"
                        minimumFractionDigits={0}
                        maximumFractionDigits={0}
                      />
                    </Card.Title>
                    <Badge
                      bg={`soft-${getProgressVariant(apiData[0].airbnb_score)}`}
                      text={`${getProgressVariant(apiData[0].airbnb_score)}`}
                    >
                      <i className="bi-info-circle-fill me-1" />
                      {apiData[0].airbnb_score}
                    </Badge>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs="12">
                <h3 className="mb-3">
                  <FormattedMessage id="app.common.demography" />
                </h3>
              </Col>
              <Col md="6" lg="3" className="mb-3 mb-lg-5">
                <Card className="card-hover-shadow h-100">
                  <Card.Body>
                    <Card.Subtitle
                      as="h6"
                      className="d-flex justify-content-between"
                    >
                      <FormattedMessage id="app.common.collegeDegreeRate" />
                      <Tooltip
                        content={formatMessage({
                          id: 'app.toolTips.collegeDegreeRate',
                        })}
                      >
                        <i className="bi-info-circle-fill" />
                      </Tooltip>
                    </Card.Subtitle>

                    <Card.Title as="h2" className="text-inherit">
                      {apiData[0].college_degree_rate}%
                    </Card.Title>
                    <Badge
                      bg={`soft-${
                        Number(apiData[0].college_degree_rate) >
                        EconomicIndicators.US_AVERAGE_COLLEGE_DEGREE
                          ? 'success'
                          : 'danger'
                      }`}
                      text={`${
                        Number(apiData[0].college_degree_rate) >
                        EconomicIndicators.US_AVERAGE_COLLEGE_DEGREE
                          ? 'success'
                          : 'danger'
                      }`}
                    >
                      {`The US average is ${EconomicIndicators.US_AVERAGE_COLLEGE_DEGREE}%.`}
                    </Badge>
                  </Card.Body>
                </Card>
              </Col>
              <Col md="6" lg="3" className="mb-3 mb-lg-5">
                <Card className="card-hover-shadow h-100">
                  <Card.Body>
                    <Card.Subtitle
                      as="h6"
                      className="d-flex justify-content-between"
                    >
                      <FormattedMessage id="app.common.highSchoolRate" />
                      <Tooltip
                        content={formatMessage({
                          id: 'app.toolTips.highSchoolRate',
                        })}
                      >
                        <i className="bi-info-circle-fill" />
                      </Tooltip>
                    </Card.Subtitle>

                    <Card.Title as="h2" className="text-inherit">
                      {apiData[0].high_school_rate}%
                    </Card.Title>
                    <Badge
                      bg={`soft-${
                        Number(apiData[0].high_school_rate) >
                        EconomicIndicators.US_AVERAGE_HIGH_SCHOOL_RATE
                          ? 'success'
                          : 'danger'
                      }`}
                      text={`${
                        Number(apiData[0].high_school_rate) >
                        EconomicIndicators.US_AVERAGE_HIGH_SCHOOL_RATE
                          ? 'success'
                          : 'danger'
                      }`}
                    >
                      {`The US average is ${EconomicIndicators.US_AVERAGE_HIGH_SCHOOL_RATE}%.`}
                    </Badge>
                  </Card.Body>
                </Card>
              </Col>
              <Col md="6" lg="3" className="mb-3 mb-lg-5">
                <Card className="card-hover-shadow h-100">
                  <Card.Body>
                    <Card.Subtitle
                      as="h6"
                      className="d-flex justify-content-between"
                    >
                      <FormattedMessage id="app.common.propertyCrime" />
                      <Tooltip
                        content={formatMessage({
                          id: 'app.toolTips.propertyCrime',
                        })}
                      >
                        <i className="bi-info-circle-fill" />
                      </Tooltip>
                    </Card.Subtitle>

                    <Card.Title as="h2" className="text-inherit">
                      {apiData[0].propertyCrime}%
                    </Card.Title>
                    <Badge
                      bg={`soft-${
                        Number(apiData[0].propertyCrime) <
                        EconomicIndicators.US_AVERAGE_PROPERTY_CRIME
                          ? 'success'
                          : 'danger'
                      }`}
                      text={`${
                        Number(apiData[0].propertyCrime) <
                        EconomicIndicators.US_AVERAGE_PROPERTY_CRIME
                          ? 'success'
                          : 'danger'
                      }`}
                    >
                      {`The US average is ${EconomicIndicators.US_AVERAGE_PROPERTY_CRIME}%.`}
                    </Badge>
                  </Card.Body>
                </Card>
              </Col>
              <Col md="6" lg="3" className="mb-3 mb-lg-5">
                <Card className="card-hover-shadow h-100">
                  <Card.Body>
                    <Card.Subtitle
                      as="h6"
                      className="d-flex justify-content-between"
                    >
                      <FormattedMessage id="app.common.violentCrime" />
                      <Tooltip
                        content={formatMessage({
                          id: 'app.toolTips.violentCrime',
                        })}
                      >
                        <i className="bi-info-circle-fill" />
                      </Tooltip>
                    </Card.Subtitle>

                    <Card.Title as="h2" className="text-inherit">
                      {apiData[0].violentCrime}%
                    </Card.Title>
                    <Badge
                      bg={`soft-${
                        Number(apiData[0].violentCrime) <
                        EconomicIndicators.US_AVERAGE_VIOLENT_CRIME
                          ? 'success'
                          : 'danger'
                      }`}
                      text={`${
                        Number(apiData[0].violentCrime) <
                        EconomicIndicators.US_AVERAGE_VIOLENT_CRIME
                          ? 'success'
                          : 'danger'
                      }`}
                    >
                      {`The US average is ${EconomicIndicators.US_AVERAGE_VIOLENT_CRIME}%.`}
                    </Badge>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="6" className="mb-3 mb-lg-5">
                <HalfDoughnutChartCard zip={apiData[0].zip} />
              </Col>
              <Col lg="6" className="mb-3 mb-lg-5">
                <DoughnutChartCardRace zip={apiData[0].zip} />
              </Col>
              <Col lg="6" className="mb-3 mb-lg-5">
                <HalfDoughnutChartCardGender zip={apiData[0].zip} />
              </Col>
              <Col lg="6" className="mb-3 mb-lg-5">
                <HalfDoughnutChartCardVoting zip={apiData[0].zip} />
              </Col>
              <Col xs="12">
                <h3 className="mb-3">
                  <FormattedMessage id="app.common.economy" />
                </h3>
              </Col>
              <Col md="6" lg="3" className="mb-3 mb-lg-5">
                <Card className="card-hover-shadow h-100">
                  <Card.Body>
                    <Card.Subtitle
                      as="h6"
                      className="d-flex justify-content-between"
                    >
                      <FormattedMessage id="app.common.jobMarketIncrease" />
                      <Tooltip
                        content={formatMessage({
                          id: 'app.toolTips.jobMarketIncrease',
                        })}
                      >
                        <i className="bi-info-circle-fill" />
                      </Tooltip>
                    </Card.Subtitle>

                    <Card.Title as="h2" className="text-inherit">
                      {apiData[0].PercentMarketIncrease}
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
              <Col md="6" lg="3" className="mb-3 mb-lg-5">
                <Card className="card-hover-shadow h-100">
                  <Card.Body>
                    <Card.Subtitle
                      as="h6"
                      className="d-flex justify-content-between"
                    >
                      <FormattedMessage id="app.common.next10YearsJobGrowth" />
                      <Tooltip
                        content={formatMessage({
                          id: 'app.toolTips.next10YearsJobGrowth',
                        })}
                      >
                        <i className="bi-info-circle-fill" />
                      </Tooltip>
                    </Card.Subtitle>

                    <Card.Title as="h2" className="text-inherit">
                      {apiData[0].Next10YearsJobGrowth}%
                    </Card.Title>
                    <Badge
                      bg={`soft-${
                        Number(apiData[0].Next10YearsJobGrowth) >
                        EconomicIndicators.US_AVERAGE_NEXT10YEARSJOBGROWTH
                          ? 'success'
                          : 'danger'
                      }`}
                      text={`${
                        Number(apiData[0].Next10YearsJobGrowth) >
                        EconomicIndicators.US_AVERAGE_NEXT10YEARSJOBGROWTH
                          ? 'success'
                          : 'danger'
                      }`}
                    >
                      {`The US average is ${EconomicIndicators.US_AVERAGE_NEXT10YEARSJOBGROWTH}%.`}
                    </Badge>
                  </Card.Body>
                </Card>
              </Col>
              <Col md="6" lg="3" className="mb-3 mb-lg-5">
                <Card className="card-hover-shadow h-100">
                  <Card.Body>
                    <Card.Subtitle
                      as="h6"
                      className="d-flex justify-content-between"
                    >
                      <FormattedMessage id="app.common.saleriesIncome" />
                      <Tooltip
                        content={formatMessage({
                          id: 'app.toolTips.saleriesIncome',
                        })}
                      >
                        <i className="bi-info-circle-fill" />
                      </Tooltip>
                    </Card.Subtitle>

                    <Card.Title as="h2" className="text-inherit">
                      <FormattedNumber
                        value={apiData[0].saleriesIncome}
                        // eslint-disable-next-line react/style-prop-object
                        style="currency"
                        currency="USD"
                        minimumFractionDigits={0}
                        maximumFractionDigits={0}
                      />
                    </Card.Title>
                    <Badge
                      bg={`soft-${
                        Number(apiData[0].saleriesIncome) >
                        EconomicIndicators.US_AVERAGE_INCOME
                          ? 'success'
                          : 'danger'
                      }`}
                      text={`${
                        Number(apiData[0].saleriesIncome) >
                        EconomicIndicators.US_AVERAGE_INCOME
                          ? 'success'
                          : 'danger'
                      }`}
                    >
                      The US average is&nbsp;
                      <FormattedNumber
                        value={EconomicIndicators.US_AVERAGE_INCOME}
                        // eslint-disable-next-line react/style-prop-object
                        style="currency"
                        currency="USD"
                        minimumFractionDigits={0}
                        maximumFractionDigits={0}
                      />
                      &nbsp;a year.
                    </Badge>
                  </Card.Body>
                </Card>
              </Col>
              <Col md="6" lg="3" className="mb-3 mb-lg-5">
                <Card className="card-hover-shadow h-100">
                  <Card.Body>
                    <Card.Subtitle
                      as="h6"
                      className="d-flex justify-content-between"
                    >
                      <FormattedMessage id="app.common.recentJobGrowth" />
                      <Tooltip
                        content={formatMessage({
                          id: 'app.toolTips.recentJobGrowth',
                        })}
                      >
                        <i className="bi-info-circle-fill" />
                      </Tooltip>
                    </Card.Subtitle>

                    <Card.Title as="h2" className="text-inherit">
                      {apiData[0].RecentJobGrowth}%
                    </Card.Title>
                    <Badge
                      bg={`soft-${
                        Number(apiData[0].RecentJobGrowth) >
                        EconomicIndicators.US_AVERAGE_JOBMARKET_INCREASE
                          ? 'success'
                          : 'danger'
                      }`}
                      text={`${
                        Number(apiData[0].RecentJobGrowth) >
                        EconomicIndicators.US_AVERAGE_JOBMARKET_INCREASE
                          ? 'success'
                          : 'danger'
                      }`}
                    >
                      {`The US average is ${EconomicIndicators.US_AVERAGE_JOBMARKET_INCREASE}%.`}
                    </Badge>
                  </Card.Body>
                </Card>
              </Col>
              <Col md="6" lg="3" className="mb-3 mb-lg-5">
                <Card className="card-hover-shadow h-100">
                  <Card.Body>
                    <Card.Subtitle
                      as="h6"
                      className="d-flex justify-content-between"
                    >
                      <FormattedMessage id="app.common.salesTaxRate" />
                      <Tooltip
                        content={formatMessage({
                          id: 'app.toolTips.salesTaxRate',
                        })}
                      >
                        <i className="bi-info-circle-fill" />
                      </Tooltip>
                    </Card.Subtitle>

                    <Card.Title as="h2" className="text-inherit">
                      {apiData[0].salesTaxRate}
                    </Card.Title>
                    <Badge
                      bg={`soft-${
                        Number(apiData[0].salesTaxRate) <
                        EconomicIndicators.US_AVERAGE_SALES_TAX
                          ? 'success'
                          : 'danger'
                      }`}
                      text={`${
                        Number(apiData[0].salesTaxRate) <
                        EconomicIndicators.US_AVERAGE_SALES_TAX
                          ? 'success'
                          : 'danger'
                      }`}
                    >
                      {`The US average is ${EconomicIndicators.US_AVERAGE_SALES_TAX}.`}
                    </Badge>
                  </Card.Body>
                </Card>
              </Col>
              <Col md="6" lg="3" className="mb-3 mb-lg-5">
                <Card className="card-hover-shadow h-100">
                  <Card.Body>
                    <Card.Subtitle
                      as="h6"
                      className="d-flex justify-content-between"
                    >
                      <FormattedMessage id="app.common.incomeTaxRate" />
                      <Tooltip
                        content={formatMessage({
                          id: 'app.toolTips.incomeTaxRate',
                        })}
                      >
                        <i className="bi-info-circle-fill" />
                      </Tooltip>
                    </Card.Subtitle>

                    <Card.Title as="h2" className="text-inherit">
                      {apiData[0].incomeTaxRate}
                    </Card.Title>
                    <Badge
                      bg={`soft-${
                        Number(apiData[0].incomeTaxRate) <
                        EconomicIndicators.US_AVERAGE_INCOME_TAX
                          ? 'success'
                          : 'danger'
                      }`}
                      text={`${
                        Number(apiData[0].incomeTaxRate) <
                        EconomicIndicators.US_AVERAGE_INCOME_TAX
                          ? 'success'
                          : 'danger'
                      }`}
                    >
                      {`The US average is ${EconomicIndicators.US_AVERAGE_INCOME_TAX}.`}
                    </Badge>
                  </Card.Body>
                </Card>
              </Col>
            </>
          )}
          <Col xs="12">
            <h3 className="mb-3">
              <FormattedMessage id="app.common.health" />
            </h3>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.healthCostIndex" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.healthCostIndex',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>

                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].healtCostIndex}
                </Card.Title>
                <Badge
                  bg={`soft-${
                    Number(apiData[0].healtCostIndex) <
                    EconomicIndicators.US_AVERAGE_HEALTH_COST_INDEX
                      ? 'success'
                      : 'danger'
                  }`}
                  text={`${
                    Number(apiData[0].healtCostIndex) <
                    EconomicIndicators.US_AVERAGE_HEALTH_COST_INDEX
                      ? 'success'
                      : 'danger'
                  }`}
                >
                  {`The US average is ${EconomicIndicators.US_AVERAGE_HEALTH_COST_INDEX}.`}
                </Badge>
              </Card.Body>
            </Card>
          </Col>{' '}
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.waterQualityIndex" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.waterQualityIndex',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>

                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].waterQualityIndex}
                </Card.Title>
                <Badge
                  bg={`soft-${
                    Number(apiData[0].waterQualityIndex) >
                    EconomicIndicators.US_AVERAGE_WATER_QUALITY_INDEX
                      ? 'success'
                      : 'danger'
                  }`}
                  text={`${
                    Number(apiData[0].waterQualityIndex) >
                    EconomicIndicators.US_AVERAGE_WATER_QUALITY_INDEX
                      ? 'success'
                      : 'danger'
                  }`}
                >
                  {`The US average is ${EconomicIndicators.US_AVERAGE_WATER_QUALITY_INDEX}.`}
                </Badge>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.superFundIndex" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.superFundIndex',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>

                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].superfundIndex}
                </Card.Title>
                <Badge
                  bg={`soft-${
                    Number(apiData[0].superfundIndex) >
                    EconomicIndicators.US_AVERAGE_SUPERFUND_INDEX
                      ? 'success'
                      : 'danger'
                  }`}
                  text={`${
                    Number(apiData[0].superfundIndex) >
                    EconomicIndicators.US_AVERAGE_SUPERFUND_INDEX
                      ? 'success'
                      : 'danger'
                  }`}
                >
                  {`The US average is ${EconomicIndicators.US_AVERAGE_SUPERFUND_INDEX}.`}
                </Badge>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.airQualityIndex" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.airQualityIndex',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>

                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].airQualityIndex}
                </Card.Title>
                <Badge
                  bg={`soft-${
                    Number(apiData[0].airQualityIndex) >
                    EconomicIndicators.US_AVERAGE_AIR_QUALITY_INDEX
                      ? 'success'
                      : 'danger'
                  }`}
                  text={`${
                    Number(apiData[0].airQualityIndex) >
                    EconomicIndicators.US_AVERAGE_AIR_QUALITY_INDEX
                      ? 'success'
                      : 'danger'
                  }`}
                >
                  {`The US average is ${EconomicIndicators.US_AVERAGE_AIR_QUALITY_INDEX}.`}
                </Badge>
              </Card.Body>
            </Card>
          </Col>
          <Col xs="12">
            <h3 className="mb-3">
              <FormattedMessage id="app.common.riskFactors" />
            </h3>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.flood" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.flood',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>

                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].flood_factor}
                </Card.Title>
                <Badge
                  bg={`soft-${getRiskColor(apiData[0].flood_factor)}`}
                  text={`${getRiskColor(apiData[0].flood_factor)}`}
                  className="text-wrap text-start"
                >
                  <i className="bi-info-circle-fill me-1" />
                  {apiData[0].flood}
                </Badge>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.fire" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.fire',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>

                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].fire_factor}
                </Card.Title>
                <Badge
                  bg={`soft-${getRiskColor(apiData[0].fire_factor)}`}
                  text={`${getRiskColor(apiData[0].fire_factor)}`}
                  className="text-wrap text-start"
                >
                  <i className="bi-info-circle-fill me-1" />
                  {apiData[0].fire}
                </Badge>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.wind" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.wind',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>

                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].wind_factor}
                </Card.Title>
                <Badge
                  bg={`soft-${getRiskColor(apiData[0].wind_factor)}`}
                  text={`${getRiskColor(apiData[0].wind_factor)}`}
                  className="text-wrap text-start"
                >
                  <i className="bi-info-circle-fill me-1" />
                  {apiData[0].wind}
                </Badge>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.heat" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.heat',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>

                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].heat_factor}
                </Card.Title>
                <Badge
                  bg={`soft-${getRiskColor(apiData[0].heat_factor)}`}
                  text={`${getRiskColor(apiData[0].heat_factor)}`}
                  className="text-wrap text-start"
                >
                  <i className="bi-info-circle-fill me-1" />
                  {apiData[0].heat}
                </Badge>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.earthquakes" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.earthquakes',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>
                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].earthquakes}
                </Card.Title>
                <Badge
                  bg="soft-info"
                  text="info"
                  className="text-wrap text-start"
                >
                  <i className="bi-info-circle-fill me-1" />
                  In the last 20 years of magnitude greater then 5 on the
                  Richter Scale
                </Badge>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.wildfires" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.wildfires',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>

                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].wildfires}
                </Card.Title>
                <Badge
                  bg="soft-info"
                  text="info"
                  className="text-wrap text-start"
                />
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.tornadoes" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.tornadoes',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>

                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].tornadoes}
                </Card.Title>
                <Badge
                  bg="soft-info"
                  text="info"
                  className="text-wrap text-start"
                >
                  <i className="bi-info-circle-fill me-1" />
                  In the last 20 yrs, ≤2 (EF-Scale)
                </Badge>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.storms" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.storms',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>

                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].storms}
                </Card.Title>
                <Badge
                  bg="soft-info"
                  text="info"
                  className="text-wrap text-start"
                />
              </Card.Body>
            </Card>
          </Col>
          <Col xs="12">
            <h3 className="mb-3">
              <FormattedMessage id="app.common.transportation" />
            </h3>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.metroArea" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.metroArea',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>

                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].metroArea}
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.transportationTime" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.transportationTime',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>

                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].transportationTime}
                </Card.Title>
                <Badge
                  bg={`soft-${
                    Number(apiData[0].transportationTime) >
                    EconomicIndicators.US_AVERAGE_TRANSPORTATION_TIME
                      ? 'success'
                      : 'danger'
                  }`}
                  text={`${
                    Number(apiData[0].transportationTime) >
                    EconomicIndicators.US_AVERAGE_TRANSPORTATION_TIME
                      ? 'success'
                      : 'danger'
                  }`}
                >
                  {`The US average is ${EconomicIndicators.US_AVERAGE_TRANSPORTATION_TIME}.`}
                </Badge>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.walkingScore" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.walkingScore',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>

                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].WalkScore}
                </Card.Title>
                <Badge
                  bg={`soft-${
                    Number(apiData[0].WalkScore) >
                    EconomicIndicators.US_AVERAGE_WALK_SCORE
                      ? 'success'
                      : 'danger'
                  }`}
                  text={`${
                    Number(apiData[0].WalkScore) >
                    EconomicIndicators.US_AVERAGE_WALK_SCORE
                      ? 'success'
                      : 'danger'
                  }`}
                >
                  {`The US average is ${EconomicIndicators.US_AVERAGE_WALK_SCORE}.`}
                </Badge>
              </Card.Body>
            </Card>
          </Col>
          <Col xs="12">
            <h3 className="mb-3">
              <FormattedMessage id="app.common.climate" />
            </h3>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.temp" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.temp',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>

                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].avg_temp} F
                </Card.Title>
                <Badge
                  bg="soft-info"
                  text="info"
                  className="text-wrap text-start"
                >
                  <i className="bi-info-circle-fill me-1" />
                  Low:{apiData[0].low_temp} High:{apiData[0].high_temp}
                </Badge>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.averageSunnyDays" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.averageSunnyDays',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>

                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].SunnyDayCount}
                </Card.Title>
                <Badge
                  bg="soft-info"
                  text="info"
                  className="text-wrap text-start"
                >
                  <i className="bi-info-circle-fill me-1" />
                  The US Average is {EconomicIndicators.US_AVERAGE_SUNNY_DAYS}
                </Badge>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.rainFall" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.rainFall',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>

                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].rainfall} inches
                </Card.Title>
                <Badge
                  bg="soft-info"
                  text="info"
                  className="text-wrap text-start"
                >
                  <i className="bi-info-circle-fill me-1" />
                  The US Average is {EconomicIndicators.US_AVERAGE_RAIN_FALL}
                </Badge>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.snowFall" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.snowFall',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>

                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].rainFall} inches
                </Card.Title>
                <Badge
                  bg="soft-info"
                  text="info"
                  className="text-wrap text-start"
                >
                  <i className="bi-info-circle-fill me-1" />
                  The US Average is {EconomicIndicators.US_AVERAGE_SNOW_FALL}
                </Badge>
              </Card.Body>
            </Card>
          </Col>
          <Col xs="12">
            <h3 className="mb-3">
              <FormattedMessage id="app.common.other" />
            </h3>{' '}
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.tree_coverage" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.tree_coverage',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>

                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].tree_coverage}%
                </Card.Title>
                <Badge
                  bg="soft-info"
                  text="info"
                  className="text-wrap text-start"
                />
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.shoreline_total_length" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.shoreline_total_length',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>

                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].shoreline_total_length} mi
                </Card.Title>
                <Badge
                  bg="soft-info"
                  text="info"
                  className="text-wrap text-start"
                />
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.trails" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.trails',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>

                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].trails} mi
                </Card.Title>
                <Badge
                  bg="soft-info"
                  text="info"
                  className="text-wrap text-start"
                />
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.parks" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.parks',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>

                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].parks}
                </Card.Title>
                <Badge
                  bg="soft-info"
                  text="info"
                  className="text-wrap text-start"
                />
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.environmental_health" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.environmental_health',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>

                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].environmental_health}
                </Card.Title>
                <Badge
                  bg="soft-info"
                  text="info"
                  className="text-wrap text-start"
                />
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.elevation" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.elevation',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>

                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].elevation}
                </Card.Title>
                <Badge
                  bg="soft-info"
                  text="info"
                  className="text-wrap text-start"
                />
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.bodyOfWater" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.bodyOfWater',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>

                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].body_of_water}%
                </Card.Title>
                <Badge
                  bg="soft-info"
                  text="info"
                  className="text-wrap text-start"
                />
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.aridSteppe" />
                  <Tooltip
                    content={formatMessage({
                      id: 'app.toolTips.aridSteppe',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>

                <Card.Title as="h2" className="text-inherit">
                  {apiData[0].arid_steppe}
                </Card.Title>
                <Badge
                  bg="soft-info"
                  text="info"
                  className="text-wrap text-start"
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
}

export default City;
