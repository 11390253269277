import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SubscriptionPlansTable } from './partials';

function SubscriptionPlans() {
  return (
    <div>
      <h1 className="page-header-title text-center mb-5">
        <FormattedMessage id="app.common.subscriptionPlans" />
      </h1>
      <SubscriptionPlansTable />
    </div>
  );
}

export default SubscriptionPlans;
