export const Types = {
  Call: {
    id: 'Call',
    apiId: 5,
    labelKey: 'call',
    color: 'success',
    hexColor: '#00c9a7',
    icon: 'bi-telephone-fill',
  },
  Email: {
    id: 'Email',
    apiId: 3,
    labelKey: 'email',
    color: 'primary',
    hexColor: '#377dff',
    icon: 'bi-envelope-at-fill',
  },
  Meeting: {
    id: 'Meeting',
    apiId: 1,
    labelKey: 'meeting',
    color: 'danger',
    hexColor: '#ed4c78',
    icon: 'bi-people',
  },
  OnlineMeeting: {
    id: 'OnlineMeeting',
    apiId: 1,
    labelKey: 'onlineMeeting',
    color: 'dark',
    hexColor: '#ed4c78',
    icon: 'bi-camera-video-fill',
  },
};
