import React, { createContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Loader, LoaderStatus } from '@googlemaps/js-api-loader';
import { Col, Row } from 'react-bootstrap';
import { RequestLoading, RequestResult } from '../components';

const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';
const libraries = ['visualization', 'places'];

const GoogleMapsContext = createContext();

const options = {
  apiKey,
  libraries,
};

function GoogleMapsProvider({ children }) {
  const [status, setStatus] = useState(LoaderStatus.LOADING);

  useEffect(() => {
    const loader = new Loader(options);

    const setStatusAndExecuteCallback = (nStatus) => {
      setStatus(nStatus);
    };

    setStatusAndExecuteCallback(LoaderStatus.LOADING);

    loader.importLibrary('maps').then(
      () => setStatusAndExecuteCallback(LoaderStatus.SUCCESS),
      () => setStatusAndExecuteCallback(LoaderStatus.FAILURE)
    );
  }, []);

  const value = useMemo(() => ({ status }), [status]);

  return (
    <GoogleMapsContext.Provider value={value}>
      {(status === LoaderStatus.LOADING || status === LoaderStatus.FAILURE) && (
        <Row className="justify-content-center my-5">
          <Col lg="8">
            <RequestLoading
              loading={status === LoaderStatus.LOADING}
              size="lg"
              margin="5"
            />
            <RequestResult
              type="error"
              title="app.googleMaps.errorTitle"
              message={
                status === LoaderStatus.FAILURE
                  ? 'app.googleMaps.errorMessage'
                  : ''
              }
              image
            />
          </Col>
        </Row>
      )}

      {status === LoaderStatus.SUCCESS && children}
    </GoogleMapsContext.Provider>
  );
}

GoogleMapsProvider.propTypes = {
  children: PropTypes.node,
};

GoogleMapsProvider.defaultProps = {
  children: null,
};

export { GoogleMapsContext, GoogleMapsProvider };
