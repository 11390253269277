import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, ListGroup, Form } from 'react-bootstrap';

const formatCurrency = (value) =>
  value.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

function MortgageCalculator({ price, initialDownPayment }) {
  const [loanAmount, setLoanAmount] = useState(price);
  const [displayLoanAmount, setDisplayLoanAmount] = useState(
    formatCurrency(price)
  );
  const [downPayment, setDownPayment] = useState(initialDownPayment);
  const [displayDownPayment, setDisplayDownPayment] = useState(
    formatCurrency(initialDownPayment)
  );

  const handleLoanAmountChange = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, ''); // Remove non-numeric characters
    setLoanAmount(parseFloat(value));
    setDisplayLoanAmount(formatCurrency(parseFloat(value)));
  };

  const handleDownPaymentChange = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, '');
    setDownPayment(parseFloat(value) || 0);
    setDisplayDownPayment(formatCurrency(parseFloat(value) || 0));
  };

  const [interestRate, setInterestRate] = useState(7.5); // Default interest rate
  const [loanTerm, setLoanTerm] = useState(30); // Default loan term

  const calculateMonthlyPayment = () => {
    const principal = loanAmount - downPayment;
    const monthlyInterestRate = interestRate / 100 / 12;
    const numberOfPayments = loanTerm * 12;

    if (monthlyInterestRate === 0) {
      return principal / numberOfPayments;
    }

    const monthlyPayment =
      (principal * monthlyInterestRate) /
      (1 - (1 + monthlyInterestRate) ** -numberOfPayments);
    return monthlyPayment;
  };

  const monthlyPayment = calculateMonthlyPayment();

  return (
    <Card>
      <Card.Header>
        <Card.Title as="h2">Mortgage Calculator</Card.Title>
      </Card.Header>
      <Card.Body>
        <ListGroup>
          <ListGroup.Item className="d-flex align-items-center">
            <span className="h4 mb-0">Price</span>
            <input
              type="text"
              value={displayLoanAmount}
              onChange={handleLoanAmountChange}
              className="form-control ms-auto text-end"
              style={{ width: '200px' }}
            />
          </ListGroup.Item>
          <ListGroup.Item className="d-flex align-items-center">
            <span className="h4 mb-0">Down Payment</span>
            <input
              type="text"
              value={displayDownPayment}
              onChange={handleDownPaymentChange}
              className="form-control ms-auto text-end"
              style={{ width: '200px' }}
            />
          </ListGroup.Item>
          <ListGroup.Item className="d-flex align-items-center">
            <span className="h4 mb-0">Interest Rate (%)</span>
            <input
              type="number"
              value={interestRate}
              onChange={(e) => setInterestRate(e.target.value)}
              className="form-control ms-auto"
              style={{ width: '100px' }}
              maxLength="5"
            />
          </ListGroup.Item>
          <ListGroup.Item className="d-flex align-items-center">
            <span className="h4 mb-0">Loan Term (Years)</span>
            <Form.Select
              value={loanTerm}
              onChange={(e) => setLoanTerm(e.target.value)}
              className="form-control ms-auto"
              style={{ width: '100px' }}
            >
              {[10, 15, 20, 25, 30].map((term) => (
                <option key={term} value={term}>
                  {term}
                </option>
              ))}
            </Form.Select>
          </ListGroup.Item>
          <ListGroup.Item className="d-flex align-items-center bg-soft-success">
            <span className="h4 mb-0">Monthly Payment</span>
            <span className="h4 text-success mb-0 ms-auto">
              {formatCurrency(monthlyPayment)}
            </span>
          </ListGroup.Item>
        </ListGroup>
      </Card.Body>
    </Card>
  );
}

// Define the prop types
MortgageCalculator.propTypes = {
  price: PropTypes.number.isRequired,
  initialDownPayment: PropTypes.number.isRequired,
};

export default MortgageCalculator;
