import React from 'react';
import {
  Outlet,
  Navigate,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import { PageHeader } from '../../../components';
import { Routes as AppRoutes } from '../../../router/routeMapping';

const menuData = Object.values(AppRoutes.protected.LISTINGS.children)
  .map(({ title, path, hidden }) => ({ title, href: path, hidden }))
  .filter((item) => !item.hidden);

function Listings() {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const activePage = menuData.find((page) => page.href === location.pathname);

  const altPageTitle = location.pathname.substring(
    location.pathname.lastIndexOf('/') + 1
  );

  return (
    <div className="content container">
      <PageHeader
        title={
          activePage?.title || searchParams?.get('title') || altPageTitle || ''
        }
        menuData={menuData}
        breadcrumbData={{
          current: activePage?.title,
          parents: [
            {
              name: AppRoutes.protected.LISTINGS.title,
              href: AppRoutes.protected.LISTINGS.path,
            },
          ],
        }}
      />

      {location.pathname === AppRoutes.protected.LISTINGS.path ? (
        <Navigate to={menuData[0].href} replace />
      ) : (
        <Outlet />
      )}
    </div>
  );
}

export default Listings;
