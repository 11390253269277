import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, InputGroup } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Routes as AppRoutes } from '../../../router/routeMapping';

function SearchLight({ isMobile }) {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const [search, setSearch] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const onKeyDown = (event) => {
    if (event.key === 'Enter' && search.length > 0) {
      navigate(`${AppRoutes.protected.SEARCH.path}?search=${search}`);
    }
  };

  return (
    <div>
      <InputGroup
        className="input-group-merge input-group-borderless input-group-hover-light navbar-input-group"
        style={
          !isMobile && !isFocused
            ? { transition: 'min-width 0.4s', minWidth: '10rem' }
            : { transition: 'min-width 0.4s' }
        }
      >
        <InputGroup.Text className="input-group-prepend">
          <i className="bi-search" />
        </InputGroup.Text>
        <Form.Control
          style={{ width: '10rem' }}
          type="search"
          placeholder={formatMessage({
            id: 'app.common.search',
          })}
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          onKeyDown={onKeyDown}
          onFocus={() => {
            setIsFocused(true);
          }}
          onBlur={() => {
            setIsFocused(false);
          }}
        />
        {search.length > 0 && (
          <InputGroup.Text
            className="input-group-append"
            onClick={() => {
              setSearch('');
            }}
          >
            <i className="bi-x-lg" />
          </InputGroup.Text>
        )}
      </InputGroup>
    </div>
  );
}

SearchLight.propTypes = {
  isMobile: PropTypes.bool,
};

SearchLight.defaultProps = {
  isMobile: false,
};

export default SearchLight;
