const LawTypes = [
  {
    name: 'Bankruptcy',
    abbreviation: 'Bankruptcy',
  },
  {
    name: 'Business Law',
    abbreviation: 'Business Law',
  },
  {
    name: 'Criminal Law',
    abbreviation: 'Criminal Law',
  },
  {
    name: 'Employment & Labor',
    abbreviation: 'Employment & Labor',
  },
  {
    name: 'Estate Planning',
    abbreviation: 'Estate Planning',
  },
  {
    name: 'Family Law',
    abbreviation: 'Family Law',
  },
  {
    name: 'Home Ownership',
    abbreviation: 'Home Ownership',
  },
  {
    name: 'Immigration',
    abbreviation: 'Immigration',
  },
  {
    name: 'Intellectual Property',
    abbreviation: 'Intellectual Property',
  },
  {
    name: 'Personal Injury',
    abbreviation: 'Personal Injury',
  },
  {
    name: 'Tax Law',
    abbreviation: 'Tax Law',
  },
];

export default LawTypes;
