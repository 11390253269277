import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Card, Badge } from 'react-bootstrap';

import { FormattedMessage } from 'react-intl';

import { SmartTable } from '../../../components';

function MyLocations() {
  const { id } = useParams();

  const columns = [
    {
      Header: <FormattedMessage id="app.common.name" />,
      accessor: 'name',
      wrap: true,
      minWidth: '30%',
      Cell: useCallback(
        ({
          cell: {
            value,
            row: { original },
          },
        }) => (
          <>
            {original.isFavorite === 1 && (
              <i className="bi-heart-fill text-danger me-1" />
            )}
            {original.revCount > 0 && (
              <Badge bg="danger" pill className="me-1 mb-1 fs-6">
                {original.revCount}
              </Badge>
            )}
            {value}
          </>
        ),
        []
      ),
    },
    {
      Header: <FormattedMessage id="app.common.distance" />,
      accessor: 'distance',
      wrap: true,
      Cell: useCallback(({ cell: { value } }) => `${value.toFixed(2)} mi`, []),
    },
    {
      Header: <FormattedMessage id="app.common.address" />,
      accessor: 'address',
      wrap: true,
    },

    {
      Header: <FormattedMessage id="app.common.city" />,
      accessor: 'city',
      wrap: true,
    },
    {
      Header: <FormattedMessage id="app.common.zip" />,
      accessor: 'zip',
      wrap: true,
    },
    {
      Header: <FormattedMessage id="app.common.state" />,
      accessor: 'state',
      wrap: true,
    },
  ];

  return (
    <Row>
      <Col>
        <Card>
          <Card.Header>
            <Card.Title bsPrefix="card-header-title" as="h4">
              <FormattedMessage id="app.common.details" />
            </Card.Title>
          </Card.Header>
          {id && (
            <SmartTable
              columns={columns}
              requestUrl="/emlak/get_locations_by_home_id"
              requestParams={{ id, radius: 30, quantity: 10 }}
            />
          )}
        </Card>
      </Col>
    </Row>
  );
}

export default MyLocations;
