import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select/async';

function FormGoogleAutocomplete({
  defaultValue,
  size,
  apiParameters,
  disabled,
  value,
  onChange,
  isInvalid,
  isValid,
  readOnly,
  zIndex,
  placeholder,
  getOptionLabel,
  getOptionValue,
  isMulti,
  ...props
}) {
  const autocompleteService = useRef();

  let defaultOptions = [];
  if (defaultValue) {
    defaultOptions = isMulti ? defaultValue : [defaultValue];
  }

  const [lastOptions, setLastOptions] = useState([]);

  const apiFetch = async (input, callback) => {
    if (!autocompleteService.current) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }

    const result = await autocompleteService.current.getPlacePredictions({
      input,
      ...apiParameters,
    });

    const options = result?.predictions || [];
    setLastOptions(options);
    callback(options);
  };

  return (
    <ReactSelect
      className={`react-select-custom-container react-select-custom-form-item react-select-custom-${size} ${
        isInvalid ? 'is-invalid' : ''
      } ${isValid ? 'is-valid' : ''} ${
        readOnly ? 'react-select-custom-transparentWithBorder' : ''
      }`}
      styles={{
        menu: (baseStyles) => ({
          ...baseStyles,
          zIndex,
        }),
      }}
      classNamePrefix="react-select-custom"
      value={[...defaultOptions, ...lastOptions].find(
        (option) => getOptionValue(option) === value
      )}
      onChange={(nValue) => {
        let v = nValue;
        if (nValue) {
          v = isMulti
            ? nValue.map((x) => getOptionValue(x))
            : getOptionValue(nValue);
        }
        onChange(v);
      }}
      isDisabled={disabled || readOnly}
      placeholder={placeholder}
      loadOptions={apiFetch}
      defaultValue={defaultValue}
      components={
        readOnly
          ? {
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }
          : undefined
      }
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      isMulti={isMulti}
      {...props}
    />
  );
}

FormGoogleAutocomplete.propTypes = {
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
    PropTypes.number,
  ]),
  size: PropTypes.oneOf(['sm', 'lg']),
  apiParameters: PropTypes.objectOf(PropTypes.any),
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
    PropTypes.number,
  ]),
  onChange: PropTypes.func,
  isInvalid: PropTypes.bool,
  isValid: PropTypes.bool,
  readOnly: PropTypes.bool,
  zIndex: PropTypes.number,
  placeholder: PropTypes.string,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  isMulti: PropTypes.bool,
};

FormGoogleAutocomplete.defaultProps = {
  defaultValue: undefined,
  size: 'lg',
  apiParameters: {},
  disabled: false,
  value: undefined,
  onChange: () => {},
  isInvalid: null,
  isValid: null,
  readOnly: false,
  zIndex: 1,
  placeholder: '',
  getOptionLabel: (obj) => obj.description,
  getOptionValue: (obj) => obj,
  isMulti: false,
};

export default FormGoogleAutocomplete;
