import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Link, useMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Nav, Breadcrumb, Row, Col, Alert } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useScroll, useMeasure } from 'react-use';
import { protectedAreaBasePath } from '../router/routeMapping';
import FormattedHtml from './FormattedHtml';
import { AuthContext } from '../context/AuthContext';

function MenuItem({ title, href }) {
  const match = useMatch({ path: href });
  return (
    <Nav.Item>
      <Nav.Link as={Link} active={!!match} to={href}>
        <FormattedMessage id={`app.common.${title}`} defaultMessage={title} />
      </Nav.Link>
    </Nav.Item>
  );
}

MenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};

function PageHeader({
  title,
  menuData,
  renderMenuItem,
  breadcrumbData,
  children,
  infoKey,
}) {
  const { role } = useContext(AuthContext);
  const navRef = useRef(null);
  const [navContainerRef, { width }] = useMeasure();
  const prevRef = useRef();
  const nextRef = useRef();

  const [infoShow, setInfoShow] = useState(false);

  const intl = useIntl();

  const { x } = useScroll(navRef);

  const handleButtonsVisibility = useCallback(() => {
    if (!navRef.current) {
      return;
    }
    const scrollMaxLeft = (
      Number(navRef.current.scrollWidth.toFixed()) -
      Number(navRef.current.clientWidth)
    ).toFixed();
    const scrollPosition = navRef.current.scrollLeft;

    if (scrollPosition <= 0) {
      prevRef.current.style.display = 'none';
    } else {
      prevRef.current.style.display = 'flex';
    }

    if (scrollPosition + 10 >= Number(scrollMaxLeft)) {
      nextRef.current.style.display = 'none';
    } else {
      nextRef.current.style.display = 'flex';
    }
  }, [navRef]);

  const init = useCallback(() => {
    if (!navRef.current) {
      return;
    }

    const activeElementLeftPosition =
      navRef.current.querySelector('.active')?.offsetLeft;

    const navRect = navRef.current.getBoundingClientRect();
    const prevRect = prevRef.current.getBoundingClientRect();

    if (activeElementLeftPosition > navRect.width / 2) {
      navRef.current.scrollLeft = activeElementLeftPosition - prevRect.width;
    }
  }, [navRef]);

  const animateNavScroll = (dir) => {
    const prevRect = prevRef.current.getBoundingClientRect();
    const nextRect = prevRef.current.getBoundingClientRect();

    const newPos =
      dir === 'next'
        ? navRef.current.scrollLeft +
          navRef.current.clientWidth -
          nextRect.width
        : navRef.current.scrollLeft -
          navRef.current.clientWidth +
          prevRect.width;

    navRef.current.scrollLeft = newPos;
  };

  useEffect(() => {
    handleButtonsVisibility();
  }, [x, handleButtonsVisibility]);

  useEffect(() => {
    init();
    handleButtonsVisibility();
  }, [width, init, handleButtonsVisibility]);

  const getInfoString = () => {
    let str = 'app.helpers';

    if (infoKey) {
      str += `.${infoKey}`;
    } else {
      breadcrumbData?.parents?.forEach((item) => {
        str += `.${item.name}`;
      });
    }

    str += `.${title}`;

    const roleStr = `${str}.roles.${role}`;
    const genericStr = `${str}.info`;

    if (intl.messages[roleStr]) {
      return roleStr;
    }

    if (intl.messages[genericStr]) {
      return genericStr;
    }

    return undefined;
  };

  const infoString = getInfoString();
  console.log('infoString:', infoString);

  const getSectionWithMargin = (val) => (
    <section className="mt-2">{val}</section>
  );

  return (
    <>
      <div className="page-header">
        <Row className="justify-content-between align-items-center flex-grow-1 mb-3">
          <Col lg className="mb-3 mb-lg-0">
            {breadcrumbData && (
              <Breadcrumb listProps={{ className: 'breadcrumb-no-gutter' }}>
                <Breadcrumb.Item
                  linkAs={Link}
                  linkProps={{
                    className: 'breadcrumb-link',
                    to: protectedAreaBasePath,
                  }}
                >
                  <FormattedMessage id="app.common.dashboard" />
                </Breadcrumb.Item>

                {breadcrumbData.parents?.map(({ name, href }, index) => (
                  <Breadcrumb.Item
                    linkAs={Link}
                    linkProps={{
                      className: 'breadcrumb-link',
                      to: href,
                    }}
                    key={`${index.toString()}`}
                  >
                    <FormattedMessage
                      id={`app.common.${name}`}
                      defaultMessage={name}
                    />
                  </Breadcrumb.Item>
                ))}

                {breadcrumbData.current && (
                  <Breadcrumb.Item
                    linkProps={{ className: 'breadcrumb-link' }}
                    active
                  >
                    <FormattedMessage
                      id={`app.common.${breadcrumbData.current}`}
                      defaultMessage={breadcrumbData.current}
                    />
                  </Breadcrumb.Item>
                )}
              </Breadcrumb>
            )}

            {title && (
              <h1 className="page-header-title">
                <FormattedMessage
                  id={`app.common.${title}`}
                  defaultMessage={title}
                />
              </h1>
            )}
          </Col>

          <Col xs="auto">{children}</Col>
        </Row>

        {menuData && (
          <div
            ref={navContainerRef}
            className="js-nav-scroller hs-nav-scroller-horizontal"
          >
            <span className="hs-nav-scroller-arrow-prev">
              <button
                ref={prevRef}
                type="button"
                className="bg-transparent border-0 justify-content-start hs-nav-scroller-arrow-link"
                onClick={() => {
                  animateNavScroll('prev');
                }}
              >
                <i className="bi-chevron-left" />
              </button>
            </span>

            <span className="hs-nav-scroller-arrow-next">
              <button
                ref={nextRef}
                type="button"
                className="bg-transparent border-0 justify-content-end hs-nav-scroller-arrow-link"
                onClick={() => {
                  animateNavScroll('next');
                }}
              >
                <i className="bi-chevron-right" />
              </button>
            </span>

            <Nav ref={navRef} variant="tabs" className="page-header-tabs">
              {menuData.map((item, index) => {
                if (renderMenuItem) {
                  return renderMenuItem(item, index);
                }
                return (
                  <MenuItem
                    title={item.title}
                    href={item.href}
                    key={`${index.toString()}`}
                  />
                );
              })}
            </Nav>
          </div>
        )}
      </div>
      {infoString && (
        <Alert
          variant="secondary"
          className="mb-5"
          onClose={() => setInfoShow(false)}
          dismissible={infoShow}
        >
          <Alert.Heading
            as="h5"
            className={!infoShow ? 'mb-0' : undefined}
            role={!infoShow ? 'button' : undefined}
            onClick={() => {
              if (!infoShow) {
                setInfoShow(true);
              }
            }}
          >
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                <i className="bi-info-circle-fill" />
              </div>
              <div className="ms-2">
                <FormattedMessage id="app.common.aboutThisTool" />
              </div>
            </div>
          </Alert.Heading>
          {infoShow && (
            <FormattedHtml
              id={infoString}
              values={{
                section: getSectionWithMargin,
              }}
            />
          )}
        </Alert>
      )}
    </>
  );
}

PageHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  menuData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      href: PropTypes.string,
    })
  ),
  renderMenuItem: PropTypes.func,
  breadcrumbData: PropTypes.shape({
    current: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    parents: PropTypes.arrayOf(
      PropTypes.shape({ name: PropTypes.string, href: PropTypes.string })
    ),
  }),
  children: PropTypes.node,
  infoKey: PropTypes.string,
};

PageHeader.defaultProps = {
  title: '',
  menuData: null,
  renderMenuItem: null,
  breadcrumbData: null,
  children: null,
  infoKey: null,
};

export default PageHeader;
