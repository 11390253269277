import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { SmartWizard } from '../../../components';
import {
  WelcomeStep,
  BusinessInfoStep,
  ZipInfoStep,
  FinalSaveModal,
} from './partials';
import { useBlockerConfirm, useConfirmModal } from '../../../hooks';
import { protectedAreaBasePath } from '../../../router/routeMapping';
import { AuthContext } from '../../../context/AuthContext';

function AccountSetup() {
  const navigate = useNavigate();
  const { user, pack, update } = useContext(AuthContext);
  const wizardRef = useRef();
  const modalRef = useRef();
  const [wizardData, setWizardData] = useState({});
  // const [loading, setLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const { isActive, onConfirm, resetConfirmation } = useBlockerConfirm(isDirty);
  const { confirm } = useConfirmModal({ confirmVariant: 'danger' });

  const onNext = () => {
    wizardRef.current.next();
  };

  const onPrev = () => {
    wizardRef.current.prev();
  };

  const onFormComplete = (data) => {
    modalRef.current.fetch(data);
  };

  const onFormSuccess = (data) => {
    setIsDirty(false);
    modalRef.current.close();
    update({ user: { ...user, zips: data.zips, profile_id: data.profile_id } });
    navigate(protectedAreaBasePath, { replace: true });
  };

  const steps = [
    {
      titleKey: 'welcome',
      size: 12,
      Component: <WelcomeStep onNext={onNext} />,
    },
    {
      titleKey: 'wProfileInfo',
      size: 12,
      Component: (
        <BusinessInfoStep
          onNext={(formData) => {
            setIsDirty(true);
            setWizardData((prevData) => ({ ...prevData, ...formData }));
            onNext();
          }}
          onPrev={onPrev}
        />
      ),
    },
    {
      titleKey: 'zipInfo',
      size: 12,
      Component: (
        <div style={{ maxWidth: '75%', margin: 'auto' }}>
          <span className="ms-2">
            <FormattedMessage id="app.toolTips.chooseLocations" />
          </span>

          <ZipInfoStep
            onNext={(formData) => {
              const nData = {
                ...wizardData,
                zips: formData.map((item) => item.zip),
              };
              setWizardData(nData);
              onFormComplete(nData);
            }}
            onPrev={onPrev}
            max={pack.usage.max_property}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    const showConfirm = async () => {
      const isSuccess = await confirm({
        title: 'unsavedChanges',
        confirmLabel: 'leavePage',
        message: <FormattedMessage id="app.common.unsavedChangesMessage" />,
      });
      if (isSuccess) {
        onConfirm();
      } else {
        resetConfirmation();
      }
    };
    if (isActive) {
      showConfirm();
    }
  }, [isActive, confirm, onConfirm, resetConfirmation]);

  return (
    <div className="content container">
      <SmartWizard ref={wizardRef} steps={steps} />
      <FinalSaveModal ref={modalRef} onSuccess={onFormSuccess} />
    </div>
  );
}

export default AccountSetup;
