import React, {
  useRef,
  useContext,
  useCallback,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Spinner,
  Alert,
  OverlayTrigger,
  Tooltip,
  Button,
  Overlay,
  Popover,
  Image,
} from 'react-bootstrap';
import { FormattedMessage, useIntl, FormattedPlural } from 'react-intl';
import { useWindowScroll, useWindowSize } from 'react-use';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import trLocale from '@fullcalendar/core/locales/tr';
import moment from 'moment';
import ReactSelect, { components } from 'react-select';
import { lowerCase } from 'lodash';
import { LangContext } from '../../context/LangContext';
import Constants from '../../constants';
import GoogleMap from '../GoogleMap';
import ShowMoreText from '../ShowMoreText';
import { useAxiosQuery, useFormModal, useConfirmModal } from '../../hooks';
import Utils from '../../utils';
import Nouislider from '../Nouislider';
import { yup } from '../../lib';
import PlaceHolderImg from '../../assets/images/placeholder.png';

const onImageError = (e) => {
  e.target.src = PlaceHolderImg;
};

const locales = {
  tr: trLocale,
};

const MIN_RADIUS = 25;
const MAX_RADIUS = 300;

function Option({
  getStyles,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...props
}) {
  return (
    <components.Option
      {...props}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={innerProps}
    >
      <div className="d-flex justify-content-between">
        {children}
        {isSelected && <i className="bi bi-check2" />}
      </div>
    </components.Option>
  );
}

Option.propTypes = {
  getStyles: PropTypes.any.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isFocused: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired,
  innerProps: PropTypes.any.isRequired,
};

function ValueContainer({ children, ...props }) {
  return (
    <components.ValueContainer {...props}>
      {Array.isArray(children[0]) && children[0].length > 0 ? (
        <div className="position-absolute text-nowrap">
          <FormattedPlural
            value={children[0].length}
            one={<FormattedMessage id="app.common.oneItemSelected" />}
            other={
              <FormattedMessage
                id="app.common.nItemsSelected"
                values={{ n: children[0].length }}
              />
            }
          />
        </div>
      ) : (
        children[0]
      )}
      {children[1]}
    </components.ValueContainer>
  );
}

ValueContainer.propTypes = {
  children: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.objectOf(PropTypes.any),
      PropTypes.arrayOf(PropTypes.any),
    ])
  ),
};

ValueContainer.defaultProps = {
  children: [],
};

function CategorySelect({
  onChange,
  startDate,
  endDate,
  requestUrl,
  requestParams,
}) {
  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: requestUrl,
    preventFetch: !startDate && !endDate,
    params: {
      quantity: 9999,
      dates: [
        moment(startDate).format(Constants.DateFormats.API),
        moment(endDate).format(Constants.DateFormats.API),
      ],
      ...requestParams,
    },
  });

  return (
    <div
      className="position-relative"
      style={{
        zIndex: 12,
      }}
    >
      <ReactSelect
        options={!apiLoading && !apiError && apiData ? apiData : []}
        getOptionLabel={(option) => `${option.Title}`}
        getOptionValue={(option) => `${option.id}`}
        className="react-select-custom-container"
        classNamePrefix="react-select-custom"
        components={{ ValueContainer, Option }}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        isClearable
        isMulti
        isSearchable={false}
        isLoading={apiLoading}
        isDisabled={apiLoading || apiError}
        onChange={onChange}
        placeholder={<FormattedMessage id="app.common.filterByCategories" />}
      />
    </div>
  );
}

CategorySelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  requestUrl: PropTypes.string.isRequired,
  requestParams: PropTypes.objectOf(PropTypes.any),
};

CategorySelect.defaultProps = {
  startDate: null,
  endDate: null,
  requestParams: {},
};

function SmartCalendar({
  paths,
  params,
  formatter,
  withRadiusFilter,
  withCategoriesFilter,
  defaultView,
}) {
  const { formatMessage } = useIntl();

  const calendarRef = useRef();

  const views = [
    {
      label: 'month',
      value: 'dayGridMonth',
    },
    {
      label: 'week',
      value: 'timeGridWeek',
    },
    {
      label: 'day',
      value: 'timeGridDay',
    },
    {
      label: 'list',
      value: 'listWeek',
    },
  ];
  const [activeView, setActiveView] = useState(
    views.find((item) => item.value === defaultView)
  );

  const windowScroll = useWindowScroll();
  const windowSize = useWindowSize();

  const [showEventDetail, setShowEventDetail] = useState(false);
  const [targetEvent, setTargetEvent] = useState(null);
  const [activeEventData, setActiveEventData] = useState(null);

  const [currentMonthTitle, setCurrentMonthTitle] = useState('');

  const { currentLanguage } = useContext(LangContext);

  const [currentStartDate, setCurrentStartDate] = useState();
  const [currentEndDate, setCurrentEndDate] = useState();
  const [currentRadius, setCurrentRadius] = useState(MIN_RADIUS);
  const [currentCategories, setCurrentCategories] = useState([]);

  const { confirm } = useConfirmModal({ confirmVariant: 'danger' });
  const { form } = useFormModal();
  const [items, setItems] = useState();

  const getFormattedItem = (item) => {
    if (formatter) {
      return formatter(item);
    }
    const color = Utils.Color.getShadeHexColorFromHex(
      Constants.Colors.Palette[0],
      100
    );

    return {
      id: item.CalendarID,
      title: item.Title,
      start: item.Date ? item.Date[0] : item.DateStart,
      end: item.Date ? item.Date[1] : item.DateEnd,
      // className: 'bg-primary',
      backgroundColor: color,
      textColor: '#000',
      eventDescription: item.Description,
      eventVenue: item.Venue,
      eventAddress: item.Address,
      eventCategory: item.Keywords,
      // eventDistance: item.distance,
      eventLatitude: item.Latitude,
      eventLongitude: item.Longitude,
      icon: 'bi-calendar-event',
      // allDay: true,
      // guestsField: ['David Harrison'],
      image: item.Image,
      original: item,
    };
  };

  const { isLoading: apiLoading, error: apiError } = useAxiosQuery({
    url: paths.list,
    preventFetch: !currentStartDate && !currentEndDate,
    params: {
      quantity: 500,
      startDate: [
        moment(currentStartDate).format(Constants.DateFormats.API),
        moment(currentEndDate).format(Constants.DateFormats.API),
      ],
      radius: withRadiusFilter ? currentRadius : null,
      category: currentCategories,
      ...params,
      ...paths.listParams,
    },
    onSuccess: (data) => {
      setItems(data);
    },
    select: (data) => {
      const formatted = data.map((item) => getFormattedItem(item));
      return formatted;
    },
  });

  const calendarWrapperRef = useCallback((node) => {
    if (node !== null) {
      const calendarElem = node.querySelector('.fc');
      if (calendarElem) {
        calendarElem.classList?.add('fullcalendar-custom');
      }
    }
  }, []);

  const handleEventDetail = (eventClickInfo) => {
    setActiveEventData(eventClickInfo.event);
    setTargetEvent(eventClickInfo.el);
    setShowEventDetail(true);
  };

  const renderEventContent = (eventInfo) => (
    <div>
      <div className="fc-event-time">
        {eventInfo.view.type === 'timeGridWeek' && !eventInfo.event.allDay
          ? `${moment(eventInfo.event.start).format('HH:mm')}-${moment(
              eventInfo.event.end
            ).format('HH:mm')}`
          : ''}
      </div>
      <div className="d-flex">
        {eventInfo.event.extendedProps.icon ? (
          <i className={`${eventInfo.event.extendedProps.icon} me-2`} />
        ) : null}
        <span
          className="fc-event-title fc-sticky"
          style={eventInfo.textColor ? { color: eventInfo.textColor } : {}}
        >
          {eventInfo.event.title}
        </span>
      </div>
    </div>
  );

  const sliderFormatter = (tooltip) => {
    // const formatter = (v) => tooltip ? getHumanReadableMinutes(Math.round(v)) : Math.round(v);

    const sFormatter = (v) =>
      tooltip
        ? `${Math.round(v)} ${formatMessage({ id: 'app.common.miles' })}`
        : Math.round(v);

    return {
      to(value) {
        return sFormatter(value);
      },
      from(value) {
        return sFormatter(value);
      },
    };
  };

  const onDeleteItem = async (id, title) => {
    const requestUrl = paths.delete;
    const requestParams = {
      ...params,
      ...paths.deleteParams,
      CalendarID: id,
    };

    const isSuccess = await confirm({
      message: (
        <FormattedMessage
          id="app.common.areYouSureToDeleteTheXY"
          values={{
            x: title,
            y: lowerCase(formatMessage({ id: `app.common.event` })),
          }}
        />
      ),
      requestUrl,
      requestParams,
    });

    if (isSuccess) {
      setItems((prevFiles) => prevFiles.filter((item) => item.id !== id));
      calendarRef.current.render();
    }
  };

  const onCreateOrUpdateItem = async (item) => {
    const fields = [
      {
        cols: [
          {
            key: 'image',
            apiKey: 'Image',
            type: 'image',
            schema: yup.mixed(),
          },
        ],
      },
      {
        cols: [
          {
            key: 'title',
            apiKey: 'Title',
            schema: yup.string().required(),
          },
        ],
      },
      {
        cols: [
          {
            key: 'date',
            apiKey: 'Date',
            type: 'dateRangePicker',
            options: {
              controller: {
                initialSettings: { timePicker: true },
                formatter: (value) => {
                  if (value) {
                    return [
                      moment(value[0]).format(
                        Constants.DateFormats.APIWithTime
                      ),
                      moment(value[1]).format(
                        Constants.DateFormats.APIWithTime
                      ),
                    ];
                  }
                  return value;
                },
              },
            },
            schema: yup.array().min(2).nullable().required(),
          },
        ],
      },
      {
        cols: [
          {
            key: 'description',
            apiKey: 'Description',
            type: 'textarea',
            schema: yup.string().nullable(),
          },
        ],
      },
      /* {
        cols: [
          {
            key: 'slider',
            apiKey: 'Slider',
            type: 'slider',
            options: {
              controller: {
                props: {
                  range: {
                    min: 1,
                    max: 100,
                  },
                  start: 10,
                  step: 5,
                },
              },
            },
            schema: yup.number().required(),
          },
        ],
      },
      {
        cols: [
          {
            key: 'rangeSlider',
            apiKey: 'RangeSlider',
            type: 'slider',
            options: {
              controller: {
                props: {
                  range: {
                    min: 1,
                    max: 100,
                  },
                  start: [20, 80],
                  step: 5,
                },
              },
            },
            schema: yup.array().required(),
          },
        ],
      }, */
      {
        cols: [
          {
            key: 'categories',
            apiKey: 'Keywords',
            type: 'text',
            schema: yup.string().nullable(),
          },
        ],
      },
      {
        cols: [
          {
            key: 'venue',
            apiKey: 'Venue',
            schema: yup.string().nullable(),
          },
        ],
      },
      {
        cols: [
          {
            key: 'address',
            apiKey: 'Address',
            type: 'textarea',
            schema: yup.string().nullable(),
          },
        ],
      },
      {
        cols: [
          {
            key: 'latitude',
            apiKey: 'Latitude',
            options: {
              controller: {
                props: {
                  onInput: (e) => {
                    e.target.value = e.target.value.replace(/[^0-9.-]*/g, '');
                  },
                },
              },
            },
            schema: yup
              .number()
              .transform((value) =>
                Number.isNaN(value) || value === null || value === undefined
                  ? null
                  : value
              )
              .nullable(),
          },
          {
            key: 'longitude',
            apiKey: 'Longitude',
            options: {
              controller: {
                props: {
                  onInput: (e) => {
                    e.target.value = e.target.value.replace(/[^0-9.-]*/g, '');
                  },
                },
              },
            },
            schema: yup
              .number()
              .transform((value) =>
                Number.isNaN(value) || value === null || value === undefined
                  ? null
                  : value
              )
              .nullable(),
          },
        ],
      },
    ];

    const defaultValues = item
      ? {
          title: item.Title,
          image: item.Image,
          description: item.Description,
          date: item.Date,
          venue: item.Venue,
          address: item.Address,
          latitude: Number(item.Latitude) ? Number(item.Latitude) : null,
          longitude: Number(item.Longitude) ? Number(item.Longitude) : null,
          categories: item.Keywords,
        }
      : {};

    const requestParams = item
      ? {
          CalendarID: item.CalendarID,
          Status: 1,
          ...params,
          ...paths.updateParams,
        }
      : {
          Status: 1,
          ...params,
          ...paths.createParams,
        };

    const formData = await form({
      size: 'lg',
      title: item ? 'editEvent' : 'newEvent',
      confirmLabel: item ? 'save' : 'create',
      requestUrl: item ? paths.update : paths.create,
      requestParams,
      fields,
      fetchOnStart: false,
      defaultValues,
    });

    if (formData) {
      setItems((prevItems) => [
        ...prevItems.filter((nItem) => nItem.id !== formData.Result.CalendarID),
        getFormattedItem(formData.Result),
      ]);
      calendarRef.current.render();
    }
  };

  useEffect(() => {
    // setShowEventDetail(false);
  }, [windowScroll, windowSize]);

  return (
    <div className="position-relative">
      {apiLoading && (
        <div>
          <div
            className="position-absolute bg-dark opacity-75 rounded"
            style={{
              left: '-15px',
              right: '-15px',
              top: '-20px',
              bottom: '-15px',
              zIndex: 100,
            }}
          />
          <div
            className="position-absolute w-100 h-100 d-flex justify-content-center"
            style={{
              zIndex: 101,
            }}
          >
            <div className="w-50 text-center mt-5">
              <Spinner animation="border" variant="white" />
            </div>
          </div>
        </div>
      )}

      {!apiLoading && apiError && (
        <Row>
          <Col>
            <Alert variant="danger">
              <div className="d-flex">
                <div className="flex-shrink-0">
                  <i className="bi-exclamation-triangle-fill" />
                </div>
                <div className="flex-grow-1 ms-2">{apiError}</div>
              </div>
            </Alert>
          </Col>
        </Row>
      )}

      <Row className="align-items-sm-center mb-4">
        <Col lg="5" className="mb-2 mb-lg-0">
          <div className="d-flex align-items-center">
            <Button
              size="sm"
              variant="white"
              className="me-3"
              onClick={() => {
                calendarRef.current.getApi().today();
              }}
            >
              <FormattedMessage id="app.common.today" />
            </Button>
            <OverlayTrigger
              overlay={
                <Tooltip>
                  <FormattedMessage id="app.common.previousMonth" />
                </Tooltip>
              }
            >
              <Button
                variant="ghost-secondary"
                size="sm"
                className="btn-icon btn-no-focus rounded-circle me-1"
                onClick={() => {
                  calendarRef.current.getApi().prev();
                }}
              >
                <i className="bi-chevron-left" />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              overlay={
                <Tooltip>
                  <FormattedMessage id="app.common.nextMonth" />
                </Tooltip>
              }
            >
              <Button
                variant="ghost-secondary"
                size="sm"
                className="btn-icon btn-no-focus rounded-circle ms-1"
                onClick={() => {
                  calendarRef.current.getApi().next();
                }}
              >
                <i className="bi-chevron-right" />
              </Button>
            </OverlayTrigger>
            <div className="ms-3">
              <h4 className="h3 mb-0">{currentMonthTitle}</h4>
            </div>
          </div>
        </Col>
        <Col lg="7">
          <div className="d-sm-flex align-items-sm-center justify-content-end">
            <div className="d-flex align-items-center">
              {withRadiusFilter && (
                <div className="range-slider me-2" style={{ width: 200 }}>
                  <Nouislider
                    className="range-slider-ui"
                    range={{
                      min: MIN_RADIUS,
                      '20%': [100, 10],
                      '70%': [200, 25],
                      max: MAX_RADIUS,
                    }}
                    start={currentRadius}
                    step={5}
                    tooltips={[sliderFormatter(true)]}
                    format={sliderFormatter()}
                    onChange={(val) => {
                      setCurrentRadius(val[0]);
                    }}
                  />
                </div>
              )}
              {withCategoriesFilter?.requestUrl && (
                <div className="me-2" style={{ minWidth: 200 }}>
                  <CategorySelect
                    requestUrl={withCategoriesFilter.requestUrl}
                    requestParams={withCategoriesFilter.requestParams}
                    onChange={(val) => {
                      setCurrentCategories(val.map((item) => item.Title));
                    }}
                    startDate={currentStartDate}
                    endDate={currentEndDate}
                  />
                </div>
              )}
              <div
                className="position-relative me-2"
                style={{
                  zIndex: 9,
                }}
              >
                <ReactSelect
                  options={views}
                  getOptionLabel={(option) =>
                    formatMessage({ id: `app.common.${option.label}` })
                  }
                  className="react-select-custom-container"
                  classNamePrefix="react-select-custom"
                  isClearable={false}
                  isSearchable={false}
                  value={activeView}
                  onChange={(option) => {
                    setActiveView(option);
                    calendarRef.current.getApi().changeView(option.value);
                  }}
                />
              </div>

              {paths.create && (
                <Button
                  size="sm"
                  onClick={() => {
                    onCreateOrUpdateItem();
                    setShowEventDetail(false);
                    setActiveEventData(null);
                  }}
                >
                  <i className="bi-plus me-1" />
                  <FormattedMessage id="app.common.newEvent" />
                </Button>
              )}
            </div>
          </div>
        </Col>
      </Row>
      <div ref={calendarWrapperRef} id="fc-wrapper">
        <FullCalendar
          locale={currentLanguage !== 'en' && locales[currentLanguage]}
          ref={calendarRef}
          plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
          initialView={defaultView}
          views={{
            timeGridDay: {
              type: 'timeGrid',
              duration: { days: 1 },
            },
          }}
          headerToolbar={false}
          defaultAllDay={false}
          editable
          contentHeight="auto"
          dayMaxEventRows={2}
          displayEventTime
          eventDisplay="block"
          eventContent={renderEventContent}
          datesSet={(dateSet) => {
            setCurrentStartDate(dateSet.start);
            setCurrentEndDate(dateSet.end);
            setCurrentMonthTitle(dateSet.view.title);
          }}
          eventClick={(eventClickInfo) => {
            setShowEventDetail(false);
            setTimeout(() => {
              handleEventDetail(eventClickInfo);
            }, 100);
          }}
          events={items}
        />
      </div>

      <Overlay
        show={showEventDetail}
        onHide={() => {
          setShowEventDetail(false);
          setActiveEventData(null);
        }}
        target={targetEvent}
        placement="auto"
        flip
        rootClose
        container={() => document.getElementById('fc-wrapper')}
      >
        <Popover
          id="popover-contained"
          className="fullcalendar-event-popover"
          style={{
            zIndex: 99,
          }}
        >
          <Popover.Body style={{ maxHeight: '100vh', overflow: 'scroll' }}>
            {activeEventData && (
              <>
                {activeEventData.extendedProps.image && (
                  <div className="d-flex mb-3">
                    <div className="flex-grow-1">
                      <Image
                        src={activeEventData.extendedProps.image}
                        className="w-100"
                        onError={onImageError}
                      />
                    </div>
                  </div>
                )}
                <h3 className="mb-3">{activeEventData.title}</h3>

                <div className="d-flex mb-3">
                  <i className="bi bi-clock nav-icon" />
                  <div className="flex-grow-1">
                    <span className="d-block text-dark mb-2">
                      {`${
                        activeEventData.start
                          ? moment(activeEventData.start).format(
                              Constants.DateFormats.APP.Moment.CommonWithTime
                            )
                          : ''
                      } ${
                        activeEventData.end
                          ? `- ${moment(activeEventData.end).format(
                              Constants.DateFormats.APP.Moment.CommonWithTime
                            )}`
                          : ''
                      }`}
                    </span>
                  </div>
                </div>

                {activeEventData.extendedProps.eventCategory && (
                  <div className="d-flex mb-3">
                    <i className="bi bi-tag nav-icon" />
                    <div className="flex-grow-1">
                      <span className="d-block text-dark">
                        {activeEventData.extendedProps.eventCategory}
                      </span>
                    </div>
                  </div>
                )}

                {activeEventData.extendedProps.eventDescription && (
                  <div className="d-flex mb-3">
                    <i className="bi bi-text-left nav-icon" />
                    <div className="flex-grow-1">
                      <span className="d-block text-dark">
                        <ShowMoreText
                          text={activeEventData.extendedProps.eventDescription}
                          maxLine={2}
                          basedOn="words"
                          // hideButton
                        />
                      </span>
                    </div>
                  </div>
                )}

                {(activeEventData.extendedProps.eventAddress ||
                  activeEventData.extendedProps.eventVenue) && (
                  <div className="d-flex mb-3">
                    <i className="bi bi-pin-map nav-icon" />
                    <div className="flex-grow-1">
                      <span className="d-block text-dark">
                        {activeEventData.extendedProps.eventVenue && (
                          <b>
                            {activeEventData.extendedProps.eventVenue}
                            <br />
                          </b>
                        )}
                        {activeEventData.extendedProps.eventAddress && (
                          <span>
                            {activeEventData.extendedProps.eventAddress}
                            <br />
                          </span>
                        )}
                      </span>
                    </div>
                  </div>
                )}

                {activeEventData.extendedProps.eventLatitude &&
                  activeEventData.extendedProps.eventLongitude && (
                    <div className="d-flex align-items-center mb-3">
                      <GoogleMap
                        initialMarks={[
                          {
                            latitude: Number(
                              activeEventData.extendedProps.eventLatitude
                            ),
                            longitude: Number(
                              activeEventData.extendedProps.eventLongitude
                            ),
                          },
                        ]}
                        initialCenter={{
                          lat: Number(
                            activeEventData.extendedProps.eventLatitude
                          ),
                          lng: Number(
                            activeEventData.extendedProps.eventLongitude
                          ),
                        }}
                      />
                    </div>
                  )}

                <div className="d-flex justify-content-end">
                  <Button
                    variant="white"
                    size="sm"
                    className="me-2"
                    onClick={() => {
                      setShowEventDetail(false);
                      setActiveEventData(null);
                    }}
                  >
                    <FormattedMessage id="app.common.close" />
                  </Button>
                  {paths.update && (
                    <Button
                      size="sm"
                      className="me-2"
                      onClick={() => {
                        onCreateOrUpdateItem(
                          activeEventData?.extendedProps?.original
                        );
                        setShowEventDetail(false);
                        setActiveEventData(null);
                      }}
                    >
                      <i className="bi bi-pencil me-1" />
                      <FormattedMessage id="app.common.edit" />
                    </Button>
                  )}
                  {paths.delete && (
                    <Button
                      variant="danger"
                      size="sm"
                      className="me-2"
                      onClick={() => {
                        onDeleteItem(
                          activeEventData?.extendedProps?.original?.CalendarID,
                          activeEventData?.extendedProps?.original?.Title
                        );
                        setShowEventDetail(false);
                        setActiveEventData(null);
                      }}
                    >
                      <i className="bi bi-trash me-1" />
                      <FormattedMessage id="app.common.delete" />
                    </Button>
                  )}
                </div>
              </>
            )}
          </Popover.Body>
        </Popover>
      </Overlay>
    </div>
  );
}

SmartCalendar.propTypes = {
  paths: PropTypes.shape({
    list: PropTypes.string,
    listParams: PropTypes.objectOf(PropTypes.any),
    create: PropTypes.string,
    createParams: PropTypes.objectOf(PropTypes.any),
    update: PropTypes.string,
    updateParams: PropTypes.objectOf(PropTypes.any),
    delete: PropTypes.string,
    deleteParams: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
  params: PropTypes.objectOf(PropTypes.any),
  formatter: PropTypes.func,
  withRadiusFilter: PropTypes.bool,
  withCategoriesFilter: PropTypes.shape({
    requestUrl: PropTypes.string.isRequired,
    requestParams: PropTypes.objectOf(PropTypes.any),
  }),
  defaultView: PropTypes.string,
};

SmartCalendar.defaultProps = {
  params: {},
  formatter: null,
  withRadiusFilter: false,
  withCategoriesFilter: null,
  defaultView: 'dayGridMonth',
};

export default SmartCalendar;
