import { useContext } from 'react';
import { ModalContext } from '../context/ModalContext';

const useConfirmModal = ({
  title,
  message,
  cancelLabel,
  confirmLabel,
  confirmVariant,
} = {}) => {
  const context = useContext(ModalContext);

  const confirm = async (opts) => {
    const props = {
      title: opts?.title || title,
      message: opts?.message || message,
      cancelLabel: opts?.cancelLabel || cancelLabel,
      confirmLabel: opts?.confirmLabel || confirmLabel,
      confirmVariant: opts?.confirmVariant || confirmVariant,
      requestUrl: opts?.requestUrl,
      requestType: opts?.requestType || 'post',
      requestParams: opts?.requestParams,
    };

    return new Promise((resolve) => {
      context?.show({
        ...props,
        resolver: resolve,
      });
    });
  };

  return { confirm };
};

export default useConfirmModal;
