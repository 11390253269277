import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Card, Badge, Image } from 'react-bootstrap';

import { FormattedMessage } from 'react-intl';

import {
  SmartTable,
  ShowMoreText,
  getHighlightedText,
} from '../../../components';

import PlaceHolderImg from '../../../assets/images/placeholder.png';

const onImageError = (e) => {
  e.target.src = PlaceHolderImg;
};

function Projects() {
  const { id } = useParams();

  const columns = [
    {
      Header: <FormattedMessage id="app.common.logo" />,
      accessor: 'ImageURL',
      Cell: useCallback(
        ({
          cell: {
            value,
            row: { original },
          },
        }) => (
          <div className="position-relative">
            <Image
              src={value || PlaceHolderImg}
              onError={onImageError}
              style={{ height: 100, width: 150, objectFit: 'cover' }}
            />
            {original.revCount > 0 && (
              <div className="position-absolute bottom-0 end-0 zi-1">
                <Badge bg="danger" pill className="me-1 mb-1 fs-6">
                  {original.revCount}
                </Badge>
              </div>
            )}
          </div>
        ),
        []
      ),
    },
    {
      Header: <FormattedMessage id="app.common.title" />,
      accessor: 'title',
      wrap: true,

      Cell: useCallback(
        ({
          cell: {
            value,
            row: { original },
          },
        }) => (
          <>
            {original.isFavorite === 1 && (
              <i className="bi-heart-fill text-danger me-1" />
            )}
            {value}
          </>
        ),
        []
      ),
    },
    {
      Header: <FormattedMessage id="app.common.distance" />,
      accessor: 'distance',
      wrap: true,
      Cell: useCallback(({ cell: { value } }) => `${value.toFixed(2)} mi`, []),
    },
    {
      Header: <FormattedMessage id="app.common.address" />,
      accessor: 'address',
      wrap: true,
    },

    {
      Header: <FormattedMessage id="app.common.date" />,
      accessor: 'date',
      wrap: true,
    },
    {
      Header: <FormattedMessage id="app.common.description" />,
      accessor: 'description',
      wrap: true,
      minWidth: '15%',
      Cell: useCallback(
        ({ cell: { value } }) =>
          value ? (
            <ShowMoreText
              text={getHighlightedText(value, '')}
              maxLine={2}
              basedOn="words"
            />
          ) : (
            <Badge bg="secondary">
              <FormattedMessage id="app.common.n/a" />
            </Badge>
          ),
        ''
      ),
    },
  ];

  return (
    <Row>
      <Col>
        <Card>
          <Card.Header>
            <Card.Title bsPrefix="card-header-title" as="h4">
              <FormattedMessage id="app.common.details" />
            </Card.Title>
          </Card.Header>
          {id && (
            <SmartTable
              columns={columns}
              requestUrl="/emlak/get_articles_by_home_id"
              requestParams={{ id, radius: 3, quantity: 10 }}
            />
          )}
        </Card>
      </Col>
    </Row>
  );
}

export default Projects;
