import React, {
  useState,
  createContext,
  useMemo,
  useCallback,
  useContext,
  useRef,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { useEffectOnce } from 'react-use';
import { useLocation } from 'react-router-dom';
import SmartForm from '../components/SmartForm/SmartForm';
import RequestResult from '../components/RequestResult';
import { axios, axiosApiInstance, qs } from '../lib';
import { AuthContext } from './AuthContext';

const ModalContext = createContext();

function ModalProvider({ children }) {
  const location = useLocation();
  const { token: userToken } = useContext(AuthContext);

  const axiosSource = useRef(null);
  const newCancelToken = useCallback(() => {
    axiosSource.current = axios.CancelToken.source();
    return axiosSource.current.token;
  }, []);

  const [show, setShow] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const [requestError, setRequestError] = useState(null);

  const [options, setOptions] = useState(null);

  const cancelRequest = useCallback(() => {
    if (axiosSource.current) {
      setRequestLoading(false);
      setRequestError(null);
      axiosSource.current.cancel();
    }
  }, []);

  const reset = () => {
    setRequestLoading(false);
    setRequestError(null);
    setOptions(null);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  const load = () => {
    setRequestError(null);
    setRequestLoading(true);

    const params = { token: userToken, ...options?.requestParams };

    axiosApiInstance[options?.requestType || 'post'](
      options?.requestUrl,
      qs.stringify(params),
      {
        cancelToken: newCancelToken(),
      }
    )
      .then(({ data }) => {
        setRequestLoading(false);

        if (data.status) {
          handleClose();
          options?.resolver(true);
        } else {
          setRequestError(`errors.api.${data.Message}`);
          options?.resolver(false);
        }
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setRequestError(`errors.network.${err.message}`);
          setRequestLoading(false);
          options?.resolver(false);
        }
      });
  };

  const onConfirm = () => {
    if (options?.requestUrl) {
      load();
      return;
    }
    handleClose();
    options?.resolver(true);
  };

  const onCancel = () => {
    handleClose();
    options?.resolver(false);
  };

  const init = useCallback((opts) => {
    setOptions(opts);
    handleShow();
  }, []);

  const updateFields = useCallback((fields) => {
    setOptions((prevOptions) => ({
      ...prevOptions,
      fields,
    }));
  }, []);

  const value = useMemo(
    () => ({
      show: init,
      updateFields,
    }),
    [init, updateFields]
  );

  useEffectOnce(() => () => {
    cancelRequest();
  });

  useEffect(() => {
    handleClose();
  }, [location]);

  return (
    <ModalContext.Provider value={value}>
      {children}
      <Modal
        size={options?.size || 'sm'}
        show={show}
        onHide={handleClose}
        onExited={reset}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton={options?.type === 'form'}>
          <Modal.Title>
            <FormattedMessage
              id={`app.common.${
                options?.title ||
                (options?.type === 'form' ? 'form' : 'confirm')
              }`}
              defaultMessage={options?.title}
            />
          </Modal.Title>
        </Modal.Header>
        {options?.type === 'form' && (
          <SmartForm
            ref={options?.ref}
            fields={options?.fields}
            isModal
            onModalHide={handleClose}
            defaultValues={options?.defaultValues}
            requestUrl={options?.requestUrl}
            requestType={options?.requestType}
            requestParams={options?.requestParams}
            fetchOnStart={options?.fetchOnStart}
            submitButtonText={options?.confirmLabel}
            cancelButtonText={options?.cancelLabel}
            infoMessage={options?.message}
            onChange={options?.onChange}
            onRequestSuccess={(data) => {
              handleClose();
              options?.resolver(data);
            }}
            onRequestError={() => {
              options?.resolver(false);
            }}
          />
        )}
        {options?.type !== 'form' && (
          <Modal.Body>
            <RequestResult type="error" message={requestError} />

            <div className="mb-5">
              {options?.message || (
                <FormattedMessage id="app.common.areYouSure" />
              )}
            </div>

            <div className="d-flex justify-content-end gap-3 ms-auto">
              <Button
                variant="secondary"
                onClick={onCancel}
                disabled={requestLoading}
              >
                <FormattedMessage
                  id={`app.common.${
                    options?.cancelLabel ? options?.cancelLabel : 'cancel'
                  }`}
                  defaultMessage={options?.cancelLabel}
                />
              </Button>
              <Button
                variant={options?.confirmVariant || 'primary'}
                onClick={onConfirm}
                disabled={requestLoading}
              >
                {requestLoading ? (
                  <>
                    <Spinner animation="border" size="sm" className="me-1" />
                    <FormattedMessage id="app.common.loading" />
                  </>
                ) : (
                  <FormattedMessage
                    id={`app.common.${
                      options?.confirmLabel ? options?.confirmLabel : 'confirm'
                    }`}
                    defaultMessage={options?.confirmLabel}
                  />
                )}
              </Button>
            </div>
          </Modal.Body>
        )}
      </Modal>
    </ModalContext.Provider>
  );
}

ModalProvider.propTypes = {
  children: PropTypes.node,
};

ModalProvider.defaultProps = {
  children: null,
};

export { ModalContext, ModalProvider };
