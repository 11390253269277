const POICategories = [
  {
    name: 'Airport',
    abbreviation: 'Airport',
  },
  {
    name: 'attractions',
    abbreviation: 'attractions',
  },
  {
    name: 'Beach',
    abbreviation: 'Beach',
  },
  {
    name: 'Cafe/Bar',
    abbreviation: 'Cafe/Bar',
  },
  {
    name: 'College',
    abbreviation: 'College',
  },
  {
    name: 'Golf',
    abbreviation: 'Golf',
  },
  {
    name: 'Lake',
    abbreviation: 'Lake',
  },
  {
    name: 'Mountain',
    abbreviation: 'Mountain',
  },
  {
    name: 'Museum',
    abbreviation: 'Museum',
  },
  {
    name: 'Resort',
    abbreviation: 'Resort',
  },
  {
    name: 'Restaurant',
    abbreviation: 'Restaurant',
  },
  {
    name: 'River',
    abbreviation: 'River',
  },
  {
    name: 'Sea/Ocean',
    abbreviation: 'Sea/Ocean',
  },
  {
    name: 'Shopping Center',
    abbreviation: 'Shopping Center',
  },
  {
    name: 'Stadium',
    abbreviation: 'Stadium',
  },
  {
    name: 'Theater',
    abbreviation: 'Theater',
  },
  {
    name: 'University',
    abbreviation: 'University',
  },
];

export default POICategories;
