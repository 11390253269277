import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Row, Col, Card } from 'react-bootstrap';
import { useIntl, FormattedMessage, FormattedNumber } from 'react-intl';
import { useAxiosQuery } from '../../../../hooks';
import { RequestLoading, RequestResult, Tooltip } from '../../../../components';
import { Routes as AppRoutes } from '../../../../router/routeMapping';

function TableRealEstateUSANumbers({ zip }) {
  const { formatMessage } = useIntl();
  const {
    data: apiData,
    isLoading: apiLoading,
    error: apiError,
  } = useAxiosQuery({
    url: '/dashboard/REByNumbers',
    preventFetch: !zip,
    params: { zip },
  });

  return (
    <>
      <RequestLoading loading={apiLoading} size="lg" margin="5" />
      <RequestResult type="error" message={apiError} />

      {!apiLoading && !apiError && apiData?.length > 0 && (
        <Row>
          <Col xs="12">
            <h3 className="mb-3">
              <FormattedMessage id="app.common.farming" />
            </h3>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.is_absenteeOwner" />
                  <Tooltip
                    className="zi-2"
                    content={formatMessage({
                      id: 'app.toolTips.is_absenteeOwner',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>
                <Card.Title as="h2" className="text-inherit">
                  <FormattedNumber
                    value={apiData[0].absente_owners}
                    // eslint-disable-next-line react/style-prop-object
                    minimumFractionDigits={0}
                    maximumFractionDigits={0}
                  />
                </Card.Title>
                <Link
                  className="stretched-link"
                  to={`${AppRoutes.protected.FARMING.children.ABSENTEE_OWNERS.path.replace(
                    ':zip',
                    zip
                  )}`}
                />
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.is_adjustableRate" />
                  <Tooltip
                    className="zi-2"
                    content={formatMessage({
                      id: 'app.toolTips.is_adjustableRate',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>
                <Card.Title as="h2" className="text-inherit">
                  <FormattedNumber
                    value={apiData[0].adjustable_loans}
                    // eslint-disable-next-line react/style-prop-object
                    minimumFractionDigits={0}
                    maximumFractionDigits={0}
                  />
                  <Link
                    className="stretched-link"
                    to={`${AppRoutes.protected.FARMING.children.ADJUSTABLE_LOANS.path.replace(
                      ':zip',
                      zip
                    )}`}
                  />
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.is_bankOwned" />
                  <Tooltip
                    className="zi-2"
                    content={formatMessage({
                      id: 'app.toolTips.is_bankOwned',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>
                <Card.Title as="h2" className="text-inherit">
                  <FormattedNumber
                    value={apiData[0].bank_owned}
                    // eslint-disable-next-line react/style-prop-object
                    minimumFractionDigits={0}
                    maximumFractionDigits={0}
                  />
                  <Link
                    className="stretched-link"
                    to={`${AppRoutes.protected.FARMING.children.BANK_OWNED.path.replace(
                      ':zip',
                      zip
                    )}`}
                  />
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.is_cashBuyer" />
                  <Tooltip
                    className="zi-2"
                    content={formatMessage({
                      id: 'app.toolTips.is_cashBuyer',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>
                <Card.Title as="h2" className="text-inherit">
                  <FormattedNumber
                    value={apiData[0].cash_buyers}
                    // eslint-disable-next-line react/style-prop-object
                    minimumFractionDigits={0}
                    maximumFractionDigits={0}
                  />
                  <Link
                    className="stretched-link"
                    to={`${AppRoutes.protected.FARMING.children.CASH_BUYERS.path.replace(
                      ':zip',
                      zip
                    )}`}
                  />
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>{' '}
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.is_corporateOwned" />
                  <Tooltip
                    className="zi-2"
                    content={formatMessage({
                      id: 'app.toolTips.is_corporateOwned',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>
                <Card.Title as="h2" className="text-inherit">
                  <FormattedNumber
                    value={apiData[0].corporate_owned}
                    // eslint-disable-next-line react/style-prop-object
                    minimumFractionDigits={0}
                    maximumFractionDigits={0}
                  />
                  <Link
                    className="stretched-link"
                    to={`${AppRoutes.protected.FARMING.children.CORPORATE_OWNED.path.replace(
                      ':zip',
                      zip
                    )}`}
                  />
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.is_highEquity" />
                  <Tooltip
                    className="zi-2"
                    content={formatMessage({
                      id: 'app.toolTips.is_highEquity',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>
                <Card.Title as="h2" className="text-inherit">
                  <FormattedNumber
                    value={apiData[0].high_equity}
                    // eslint-disable-next-line react/style-prop-object
                    minimumFractionDigits={0}
                    maximumFractionDigits={0}
                  />
                  <Link
                    className="stretched-link"
                    to={`${AppRoutes.protected.FARMING.children.HIGH_EQUITY.path.replace(
                      ':zip',
                      zip
                    )}`}
                  />
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.is_outOfStateAbsenteeOwner" />
                  <Tooltip
                    className="zi-2"
                    content={formatMessage({
                      id: 'app.toolTips.is_outOfStateAbsenteeOwner',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>
                <Card.Title as="h2" className="text-inherit">
                  <FormattedNumber
                    value={apiData[0].out_of_states_owners}
                    // eslint-disable-next-line react/style-prop-object
                    minimumFractionDigits={0}
                    maximumFractionDigits={0}
                  />
                  <Link
                    className="stretched-link"
                    to={`${AppRoutes.protected.FARMING.children.OUT_OF_STATE_OWNERS.path.replace(
                      ':zip',
                      zip
                    )}`}
                  />
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.is_preforeclosures" />
                  <Tooltip
                    className="zi-2"
                    content={formatMessage({
                      id: 'app.toolTips.is_preforeclosures',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>
                <Card.Title as="h2" className="text-inherit">
                  <FormattedNumber
                    value={apiData[0].preforeclosures}
                    // eslint-disable-next-line react/style-prop-object
                    minimumFractionDigits={0}
                    maximumFractionDigits={0}
                  />
                  <Link
                    className="stretched-link"
                    to={`${AppRoutes.protected.FARMING.children.PREFORECLOSURES.path.replace(
                      ':zip',
                      zip
                    )}`}
                  />
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.is_lowEquity" />
                  <Tooltip
                    className="zi-2"
                    content={formatMessage({
                      id: 'app.toolTips.is_lowEquity',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>
                <Card.Title as="h2" className="text-inherit">
                  <FormattedNumber
                    value={apiData[0].low_equity}
                    // eslint-disable-next-line react/style-prop-object
                    minimumFractionDigits={0}
                    maximumFractionDigits={0}
                  />
                  <Link
                    className="stretched-link"
                    to={`${AppRoutes.protected.FARMING.children.LOW_EQUITY.path.replace(
                      ':zip',
                      zip
                    )}`}
                  />
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.is_probates" />
                  <Tooltip
                    className="zi-2"
                    content={formatMessage({
                      id: 'app.toolTips.is_probates',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>
                <Card.Title as="h2" className="text-inherit">
                  <FormattedNumber
                    value={apiData[0].probates}
                    // eslint-disable-next-line react/style-prop-object
                    minimumFractionDigits={0}
                    maximumFractionDigits={0}
                  />
                  <Link
                    className="stretched-link"
                    to={`${AppRoutes.protected.FARMING.children.PROBATES.path.replace(
                      ':zip',
                      zip
                    )}`}
                  />
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.is_tiredLandlords" />
                  <Tooltip
                    className="zi-2"
                    content={formatMessage({
                      id: 'app.toolTips.is_tiredLandlords',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>
                <Card.Title as="h2" className="text-inherit">
                  <FormattedNumber
                    value={apiData[0].tired_landlords}
                    // eslint-disable-next-line react/style-prop-object
                    minimumFractionDigits={0}
                    maximumFractionDigits={0}
                  />
                  <Link
                    className="stretched-link"
                    to={`${AppRoutes.protected.FARMING.children.TIRED_LANDLORDS.path.replace(
                      ':zip',
                      zip
                    )}`}
                  />
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="3" className="mb-3 mb-lg-5">
            <Card className="card-hover-shadow h-100">
              <Card.Body>
                <Card.Subtitle
                  as="h6"
                  className="d-flex justify-content-between"
                >
                  <FormattedMessage id="app.common.is_intraFamily" />
                  <Tooltip
                    className="zi-2"
                    content={formatMessage({
                      id: 'app.toolTips.is_intraFamily',
                    })}
                  >
                    <i className="bi-info-circle-fill" />
                  </Tooltip>
                </Card.Subtitle>
                <Card.Title as="h2" className="text-inherit">
                  <FormattedNumber
                    value={apiData[0].intra_family}
                    // eslint-disable-next-line react/style-prop-object
                    minimumFractionDigits={0}
                    maximumFractionDigits={0}
                  />
                  <Link
                    className="stretched-link"
                    to={`${AppRoutes.protected.FARMING.children.INTRAFAMILY_TRANSFERS.path.replace(
                      ':zip',
                      zip
                    )}`}
                  />
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
}

TableRealEstateUSANumbers.propTypes = {
  zip: PropTypes.string.isRequired,
};

export default TableRealEstateUSANumbers;
