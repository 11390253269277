import React from 'react';
import { useParams } from 'react-router-dom';
import { Badge, ListGroup } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { SmartSteps } from '../../../components';
import Constants from '../../../constants';

function Notes() {
  const { id } = useParams();

  const formatter = (data) => {
    let priority;
    if (data.Priority) {
      switch (Math.round(data.Priority)) {
        case 1:
        case 2:
          priority = Constants.Review.Priority.Low;
          break;

        case 4:
        case 5:
          priority = Constants.Review.Priority.High;
          break;

        case 3:
          priority = Constants.Review.Priority.Medium;
          break;

        default:
          priority = null;
          break;
      }
    }

    let type;
    if (data.Rating) {
      switch (Math.round(data.Rating)) {
        case 1:
        case 2:
          type = Constants.Review.Types.Negative;
          break;

        case 4:
        case 5:
          type = Constants.Review.Types.Positive;
          break;

        case 3:
          type = Constants.Review.Types.Neutral;
          break;

        default:
          type = null;
          break;
      }
    }

    const { UserName, UserSurname, Comment, Date, ReviewID, UserPP } = data;

    const formatted = {
      original: data,
      id: ReviewID,
      title: `${UserName} ${UserSurname}`,
      content: (
        <>
          <ListGroup className="list-group-sm mb-2">
            <ListGroup.Item className="list-group-item-light">
              <span className="d-block fs-6 text-dark">{Comment}</span>
            </ListGroup.Item>
          </ListGroup>
          <div className="d-flex flex-column flex-sm-row justify-content-between">
            <div>
              {priority && (
                <Badge
                  bg={`soft-${priority.color}`}
                  text={priority.color}
                  className="mt-2 me-1"
                >
                  <i className={`${priority.icon} me-1`} />
                  <FormattedMessage id={`app.common.${priority.labelKey}`} />
                </Badge>
              )}
              {type && (
                <Badge bg={`${type.color}`} className="mt-2">
                  <i className={`${type.icon} me-1`} />
                  <FormattedMessage id={`app.common.${type.labelKey}`} />
                </Badge>
              )}
            </div>
            <div className="mt-2">
              <span className="small text-muted">{moment(Date).fromNow()}</span>
            </div>
          </div>
        </>
      ),
      color: priority?.color ? `soft-${priority?.color}` : undefined,
      icon: UserPP,
    };

    return formatted;
  };

  return (
    <SmartSteps
      paths={{
        list: '/reviews/list',
        listParams: { RefID: id },
        create: '/reviews/insert',
        createParams: { RefID: id },
        delete: '/reviews/delete',
        update: '/reviews/update',
      }}
      params={{ RefTable: 'admin_operism.businesses' }}
      formatter={formatter}
      type="avatar"
    />
  );
}

export default Notes;
