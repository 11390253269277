import React, { useContext, useRef } from 'react';
import { yup } from '../../../../lib';
import { SmartForm } from '../../../../components';
import { AuthContext } from '../../../../context/AuthContext';

function SettingsBasicSocial() {
  const formRef = useRef();
  const { user, update } = useContext(AuthContext);

  const fields = [
    {
      cols: [
        {
          key: 'Linkedin',
          apiKey: 'Linkedin',
          schema: yup.string().nullable(),
        },
      ],
    },

    {
      cols: [
        {
          key: 'Facebook',
          apiKey: 'Facebook',
          schema: yup.string().nullable(),
        },
      ],
    },
    {
      cols: [
        {
          key: 'Instagram',
          apiKey: 'Instagram',
          schema: yup.string().nullable(),
        },
      ],
    },
    {
      cols: [
        {
          key: 'Twitter',
          apiKey: 'Twitter',
          schema: yup.string().nullable(),
        },
      ],
    },
    {
      cols: [
        {
          key: 'Youtube',
          apiKey: 'Youtube',
          schema: yup.string().nullable(),
        },
      ],
    },
  ];

  const onRequestSuccess = (data) => {
    const payload = {
      user: data.Result,
    };
    update(payload);
    const { Facebook, Linkedin, Twitter, Youtube, Instagram } = payload.user;
    formRef.current?.reset({ Facebook, Linkedin, Twitter, Youtube, Instagram });
  };

  return (
    <div>
      <SmartForm
        ref={formRef}
        isHorizontal
        fields={fields}
        submitButtonText="saveChanges"
        requestUrl="/users/update"
        onRequestSuccess={onRequestSuccess}
        requestParams={{ user_type: 0, id: user.id }}
        defaultValues={
          user
            ? {
                Facebook: user.Facebook,
                Linkedin: user.Linkedin,
                Twitter: user.Twitter,
                Youtube: user.Youtube,
                Instagram: user.Instagram,
              }
            : {}
        }
      />
    </div>
  );
}

export default SettingsBasicSocial;
